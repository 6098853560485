import { createContext } from 'preact';

// export interface AppState {
//   authenticated: boolean;
//   language: string;
// }

// export const AppContext = createContext<AppState>({
//   authenticated: false,
//   language: 'en',
// });

export type Language = 'en' | 'es' | 'fr';

export enum PrivLevelEnum {
  normal = 1,
  moderator,
  siteAdmin,
}

export interface UserData {
  username: string | undefined;
  privLevel: PrivLevelEnum | undefined;
  authenticated: boolean | undefined;
  errorMode: number;
}
export interface AppState {
  userData: UserData | undefined;
  language: Language;
}
/*  Turn auth on/off for debugging IN INDEX.TSX */
/*  userData errorMode:  0==blacklisted  1===acctAlreadyExists  2===no problems, all is normal */
export const AppContext = createContext<AppState>({
  language: 'en',
  userData: {
    username: undefined,
    privLevel: undefined,
    authenticated: undefined,
    errorMode: 2,
  },
});

export interface AppSettingsData {
  allowNoLoginDL: boolean;
}

export interface ManageUserData {
  email: string;
  approved: boolean;
  privLevel: PrivLevelEnum;
  language: Language;
  dtLastLogin: string;
  password: string;
  regIP: string;
  loginIPs: string;
  dtCreated: string;
}

export interface CMSContent {
  id: string;
  html: string;
}
