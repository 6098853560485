import { h, FunctionalComponent } from 'preact';
import style from './userregistrationreceived.scss';
//import { useState } from 'preact/hooks';
//import { Redirect } from 'wouter-preact';

interface UserRegistrationReceivedProps {
  email: string;
  name: string;
}

export const UserRegistrationReceived: FunctionalComponent<UserRegistrationReceivedProps> = ({ name, email }) => {
  //const [varName, setvarName] = useState('');
  const nname = name.replace(/\%20/g, ' ');
  return (
    <div class={style.userRegistrationReceivedDiv}>
      <div class={style.greeting}>
        Hello <span class={style.fgBlue}>{`${nname}`}</span>,
      </div>
      <div class={style.confirmation}>
        Thank you for registering for a Flottec account from email address: <span class={style.fgBlue}>{`${email}`}</span>
        {/* Thank you for registering <span class={style.fgBlue}>{`${email}`}</span> for a Flottec account. */}
      </div>
      <div class={style.message}>
        We <span class={style.fgBlue}>confirm receipt</span> of your user account registration request and have added it to our processing list.
      </div>
      <div class={style.message}>
        Due to workload, please allow up to <span class={style.fgBlue}>1</span> full business day for processing. If urgent, please{' '}
        <a
          class={style.urgentEmailA}
          href={
            'mailto:service@flottec.com?subject=Immediate%20access%20to%20product%20datasheets&body=We%20have%20an%20immediate%20need%20to%20view%20the%20material%20safety%20datasheets%20for%20Flottec%20product%20[which%20product?].%0D%0A%0D%0ATo%20discuss,%20please%20telephone%20me%20at%20[(area%20code)%20telephone%20number].%0D%0A%0D%0AThank%20you,%0D%0A[name]%0D%0A[company]'
          }
        >
          email
        </a>{' '}
        or telephone <span class={style.fgBlue}>1.713.425.7055</span>
      </div>
      <div class={style.closing}>
        <div class={style.ty}>Thank you,</div>
        <div>Flottec Network Services,</div>
        <div>Main Corporate Office</div>
      </div>
    </div>
  );
};
