.backdropDiv {
  position: relative;
  left: 0;
  min-height: 95vh;
  width: 100vw;
  .categoriesDiv {
    z-index: 2;
    xxxposition: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 95vh;
    overflow: auto;
    color: #444 !important;
    padding: 0 12vw;
    background: rgba(230, 230, 230, 0.85);
    & > p {
      padding: 0;
    }
    & > p > div > p {
      padding: 0;
      font-size: calc(8px + 1vw);
    }
    .catTitle {
      padding-top: 55px;
      font-size: calc(10px + 1vw);
    }
    .buttonsDiv {
      padding: 5vh;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
      button {
        width: 300px;
        margin: 6px;
        padding: 2vh 0;
        border: 1px solid rgba(100, 100, 100, 0.3);
      }
    }
  }
}
.pdfLinkDiv {
  & > div {
    display: inline-block;
    padding: 2px 20px;
    margin-right: 30px;
    border: 1px solid rgb(150, 150, 150, 0.5);
    border-radius: 5px;
    &:hover {
      background: rgba(200, 200, 200, 0.3);
    }

    a {
      color: #4774b3; // #444;
      text-decoration: none;
      &:hover {
        color: #0068ff;
        // text-decoration: underline;
      }
    }
  }
}
.lookLikeAnchor {
  color: #3f51b5;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.catPDF {
  display: inline-block;
  padding: 0 2vw;
  font-size: calc(6px + 1vw);
  a {
    color: #3f51b5;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.mustEmailDlg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  font-size: 5rem;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .mustEmailInner {
    position: relative;
    max-width: 60vw;
    padding: 10vh;
    font-family: 'Segoe UI Light', 'Segoe UI', Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    background: white;
    .meCloseX {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 2rem;
      padding: 0 15px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      &:hover {
        color: #3f51b5;
        border: 1px solid #3f51b5;
      }
    }
    .meBody {
      text-align: center;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 415px) {
  .buttonsDiv {
    button {
      width: 200px !important;
    }
  }
}
