.companyDiv {
  padding: 5vh 12vw;
  color: #444 !important;
  background: white;
  font-family: 'europa', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  img {
    border-radius: 10px;
    @media (max-width: 999px) {
      width: 100%;
    }
    @media (min-width: 1921px) {
      width: 80%;
    }
  }
  p > div > p:nth-child(5) {
    text-align: center;
  }
  p > div > p:nth-child(8) {
    text-align: center;
  }
  p > div > p:nth-child(9) {
    text-align: center;
  }
  p > div > p:nth-child(10) {
    text-align: center;
  }
}
.pageText {
  h1 {
    margin: calc(4px + 1vw) 0 0 0;
    color: rgb(0, 102, 204);
  }
  h2 {
    color: rgb(0, 102, 204);
  }
  p {
    margin: 0;
    padding: 0 0 2rem;
    font-size: calc(6px + 1vw);
  }
}
@media (max-width: 1200px) {
  .pageText p {
    font-size: calc(9px + 1vw);
  }
}
