:global(#nav-client-logo) {
  height: 75%;
  margin-top: 7px;
  margin-left: 7px;
  float: left;
  :global(#mxLogo) {
    max-height: 100%;
  }
}

.logoLeft {
  display: flex;
  position: absolute;
  width: 30vw;
  & > div {
    position: relative !important;
  }
}
.navHeader {
  z-index: 8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* 12px is width of scrollbar set in index.scss */
  height: 45px;
  background: #111;
  display: grid;
  grid-template-columns: 3.5fr 2.5fr 4fr;
  grid-template-areas: 'logosrx flags navlock';
  .logoLeft {
    grid-area: logosrx;
    display: flex;
    position: absolute;
    width: 30vw;
    height: 100%;
    align-items: center;
    .clientLogoALink {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding-left: 20px;
      margin-right: 2vw;
      img {
        height: 1.3vw;
        max-height: 37px;
        min-height: 17px;
      }
    }
  }
  .flagsLangDiv {
    grid-area: flags;
    width: fit-content;
    height: 100%;
    padding-right: 4.3vw;
    display: flex;
    .flagsDiv {
      display: flex;
      align-items: center;
      padding-top: 5px;
      padding-right: 2.25vw;
      a {
        margin-right: 0.75vw;
        border-radius: unset;
        img {
          width: 3vw;
          height: 1.8vw;
          max-width: 55px;
          max-height: 30px;
          border-radius: unset;
        }
      }
    }
    .langDiv {
      display: none;
    }
  }
  nav {
    grid-area: navlock;
    height: 100%;
    padding-top: 0.4vw;
    text-align: right;
    font-size: 100%;
    a {
      margin: 0;
      // display: inline-block;
      min-width: 3.75vw;
      height: 100%;
      // line-height: 56px;
      padding: 0 0.5vw;
      text-align: center;
      vertical-align: middle;
      font-size: calc(5px + 0.8vw);
      text-decoration: none;
      color: #2fc4dc;
      background: transparent;
      will-change: background-color;
      vertical-align: middle;
      cursor: pointer;
      &:hover,
      &:active {
        background: rgba(0, 0, 0, 0.2);
      }
      svg {
        width: 1.25vw;
      }
    }
  }
}

.active {
  background: rgba(255, 255, 255, 0.15) !important;
}
@media (max-width: 599px) {
  .flagsDiv,
  .langDivText {
    display: none !important;
  }
}
@media (max-width: 999px) {
  .navHeader {
    grid-template-columns: 1fr 0 3fr !important;
  }
}
