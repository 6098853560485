import { h, FunctionalComponent } from 'preact';
import style from './svgMap.scss';
import logoFlottecMexico from 'url:../../../../public/img/Flottec_LOGO_Mexico_175.png';
import logoFlottecChile from 'url:../../../../public/img/Flottec_LOGO_Chile_175.png';
import logoFlottecUsa from 'url:../../../../public/img/Flottec_LOGO_USA_175.png';
import logoChemiqaLite from 'url:../../../../public/img/Flottec_LOGO_Canada_175.png';

interface SvgMapProps {
  inChemiqa(): void;
  inFlotUsa(): void;
  inFlotMex(): void;
  inFlotChil(): void;
}

export const SvgMap: FunctionalComponent<SvgMapProps> = ({ inChemiqa, inFlotUsa, inFlotMex, inFlotChil }) => {
  return (
    <div class={style.svgMapDiv}>
      <svg
        id='Layer_1'
        class={style.svgNetworkMap}
        xmlns='//www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 1193.9 624.8'
        style='enable-background:new 0 0 1193.9 624.8;'
      >
        <g id='map'>
          <g id='regions'>
            <g id='Africa'>
              <path
                id='SouthAfrica'
                class={style.st2}
                d='M628.4,445.4l0.9-0.5l0.9-0.3h1.2l0.9-0.4l1.2-0.5l1.1,0.2l1-0.3l1.1,0.1l1.1,0.3l1,0.2l0.9-0.6l1,0.2
               l0.5-0.9l1.1,0.1l1-0.3l0.9-0.4l0.9-0.7l0.9-0.7l0.8-0.7l0.8-0.7l0.8-0.7l0.8-0.9l0.8-0.9l0.8-0.7l0.9-0.8l0.6-0.8l0.6-0.9
               l0.6-0.9l0.6-0.9l0.5-1l0.9-1.1l1.3-1l0.9-0.9l0.3-0.9l0.3-1l0.6-1.7l0.3-1l-1.3,0.1l-0.5-0.1l-0.5,0.9l-1,0.4l-0.9-0.6l-0.4-0.9
               l-0.1-1.1l0.6-0.9l0.7-0.9l0.9,0.4l0.7-1l0.1-1.1l0.1-1.3v-1l-0.2-1.1l-0.4-1l-0.2-1.1l-0.3-1.4l-0.2-0.9l-0.6-0.2h-1l-1.2-0.2
               l-1-0.4l-0.7,0.2l-0.9,0.6l-0.9,0.5l-1,0.4l-0.6,0.9l-0.9,0.6l-0.9,0.7l-0.7,0.8l-0.3,1l-0.8,0.9l-1.4,0.8l-0.5,1l-0.4,1l-0.7,0.9
               l-1.1,0.2l-1-0.3l-1-0.3l-0.9-0.6l-0.9-0.4l-1,0.5l-0.4,0.9l-0.4,0.9l-0.8,0.7l-0.7,0.9l-0.9,0.6h-1.1h-1.1l-0.2-1l0.5-0.9l-0.1-1
               l-0.3-1l-0.4-0.9l-0.7-0.8l-0.3-0.3v1.1l-0.1,1.1v1.1l-0.1,1.1l-0.1,1.1v1.1l-0.1,1.1v1.1l-0.1,1.1l-0.9,0.7l-0.9,0.7l-1.2,0.2
               l-1,0.2l-1.3-0.4l-0.9-0.5l-0.4-1.1l-0.9,0.3l-0.7,0.9v0.1v0.3l0.5,0.9l0.4,1l0.3,1l0.3,1.1l0.3,1l0.4,1l0.5,1.1l0.6,0.9l0.4,1
               l0.1,1l-0.3,1.1l-0.9,0.8l0.5,0.9l0.5,0.9v1l0.2,1.1l0.8-0.7l0.7,0.8l0.7,0.8l1,0.3L628.4,445.4z M647.1,434l-1.1-0.3l-0.4-0.9
               l-0.6-1.2l0.7-0.9l0.7-0.9l0.7-0.7l0.9-0.5l0.2-0.1l0.9,0.1l0.7,0.8l0.7,0.9l-0.3,0.9l-0.5,0.9l-1.1,0.5l-0.9,0.4L647.1,434z'
              ></path>
              <path
                id='Zambia'
                class={style.st2}
                d='M646.9,397.7l1.2-1.5l0.6-0.9l1-0.6l1.3-0.7l0.2-1l0.7-0.8l0.9-0.4l1.1-0.2l0.8-1l0.3-0.9l1-0.5l1.2-0.3
               l1.2-0.5l1-0.4l1.3-0.5l1.1-0.4l-0.9-0.6l0.2-1.1l0.3-1.1l0.2-1.1l0.9-0.4l-0.3-1l0.2-1.4l0.2-1.1l-0.3-1l0.9-0.6l-0.1-1.1
               l-0.4-0.9l-0.5-0.9l-1.4-0.9l-0.9-0.5L659,373l-0.8-0.9l-1-0.2l-0.6-1l-1,0.2l-1.1,0.2l-1.2,0.3l-1.1,0.2l-0.4,1.3l-0.9,0.8
               l0.4,0.9l0.1,1.1l-0.1,1l0.2,1.1l-0.5,0.9l-0.2,1v1.2l0.8,0.8l0.6,0.9l1,0.3l0.7-0.8l0.2,1.1l-0.1,1.4v1.1l-1.2-0.3l-0.9-0.5
               l-0.6-0.9l-0.5-0.9l-1-0.4l-1-0.3l-0.8-1.3l-1,0.5l-1.1-0.2l-1-0.2l-0.9-0.3l-0.6-1l-1.1-0.3l-1,0.4l-0.2-1.1l-0.9-0.6l0.1,1.2
               l-0.2,1v1l0.1,1l-0.3,1.1l-1,0.7h-4l-0.1,1.3v1.3v1.3l-0.1,1.3v1.3v1.1l-0.1,1.1l0.3,1l0.6,0.9l0.7,0.8l0.8,0.9l0.8,0.7l1.2,0.1
               l1-0.3l1.1,0.1l0.9,0.3l1,0.7l0.9,0.3l1-0.2l1,0.3L646.9,397.7z'
              ></path>
              <path
                id='Zimbabwe'
                class={style.st2}
                d='M656.9,410l0.9-0.9l0.8-0.9l0.4-0.9l0.3-1l0.5-0.9l0.5-1l0.3-1l-0.4-0.9l0.2-1l0.3-1l0.3-1l-0.2-0.9l0.3-1
               l-0.1-1.1l-0.1-1l-0.8-0.9l-1.3-0.3l-1.2-0.7l-0.9-0.6h-1.3l-1.4-1l-1.1,0.2l-0.9,0.4l-0.7,0.8l-0.2,1l-1.3,0.7l-1,0.6l-0.6,0.9
               l-1.2,1.5l-0.9,0.7l-1-0.3l-1,0.2l-0.9-0.3l-0.3,0.9l0.5,0.9l0.7,1.2l0.4,1.6l0.9,0.8l0.9,0.7l0.9,0.7l0.8,0.7l0.2,1l0.3,1.1
               l0.4,0.9l0.9,0.3l1.1,0.3l0.5,0.9l0.5,0.3l0.7-0.2l1,0.4l1.2,0.2h1l0.6,0.2L656.9,410z'
              ></path>
              <path
                id='Tanzania'
                class={style.st2}
                d='M673.3,380.2l0.7-0.9l0.9,0.4l0.9-0.6l1-0.1l1.2-0.6l0.9-0.7l0.8-0.7l-0.1-0.1l-0.7-0.8l-0.9-0.4v-1l-0.2-1
               l-0.2-1l-0.4-1l0.1-1.1l-0.2-1.1l0.1-1l0.5-0.9l-0.6-0.8l-0.6-0.9l-0.4-0.9l0.1-1l0.3-1l0.3-1l0.3-1l-0.9-0.7l-0.8-0.8l-0.9-0.7
               l-0.9-0.7l-0.4-0.9l-0.2-1.1l-1-0.7l-1-0.7l-1.1-0.6l-1-0.7l-1-0.7l-1-0.7l-1-0.7l-1.1-0.7l-0.5-0.3h-6.5h-1l-0.3,1.1l0.3,1
               l0.1,1.3l-1,1.5l0.9,0.7l-0.4,0.9l-0.6,1l-0.5,1l-0.7,0.9l-1.2,0.3l-0.3,1.1l0.3,1.1l0.3,1.1l-0.3,1l0.3,1l0.7,1.1l0.9,0.9
               l0.4,1.1l0.6,1.4l0.2,0.8l0.6,1l1,0.2l0.8,0.9l0.9,0.4l0.9,0.5l1.3,0.5l0.9,0.5l1.1,0.2l1,0.1l0.5,0.9l0.1,1l0.2,1v1.1l0.6,0.9
               l0.1,0.3h1.1l0.9-0.4l0.9,0.5l1.1,0.3l0.9-0.3l1,0.4L673.3,380.2z'
              ></path>
              <path
                id='Uganda'
                class={style.st2}
                d='M655.5,350.8l1.3-0.4h1h6.5V349l0.1-1l0.3-1.3l0.4-0.9l0.6-0.9l0.8-0.8l0.3-0.9l0.2-1l-0.3-1.2l-0.3-1.2
               l-0.5-0.9l-0.4-0.9l-0.3-1l-0.4-0.3l-0.3-0.9l-0.9,1l-1,0.3h-1l-1.4,0.4l-0.9-0.5l-1.1,0.2l-1,0.1l-0.3,0.6l-0.2,1.2v1.1l0.7,0.9
               l0.3,1l-0.9,1l-0.9,1l-0.7,0.9l-0.7,0.9l-0.3,1.4l-0.3,1.3l-0.2,1.2l-0.1,1v0.9l1.1,0.1L655.5,350.8z'
              ></path>
              <path
                id='Rwanda'
                class={style.st2}
                d='M654.5,355.2l0.1-1h1l1.3-0.3l-0.1-1.3l-0.3-1l-0.9-0.7l-0.7,0.8l-1.1-0.1l-0.8,0.7l-0.3,1.3l-0.6,0.9
               l0.3,0.9l1.1,0.3L654.5,355.2z'
              ></path>
              <path
                id='SaharaDesert'
                class={style.st2}
                d='M541.4,288.1l1.2-0.5h1.3h1.5h1.6h1.5h1.2h1.2l-0.1-1.9v-1.4l-0.1-1.1l0.9-0.8l0.9-0.4l0.9-0.5l0.1-1.1v-1.1
               v-1l0.1-1.1v-1v-1.1l0.9-0.6h6l1-0.3v-1.3v-1.3v-0.8l-0.3-0.1l-0.9,0.6l-1.1,0.5l-1.1,0.1l-0.9-0.3H556l-0.7,0.9l-0.6,0.9
               l-0.8,0.8l-0.6,0.9l-0.3,1.4l-0.3,1.1l-0.8,0.7L551,279l-0.5,1l-0.9,0.6l-0.8,0.9l-0.3,1.2l-0.3,1.4l-0.3,1.1l-0.6,0.8l-0.5,1.2
               l-1.3,0.2l-1-0.2l-1.1,0.1h-1.3l-0.7,0.2v0.3l-0.2,1.5L541.4,288.1z'
              ></path>
              <path
                id='Sudan'
                class={style.st2}
                d='M640.6,323l0.8-0.7l0.3-1.2l0.3-1.2l0.4-0.9l1-0.4h1.1l0.7,1l1.1,1.4l1.1-0.3h1.4l1,0.8l1.9-0.3l1-0.7
               l0.9-1.1l0.9-0.5l1.1,0.9l0.9,0.7l1-0.2l1-1.2l0.6-1.3l0.9-1.8v-1.2l-0.7-0.8l1.5-0.6l1.2,0.3l-0.3,1.5l0.3,2.1l0.8,0.8l1.2,2
               l0.3,1.1l0.3-1.6l0.1-1.3l0.6-1.1l1-0.8l0.2-1.1l0.3-1l0.4-0.9l0.4-0.9l1-0.4l0.4-1.8l0.5-1.3l0.1-1.3l-0.2-1.2l-0.2-1.2l0.4-0.9
               l0.3-1.1l0.3-1.1l-0.1-1l0.1-1l1-0.3l0.6-0.9l0.9-0.5l0.9-0.9l0.3-0.4l-0.4-0.6l-0.9-0.6l-0.8-0.8l-0.9-0.6l-0.3-1l-0.2-1.3
               l-0.3-1v-1.2l-0.3-1l-0.2-1.2l-0.5-0.9l-0.2-1h-1.1h-1.1h-1.1h-1.1h-1.1h-0.5h-1.1h-1.1H657l-0.3-0.3l-1.1,0.3h-1.1h-1.2h-1.1
               h-1.3h-1.1h-1.2h-1.2h-1.1h-1.2H644h-1.2h-1.1h-0.5l0.1,1v1l0.1,1.1v1l0.1,1.1l-1.2,0.3h-1.2v1l0.1,0.4v1.3l0.1,1.3v1.4l0.1,1.3
               v1.4l0.1,1.3v1.3l0.1,1.4l-0.3,0.1l-1.3,0.2l-0.9,0.6v1l-0.6,0.9l-0.5,0.9v1.2l-0.7,1l0.4,1l-0.9,0.8l0.7,0.9l0.5,1l0.5,1.1v1.3
               l0.9,0.6l-0.2,1.2l0.6,0.9l1.4,2l-0.1,1l-0.2,1v1l1,0.3L640.6,323z'
              ></path>
              <path
                id='SouthSudan'
                class={style.st2}
                d='M657.1,337.2l1-0.1l1.1-0.2l0.9,0.5l1.4-0.4h1l1-0.3l0.9-1l0.8-0.9l0.8-0.9l0.8-0.9l0.9-0.9l-0.8-1l-0.3-1.1
               l-0.3-1.1l-0.8-0.9l-0.8-0.7l-0.6-1l-0.9-0.5l-1-0.2l0.3-1l0.7-0.9l1.1,0.1l0.3-2.9l-0.3-1.1l-1.2-2l-0.8-0.8l-0.3-2.1l0.3-1.5
               l-1.2-0.3l-1.5,0.6l0.7,0.8v1.2l-0.9,1.8l-0.6,1.3l-1,1.2l-1,0.2l-0.9-0.7l-1.1-0.9l-0.9,0.5l-0.9,1.1l-1,0.7l-1.9,0.3l-1-0.8
               h-1.4l-1.1,0.3l-1.1-1.4l-0.7-1h-1.1l-1,0.4l-0.4,0.9l-0.3,1.2l-0.3,1.2l-0.8,0.7l-0.3,1.1l0.9,0.4l0.9,0.3l0.9,0.7v1.1l0.9,0.7
               l0.9,0.5l0.7,0.9l0.3,1l0.5,0.9l0.9,0.5l0.8,0.7l0.1,1.1l0.5,0.5l0.3,0.4l0.2,0.1l0.5,0.9l0.9,0.6l0.4,0.2h0.1l0.8-0.7l0.9,0.3
               l0.9-0.7l0.8,0.8l0.8,0.8l0.9,0.7l0.3,0.8l0,0l0.7,0.3L657.1,337.2z'
              ></path>
              <path
                id='Senegal'
                class={style.st2}
                d='M542.5,312.7h1.4l1-0.6l1.2-0.2h1.7l0.9,0.1h1.1l1,0.4l0.9,0.3h1.1l1.2-0.1l0.3-1l-0.3-1l-0.9-0.6l-0.5-1
               l0.3-1.1l-0.5-0.9l-0.1-0.9l-0.9-0.9l-0.5-0.8L550,303l-0.5-0.9l-1-0.2l-0.7-0.8l-0.9-0.3l-1-0.1l-1,0.2l-1.1,0.2l-1,0.2l-0.3,1
               l-0.2,0.9l-0.3,0.7l-0.5,0.9l-0.7,0.9l-0.9,0.5l1,0.6l0.5,1l0.9,0.5l-0.2,1.1h1h1l0.9-0.5h1l0.9,0.5l1,0.1l-0.5,0.9l-1-0.3
               l-0.9-0.6l-0.9,0.5l-0.9,0.6h-1.1l-1.1,0.2v0.2l0,0l-0.1,1l1,0.3l0.9-0.2h1.1l-1,0.1l-1.1,0.2l-0.9,0.6L542.5,312.7z'
              ></path>
              <path
                id='SierraLeone'
                class={style.st2}
                d='M554.7,327.3l0.8-0.8l0.7-1.4l0.8-0.9l0.1-0.5l-1,0.4l0.5-0.9l-0.3-1.1l-0.4-1l-0.5-0.9l-0.4-0.5l-1.8-0.3
               l-1.3,0.3l-0.5,1l-0.7,0.8l-0.9,0.6l-0.1,0.1l0.2,0.6l0.9,0.7l-0.9,0.3l0.7,0.7l0.2,1.1l0.9,0.6l0.6,0.9l1.1,0.5l0.9,0.7
               L554.7,327.3z'
              ></path>
              <path
                id='Togo'
                class={style.st2}
                d='M584.8,330.5l0.9-0.3v-1l-0.2-1.2l0.3-1.2v-1.2v-1.2l-0.1-1.3l-0.1-1l-0.4-1l-0.1-1.2l-1-1l-0.3-1l-1.2-1.3
               l-0.9,0.8l0.6,1.1l0.5,0.9l-0.1,1l0.5,1l-0.3,1v1.1l0.7,0.8l-0.3,1l-0.2,1l0.3,1.1l-0.3,1l0.5,0.9l0.7,0.8L584.8,330.5
               L584.8,330.5z'
              ></path>
              <path
                id='Tunisia'
                class={style.st2}
                d='M605.1,261.6l0.6-1l-0.3-1.1l0.2-1.2l0.8-0.8l2-1.2V255v-1l-0.9-0.6l-1-0.3l-0.9-0.6l-0.7-1.1l0.6-0.9
               l0.8-0.7l0.6-0.9l0.4-0.9l-0.3-1.1l-0.9-0.5l-0.3-1l0.8-0.8l-0.1-1.3l-1,0.5l-0.3-1l-0.9-0.5l-1,0.1l-0.9,0.4l-0.9,0.5l-0.7,1v1
               l-0.1,1.1l0.2,1.1v1l-0.2,1l-0.8,0.9l-0.6,0.9l-0.2,1l0.4,0.9l0.7,0.9l0.8,0.8l0.4,1.2l0.9,0.6l0.6,0.9l0.3,1.3l0.3,1.2l0.3,1.3
               l0.2,0.9L605.1,261.6z'
              ></path>
              <path
                id='NorthSomalia'
                class={style.st2}
                d='M698.2,325.1l0.5-1l0.6-1l0.5-1l0.5-1v-1.3l-0.1-1.3v-1.2l-0.1-1.3l-0.4-0.2l-1.1,0.2l-1,0.3l-1.3-0.2
               l-0.8,0.7l-1.1,0.6l-1-0.2l-1-0.2l-0.9,0.5l-0.9,0.7h-1.2l-0.9-0.5l-0.9-0.9l-0.6-0.9l-0.7-0.8l-0.7,1.4l-0.5,0.9l0.3,1.1l0.6,0.9
               l0.6,0.9l0.7,0.9l0.9,0.8l1.1,0.5l1.1,0.4l1,0.4l1.1,0.4l1,0.3l1,0.4l0.9,0.3l1.1,0.1h1.2h0.5V325.1z'
              ></path>
              <path
                id='Somalia'
                class={style.st2}
                d='M683.3,351.7l0.7-1.3l0.6-0.9l0.9-1.1l0.7-0.9l1-1.1l1-1.3l0.8-0.7l0.8-0.9l1.2-1l0.9-0.6l1.4-0.9l0.8-0.8
               l0.9-0.9l0.8-0.9l0.9-1.2l0.9-1.2l1.2-1.5l0.6-1.3l0.8-1.2l0.4-0.9l0.5-1.7l0.5-1.3l0.7-1.2l0.5-1.4l0.7-0.9l0.4-1l0.3-0.9
               l0.5-0.9l0.3-1l0.2-1v-1.2l1.1-0.2l-0.6-0.8l-0.1-1.4v-1l-0.3-0.9l-1.1-0.1l-0.7,0.9l-1,0.4l-1,0.3l-1.1,0.3l0.1,1.3v1.2l0.1,1.3
               v1.3l-0.5,1l-0.5,1l-0.6,1l-0.5,1l-0.7,0.8l-0.7,0.9l-0.7,0.8l-0.8,0.8l-0.7,0.9l-0.8,0.9l-0.9,1l-0.8,1l-0.8,0.9l-0.8,0.8
               l-1.1-0.1h-1l-1.1,0.3l-0.9,0.6l-0.6,0.9L685,336l-1.2,0.3l-0.1,0.3l-0.6,1l-0.6,0.9l-0.7,0.9l-0.3,1.3v1.1v1.4l0.1,1v1v1.1v1v1.3
               v1.1l0.3,0.9l0.6,0.9l0.4,0.9L683.3,351.7z'
              ></path>
              <path
                id='EswatiniAfrica'
                class={style.st2}
                d='M656.8,424.3l0.5-0.9l-0.1-1.2l0.1-1.1l-0.8-0.3l-0.9-0.4l-0.7,0.9l-0.6,0.9l0.1,1.1l0.4,0.9l0.9,0.6
               L656.8,424.3z'
              ></path>
              <path
                id='Chad'
                class={style.st2}
                d='M621.6,326.1l1,0.3l0.9-0.6l0.9-0.5l1-0.2l1.3-0.1l0.9-0.9l0.5-0.9l0.3-0.9l1-0.1l1-0.2l1-0.2l0.8-0.7
               l0.5-0.9l0.9-0.7l0.7-0.8l0.1-1.1l1-0.5l0.9-0.5l0.9,0.3l0.2-1.2l-0.9-0.6v-1.3l-0.5-1.1l-0.5-1l-0.7-0.9l0.9-0.8l-0.4-1l0.7-1
               v-1.2l0.5-0.9l0.6-0.9v-1l0.9-0.6l1.3-0.2l0.3-0.1l-0.1-1.4v-1.3l-0.1-1.3v-1.4l-0.1-1.3V295l-0.1-1.3v-1.3l-0.9-0.6l-0.9-0.5
               l-0.9-0.5l-0.9-0.5l-0.9-0.5l-0.9-0.5l-0.9-0.5l-0.9-0.5l-0.9-0.5l-0.9-0.5l-0.9-0.5l-0.9-0.5l-0.9-0.5l-0.9-0.6l-0.9-0.5
               l-0.9-0.5l-0.9-0.5l-1-0.3l-0.9-0.5l-0.9-0.5l-0.9-0.5h-1.1l-0.9,0.5l-0.9,0.4l0.3,1.1l0.2,1l0.2,1.1l0.1,1.1l0.6,0.9l0.3,1
               l0.7,0.9l0.1,1l-0.3,1l-0.1,1.1v1l-0.1,1l-0.1,1v1l-0.1,1v1l-0.2,1.1l-0.7,0.8l-0.7,0.9l-0.7,0.9l-0.6,0.8l-0.5,0.9l-0.6,0.9
               l-0.3,1l-0.5,0.9l0.3,1.1l1.3,2.4l0.9,0.3l0.3,0.6l0.7,0.8l0.3,1.1l0.4,0.9l-0.1,1l-0.1,1.2l0.3,1l0.8,1.3l-0.9,0.6l-1.5-0.1
               l-1.1,0.8l0.8,1.4l1.2,1.2l0.8,0.8l0.6,1.2l0.5,0.9l-0.2,0.8l1.1,0.1L621.6,326.1z'
              ></path>
              <path
                id='Morocco'
                class={style.st2}
                d='M542.1,287.1h1.3l1.1-0.1l1,0.2l1.3-0.2l0.5-1.2l0.6-0.8l0.3-1.1l0.3-1.4l0.3-1.2l0.8-0.9l0.9-0.6l0.5-1
               l0.9-0.7l0.8-0.7l0.3-1.1l0.3-1.4l0.6-0.9l0.8-0.8l0.6-0.9l0.7-0.9h1.2l0.9,0.3l1.1-0.1l1.1-0.5l0.9-0.6l0.3-2.2v-1.2l0.5-0.9
               l0.9-0.8l0.9-0.7l0.9-0.6l1.1-0.2l0.9-0.7h1.1l1-0.2l1-0.9l0.5-0.9l1.1-0.4l0.9-0.7l1-0.3l-0.5-0.9v-1.1l1.3-0.2l0.9-0.9l1.3-0.2
               h1.1l1,0.1l0.6-0.9l-0.5-1l-0.3-0.9l-0.3-1.1l-0.2-1.1l-0.1-1.2l-0.1-1.1l-0.8-0.9l-0.2-0.3h-0.7l-0.9-0.5l-0.1-0.2l-0.5,0.3
               l-1.1-0.1l-1.1,0.3H571l-1-0.7l-0.6-1.1v0.3l-0.1-0.2l-0.8,0.3l-0.7,0.9l-0.7,1.7l-0.5,1.1l-0.5,0.9l-0.8,0.9l-0.9,0.5l-0.9,0.4
               l-0.6,0.5l-0.9,0.8l-0.9,0.9l-0.6,0.9l-0.3,1l-0.6,0.9L559,259v1l-0.1,1.3l0.5,0.9l-0.5,1.3l-0.6,0.9l-0.7,0.9l-1,0.9l-1,0.8
               l-0.7,0.9l-2,0.8l-1.1,0.3l-0.9,0.8l-0.3,1l-0.4,1.2l-0.7,0.9l-1,0.6l-0.8,0.8l-0.3,1l-0.4,0.9l-0.1,1.1l-0.3,1l-0.8,0.7l-0.8,0.9
               l-0.6,1l-0.5,0.9l-0.4,1.1l-0.5,0.9l-0.3,1.1l-0.9,0.6l-0.3,1l-0.2,1L542.1,287.1z'
              ></path>
              <path
                id='Madagascar'
                class={style.st2}
                d='M689.5,419.5l0.9-0.6l1.4-0.3l0.9-0.5l0.6-0.9l0.3-1l0.5-0.9l0.3-1.1l0.4-1.4l0.3-1l0.3-1.1l0.7-1.6l0.5-1.2
               l0.3-1.2l0.3-1l0.4-1.1l0.3-0.9l0.5-1.4l0.3-1l0.6-1.4l0.4-1l0.2-1v-1.1l0.3-1l0.5-0.9l0.3-1.1l-0.2-1.2l0.6-0.9l0.7,0.9l0.5-1.1
               v-1.1l-0.4-1v-1v-1.2l-0.3-1.4l-0.2-1.1l-0.6-0.9l-0.3-1l-0.4-0.9l-0.3-0.3l-0.5,0.9l-0.3,1l-0.2,1l-0.3,0.9l-0.9,0.6l-1.1,0.3
               l-0.1,1.1l-0.5,0.9l-0.3,1l-0.6,0.9l0.2-1.2l-0.8,1.2l-0.1,1l-1-0.3l-0.9,0.7l-0.1,1l-0.9-0.5l-0.9,0.4l-1.1,0.2l-0.9,0.5
               l-0.9,0.5l-0.1,1.1l-0.5,0.9l-0.6,0.9v1.2v1.1l0.3,1.2l0.1,1l0.3,1l-0.1,1l-0.1,1l-0.6,1l-0.5,0.9l-0.4,0.9l-0.9,0.7l-0.3,1
               l-0.4,0.9l-0.1,1.1l0.1,1l0.5,0.9l0.3,1l-0.3,1l-0.1,1.1l0.5,1.2l0.2,1.1l0.8,0.7l1,0.3l0.9,0.7L689.5,419.5z'
              ></path>
              <path
                id='Mali'
                class={style.st2}
                d='M563.3,318.3l0.9,0.5l1-0.3l0.6-0.9l0.9-0.1l0.1,1l1.3-0.3l0.4,0.1l0.2-1l0.3-1l0.3-1.1l0.1-1.1l1-0.2
               l0.7-0.9v-1l0.7-0.9l0.2-1l1.1,0.2l1-0.1v-1l0.9-0.6l0.7-0.9l1.2,0.1l0.4-0.9l1.4-0.7l1.3-0.8l0.9-0.1l1.6,0.3l0.7-0.2h1.1
               l0.7-0.8l1-0.1l1.2-0.1h1.1l1-0.3l1.1-0.2l0.9-0.6l0.2-1l0.5-0.9l0.1-1.3l0.1-1.2l-0.1-1.1v-1v-1v-1l-1.2-0.9l-1,0.2l-0.1-1
               l-0.2-1.2l-1.3-0.6l-0.9-0.6l-1-0.3l-0.7-0.9l-0.9-0.4l-0.1-1l-0.9-0.7l-0.9-0.6l-0.9-0.7l-0.8-0.7l-0.9-0.6l-0.8-0.7l-0.9-0.6
               l-0.9-0.7l-0.8-0.6l-0.9-0.7l-0.9-0.6l-0.8-0.7l-0.9-0.8l-0.9-0.7l-0.9-0.8l-0.9-0.6l-0.5-0.4h-1h-1.1h-1h-1l0.1,1.1l0.1,1.1
               l0.1,1.3l0.1,1l0.1,1l0.1,1l0.1,1l0.1,1l0.2,1l0.1,1v0.3l0.1,1.3l0.1,1.2l0.1,1l0.2,1.1l0.1,1l0.1,1l0.1,1l0.1,1.1l0.1,1l0.1,1
               l0.1,1l0.1,1l0.6,0.8l-0.2,1.1l-0.2,1.1h-1.4h-1.1h-1.1h-1.3h-1.2h-1.1h-1.3l-1-0.3l-0.9,0.6l-1-0.1l-1-0.1l-0.9,0.5l-1.3-0.9
               l-0.7,1l-0.1,1l-0.9,0.3l0.1,0.9l0.5,0.9l-0.3,1.1l0.5,1l0.9,0.6l0.3,1l0.2,2l0.9-0.4l0.7,0.9l0.8-0.8l1,0.3l1.1-0.3l0.8-0.8
               l0.6,0.9l0.2,1.4l0.9,0.7l-0.3,1l0.8,0.8l0.3,1l0.5,0.8L563.3,318.3z'
              ></path>
              <path
                id='Mauritania'
                class={style.st2}
                d='M553.3,305.8l0.1-1l0.7-1l1.3,0.9l0.9-0.5l1,0.1l1,0.1l0.9-0.6l1,0.3h1.3h1.1h1.2h1.3h1.1h1.1h1.4l0.2-1.1
               l0.2-1.1l-0.6-0.8l-0.1-1l-0.1-1l-0.1-1l-0.1-1L568,296l-0.1-1l-0.1-1l-0.1-1l-0.2-1.1l-0.1-1l-0.1-1.2l-0.1-1.3v-0.3l-0.1-1
               l-0.2-1l-0.1-1l-0.1-1l-0.1-1l-0.1-1l-0.1-1l-0.1-1.3l-0.1-1.1l-0.1-1.1h1h1h1.1h1l-1.3-0.9l-1-0.8l-1.2-0.9l-0.9-0.7l-0.9-0.6
               L564,273l-0.9-0.7l-0.9-0.7l-0.9-0.6v0.8v1.3v1.3l-1,0.3h-6l-0.9,0.6v1.1v1l-0.1,1.1v1v1.1l-0.1,1.1l-0.9,0.5l-0.9,0.4l-0.9,0.8
               l0.1,1.1v1.4l0.1,1.9h-1.2h-1.2h-1.5h-1.6h-1.3h-1.3l-1.2,0.5l-0.2,1l0.1-0.1l0.3-1l0.3,1l0.9,0.6l0.4,1l-0.2,0.9l-0.4,1l0.2,0.9
               l0.4,1l0.2,1l0.1,1.4l-0.2,1.2l-0.5,1.4l-0.4,1.1l-0.3,1.1v1.3l0.2-0.9l0.3-1l1-0.2l1.1-0.2l1-0.2l1,0.1l0.9,0.3l0.7,0.8l1,0.2
               l0.5,0.9l0.9,1.4l0.7,0.8l0.9,0.9L553.3,305.8z'
              ></path>
              <path
                id='Mozambique'
                class={style.st2}
                d='M659.1,423.3l0.1-0.6l-0.2-1.1l-0.2-1l0.5-0.9l1.4-0.9l1.1-0.5l1-0.3l1.1-0.5l1.3-1l0.2-1.2l0.2-0.9l0.3-1.4
               l0.1-1.3l-0.4-0.9l0.1-1.6l-0.3-1.2l0.1-1.1l-0.6-0.9l-0.3-1l0.2-1.1l-0.3-0.9l0.6,0.8l0.9-0.7l0.9-1l0.7-0.8l1-0.3l0.6-0.9
               l0.7-0.8l0.7-0.9l0.9-0.9l0.9-0.5l1.2-0.7l1.6-0.5l0.9-0.7l1.2-0.8l0.7-0.9l0.5-0.9l0.7-0.9l0.7-0.9l0.2-1.1l0.3-1l-0.6-0.8
               l0.2-1.1v-1.4l-0.3-1l0.4-1l-0.2-0.9l-0.1-1.1l-0.1-1l0.3-1l0.3-1l-0.3-0.9v-0.1l-0.8,0.7l-0.9,0.7l-1.2,0.6l-1,0.1l-0.9,0.6
               l-0.9-0.4l-0.7,0.9l-0.9,0.5l-1-0.4l-0.9,0.3l-1.1-0.3l-0.9-0.5l-0.9,0.4h-1.1l-1,0.7l-0.4,1.3l0.3,1.4v1.3l0.8,0.8l0.7,0.8
               l0.7,0.9l0.6,1l0.4,1.1l-0.3,1.2v1.3l-0.8,1.1l-0.8,0.8l0.1,1l-0.6,0.9l-0.3-1l-0.6-0.9l-0.6-0.9l-0.3-1l0.5-0.9l0.3-0.9l-0.1-1.1
               l-0.4-0.9l-1,0.3l-1-0.3l-0.6-1l-1.1,0.4l-1.3,0.5l-1,0.4l-1.2,0.5l-1.2,0.3l-1,0.5l-0.3,0.9l0.7,2h1.3l0.9,0.6l1.2,0.7l1.3,0.3
               l0.8,0.9l0.1,1l0.1,1.1l-0.3,1l0.2,0.9l-0.3,1l-0.3,1l-0.2,1l0.4,0.9l-0.3,1l-0.5,1l-0.5,0.9l-0.3,1l-0.4,0.9l-0.8,0.9l-0.9,0.9
               l-0.8,0.7l0.2,0.9l0.3,1.4l0.2,1.1l0.4,1l0.2,1.1v1l-0.1,1.3l-0.1,1.1l0.1,1.4l-0.1,1.1l0.1,1.2l0.5,0.1L659.1,423.3z'
              ></path>
              <path
                id='Malawi'
                class={style.st2}
                d='M666.5,394.9l-0.1-1l0.8-0.8l0.8-1.1v-1.3l0.3-1.2l-0.4-1.1l-0.6-1l-0.7-0.9l-0.7-0.8l-0.8-0.8v-1.3l-0.3-1.4
               l0.4-1.3l1-0.7l-0.1-0.3l-0.6-0.9v-1.1l-0.2-1l-0.1-1l-0.5-0.9l-1-0.1l-1.1-0.2l-0.9-0.5l0.2,0.4l0.5,0.9l0.4,0.9l0.1,1.1
               l-0.9,0.6l0.3,1l-0.2,1.1l-0.2,1.4l0.3,1l-0.9,0.4l-0.2,1.1l-0.3,1.1l-0.2,1.1l0.9,0.6l0.6,1l1,0.3l1-0.3l0.4,0.9l0.1,1.1
               l-0.3,0.9l-0.5,0.9l0.3,1l0.6,0.9l0.6,0.9l0.3,1L666.5,394.9z'
              ></path>
              <path
                id='Namibia'
                class={style.st2}
                d='M625.6,429l1.2-0.2l0.9-0.7l0.9-0.7l0.1-1.1v-1.1l0.1-1.1V423l0.1-1.1l0.1-1.1v-1.1l0.1-1.1v-1.1l0.1-1.5
               l0.1-1.4v-1.4l0.1-1.4v-1.3l1.2-0.7h1.1l0.2-1l0.1-1.1v-1.4v-1.1l0.1-1v-1.1l0.1-1v-1V400l0.9-0.5l1.1-0.3l1-0.2l1-0.3l1.1-0.2
               l0.9,0.6l1,0.1l0.8-0.7l1-0.2l1-0.4l0.1-0.5l-0.9-0.3l-1.1-0.1l-1,0.3l-2.2,0.5l-1.6,0.3l-1.2,0.3h-1l-1.1,0.2l-1-0.3l-1.1-0.1
               h-1.3l-1.2-0.2l-0.8-0.7l-1-0.5h-1.3h-1.4h-1.3h-1.2h-1.3h-1.2h-1.2l-1.1,0.1l-0.9-0.5l-0.8-0.8l-1.1,0.2l-0.9,0.6l-1-0.3
               l-0.9,0.3h-0.1l-0.1,1.2l0.2,1.1l0.4,0.9l0.7,0.9l0.6,1l0.4,0.9l0.5,1.1l0.4,0.9l0.3,1l0.4,1.2l0.5,0.9l0.5,1l0.6,0.9l0.5,0.9
               l0.3,1.2l-0.2,1.4v1.2l-0.1,1l0.3,1l0.3,1l0.3,1.1v1.2v1l0.1,1.1l0.5,0.9l0.1,1l0.2,1l0.4,1.1l0.5,0.9l0.9,0.8l0.9,0.8v-0.1
               l0.7-0.9l0.9-0.3l0.4,1.1l0.9,0.5l1.3,0.4L625.6,429z'
              ></path>
              <path
                id='Niger'
                class={style.st2}
                d='M590.7,314.6l-0.1-1.1l0.1-1.1l1-1.3l0.1-1.4l0.9-0.6l1.4-0.2l1.1-0.3l2,0.7l0.7,0.9l0.8,0.9l1-0.3l0.9-0.7
               l1,0.3l0.9,0.6l0.9,0.5h1l1-0.5l0.9-0.7l1.9-0.3h1.2l1.5,0.7l1-0.1l0.8-0.7l0.9-0.4l0.9-1.1l-0.3-1.1l0.5-0.9l0.3-1l0.6-0.9
               l0.5-0.9l0.6-0.8l0.7-0.9l0.7-0.9l0.7-0.8l0.2-1.1v-1l0.1-1v-1l0.1-1l0.1-1v-1l0.1-1.1l0.3-1l-0.1-1l-0.7-0.9l-0.3-1l-0.6-0.9
               l-0.1-1.1l-0.2-1.1l-0.2-1l-0.3-1.1l-1.2,0.8l-0.6,0.3l-1-1l-0.9-0.7l-1.1-0.3l-1-0.3l-1-0.3l-0.3-0.1l-0.9,0.7l-0.9,0.7l-0.9,0.7
               l-1,0.7l-0.9,0.7l-0.9,0.7l-0.9,0.7l-1,0.7l-0.9,0.7l-0.9,0.7L600,289l-0.8,0.7l-0.8,0.8l-0.7,0.8l-0.8,0.8l-0.8,0.8l-0.9,0.4
               l-1,0.3l-1.1,0.3l-0.9,1.3v1v1v1l0.1,1.1l-0.1,1.2l-0.3,0.9l-0.5,0.9l-0.2,1l-0.9,0.6l-1.1,0.2l-1,0.3h-1.1l-1.2,0.1l-1,0.1
               l-0.7,0.8h-1.1l-0.7,0.2l-0.2,1.1l0.5,0.8l0.3,1l0.8,0.8l0.9,0.7l-0.4,0.9l0.9,0.7l0.9,0.5l1.1-0.2L587,313l0.7,1l0.1,0.1l0.2-1
               l0.9-0.3l0.9,1l0.8,0.7l0.1,0.3L590.7,314.6z'
              ></path>
              <path
                id='Nigeria'
                class={style.st2}
                d='M597.5,335.4l1-0.1l0.3-1l0.9,0.4l1,0.2l1.4-0.3l-0.3-1l0.9,0.4l0.5-0.9l0.2-1l0.1-1l0.9-0.9l0.7-0.8l0.8-0.9
               l1.4-0.3l1,0.3l0.4,0.9l1-0.4l0.4-1l0.4-1.2l0.4-1.1l0.4-1.1l0.4-0.9l0.9-0.7l0.2-1.1l0.5-1l0.4-0.9l0.5-0.9l0.3-1.3l0.5-0.9
               l0.5-0.9l0.9-0.3l0.5-1l0.1-1.1l-1-0.6l-0.3-2l-2.1-1.3l-0.9,0.4l-0.8,0.7l-1,0.1l-1.5-0.7h-1.2l-1.9,0.3l-0.9,0.7l-1,0.5h-1
               l-0.9-0.5l-0.9-0.6l-1-0.3l-0.9,0.7l-1,0.3l-0.8-0.9l-0.7-0.9l-2-0.7l-1.1,0.3l-1.4,0.2l-0.9,0.6l-0.1,1.4l-1,1.3l-0.1,1.1
               l0.1,1.1l-0.2,0.2l0.2,1.5l0.3,1v1l-0.3,1l-0.7,0.8l-0.5,0.9l-0.1,1l-0.8,0.7l-0.1,1.1l-0.1,1.1l0.1,1.2l0.1,1.4l-0.1,1l-0.1,1
               v0.2l0.6-0.1l1.2-0.4l1-0.2l-1,0.6h1.7l1,0.3l0.9,0.8l1,0.8l0.4,1l-0.2,1l0.4,1l1,0.9L597.5,335.4z'
              ></path>
              <path
                id='Djibouti'
                class={style.st2}
                d='M684.6,316.5l1.1,0.2l0.7-1.4l-0.3-0.3h-1l0.9-0.7l0.9-0.6l-0.2-1.1l-0.6-0.9l-0.7,0.4l-1.1,0.3l-0.2,0.4
               l-0.5,0.9l-0.7,1l0.1,1l0.6,0.9L684.6,316.5z'
              ></path>
              <path
                id='Gabon'
                class={style.st2}
                d='M609.5,357.7l1,0.3l0.3-1l-0.6-0.9l-0.2-1l0.5-0.9l1.5-0.1l-0.1-1.2l1,0.3l0.3,0.9l1.1,0.2l0.7-0.7l0.5,0.9
               l0.7-0.8l0.5-1l0.2-1l-0.1-1l0.2-1.3l-0.9-0.7l-0.8-0.7l0.1-1l0.5-1l0.9-0.6l-0.3-1l-0.7-0.8l-1,0.3l-1,0.2v-1l0.1-1l0.3-0.5
               l-1-0.3l-1.1-0.2l-1.1,0.1h-0.5l-1,0.2l-0.1,0.2l0.1,1v1.5l-0.8,0.7h-1.4h-1.5l-0.4,0.6l-0.5,0.9l0.8,0.9l-1-0.3v1.3l-0.3,0.9
               l-1,0.6l0.5,1l0.9,0.5l-0.2,1l-0.6-0.9l0.5,1.3l0.5,0.9l0.7,1.1l1,0.3l-1-0.3l1.2,1.2l0.9,1l0.6,0.8l0.8,1L609.5,357.7z'
              ></path>
              <path class={style.st2} d='M236.1,119.7'></path>
              <path
                id='Ghana'
                class={style.st2}
                d='M577.9,333.6l0.9-0.3l1.1-0.3l0.9-0.6l0.9-0.5l1-0.5h1.4l0.5-0.9l-0.4-0.4l-0.7-0.8l-0.5-0.9l0.3-1l-0.3-1.1
               l0.2-1l0.3-1l-0.7-0.8v-1.1l0.3-1l-0.5-1l0.1-1l-0.5-0.9l-0.6-1.1l-1.1-0.6l-1.3-0.1H578l-1.5,0.1h-1l-0.8,0.8l0.3,1l0.2,1v1.1
               l0.2,0.3l-0.2,1.1l0.6,2.5l-0.7,0.8l-0.3,1l-0.1,1l-0.6,1l0.1,1.6l0.3,1.1l0.6,0.9v1l-0.2,0.2l-0.7,0.2l0,0l0.8,0.3l1.1,0.3
               l0.9,0.4L577.9,333.6z'
              ></path>
              <path
                id='GuineaAfrica'
                class={style.st2}
                d='M561.3,326.4l0.9-0.6l0.3-1l-0.5-0.9l1-0.2l-0.3-1.2l0.2-1.1l-0.5-1.5l0.3-0.9l-0.5-0.8l-0.3-1l-0.8-0.8
               l0.3-1l-0.9-0.7l-0.2-1.4l-0.6-0.9l-0.8,0.8l-1.1,0.3l-1-0.3L556,314l-0.7-0.9l-0.9,0.4l-0.4-1l-1.2,0.1h-1.1l-0.8-0.2l-1-0.4
               h-1.1l0.2,0.8l-0.2,1.1l-0.4,0.9l-1,0.2l-1.3,0.9l-0.4,0.9l0.3-0.2l0.9,0.5l-0.4,1l0.8,0.7l0.9,0.8l0.8,0.7l0.5,0.9l0.3,1l0.1-0.1
               l0.9-0.6l0.7-0.8l0.5-1l1.3-0.3l1.8,0.3l0.4,0.5l0.5,0.9l0.4,1l0.3,1.1l-0.5,0.9l1-0.4h0.9l1,0.3l0.3,1.8l0.2,1h1L561.3,326.4z'
              ></path>
              <path
                id='Gambia'
                class={style.st2}
                d='M541.5,310.7l1.1-0.2h1.1l0.9-0.6l0.9-0.5l0.9,0.6l1,0.3l0.5-0.9l-1-0.1l-0.9-0.5h-1l-0.9,0.5h-1h-1l0.1,0.7
               l1-0.3l1.3-0.2l-1,0.3l-1,0.5l-0.7-0.7l-0.2,1.2L541.5,310.7L541.5,310.7z'
              ></path>
              <path
                id='GuineaBissau'
                class={style.st2}
                d='M546,315.8l1.3-0.9l1-0.2l0.4-0.9l0.2-1.1l-0.2-0.8l-0.9-0.1h-1.7l-1.2,0.2l-1,0.6h-1.4l-1,0.3l0.4,0.1h1
               l-0.2,1.1l1.4,0.2l1-0.1l-0.6,0.9l0.1,1l0.9,0.6L546,315.8z'
              ></path>
              <path
                id='Angola'
                class={style.st2}
                d='M607.2,344.8h1.4l0.8-0.7v-1.5l-0.1-1h-1.4h-1.4h-0.3l-0.4-0.5v0.3l-0.2,1.3l-0.5,0.9l-0.3,1l0.9,0.3
               L607.2,344.8L607.2,344.8z'
              ></path>
              <path
                id='Angola'
                class={style.st2}
                d='M632.9,398.2h1l1.2-0.3l1.6-0.3l1-0.6l-0.8-0.7l-0.8-0.9l-0.7-0.8l-0.6-0.9l-0.3-1l0.1-1.1v-1.1v-1.1l0.1-1.3
               v-1.3v-1.3l0.1-1.3h3.6l1-0.7l0.3-1.1l-0.1-1v-1l0.2-1l-0.1-1.2l-1,0.3l-1.1,0.3l-1,0.1l-1,0.2l0.2-1.1v-1l-0.3-1l-0.6-0.9
               l-0.3-0.9l0.2-1.1l0.2-1.1l-0.2-1.2l-0.2-1l0.2-1.1l-0.9-0.3h-1.1h-1.1l-0.3-1l-1,0.2l-1.1,0.3v1l-0.4,1l-1,0.5l-1-0.1l-1,0.3
               h-1.2l-0.5-0.9l-0.5-0.9l-0.5-0.9l-0.3-1l-0.2-1l-0.3-1l-0.9-0.5h-1.1h-1.1h-1.1l-1.1,0.1l-1.1-0.1h-0.9h-1.2l-0.2,0.1l-1.1,0.3
               l-0.9,0.3l0.4,0.9l0.6,0.9l0.3,1.1l0.3,1l0.6,1.6l0.3,1l-0.3,0.9l-0.4,1l0.4,1.2l0.3,1l0.5,1.2l0.4,0.9l0.3,1l-0.2,1.4l-0.1,1.1
               l-0.4,0.9l-0.8,0.8l-0.9,0.8l-0.3,0.9l-0.6,0.9l-0.3,1.1l-0.3,0.9l-0.1,1l-0.3,1.1l-0.3,1.1l-0.3,1.1l-0.3,0.9v2v1.3l0.9-0.3
               l1,0.3l0.9-0.6l1.1-0.2l0.8,0.8l0.9,0.5l1.1-0.1h1.2h1.2h1.3h1.2h1.3h1.2h1.3l1,0.5l0.8,0.7l1.2,0.2h1.3l1.1,0.1l1,0.3
               L632.9,398.2z M612.3,363.5v-1.1l0.5-1l0.9-0.7l-0.7-0.6l-0.3,0.3l-0.8,0.8l-0.8,0.7l0.3-0.1l0.2,1v1.2L612.3,363.5z'
              ></path>
              <path
                id='Cote DIvoire'
                class={style.st2}
                d='M564.3,335l0.9-0.5l1.1-0.4l1-0.5l1.2-0.3l1-0.3l1.1,0.1l1.4-0.3l-1.1-0.1h1l1,0.3l1-0.5l0.9,0.6l0.2-0.2v-1
               l-0.6-0.9l-0.3-1.1l-0.1-1.6l0.6-1l0.1-1l0.3-1l0.7-0.8l-0.6-2.5l0.2-1.1l-0.9-0.7l-0.9-0.5h-1l-0.9,0.4l-1.1,0.2l-0.7-0.8
               l-0.4-0.9l-0.9-0.3l-0.4-0.1l-1.3,0.3l-0.1-1l-0.9,0.1l-0.6,0.9l-1,0.3l-0.9-0.5l-0.8,0.8l-0.3,0.9l0.5,1.5l-0.2,1.1l0.3,1.2
               l-1,0.2l0.5,0.9l-0.3,1l-0.9,0.6l0.3,0.9l0.1,1.1l-0.3,1l0.9,0.4l0.6,0.9l0.9,0.5l0.1,1.1l-0.3,0.9l-0.1,1l0.1,1.1L564.3,335z'
              ></path>
              <path
                id='Kenya'
                class={style.st2}
                d='M677.6,360.6l0.4-0.9l0.5-1l0.5-1.6l0.5-0.9l0.2-1l0.9-0.3l0.7-0.9l0.8-0.7l0.8-0.8l-0.4-0.9l-0.6-0.9
               l-0.3-0.9v-1.1v-1.3v-1v-1.1v-1l-0.1-1v-1.4v-1.1l0.3-1.3l0.7-0.9l0.6-0.9l0.6-1l-1.1,0.1l-1-0.4l-1.2-0.2l-1,0.4l-0.8,0.9
               l-0.6,0.9l-1.1-0.3l-1-0.3h-1.2l-1-0.8l-0.9-0.7l-0.9-0.7l-1.4-0.2h-1l-0.6-0.9v-1.1l-0.8-0.8l-0.3,0.3l-0.1-0.1l-0.1-0.3
               l-0.9,0.9l-0.8,0.9l-0.8,0.9l-0.8,0.9l0.3,0.9l0.4,0.3l0.3,1l0.4,0.9l0.5,0.9l0.3,1.2l0.3,1.2l-0.2,1l-0.3,0.9l-0.8,0.8l-0.6,0.9
               l-0.4,0.9l-0.3,1.3l-0.1,1v1.4l0.5,0.3l1.1,0.7l1,0.7l1,0.7l1,0.7l1,0.7l1.1,0.6l1,0.7l1,0.7l0.2,1.1l0.4,0.9l0.9,0.7l0.9,0.7
               l0.8,0.8l0.9,0.7L677.6,360.6z'
              ></path>
              <path
                id='Burundi'
                class={style.st2}
                d='M654.5,359.9l0.7-0.9l0.5-1l0.6-1l0.4-0.9l-0.8-0.7l-0.3-1.2h-1l-0.1,1l-1,0.3l-1.1-0.3l0.1,0.3l0.4,0.9v1.2
               l0.1,1.1l0.3,1.3L654.5,359.9z'
              ></path>
              <path
                id='Benin'
                class={style.st2}
                d='M587.3,329.9l1.1-0.2v-0.2l0.1-1l0.1-1l-0.1-1.4l-0.1-1.2l0.1-1.1l0.1-1.1l0.8-0.7l0.1-1l0.5-0.9l0.7-0.8
               l0.3-1v-1l-0.3-1l-0.2-1.5l-0.1-0.3l-0.8-0.7l-0.9-1l-0.9,0.3l-0.2,1l-0.3,0.6l-0.7,0.8l-1-0.1l-1,0.4l-0.9,2l0.3,1l1,1l0.1,1.2
               l0.4,1l0.1,1l0.1,1.3v1.2v1.2l-0.3,1.2l0.2,1.2v1L587.3,329.9z'
              ></path>
              <path
                id='BurkinaFaso'
                class={style.st2}
                d='M575.2,320.6v-1.1l-0.2-1l-0.3-1l0.8-0.8h1l1.5-0.1h1.2l1.3,0.1l2-0.2l2-0.7l1-0.4l1,0.1l0.7-0.8l0.3-0.6
               l-0.1-0.1l-0.7-1l0.2-1.1l-1.1,0.2l-0.9-0.5l-0.9-0.7l0.4-0.9l-0.9-0.7l-0.8-0.8l-0.3-1l-0.5-0.8l0.2-1.1l-1.6-0.3l-0.6,0.1
               l-1.3,0.8l-1.4,0.7l-0.4,0.9l-1.2-0.1l-0.7,0.9l-0.9,0.6v1l-1,0.1l-1.1-0.2l-0.2,1L571,312v1l-0.7,0.9l-1,0.2l-0.1,1.1l-0.3,1.1
               l-0.3,1l-0.2,1l0.9,0.3l0.4,0.9l0.7,0.8l1.1-0.2l0.9-0.4h1l0.9,0.5l0.9,0.7V320.6z'
              ></path>
              <path
                id='Botswana'
                class={style.st2}
                d='M631.6,423.4h1.1l0.9-0.6l0.7-0.9l0.8-0.7l0.4-0.9l0.4-0.9l0.9-0.5l0.9,0.4l0.9,0.6l1,0.3l1,0.3l1.1-0.2
               l0.7-0.9l0.4-1l0.5-1l1.4-0.8l0.8-0.9l0.3-1l0.7-0.8l0.9-0.7l0.9-0.6l0.6-0.9l1-0.4l0.9-0.5l0.9-0.6l-0.5-0.3l-0.5-0.9l-1.1-0.3
               l-0.9-0.3l-0.4-0.9l-0.4-1.1l-0.2-1l-0.8-0.7L646,404l-0.9-0.7l-0.9-0.8l-0.4-1.6l-0.7-1.2l-0.5-0.9l-0.8-1l-1,0.4l-1,0.2
               l-0.8,0.7l-1-0.1l-0.9-0.6l-1.1,0.2l-1,0.3l-1,0.2l-1.1,0.3l-0.9,0.5v1.1v1l-0.1,1v1.1l-0.1,1v1.1v1.4l-0.1,1.1l-0.2,1h-1.1
               l-1.2,0.7v1.3l-0.1,1.4v1.4l-0.1,1.4l-0.1,1.5l0.3,0.3l0.7,0.8l0.4,0.9l0.3,1l0.1,1l-0.5,0.9l0.2,1h1.2V423.4z'
              ></path>
              <path
                id='CentralAfricanRepublic'
                class={style.st2}
                d='M621.6,340.3l0.3-1.2l0.1-1.1l1-0.3l1-0.4l1,0.2l1,0.3l1-1.5l0.3-1.1l0.5-0.9l0.7-0.8l1.2-0.3l0.9,0.7
               l0.8,0.8l0.9,0.4l0.9,0.5l1-0.1l1,0.3h1.2l0.4-0.9l0.6-0.8l0.9,0.6l0.9-0.4l1-0.4l0.9-0.5l1,0.3l1-0.3l0.5-0.9l0.7,0.4l1,0.1
               l0.9,0.3l1-0.3l1,0.3l-0.5-0.5l-0.1-1.1l-0.8-0.7l-0.9-0.5l-0.5-0.9l-0.3-1l-0.7-0.9l-0.9-0.5l-0.9-0.7v-1.1l-0.9-0.7l-0.9-0.3
               l-0.9-0.4l-0.5-1l-1-0.3v-1l0.2-1l0.1-1l-1.4-2l-0.6-0.9l-0.9-0.3l-0.9,0.5l-1,0.5l-0.1,1.1l-0.7,0.8l-0.9,0.7l-0.5,0.9l-0.8,0.7
               l-1,0.2l-1,0.2l-1,0.1l-0.3,0.9l-0.5,0.9l-0.9,0.9l-1.3,0.1l-1,0.2l-0.9,0.5l-0.9,0.6l-1-0.3l-1,0.5l-1.1-0.1l-0.3,0.4l-0.6,0.9
               l-0.4,0.9l-0.5,1.3l-0.7,0.8l0.3,1l-0.1,1l0.3,0.9l0.3,1l0.7,0.8l0.1,1.1l0.6,0.9l0.7,0.9l1,0.9l0.2,1l0.3,1L621.6,340.3z'
              ></path>
              <path
                id='Camaroon'
                class={style.st2}
                d='M621.3,341.3l-0.3-1l-0.2-1l-1-0.9l-0.7-0.9l-0.6-0.9l-0.1-1.1l-0.7-0.8l-0.3-1l-0.3-0.9l0.1-1l-0.3-1
               l0.7-0.8l0.5-1.3l0.4-0.9l0.6-0.9l0.3-0.4l0.2-0.8l-0.5-0.9l-0.6-1.2l-0.8-0.8l-1.2-1.2l-0.8-1.4l1.1-0.8l1.5,0.1l0.9-0.6
               l-0.8-1.3l-0.3-1l0.1-1.2l0.1-1l-0.4-0.9l-0.3-1.1l-0.7-0.8l-0.3-0.6l-0.9-0.3l0.3,2l1,0.6l-0.1,1.1l-0.5,1l-0.9,0.3l-0.5,0.9
               l-0.5,0.9l-0.3,1.3l-0.5,0.9l-0.4,0.9l-0.5,1l-0.2,1.1l-0.9,0.7l-0.4,0.9l-0.4,1.1l-0.4,1.1l-0.4,1.2l-0.4,1l-1,0.4l-0.4-0.9
               l-1-0.3l-1.4,0.3l-0.8,0.9l-0.7,0.8l-0.9,0.9l-0.1,1l-0.2,1l-0.5,0.9l0.7,0.6l0.3,1l0.7,0.8l1,0.2l-0.1,1l0.7,0.9l-0.1,1.4
               l-0.2,1.1l0.4,0.5h0.3h1.4h1.4l0.1-0.2l1-0.2h0.5l1.1-0.1l1.1,0.2l1,0.3h0.4h1.3h1.1l1,0.3l1,0.3l1,0.1l1,0.2L621.3,341.3z'
              ></path>
              <path
                id='DemRepubCongo'
                class={style.st2}
                d='M653.8,384.3l0.1-1.4l-0.2-1.1l-0.7,0.8l-1-0.3l-0.6-0.9l-0.8-0.8v-1.2l0.2-1l0.5-0.9l-0.2-1.1l0.1-1
               l-0.1-1.1l-0.4-0.9l0.9-0.8l0.4-1.3l1.1-0.2l1.2-0.3l1.1-0.2l1-0.2l-0.2-0.8l-0.6-1.4l-0.4-1.1l-0.9-0.9l-0.7-1.1l-0.3-1l0.3-1
               l-0.3-1.1l-0.3-1.1l0.3-1.1l-0.3-1.3l-0.1-1.1v-1.2l-0.4-0.9l-0.1-0.3l-0.3-0.9l0.6-0.9l0.3-1.3l0.8-0.7v-0.9l0.1-1l0.2-1.2
               l0.3-1.3l0.3-1.4l0.7-0.9l0.7-0.9l0.9-1l0.9-1l-0.3-1l-0.7-0.9v-1.1l0.2-1.2l-0.7-0.3l0,0l-0.3-0.8l-0.9-0.7l-0.8-0.8l-0.8-0.8
               l-0.9,0.7l-0.9-0.3l-0.8,0.7h-0.1l-0.4-0.2l-0.9-0.6l-0.5-0.9l-0.2-0.1l-0.3-0.4l-1-0.3l-1,0.3l-0.9-0.3l-1-0.1l-0.7-0.4l-0.5,0.9
               l-1,0.3l-1-0.3l-0.9,0.5l-1,0.4l-0.9,0.4l-0.9-0.6l-0.6,0.8l-0.4,0.9h-1.2l-1-0.3l-1,0.1l-0.9-0.5l-0.9-0.4l-0.8-0.8l-0.9-0.7
               l-1.2,0.3l-0.7,0.8l-0.5,0.9l-0.3,1.1v2l-0.4,1l-0.3,1l-0.5,0.9l-0.1,1.2l-0.2,1.4l-0.3,1l0.1,1.1l-0.1,1l-0.3,1.4l-0.3,1.3
               l-1.4,1.1l-0.7,0.9l-0.3,0.9l-0.7,0.9l-0.4,1v1.7l0.1,1l-0.5,1l-0.8,0.8l-0.8,0.7l-0.9,0.9l-0.8,0.9l-0.8-0.7l-0.8-0.7l-0.9,0.6
               l-0.8,0.8l-0.8-0.8l-0.9,0.7l-0.5,1v1.1l-0.8,0.4l0.3,0.5l1-0.3h1h1.1h0.9l1.1,0.1l1.1-0.1h1.1h1.1h1.1l0.9,0.5l0.3,1l0.2,1l0.3,1
               l0.5,0.9l0.5,0.9l0.5,0.9h1.2l1-0.3l1,0.1l1-0.5l0.4-1v-1l1.1-0.3l1-0.2l0.3,1h1.1h1.1l0.9,0.3l-0.2,1.1l0.2,1l0.2,1.2l-0.2,1.1
               l-0.2,1.1l0.3,0.9l0.6,0.9l0.3,1v1l-0.2,1.1l1-0.2l1-0.1l1.1-0.3l1-0.3l0.9,0.6l0.2,1.1l1-0.4l1.1,0.3l0.6,1l0.9,0.3l1,0.2
               l1.1,0.2l1-0.5l0.8,1.3l1,0.3l1,0.4l0.5,0.9l0.6,0.9l0.9,0.5l1.2,0.3v-0.5H653.8z'
              ></path>
              <path
                id='Algeria'
                class={style.st2}
                d='M590.8,293.9l2.1-0.4l1.1-0.3l1-0.3l0.9-0.4l0.8-0.8l0.8-0.8l0.7-0.8l0.8-0.8l0.8-0.7l0.9-0.7l0.9-0.7
               l0.9-0.7l1-0.7l0.9-0.7l0.9-0.7l0.9-0.7l1-0.7l0.9-0.7l0.9-0.7l0.9-0.7l-0.3-0.8l-0.8-1.4l-1.4-0.5h-1.2l-0.9-1v-0.8l-0.5-0.9
               l-0.7-0.9l-0.2-1.2l0.9-0.9l0.1-1.1V270l0.3-1l-0.4-1.1l0.1-2.5l-0.8-1.9l-0.1-1.2l-0.2-0.9l-0.3-1.3l-0.3-1.2l-0.3-1.3l-0.6-0.9
               l-0.9-0.6l-0.4-1.2l-0.8-0.8l-0.7-0.9l-0.4-0.9l0.2-1l0.6-0.9l0.8-0.8l0.2-1v-1l-0.2-1.1l0.1-1.1v-1l0.7-1l-0.9-0.1h-1.2l-1-0.3
               l-0.9,0.4l-1-0.4l-0.9,0.7l-1.1,0.4l-1-0.1l-0.9-0.4h-1.2h-1l-1,0.3l-1,0.3l-1,0.2l-1.1,0.2l-1,0.2l-1.1,0.2l-0.9,0.5l-0.9,0.6
               l-0.8,0.8l-1.1,0.3l-1,0.3l-0.5,0.9l-0.9,0.5l-1,0.1l0.2,0.3l0.8,0.9l0.1,1.1l0.1,1.2l0.2,1.1l0.3,1.1l0.3,0.9l0.5,1l-0.6,0.9
               l-1-0.1h-1.1l-1.3,0.2l-0.9,0.9l-1.3,0.2v1.1l0.5,0.9l-1,0.3l-0.9,0.7l-1.1,0.4l-1,0.3l-1,0.9l-1,0.2h-1.1l-0.9,0.7l-1.1,0.2
               l-0.9,0.6l-0.9,0.7l-0.9,0.8l-0.5,0.9v1.2l-0.1,2.3l0.9,0.6l0.9,0.7l0.9,0.7l0.9,0.7l0.9,0.6l0.9,0.7l1.2,0.9l1,0.8l1.3,0.9
               l0.5,0.4l0.9,0.6l0.9,0.8l0.9,0.7l0.9,0.8l0.8,0.7l0.9,0.6l0.9,0.7l0.8,0.6l0.9,0.7l0.9,0.6l0.8,0.7l0.9,0.6l0.8,0.7l0.9,0.7
               l0.9,0.6l0.9,0.7l0.1,1l0.9,0.4l0.7,0.9l1,0.3l0.9,0.6l1.3,0.6l0.2,1.2l0.1,1L590.8,293.9z'
              ></path>
              <path
                id='Egypt'
                class={style.st2}
                d='M656.6,285.4l0.3,0.3h3.7h1.1h1.1h1.1h1.1h1.1h1.1h1.1h1.1l-0.2-0.3l-0.9-0.6l-0.9-1l-0.9-0.6l-0.4-1
               l-0.3-1.1l0.6-0.9l-0.9-0.6l-0.6-1.2l-0.4-0.9l-0.5-0.9l-0.5-1l-0.8-1.3l-0.5-1l-0.6-0.9l-0.1-1l-0.6-1l-0.4-0.9l-0.4-0.9
               l-0.7-0.8l-0.7-0.9l-0.7-1.2l-0.2-1l0.2-1l0.4,1l0.6,0.9l0.4,0.9l0.5,0.9l0.7,0.8l0.9,0.8l1-0.3l-0.1-1.3l0.3-0.9l0.1-1l0.3-1
               l-0.2-0.7l-0.4-1l-0.5-0.9l-0.3-1l-0.6-1.4l-0.3,0.3l-1.1,0.3l-1.1-0.2l-0.3,0.3h-1.1h-1l-0.2-1h-1.1h-1.2l-1,0.1l0.9-0.4
               l-1.4,0.3l-0.8,0.7l-0.8,0.7l-0.9,0.5l-1.1-0.4l-1.4-0.3l-1.2-0.3l-0.9-0.5l-1-0.3l-1.1-0.2l-1.3-0.3l-1.2,0.3l-0.3-0.4L640,259
               l-0.4,1l0.3,1.1l-0.2,1l-0.3,1.1l0.3,1.1l0.3,1.1l0.1,1.1l0.1,1.2l0.1,1.3l0.1,1.1l0.1,1.4v1.3l0.1,1.7l0.1,1.6l0.1,1.7l0.1,1.6
               l0.1,1.6l0.1,1.7l0.1,1.6l0.1,1.7h0.5h1.1h1.2h1.1h1.2h1.1h1.2h1.2h1.1h1.2h1.1h1.2h1.1L656.6,285.4z'
              ></path>
              <path
                id='Libya'
                class={style.st2}
                d='M639.1,292.3v-1h1.2l1.2-0.3l-0.1-1.1v-1l-0.1-1.1v-1l-0.1-1l-0.1-1.7l-0.1-1.6l-0.1-1.7l-0.1-1.6l-0.1-1.6
               l-0.1-1.7l-0.1-1.6l-0.1-1.7v-1.3l-0.1-1.4l-0.1-1.1l-0.1-1.3l-0.1-1.2l-0.1-1.1l-0.3-1.1l-0.3-1.1l0.3-1.1l0.2-1l-0.3-1.1l0.4-1
               l0.2-0.3l-0.3-0.8l-1.1-0.2l-1.3-0.3l-1.1-0.3l-1-0.4l-0.4-1.1l-0.9-0.3l-1-0.4l-1-0.1l-0.9,0.5l-1.1,0.4l-0.9,0.6l-0.8,0.8
               L628,258l0.3,1l0.3,1l-0.5,1l-1.1,0.9l-1.1,0.2l-0.9-0.6l-0.9-0.9l-0.9-0.4l-1-0.4l-0.9-0.3l-1.4-0.1l-1.4-0.5l-0.7-0.8l-0.3-1.2
               l-0.6-0.9l-1.1-0.2l-1.1-0.7l-1.3-0.2l-1-0.3l-1,0.3l-1.4-0.3l-0.9-0.5l-0.6-0.3v1v1.3l-2,1.2l-0.8,0.8l-0.2,1.2l0.3,1.1l-0.6,1
               l-1.1,0.8l0.1,1.2l0.8,1.9l-0.1,2.5l0.4,1.1l-0.3,1v1.4l0.2,1.1l-0.9,0.9l0.2,1.2l0.7,0.9l0.5,0.9v1.2l0.9,1h1.2l1.4,0.5l0.8,1.4
               l0.3,0.8l0.3,0.1l1,0.3l1,0.3l1.1,0.3l0.9,0.7l1,1l0.6-0.3l1.2-0.8l0.9-0.4l0.9-0.5h1.1l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.5
               l0.9,0.5l0.9,0.5l0.9,0.6l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.5l0.9,0.5
               l0.9,0.6L639.1,292.3z'
              ></path>
              <path
                id='Liberia'
                class={style.st2}
                d='M563.5,334.3l0.1-1l0.3-0.9l-0.1-1.1l-0.9-0.5l-0.6-0.9l-0.9-0.4l0.3-1l-0.1-1.1l-0.3-0.9l-0.9,0.5h-1l-0.2-1
               l-0.3-1.8l-1-0.3H557l-0.1,0.5l-0.8,0.9l-0.7,1.4l-0.8,0.8l-0.6,0.9l0.2,0.2l0.9,0.8l0.9,1l1.1,0.6l0.9,0.9l0.9,1l0.9,0.8l0.9,0.6
               l1,0.5l0.9,0.4l0.9,0.4L563.5,334.3z'
              ></path>
              <path
                id='Lesotho'
                class={style.st2}
                d='M647.5,433l0.9-0.4l1.1-0.5l0.5-0.9l0.3-0.9l-0.7-0.9l-0.7-0.8l-0.9-0.1l-0.2,0.1l-0.9,0.5l-0.7,0.7l-0.7,0.9
               l-0.7,0.9l0.6,1.2l0.4,0.9l1.1,0.3L647.5,433z'
              ></path>
              <path
                id='Congo'
                class={style.st2}
                d='M611.8,361.1l0.8-0.8l0.3-0.3l0.7,0.6l0.8,0.8l0.8-0.8l0.9-0.6l0.8,0.7l0.8,0.7l0.8-0.9l0.9-0.9l0.8-0.7
                l0.8-0.8l0.5-1l-0.1-1v-1.7l0.4-1l0.7-0.9l0.3-0.9l0.7-0.9l1.4-1.1l0.3-1.3l0.3-1.4l0.1-1l-0.1-1.1l0.3-1l0.2-1.4l0.1-1.2l0.5-0.9
                l0.3-1l0.4-1l-1-0.5l-1-0.3l-1-0.2l-1,0.4l-1,0.3l-0.1,1.1l-0.3,1.2l-0.3,1l-0.2,1.1l-1-0.2l-1-0.1l-1-0.3l-1-0.3h-1.5h-1.3h-0.4
                l-0.3,0.5l-0.1,1v1l1-0.2l1-0.3l0.7,0.8l0.3,1l-0.9,0.6l-0.5,1l-0.1,1l0.8,0.7l0.9,0.7l-0.2,1.3l0.1,1l-0.2,1l-0.5,1l-0.7,0.8
                l-0.5-0.9l-0.7,0.7l-1.1-0.2l-0.3-0.9l-1-0.3l0.1,1.2l-1.5,0.1L610,355l0.2,1l0.6,0.9l-0.3,1l-1-0.3l-0.6,0.9l0.1,0.2l0.8,0.9
                l0.7,1l0.6,1L611.8,361.1z'
              ></path>
              <path
                id='Sudan'
                class={style.st2}
                d='M678.5,337.2l0.8-0.9l1-0.4l1.2,0.2l1,0.4l1.1-0.1l0.1-0.3l1.2-0.3l1.1-0.3l0.6-0.9l0.9-0.6l1.1-0.3h1
               l1.1,0.1l0.8-0.8l0.8-0.9l0.8-1l0.9-1l0.8-0.9l0.7-0.9l0.8-0.8l0.7-0.8l0.7-0.9l0.7-0.8h-0.5h-1.2l-1.1-0.1l-0.9-0.3l-1-0.4
               l-1-0.3l-1.1-0.4l-1-0.4l-1.1-0.4l-1.1-0.5l-0.9-0.8l-0.7-0.9l-0.6-0.9l-0.6-0.9l-0.3-1.1l0.5-0.9l-1.1-0.2l-1.1,0.3l-0.6-0.9
               l-0.1-1l0.7-1l0.5-0.9l0.2-0.4l-0.6-0.9l-0.9-0.9l-0.7-0.8l-0.9-0.7l-0.7-1l-0.9-0.6l-0.9-0.7l-1.1-0.2l-1,0.1l-1.1-0.2l-1,0.3
               l-0.7-0.8h-0.3l-0.9,0.6l-0.3,1l-0.6-0.9l-1,0.3l-0.9,0.2l-0.1,1.3l-0.5,1.3l-0.4,1.8l-1,0.4l-0.4,0.9L667,314l-0.3,1l-0.2,1.1
               l-1,0.8l-0.6,1.1l-0.1,1.3l-0.3,1.6l-0.3,2.9l-1.1-0.1l-0.7,0.9l-0.3,1l1,0.2l0.9,0.5l0.6,1l0.8,0.7l0.8,0.9l0.3,1.1l0.3,1.1
               l0.8,1l0.1,0.3l0.1,0.1l0.3-0.3l0.8,0.8v1.1l0.6,0.9h1l1.4,0.2l0.9,0.7l0.9,0.7l1,0.8h1.2l1,0.3l1.1,0.3L678.5,337.2z'
              ></path>
            </g>
            <g id='NorthAmerica'>
              <path
                id='Canada'
                class={style.st2}
                d='M398.8,229.8l1.1-0.5l1.2-0.6l1.3-0.3l1.2-0.2l1-0.4l1.2-0.4l1.2-0.4l0.2-1l0.6-1.1l1.1-0.2h1.5h1.5l1-0.5
            l0.8-0.9l1-0.7l1-0.7l1.2-0.8l2.1-0.9l0.5-0.9l1-0.2l1.1-0.5l0.8-0.8l0.9-0.6l1.1-0.4l1-0.5l0.2-0.1l-0.9,0.5l-0.9,0.4l-1,0.5
            l-0.8,0.7l-0.9,0.9l-0.9,0.5l-1.1,0.4l-0.3,0.5h1.1h1h1.1h1.1l1.1-0.1l1.1-0.3l1-0.4l0.9-0.6l0.9-0.6l0.5-0.9l0.6-0.9l0.6-0.9
            l0.9-0.8l0.8-0.8l1.2,0.3l1.2-0.4l0.9,0.5l-0.1,1l-0.3,1.2l-0.4,1.3l0.6,0.9l0.2,1.1l0.9,0.3l1-0.3l1-0.2l1-0.4l1.2-0.5l0.9-0.5
            l0.1-1l0.4,1l-0.5,0.9l1.2,0.1l1,0.2l-1,0.3h-1l-1,0.3l-0.9,0.5l-1,0.8l-0.9,0.6l-0.8,0.9l0.5,0.9l0.6,0.9l0.7-0.8l1-0.3l0.7-0.8
            l0.9-0.7l0.8-0.7l0.9,0.4l0.9-0.5l0.9-0.3l1-0.4l1-0.3l1-0.3l1-0.6l-0.8-0.8l-0.9-0.4l-1.4,0.1l-1.1-0.1l-1.1-0.3l-0.7-0.8
            l-0.9-0.5l-0.1-1l0.2-1h-1.1l1.2-1l0.7-0.9l-1-0.2l-1.1,0.3l-0.9-0.7l-1.1-0.1l1-0.3h1l1,0.3l0.9-0.4l1-0.6l0.9-0.8l-0.3-1
            l-0.9-0.5l-1.2-0.1l-1.1,0.1l-1.3,0.3l-1.2,0.4l-1.2,0.3l-1,0.3l-0.9,0.4l-1,0.5l-0.9,0.6l-1,0.7l-0.8,0.8l-0.9,0.7l-0.9,0.7
            l-1.4,0.6l-0.9,0.4l-1,0.3l0.3-0.1l1-0.4l1.4-0.9l0.8-0.8l0.9-0.7l0.8-0.6l-0.3-0.9l-1-0.4l0.9,0.2l0.9,0.5l0.9-0.6l0.9-0.8
            l0.9-0.8l1-0.4l0.9-0.6l1.1-0.1l0.9-0.5l0.5-0.9l0.9-0.5l0.9-0.5l1-0.2h1l1.3,0.1h1.3l1.1-0.1l1,0.2l1-0.2l1,0.1l0.9,0.4l1.1-0.3
            h1.1l1-0.2l1-0.3l0.9-0.5l0.9-0.6l0.9-0.7l0.9-0.7l1.1-0.1l1-0.3h1.2l0.9-0.6l1-0.3l0.8-0.7l0.9-0.5l-0.9-0.6h1l-1.1-0.3l1-0.4
            l-0.5-0.9l0.9-0.5l-0.3-0.9l-1-0.3l-1,0.2l-1,0.2l0.9-0.5l-0.1-1l-1-0.2l-1,0.3l1,0.1l-1.5,0.1l-1.1,0.5l-1,0.6l-1.1,0.3l-0.3,0.3
            l-1,0.3l0.7-0.8l-0.9-0.6l0.9,0.5l0.9-0.6l1-0.1l0.9-0.5l0.9-0.3l-1,0.2l1-0.2l1.2-0.5l1.5-0.3l-0.3-0.4l-0.9-0.4l-1.2-0.1
            l-0.9-0.7l-0.9,0.4l0.3-0.9l-1,0.1l-1.2,0.6l0.6-0.9l0.4-0.9l-1-0.2L456,191l-0.1-0.7l-0.5-0.9l-1-0.4l1,0.3l0.9-0.5l0.3-0.9
            l-0.9-0.7l-0.1-0.2l0.4-0.9l-0.9-0.6l-1,0.2l0.9-0.5h-0.1l1-0.8l-1,0.1l-1,0.3l0.9-0.4l0.8-0.8l-0.9-0.6l-1-0.1l0.7-0.8l-0.6-0.9
            l0.1-1l-0.9,0.3l0.7-0.9l-0.9,0.5l-0.3,1l-0.9,0.7l-0.9,0.6l-0.3,0.5l-0.9,0.5l-0.3,1l-0.6-0.9l-0.7,0.8l-1,0.3l-1,0.3l-0.7,0.8
            l0.2-1l-0.8,0.6l0.3-1l-0.7,1l-1,0.3l-1,0.3l1.1-0.3l0.9-0.5l0.5-1l-0.6-0.9l-1,0.1l-0.9,0.5l-1.1-0.3h1.2l0.6-0.9l0.1-1l-0.1-1
            l-1.2-0.1l1.4-0.1l0.6-0.9l0.5-0.9l0.5-0.9l-0.9,0.6l-0.9-0.5l-1-0.3l-0.9-0.6l-0.3-0.9l-0.9-0.6l-0.3-0.4l-0.9-0.6h-1l-1,0.3h-1.3
            l-1.1-0.1l-1.1-0.3L432,174l-1-0.2l-0.9,0.4l-0.9,0.7l-0.2,1v1l-0.7,0.8l-0.9,0.7l1.1,0.1l-0.5,0.9l0.2,1l-0.8,0.8h-0.5l-0.5,0.9
            l-0.9,0.5l-1,0.4v1l0.9,0.6l0.9,0.7l0.3,1v1l-0.3,1.1l-0.4,1l-0.5,0.9l-0.9,0.7l-1,0.8l-1.2,0.6l-0.9,0.5l-1,0.4l-1,0.3l-1,0.3
            l-0.8,0.7l0.6,0.9l-0.3,0.9l-0.3,1.1l-0.2,1l-0.1,1v1l-0.8,0.8l-0.5,0.9l-0.6,0.9l-0.5-0.9l-1,0.8l-0.2,1l-0.9-0.9l-1.1,0.3l-1,0.4
            l0.9-0.5l0.8-0.8l-0.3-1l-0.9-0.5l-1-0.3l0.9-0.6l-0.4-0.9l-0.3-1l0.6-0.9l0.5-0.9l-0.2-1l0.7-0.9l0.4-1.4l-1-0.3l-0.9-0.2l-1-0.2
            l-1.2,0.1l-1.1-0.1l-0.9,0.6l0.3-1l-0.9-0.6l-1.3-0.5l-1-0.3l-0.7-0.9l-0.8-0.7l-0.7-0.8l-1.1-0.2l-1-0.3l-1-0.4h-1l-1.3,0.3
            l-1.1,0.2l-1.4,0.3l0.9-0.4l0.6-1.1v-1l0.1-1.2l0.2-1h-1l-1,0.3l-0.8,0.9l0.3-1l-0.1-1l0.5-0.9l0.4-0.9l0.7-0.8l0.4-1l1-0.5
            l0.9-0.7l0.8-0.8l0.8-0.6l0.9-0.9l1-0.3l0.9-0.5l-0.4-0.4l1.1-0.3l1.1-0.2l0.6-0.9l1.1,0.2l1-0.3l0.9-0.4l-0.4-0.9l-1.1-0.5l-1-0.3
            l-1-0.2l-0.8-0.8l0.9,0.6l1,0.3l1,0.2l1,0.3l1,0.2l1.1-0.1l0.7-0.9l0.9-0.3h1l1.1,0.1l1-0.3l0.3-0.4l0.9-0.5l0.9-0.6l0.1-1
            l-1.1-0.1l-1.7-0.1l-0.6-0.9l-1-0.4l-1.3-0.3h2l1.2,0.3l1,0.6l0.8,0.6h1.2l0.9-0.5l1.1-0.4l0.9-0.6l0.9-0.6l-1-0.9l1.1,0.1l1.3-0.2
            l0.8,0.8h1l0.9-0.6l-0.4-1h-1.2l1.3-0.1l0.9,0.5l0.3,0.9l1-0.3l1-0.3l1-0.4l0.9-0.6l1-0.3l0.8-0.8l-0.6-0.9l-0.5-0.9l0.8-0.9h1
            l1.1-0.7l-0.9-0.3l0.9-0.7l-0.9-0.4l-1.6-0.3l1.7-0.3l-1.1-0.1h-1.2l-0.9-0.4l-1.5,0.3l-0.9,0.5l-0.3,0.9l1,0.3l-0.9,0.7l-1-0.1
            l-0.8,0.8l-0.9,0.7l-0.9,0.5l-0.9,0.6l-0.8,0.8l-1,0.3l-0.9,0.3l-0.1-1l-0.3-1l0.9-0.6l0.9-0.6v-1.1l-1-0.8l-1.2,0.4l-0.9,0.9
            l-0.8,0.7l-1-0.1l0.7-0.9l-0.3-1l0.3-0.1l0.9-0.4l-1-0.1h-1l1.1-0.3l-1,0.1l-1-0.2l-1.1-0.1l1-0.3l0.6-0.9l1-0.4l-0.8-0.7l0.3-1
            l-0.3-1l-0.9-0.6l-1-0.1l-1,0.3l-0.9,0.6l-1,0.2l-0.6,0.2l-0.8,0.7l-0.5,0.9l1-0.1l-0.9,0.3H407l-0.9,0.6l0.1,1.1l0.9,0.3l1.2,0.1
            l1,0.5l1.1,0.2h-1.5l-0.9,0.6l-0.3,0.2l-0.7,0.8l0.7-0.1l1-0.2l-0.8,0.8l-1,0.3l-0.9,0.4l-1.1,0.3l-1.1,0.2l-0.5,0.9l-0.3,0.9
            l-1,0.8l-1.1-0.2l1.1,0.1l0.3-1l-1,0.3l0.4-1l0.9-0.6l0.7-0.8l-1-0.1l-0.9-0.5l-1.1-0.1l-0.9,0.5l-0.9,0.5l1,0.3l0.7,0.8h-1l-1-0.3
            l-1.1,0.2l-1-0.3l-1-0.1h-1l-1,0.2l-1,0.2l-1-0.1l-1-0.4l-1-0.3l-1,0.1l-0.8-0.8l-1-0.3l0.3-1h-1.1l-1,0.2l-1,0.3l-1,0.1l-1.3,0.3
            l-0.8,1.1l1.1-0.2l1-0.3l1.1-0.2l1-0.3l1,0.3l-1,0.2l-1,0.3l-1,0.3l-1,0.1l-0.9,0.6l-0.3,1l-0.5,0.9l-0.8,0.8l0.3,1l-0.7-0.9
            l-0.5-0.9l0.7-0.8l-0.5-0.9l-1.1-0.3l-1-0.3l-0.9,0.3l-1.1,0.1l-1.1,0.2l-1,0.1l-1.6-0.1l-1.5-0.1l-1-0.2l-1-0.3l0.9-0.6l1.3-0.1
            l1.1-0.2v-1l-1.5-0.4l-1.3-0.1l-1,0.2l-1.1-0.1l-1-0.2l-1.2-0.3l-1.1-0.4l-1.2-0.1l-0.9-0.7l-1.9-0.4h-1.1l-1,0.2l-0.9,0.5l-1,0.3
            l-1-0.3l0.7-0.9l0.8-0.8l-0.9,0.4l-0.9,0.7l-0.9,0.5l-0.9,0.4l-1-0.6l-0.1-1l-0.9-0.6l-1.3,0.2l-0.9,0.5l-1.4,0.3l-1.2,0.1l-1,0.3
            l-1,0.3L341,157l-1,0.2l-1,0.3l-0.6,0.1l-1,0.3l-0.9,0.6l-1.1-0.2l1.1-0.3l0.9-0.4l1-0.2l1.1-0.5l1-0.1h1l1-0.4l0.9-0.3l1-0.5
            l-1-0.2l-1,0.3l-1,0.2l-0.9,0.3l-1.1,0.1l-1,0.3l-1.1,0.5l-1.1,0.1l-1.1,0.3l0.5-1l-0.9,0.7l-1.1-0.1l-0.9,0.3l-0.8,0.8l-1,0.6
            l-0.9-0.3l-1.1-0.1l-1.2-0.3l-1-0.7l-1-0.5h-1.1l-1.3-0.2l-0.7,0.7l-1,1.1l-1.1,1l-1,1l-1,1.1l-1,1l-0.9,1.1l-1,1l-1,1.1l-1,1
            l-0.9,1.1l-1,1.1l-1,1l-0.9,1.1l-0.9,1.1l-1,1.1l-0.9,1.1l-0.9,1.1l-1,1.1l-0.9,1.1l-0.9,1.1l-0.6,0.9h1.1l1.1,0.1l1.1,0.3l0.3,1
            l0.6,0.9l0.4,1l1.3-0.4l1-0.3l0.9-0.8l1.2-0.3l0.9,0.7l0.7,0.9l0.5,0.9v1l0.2,1.1v1.2l0.1,1l-0.3,1.1l0.9,0.4l0.9,0.5l0.6,0.9h0.1
            l-0.9,0.8l-0.3,1.1l1-0.7l-0.9,0.6l-0.9,0.7l-0.3,0.9l-1.2,0.1l0.1,1h1l-1.1,0.1l-0.2,1l0.7,0.9l0.9-0.8l1-0.3l0.1,1l-1-0.1l-0.1,1
            l0.2,1.1l-0.7,0.8l0.9-0.4l-0.5,0.9l1-0.4l0.9-0.5l0.9-0.6l-0.3,1l-0.5,0.9l-0.9-0.5l-1.1,0.5l-1.2,1.4h1.1l1-0.2l-1,0.3l-1.2,0.3
            l-0.9,0.6l1.1,0.1l-1.2,0.5l0.9,0.6l1.1,0.2l0.2,0.1l0.2-0.1v0.2l-0.2-0.1l-0.8,0.4l1-0.3l-0.1-0.1l0.7,0.2l1-0.6l-0.8,0.7l-1,0.4
            l1,0.2l1-0.2l1-0.6l-0.4,1l1-0.3l-0.7,0.7l-0.6,0.9l0.9,0.3l0.9-0.7v1l-1,0.3l1,0.1l0.9-0.4l-0.3,1l-0.6,0.9h21.6h1.4h21.2h1.4
            h12.8h1.4l1-0.4l-0.1,1.1l1.1,0.3l1.2,0.1h1l0.9,0.5l0.9,0.5l0.9,0.5l1.1-0.4l1,0.3l1.5,0.3l1-0.1l1.2-0.5l1.4-0.1l0.9,0.6l1,0.7
            l0.9,0.5l0.9,0.6l0.9,0.6l0.9,0.6l0.3,1l1,0.8l0.9,0.5l0.1,1l1.2,0.8l0.3,1l-0.2,1.2l-0.2,1.2l-0.2,1.1l-0.4,1.1l-0.7,0.9l-0.4,0.9
            l-0.8,0.8l-0.9,0.5l-0.5,0.9l0.7,0.9L398.8,229.8z M450,219l0.9-0.6l0.6-0.9l-1.1,0.1l-0.8,0.9l-1,0.2l0.7-0.8l0.9-0.6l0.7-1v-1
            l-0.5,0.3l-0.9,0.8l-0.6,0.9l-0.9,0.8v1l1,0.2L450,219z M445.7,217.4l0.8-0.7l-1.1,0.1l-1.1-0.2l-1,0.1l-0.2-0.9l-0.2-0.4l-0.8,0.9
            l0.8,0.7l0.9,0.5l0.9,0.4L445.7,217.4z M466.8,215.9l0.6-0.9l0.5-0.9v-1l-0.9,0.8l0.3-0.9l0.6-0.9l-0.9,0.6l-1,0.3l0.3-1l0.9-0.4
            l0.8-0.8l-0.9,0.4l-1.1,0.1l0.9-0.7l-1-0.3l0.9-0.5l0.7-0.9l-0.9-0.3l-1.1-0.1l-0.9,0.5l-1,0.3l0.4-1l-0.9,0.4l-0.6-0.9l0.7-0.8
            l-1-0.1l-1.1,0.5l0.6-0.9l0.8-0.9l0.6-0.8l0.9-0.6l-0.3-1l0.7-0.8l-0.4,0.2l-1,0.3l-1.1,0.9l-0.7,0.8l-0.9,0.8l-0.6,0.9l-0.7,0.9
            l-0.6,0.9l-0.8,0.7l0.4,0.9l-0.9-0.4l-0.7,0.9l-0.7,0.8h1.2l-0.9,0.5l-0.9,0.6l-1,0.6l0.3,0.9l1.1-0.2l1-0.2l1.1,0.2l1,0.1l1-0.1
            l1-0.1h1l-0.9,0.4l1,0.2l0.9-0.6l0.9,0.3l-0.9,0.4l-0.8,0.8l-0.9,0.7l1-0.1l0.9-0.7l0.9-0.6l0.9-0.5l0.5-0.9l0.6,0.9l-0.5,1l-0.2,1
            l0.9-0.5l0.1,1L466.8,215.9z M449.3,208.7l-0.9-0.6l-1-0.4l-1.1-0.3l-1-0.3l-0.8,0.4l1,0.3l0.9,0.9l0.9,0.4l1.3,0.3L449.3,208.7z
             M316.7,210.5l0.1-1.1l-0.9-0.6l-0.5-0.9l-0.3-1l-0.6-0.9l-1-0.3l-1.2-0.3l-0.9-0.6l-1.2-0.2l-0.1,1l1-0.2l-1,0.3l-0.4,0.9l1,0.2
            l1,0.4l0.8,0.6l-0.6,0.9l1,0.1l0.3,0.9h1l-0.2,1l1,0.5l1.4,0.1L316.7,210.5z M312.6,199.6l0.6-1.1l-0.9,0.4l-0.8,0.7H312.6z
             M412.8,198.8l-0.9-0.5l-0.2,0.1l-0.9,0.7l1.2,0.3L412.8,198.8z M305.9,200l0.2-1l0.7-0.8l-0.1,0.1l-1.1,0.2l-0.4,0.9l0.5,0.9
            l0.9,0.6L305.9,200z M306.9,197.7l0.7-0.9l0.7-0.9l-1,0.6l-0.9,0.3l0.6-0.9l-1-0.2l-0.4,0.3l-0.4,0.9l-0.1,1.1l1,0.1L306.9,197.7z
             M420,190.5l0.1-1.1l-0.6,0.3l-0.9,0.8l-0.9,0.7h1.1L420,190.5l-0.8,0.8L420,190.5z M426.2,175.5l0.4-1l-0.7-0.1l-0.9,0.5l-0.5,1.2
            l1,0.1L426.2,175.5z M452.1,174.8l-0.3-1l-0.9-0.6l-0.9-0.6l-0.8-0.8l-0.7-0.8l1.1,0.3l1-0.2l0.7,0.9l0.8,0.8l1-0.2l0.9,0.7
            l0.7,0.8l0.4-0.9l0.8-0.8l0.2-1l0.4,0.9l0.5-0.9l-0.1-1l-0.9-0.6l-0.3-0.2l0.9-0.4l-0.3-0.4l-0.7,0.4l0.7-0.4l0,0l0.3-0.1v-0.1
            l-0.3,0.2l-0.4-0.3l-0.9-0.3l-1,0.2l-0.4-0.9l0.3-1h-1.2l-0.2-1l-0.9-0.3l1-0.1l0.8,0.8l0.9-0.5l-0.7-0.9l1-0.3l1.1,0.2l0.7,0.7
            l0.4,0.3l1-0.3l-0.8,0.8l1.6-0.3l0.9-0.4l-0.9,0.7l-0.5,0.1l-0.9,0.7l1.3-0.1l-0.9,0.4l1-0.3l-0.8,0.6l1-0.1l-0.8,0.7l1,0.3l1,0.3
            l0.6-0.9l-0.2-1l1,0.1h1l0.3-0.9l1.2-0.2l-0.7-0.9l1,0.3l1-0.2l-0.7-0.8l1-0.3l-0.9-0.7l-0.9,0.7l-0.9-0.3l-1.2,0.3l0.7-0.8l-1-0.1
            l-1.3,0.1l1.3-0.3l-1.4-0.2l1.6-0.6l-0.9-0.5l-1,0.2l0.8-0.8l-1.1,0.2l-1-0.1l-1.1-0.1l-0.7-0.8l-1.1,0.1l1.3-0.1l0.9-0.2l-1.1-0.1
            l-1.1-0.2l-0.9-0.5l-1-0.2h1.1l1.1,0.1l-1-0.4l0.9-0.4l-1-0.2l1.1-0.1l-1.4-0.3l1.1,0.2l0.5,0.1l1,0.3l0.8-0.7h-1.4L455,157l-1,0.2
            l-1.2-0.1l1.3,0.1l1-0.3l1.1-0.2l1-0.2l1,0.1l-0.4-1l-1.1,0.1l-1.1,0.4l-1,0.2l-0.3,0.1l0.8-0.9l-1,0.2l-1,0.3l1-0.3l1-0.3l1-0.2
            l0.9-0.6l-1.4-0.4l-1,0.2l-1.1,0.2l0.9-0.6l-1.6,0.4l-1.1,0.7l-0.9,0.6l0.3-0.9l-0.9,0.6l0.8-0.7l1-0.2l0.9-1l-1.1,0.5l-1.3,0.3
            l-1.4,0.3l1-0.5l-1.1,0.1l1.3-0.3l1.1-0.3l1.1-0.4l-0.9-0.7l-1.1-0.3l-0.9,0.5l-1,0.3l-1,0.5l0.3-1l-1,0.3l0.7-0.8l-1.1,0.4
            l-0.9,0.1l1-0.4h-1.2l1.5-0.3l0.9-0.7l-2,0.2l-1,0.5l0.9-0.8l-1.2,0.3l1-0.3l0.7-0.9l-1.1-0.2l-1-0.3l-2,0.2l-0.4,0.9l1,0.2
            l-1.2-0.2l-1.2,0.3l0.7,0.9l-1-0.3l-1-0.3l0.7-0.9l-1-0.3l-0.9,0.5l-0.9,0.6l-1.1,0.3l0.8-0.8l0.9-0.6l-1.4,0.3l0.9-0.4l0.9-0.9
            l-0.6-0.9v-1h-1.5l-1.4,0.2l-0.9,0.2l-1.3,0.3l-1.1,0.9l1.1,0.2l-1-0.1l-1.3,0.1l0.9,0.4l-1-0.3l-1,0.2l-0.2,1l0.9,0.7l-1-0.3
            l-1.7,0.7l0.9,0.8l1.1,0.3l-0.7,0.8l-1,0.2h-1.2l-1.1,0.2l0.9-0.3l1.1-0.2l0.9-0.4l-1.1-0.5l-0.4-1l0.8-0.7l-0.1-1l0.9-0.5l0.9-0.6
            l1.3-0.4l0.9-0.7l-1.3-0.1l-1.5,0.1l-1.1,0.1l-1.2,0.3l-1.4,0.4l-0.9,0.6l-1,0.4l-0.9,0.5l-0.8,0.7l-0.6,0.9l-0.8,0.9l-0.5,0.9
            l2,0.2h1.1l0.8,0.7l-1,0.1l-1.6-0.3l-1.2,0.5l0.7,0.9l1,0.5l1-0.2h1.1l1.3-0.4l-0.9,0.6l1.2,0.3h1.1l1.3,0.3l1.3,0.1l1-0.1l1,0.3
            l0.9-0.5l-1-0.5l1,0.3l0.9,0.5l1.1,0.3l-0.9-0.7l1.3,0.1l1.3,0.3l1,0.2l1.5-0.9l-1-0.5h1.1l0.9,0.6l1-0.1l-0.3,0.9l0.9,0.6l0.9,0.4
            l0.6,0.9h-1.1l-0.7,0.9l1.3-0.4l1-0.4l1,0.3l0.5,0.9l1.1-0.3l0.1,1l0.9,0.4l0.1,1l0.1,1l-0.9,0.4l-0.6,0.9l1.4,0.1l1.4-0.2l0.9-0.6
            l0.9,0.7l0.9,0.3l0.9,0.5l-0.9,0.5l-1.1-0.1h-1l-1,0.3l0.2-1l-1.3-0.3l-1.6-0.1l-1,0.4l-1.5,0.6l-0.4,0.9l0.3,1.1h-1l-1.1,0.1
            l-0.9,0.4l-0.9,0.4l-1-0.5l-1.4-0.3l-0.9,0.5l-1.1,0.3l-0.9,0.7l-0.3,1l0.9,0.4l1.2,0.1l0.9-0.4l1.1-0.2l0.3-0.4l1.1,0.3l1-0.1
            l0.5-0.9l-0.3,0.5l0.8,0.7l1-0.1l0.7,0.9l0.9,0.7l0.3,0.2l0.8,0.7h-1l0.3,0.1l0.7,0.9l0.9,0.4l1,0.3l1-0.1l0.7,0.9l1,0.5l1.6,0.3
            l1,0.3l1,0.3h1L452.1,174.8z M444.9,173.9l-0.9-0.6l-0.9-0.1l0.7,0.8L444.9,173.9z M419.3,175l1.1-0.5l1.2-0.5l0.5-0.9l-0.2-0.1
            l-1,0.2l-1.6,0.3l-0.8,0.7l-0.5,1L419.3,175z M431.5,172.2l-0.9-0.5l-0.9,0.3l0.9,0.7L431.5,172.2z M417.8,172.1l0.9-0.7l1-0.3
            l0.9-0.8l1,0.2l0.9,0.7l1.2,0.1l1,0.3l1-0.2l1-0.7l-0.9-0.3l-1.1-0.3l0.1-1l-1.1-0.6l-0.9-0.5l-0.9-0.5l-0.9-0.7l-1,0.2l-0.3-0.9
            l-0.4-0.3l-1.1,0.8l-0.6,0.9l-0.7,0.9l-0.9,0.7l-0.2,1l-1,0.3l-0.8,0.9l1.1-0.1l0.9,0.3l0.5,0.9L417.8,172.1z M444.6,160.6
            l-1.1-0.2v-0.2l-0.6,0.9L444.6,160.6z M439.2,162.4l1-0.2l1-0.4l0.5-0.9l-0.3-1l-1.3-0.1l-1.4,0.5l-0.9,0.7l-0.5,0.9l0.9,0.6
            L439.2,162.4z M436.8,158.1l0.7-0.9l-0.2,0.1l-0.9,0.5l-0.8,0.7L436.8,158.1z M373.7,159.4l1.8-0.2h1.1l1-0.1h1.1l1.5-0.3l1.1-0.4
            h1.2l1.3-0.3l1.1-0.7l1-0.4l0.7,0.9l0.9,0.6l1.9,0.2l1.2,0.1l1-0.1l1.2-0.3l0.9-0.3l0.4-1l-1.3,0.2l-1,0.4l0.7-0.9l1-0.4l1-0.2
            l1,0.1l1,0.2l0.3-1l-1.1-0.3l-0.9-0.4l-1-0.3l-1-0.3l-0.5-0.9l0.9-0.5l0.3-0.9l0.1-1.1l0.3-0.9l-0.6-0.9l-0.9-0.6l-1,0.2l-1,0.1
            l-0.9,0.5l-0.3,1l-0.4,0.9l-0.6,0.9l-0.9-0.3l0.2-1l-0.1-1l-1.2-0.5l-1.1,0.3l0.7,0.8l-1,0.2l-1.2-0.1l-1.1,0.1l0.5-0.9l-1.2-0.3
            l-1.1-0.1l-1.2,0.7l-1.4-0.1l0.9-0.4l0.4-0.9h-1.2l-1,0.2l-1.3,0.3l-1.4,0.3l-1,0.2l-1.2,0.3l-1.2,0.3l-0.8,0.8l-1,0.3l-1.1,0.4
            l-0.9,0.9l1-0.1l0.9,0.6l1.3-0.1l1-0.1l1.1-0.2l1.1,0.2l-1,0.3h-1l-1.6,0.3l-1.4,0.2l-1.4,0.8l1.3,0.5l1,0.1l1.1,0.1l1-0.1l1.2-0.1
            l1.6-0.2l1.1,0.2l1.2,0.3l0.9,0.6l-1.2,0.1h-1l-1.1-0.1l-1.5-0.1l-1.4,0.1l-1.5,0.1l-1.9,0.3l-1.1,0.3l0.4,0.9l0.9,0.7l1.8,0.1
            l1.1-0.1l1,0.7l-0.4,0.9l0.9,0.5L373.7,159.4z M403.6,159.3l1-0.4l0.9-0.4l-1.3-0.3l-0.3-1l-1-0.3l0.2-0.4l-1.2-0.1l-0.9,0.5
            l-0.9,0.7l-0.9,0.4l-0.9,0.7l1,0.1l1,0.3l1,0.4l1.1,0.1L403.6,159.3z M435.9,156.3h-0.2l-1,0.1l-1,0.3l1.2,0.1L435.9,156.3z
             M404.8,151.8l1,0.3l1.4-0.3l0.9-0.8l1.3-0.7l0.5-0.9L409,149l-1.3-0.2l-0.9,0.3l0.9-0.6l1.3-0.4l0.9-0.6l0.8-0.6h-1.3l-1,0.1
            l-1.2,0.1l-0.9-0.3l-0.6,0.3l-1-0.3l-0.9,0.6l-1.4,0.3l0.9,0.5h1.4l-0.9,0.5l-0.9,0.6l-1.2-0.1l-0.9-0.7l-1,0.3l-0.7,0.8l0.9,0.8
            l1,0.1l0.9,0.5l0.8,0.7l0.5,0.9l1.1,0.1L404.8,151.8z M441.8,149.2l1.1-0.2l1.3,0.1l1.1,0.1l1.1-0.3l-0.6-0.9l-0.9-0.5l-1-0.1
            l-1.5,0.1l-1.1-0.2l-1.1-0.2l-0.9,0.1l-0.1,1.1l0.5,0.9l1,0.2L441.8,149.2z M395.5,148.6l1-0.5l-0.2-0.9h-1.1l-1.7,0.1l-1,0.5
            l1.1,0.6l0.9,0.6L395.5,148.6z M409.1,146.8l1-0.3l-0.9-0.1l-1.3,0.3l-1.2,0.3l1.2,0.1L409.1,146.8z M412.1,151.1l1.1-0.3l0.9-0.4
            l-0.1-1h2l1.5,0.1l1-0.4l1.1-0.8l1-0.4l1.3-0.5l0.9-0.6l-1-0.2l-1.4-0.1l-1.1-0.1l-1.2-0.3l-1.2,0.1l-1.5,0.1l-0.5,1l-1.3-0.3
            l-0.9,0.9l-0.7,0.9l-0.2,1l0.3,0.9l-0.9,0.5L412.1,151.1z M360.5,153l0.9-0.3h1.1l1.4-0.3l1-0.3l0.8-0.9l0.9-0.6l1-0.1l1.2-0.5
            l0.9-0.4l1.1-0.3l1.3-0.3l1.1-0.3l1-0.2l1-0.3l1.4-0.3l0.9-0.7l-0.9-0.6l-1.2-0.7l-1-0.2l-1.6,0.3l-1-0.2l-1-0.2l-1.9-0.6l-0.4,0.1
            l-1.2,0.1l-1.1,0.1l-1.3,0.1l-1,0.1l-1,0.6l-0.2,1l-1,0.4l-0.9,0.5l-0.8,0.8l-1.1,0.5l-1,0.4l-0.9,0.5l-0.8,0.7l1.3,0.4l1.2,0.8
            l0.7,0.9H360.5z M440,145.3l1.2-0.1l0.8-0.6l1-0.3l-1.1-0.1l1-0.2l1-0.3l-0.1-0.4l-1-0.3l-1-0.3l-2.6-0.1l-1.4,0.2l-1-0.1l-1.2,0.3
            h-1.1l-0.9,0.5l-1.4-0.1l-0.9-0.3l-1.6,0.2l-1-0.3l-0.9,0.3l-0.5-0.9l-1-0.1l-1.1-0.1l1.7-0.1l-1.5-0.3h1.2l2.2-0.3l-1.8-0.3
            l-1-0.2l1.1-0.2l-1.3-0.3l-2,0.2l-1.2,0.1l-1,0.3l0.5-1L421,140l-1.4-0.2h-1l-1,0.6l-1.1,0.2l1,0.2h1l-1,0.3l1.1,0.4h1l1.6-0.1
            l1.1-0.1l0.2,0.3l0.6,0.9l-0.8,0.7l-0.9,0.6v1l1,0.3h1l1.2,0.3l1-0.2l1.2-0.2l1-0.3l-0.9,0.7h1.1h1.1l1-0.3l0.9,0.3h1.4l1-0.1h1
            l1.3-0.1l0.9-0.6l0.9,0.7l1.1,0.2L440,145.3z M385.2,145.6l1.9-0.3l1-0.2l1.1-0.4l1-0.1l1-0.2h1.5l1-0.2l1,0.2l1.1-0.2l2.2-0.6
            l1-0.6l0.6-0.9l-1-0.3l-1,0.6l-1-0.3l-1.4,0.3l1-0.5l-1.1-0.2l0.9-0.6l-0.3-1l-1.1,0.3l-0.9,0.4l-1.1,0.2l-0.3,0.3l1.1,0.6
            l-0.9,0.5l1,0.3l-0.5,0.4l-2.3-0.2l-1.1,0.1l-0.5-0.9l-1-0.6l-1.1-0.1l-1-0.2l0.2-0.3l-1.1-0.2l-1.2,0.2l-1,0.4l1,0.2l-1.3-0.1
            l-1.1,0.1l-0.9,0.6l1.7,0.1h1.4l-1.6,0.1l-2,0.1l-1,0.5l2.7-0.2l1.1-0.1l-1,0.2l-1.8,0.3l-1.4,0.1l-1.1,0.4l1,0.2l1,0.3l1,0.2h1
            l1.3-0.3l0.9-0.5l1.1-0.1l-0.9,0.8l1-0.1l1-0.2h1.6h1.1l-1.1,0.3h-1.1l-1.1,0.1l-1.1,0.3l-1,0.1l-0.8,0.9l1.1,0.1L385.2,145.6z
             M401.7,143.7v-0.2l-1-0.1l-1.4,0.6l1.2,0.3L401.7,143.7z M418.8,144.6l0.6-0.9l-0.9-0.8h-1.7l-0.9,0.4l-1,0.3l-0.8,0.8l0.9,0.3
            l1.1,0.2l1,0.1L418.8,144.6z M411.2,143.9l0.8-0.7l1,0.1l0.4-0.9l0.7-0.8l-0.7-0.9l-0.3,0.1l-1,0.3l-1-0.4l-1.1,0.1l-0.9,0.5
            l1.2,0.3l-1.1,0.1l0.9,0.6l-1-0.2l-0.8-0.7l-1-0.3l-0.9,0.5l1.3-0.1l-1.1,0.6l0.9,0.4l-1.3-0.3l-1,0.4l-1.1,0.5l1.1-0.2l1.9-0.1
            l1.4-0.1l1.1-0.1h1.3l-2.3,0.5l-1.4,0.3l1,0.3l-0.9,0.4l1.6,0.1h1.1L411.2,143.9z M404.9,142.5l0.9-0.3h-0.7l-1.9,0.5L404.9,142.5z
             M405.6,142l-0.2-0.1l-1.4,0.2l-1.1,0.3L405.6,142z M377.2,143l1.3-0.6l0.9-0.6l-0.3,0.1l-1.2,0.3l-1,0.3l-0.8,0.7L377.2,143z
             M375.6,142.2l0.7-0.8l1.1,0.3l0.7-0.8h1.1l1-0.3l-0.7,0.8l1.1,0.1l0.9-0.6h1.1l1-0.4l0.4-0.9l0.9-0.6l-0.3-0.2l-1.2,0.5h-1.1
            l-1.1-0.1l-1.3,0.1l-1.1,0.3l-1,0.3l-1,0.3l-1,0.3l-1.1,0.2l-0.9,0.4l-1.1,0.1l-1.5,0.4l-0.8,0.7l1.2-0.2l1.2,0.2l1.1-0.5l0.8,0.8
            L375.6,142.2z M404,142l1.4-0.4v-0.1l-1.6,0.1l-1,0.5L404,142z M444.1,141.6l2-0.4l1.1-0.1l1-0.3l0.5-0.9l-1.3,0.3l-1-0.2l0.9-0.5
            l-1-0.2h-1.1l-1.2-0.1l-1.7-0.1l1-0.7l1.1,0.6l1,0.2l1.1-0.1h1.6l1-0.3l0.9-0.7l0.4-0.3h2l1-0.2l1.1-0.3l-1-0.3l1.4,0.4l1-0.3
            l-2-0.3l1.8,0.1l1-0.8l-1-0.1l-1.2,0.1l1.3-0.3l-1.5-0.1h-0.8h-0.6l-1.2-0.1l1.8,0.1l0.4-0.1l1.4-0.1l-1.4-0.1l-1.9-0.1h2.6
            l1.2,0.3l1,0.1l1.4-0.4h-1.4h-1.5l-1.4-0.1l-1-0.2h1.6l1.1-0.2l1.1,0.1l1.4-0.1l1.4-0.2l1-0.4l-2-0.2l1.1-0.1l1.3,0.1l1,0.3
            l1.1-0.1l1.3-0.4l0.9-0.4l-1.3,0.1h-1l1.1-0.1l1.1-0.1l1.6-0.3l1.5-0.1l1-0.4l1.1-0.2l1.1-0.3l1.1-0.2l1.5-0.3l1.1-0.3l1.4-0.2
            l1.3-0.4l-1.2,0.1l-1.7,0.1l-1.7,0.2l-1,0.1l-3,0.3h-1l1.3-0.2h1.4l1-0.2l1.5-0.3h-1.8l-1.2-0.3l2.4,0.3h1.8l1.1-0.1l1-0.1h-1.1
            l2-0.1l1.5-0.1l1.4-0.3l1-0.1l1-0.3l1.5-0.5l-1.2-0.2l-1.5-0.5l-1.7,0.2l-1-0.2h-1l-1.8,0.2l-1,0.2l-1.5-0.1l1.1-0.1l1.1-0.1
            l1.4-0.3h-1.3h-1.3l-1-0.1l-1.4-0.3l-2,0.1h-1l-1.1-0.1l-1.1,0.2l-1.1,0.3l1,0.3l-2-0.5l-1.9-0.1h-0.8l-2,0.1l1.5,0.7l-1.2-0.3
            l-1.2-0.3h-1.1l-1.1-0.1l-1.3,0.3l1,0.2l-1.7-0.2h-1.9l1,0.4l-1.2-0.2l-1.4,0.1l1.1,0.2l-2.2,0.2l2.3,0.5l1.6,0.4l-1.3-0.3l-2-0.3
            h-1.4l1.1,0.3l-1.9-0.5l-1.4-0.3l-2,0.4l-2,0.3l2.6,0.4h-1.1l-1.5-0.3h-1.1l-1.1-0.1l-1,0.1l-1.2,0.3h-1.2h-1.1l-1,0.2l-1.1,0.5
            l1.6-0.3l1,0.2l-0.9,0.4l1.8-0.3l1.4-0.1l1.1,0.1h-1.2l-1.4,0.2l-1.1,0.3l1.3,0.1l-1.9,0.4l4.1-0.1l1.4-0.1l1.3-0.3h1.1l-1.4,0.3
            l-1.1,0.1l-1.1,0.1l-1.6-0.1l-1.2,0.1l-1.1,0.2l1,0.3l1.4,0.2l1.2-0.2l1.5-0.3l1.1-0.2l1.1-0.1l1.6-0.1l2.4-0.1l-2.5,0.2l-1.8,0.1
            l-1,0.2l-2.2,0.6l1.8,0.2h1.1h1.4l1-0.4l1.1,0.1h1.4l-1.9,0.3l1.3-0.1l1.4-0.1l1.2-0.1l1.3-0.2l1-0.2l0.9-0.7l1.7-0.1l1.4-0.3
            l1.8-0.1l-1.1,0.2l-1.2,0.2l-1,0.3l-1,0.5l1.2-0.1h1.3l1.2,0.1h-2l-1.5,0.2l-1.4,0.1l-1.1,0.2h1.2l1.8,0.1h-1.2l-1.1,0.1l-1.5,0.1
            L448,133l-1.4,0.6l0.9,0.7l1,0.2l1.5,0.1l-1.2,0.1l-1.8-0.3l-0.9-0.6l-1.4-0.5h-1.3l-2-0.2l-1.5,0.7l1.4,0.2l-0.9,0.4l1,0.1
            l0.6,0.9l1.2,0.3l-1.5,0.2h1.5l1.3,0.2h1.3l-1,0.1l-1,0.3l-1.4-0.3l-1.5-0.1h-1.1l-1.1,0.1h-1.1l-1.4,0.6l-1.3,0.9l1.9-0.2l1.1-0.2
            l-0.9,0.5l1.3-0.1l1.1-0.5l0.9-0.4l-0.9,0.7l1,0.1l-1,0.3l-1.1,0.3l1.4,0.1h-1l-0.9,0.5l1.4,0.3h1.1l1.1-0.4l1.2-0.3l-1,0.4l-1,0.4
            l-1.1,0.2l-1.4,0.2l-1.2-0.3l-0.8-0.9l-1.2-0.1l-1.7,0.7l0.7,0.8l-1.4,0.2l-1.3,0.1l-1,0.3l-1.1,1.2l1-0.1l0.7-0.8l-0.5,0.9
            l1.1,0.1l1.1-0.1l1.2-0.6l0.9,0.6l0.9,0.2l1.4-0.3l1.3-0.2l1.7,0.3l1.4-0.3l1.1,0.1l0.8,0.7L444.1,141.6z M403.7,139.1l0.3-0.3
            l-1.3-0.3l0.1,1L403.7,139.1z M423.8,138.3h-1.3h-1.8h-0.6l-0.9,0.6l3.7,0.1L423.8,138.3z M410.2,138.4h1v-0.1l-1.1-0.3l-1,0.4
            H410.2z M393.3,139.1l1.1-0.1l1.4-0.7l-1-0.3l1.4-0.1l1.2-0.3h-0.2h-2.1l-1.2,0.1l-1.2,0.1l-1.4,0.3l-0.4,0.9l1,0.3L393.3,139.1z
             M415.2,137.7l-0.9-0.6l1-0.5l-0.9-0.5l-1-0.3l-1.1-0.3l-1.2-0.3l-1-0.2l-0.1-0.1l-3,0.1v1l1-0.3l0.7,0.9h-1l-1.5,0.4l1.3,0.1
            l1-0.1l1.1,0.2h1.8l0.8,0.9l1.3,0.1L415.2,137.7z M395.8,137.1l1.6,0.1l1.2-0.6l-1.1-0.3h-0.9l-1.2,0.3l-1.7,0.2l-1.2,0.4l1.1-0.1
            l1,0.2L395.8,137.1z M430.2,137.1l1.2-0.3l0.8,0.7l1.1-0.4l0.9-0.6l0.8-0.7l1,0.5l1-0.4l1-0.3l1.8-0.2l0.6-0.9l-1-0.1H438l0.6-0.8
            l-1.2-0.2l1.1-0.5l-1.4,0.6l-0.8-0.8h-1.2l-1-0.4l-0.9-0.9l-1.2-0.3h-1.4l-1,0.2l1,0.3l-1,0.1l-1,0.1h-1l-1,0.4l1.4,0.4l-1.2-0.1
            l-1.1,0.4h-1l-1,0.3l1.3,0.2l1.1,0.1l1.5-0.1l-1,0.4l-1.1-0.2h-1.3h-1.3l0.7,0.8h1.2l1.6-0.2l-1,0.3l-2.1,0.4l1.1,0.3l1.6,0.1
            l1.1-0.2l1.4-0.1l1.1,0.1l1.2-0.1l-0.7,0.1l-1.2,0.3l1.3-0.1l1,0.1l-1.5,0.1h-1.2h-1.4l-1.2,0.2l-1.1,0.3l0.9,0.8l1-0.1l1.3,0.2
            l-1.3,0.5l1,0.3l1.1,0.1L430.2,137.1z M419.3,138.2l1.4-0.2l1-0.2l0.5-0.9l-1.2-0.3l-1.1-0.2l-0.7-0.2l-1.7,0.2l-0.1,1l1.2,0.3
            l-1.4,0.2l0.9,0.4L419.3,138.2z M418.7,133.6l-0.2-0.1l-1,0.1l-0.3,0.4L418.7,133.6z'
              ></path>
              <polygon
                id='USA'
                class={style.st2}
                points='391.7,276.6 392.5,276 393,275 393.4,274.1 393.7,273.2 394,272.1 393.7,271 393.5,269.6 393.1,267.6
            393.3,266.5 393.4,267.5 393.4,267.7 393.5,266.8 393.1,265.8 392.8,264.7 392.7,263.5 392.7,262.4 392.8,261.2 392.9,260.1
            393.2,259.1 393.8,258.3 394.6,257.5 395.4,256.8 395.7,255.9 396.9,255.7 397.9,255.1 398.7,254.5 399.6,253.9 400.1,253
            400.9,252.3 402,252 403,251.6 403.8,250.6 404.8,250 405.8,249.6 406.7,249.1 405.8,248.5 406.8,248.5 406.3,247.5 407.3,247.8
            408.3,247.4 409.1,246.8 408.4,246 407.2,245.9 408.1,245.4 409.1,245.6 409.3,244.5 409.4,245.7 409.4,244.5 409.3,243.4
            408.2,243.3 407.4,242.5 408.4,242.5 407.8,241.7 408.9,241.6 408.2,240.7 407.6,239.9 408.3,240.6 409,241.4 409,240.4
            408.1,239.9 407.3,239.1 408.2,238.3 408.1,239.3 409.1,239.4 409.3,238.4 409.9,237.5 410.1,236.5 410.5,236.8 410.4,237.9
            409.8,238.8 410.4,239.5 410.6,240.5 410,241.4 409.8,242.4 410.6,241.6 411.2,240.7 411.8,239.9 412.5,238.9 412.4,237.9
            412.3,236.8 412.1,235.7 412.9,235.2 412.2,235.9 412.7,236.8 413.4,237.6 414,236.7 414.9,236.1 415.6,235.3 416,234.4
            415.8,233.3 416.5,232.5 417,231.6 416.5,232.7 417.5,231.9 418.4,231.5 419.3,231.2 420.4,231.1 421.5,231 422.6,230.8
            423.2,229.9 424.2,230.1 425.2,230.1 426.2,230.1 426.2,229 425.6,229.9 425,229 424.4,228.1 425.2,227.5 425.3,226.5 426,225.5
            426.7,224.8 427.4,224 428.5,223.9 429.5,223.7 430.2,222.8 431.3,222.7 432.2,222.2 433.2,222.1 434.1,221.5 435.1,221.3
            434.8,220.3 434.7,219.2 434.1,218.3 434.5,217 434.8,215.8 434.8,214.8 434,214.3 432.8,214.7 431.6,214.5 430.8,215.2 430,216
            429.4,216.9 428.8,217.8 428.3,218.7 427.4,219.3 426.6,219.9 425.6,220.4 424.4,220.7 423.3,220.8 422.2,220.8 421.1,220.8
            420.1,220.8 419,220.8 417,221.1 415.8,221.9 414.7,222.6 413.7,223.3 412.9,224.1 411.9,224.6 410.4,224.6 408.9,224.6
            407.8,224.8 407.2,225.9 407,226.9 405.8,227.3 404.6,227.8 403.6,228.2 402.4,228.4 401.1,228.7 399.9,229.3 398.8,229.8
            397.8,230.1 397.1,229.1 397.6,228.2 398.6,227.7 399.3,226.9 399.7,226 400.4,225.1 400.9,224 401,222.9 401.2,221.7 401.4,220.5
            401.1,219.5 399.9,218.7 399.8,217.7 399,217.2 398,216.4 397.7,215.4 396.8,214.8 395.9,214.2 395,213.6 394.1,213.1 393.1,212.4
            392.2,211.8 390.8,211.9 389.6,212.4 388.6,212.5 387.1,212.3 386,211.9 384.9,212.3 384,211.8 383.1,211.3 382.2,210.8
            381.2,210.8 380,210.7 378.9,210.5 379,209.4 377.9,209.8 376.6,209.8 363.8,209.8 362.4,209.8 341.2,209.8 339.8,209.8
            318.8,209.8 318.9,210.6 318.6,211.6 318.3,212.6 317.6,213.4 317.2,214.3 316.3,214.6 316.9,213.8 317.4,212.9 316.2,213.7
            317.1,213 317.5,212.1 316.4,212.2 315.3,212.1 314.2,211.7 313.4,212.2 313.4,213.3 313.1,214.4 313.4,215.4 312.6,216.2
            312.5,217.3 313.5,217.6 312.4,217.5 311.7,218.2 311.3,219.2 310.7,220.1 310.2,221 309.7,221.9 309.1,222.7 308.6,223.7
            308,224.6 307.2,225.5 306.6,226.3 306.3,227.3 305.8,228.2 305.8,229.2 305.5,230.4 305.1,231.3 304.7,232.4 303.8,233
            303.4,234.1 303.9,235.3 303.6,236.3 303.4,237.2 303.5,238.3 304.1,239.7 304.3,240.6 305.1,240 306.1,240.1 307.2,240.4
            306.1,240.2 305.1,240.8 305.1,241.9 304.5,241.1 304.3,242.2 304.4,243.2 304.6,244.2 304.4,245.2 304.9,246.2 305.4,247.1
            305.5,248.3 305.7,249.3 305.7,250.4 306.8,250.3 307.9,250.6 308.6,251.4 309.7,251.5 310.4,252.4 311.2,253.4 311.6,254.5
            311.6,255.8 312.6,255.7 313.6,255.6 314.7,255.5 315.8,255.4 316.8,255.4 317.7,256.3 319.4,257 321,257.7 322.7,258.4
            323.7,258.9 324.8,259.2 325.9,259.2 326.9,259.2 328,259.2 329.1,259.2 330.2,259.2 331.2,258.9 331.6,257.9 332.6,257.9
            333.7,257.9 334.8,257.9 335.8,258.5 336.5,259.3 337,260.1 337.8,260.9 338.2,261.8 338.2,262.9 338.4,263.9 339.1,264.6
            340,265.2 340.9,265.8 341.9,265.2 342.3,264.3 343.1,263.6 344.4,263.5 345.4,263.6 346.1,264.3 346.8,265.1 347,266.1
            347.3,267.1 347.7,268.2 348,269.2 348.6,270 348.6,271 348.8,272.1 348.9,273.1 349.8,273.6 350.8,274.1 351.8,274.2 352.6,274.8
            353.2,274.4 353,274 352.8,273 352.8,272 353.2,271 352.6,270.2 353.7,270 354.3,269.1 354.9,268.3 355.9,267.7 356.4,266.9
            357.3,267.1 358.3,266.6 359.2,266.1 359.9,265.2 360.8,264.7 360.5,263.8 361.2,264.6 362.7,263.9 363.7,263.6 364.8,263.6
            365.8,264.1 366.9,264.2 367.6,263.5 368.6,264 368.9,265 369.8,265.4 370.8,265 371.7,265.3 372,264.3 372.9,265 373.3,265.9
            374.2,265.4 373.4,264.7 373.3,263.7 373.9,262.9 372.8,262.5 371.9,262.2 372.9,262.3 373.9,262.3 375,261.9 376,262 377,262
            377.7,261.2 377.4,262.3 378.5,262.2 379.6,261.9 380.1,261.8 379,262.1 380.2,261.8 380.1,261.8 380.3,261.8 380.6,261.8
            380.2,261.8 380.8,261.8 382,262.3 382.8,262.9 383.3,263.9 384.6,263.5 385.5,262.9 386.5,262.9 387.2,263.6 387.7,264.6
            388.4,265.2 388.9,266.2 388.7,267.2 388.2,268.2 387.9,269.2 388.9,269.2 388.3,269.9 388.1,270.2 388.3,271.2 389.2,271.9
            389.2,272.9 389.4,273.9 390.2,274.7 390.5,275.7 390.6,276.8 	'
              ></polygon>
              <polygon
                id='Alaska'
                class={style.st2}
                points='313.2,193.5 314.1,192.8 314.5,191.9 315.2,191.2 314.7,190.3 313.8,189.8 312.9,189.3 313.2,188.2
            313.1,187.2 313.1,186 312.9,184.9 312.9,183.9 312.4,183 311.8,182 310.8,181.3 309.6,181.6 308.8,182.4 307.8,182.7 306.5,183.1
            306,182.1 305.5,181.2 305.2,180.1 304.1,179.9 303,179.8 301.9,179.8 302.5,179 303.4,177.8 304.3,176.7 305.4,175.6 306.3,174.5
            307.2,173.4 308.3,172.3 309.2,171.2 310.1,170.1 311.2,169.1 312.2,168 313.1,166.9 314.1,165.8 315.2,164.7 316.2,163.7
            317.1,162.6 318.1,161.6 319.2,160.5 320.2,159.5 321.3,158.4 322.3,157.3 323,156.6 322.1,156.5 321.1,156 320.2,155.6
            319.2,155.6 318.1,155.7 317,155.9 315.9,155.8 314.9,155.4 313.8,155.4 312.6,155.4 311.4,155 310.3,154.8 308.9,154.6
            307.8,154.7 306.7,154.8 305.6,154.5 304.5,154.3 305.5,153.9 304.3,153.7 303.2,153.7 301.7,153.9 300.8,153.2 299.8,153.6
            298.8,153.7 299.5,152.9 298.4,152.8 297,153.3 295.8,153.7 294.3,153.9 293,153.7 291.7,154 290.6,154.3 291.7,154.6 290.7,154.7
            289.7,155.1 290.6,154.4 289.6,154.7 288.4,155 287.3,155.2 286.1,155.2 285.2,155.5 284.2,156 283,156.4 282.1,157 281.2,157.5
            279.3,158.1 278.1,158.3 276.7,158.5 275.3,158.5 274.1,158.9 273.3,159.5 272.2,159.8 273.5,160.4 274.3,161 275,161.8
            274.4,162.8 275.4,162.9 276.4,163 277.6,163.2 277.2,164.1 278.1,163.9 279.5,164 278.6,164.5 277.6,164.1 276.6,164.4
            275.7,163.5 275.9,164.6 276.9,164.9 275.9,165 274.9,165.4 273.8,165.3 272.7,165.3 271.8,164.8 272.5,164 271.4,164.1
            270.2,164.4 269.1,164.5 267.8,165.2 266.5,165.2 265.4,165.4 264.5,165.8 263.5,166.1 262.9,166.9 264,167.5 263,168 262.9,169
            264.1,169.2 265.7,169.2 267.1,169 268.4,168.9 269.6,169.2 270.6,168.8 271.6,168.6 272.7,168.5 273.7,168.4 272.8,169.1
            271.8,169.2 271.7,170.2 271.1,171 270.2,171.5 268.7,171.7 267.4,171.8 266.4,172.2 265.4,172.6 264.3,172.7 263.4,172.2
            262.2,172.8 261.2,173.1 260.4,173.8 261.4,173.6 260.3,174.4 259.3,174.3 258.3,174.7 257.1,175.4 256.1,175.9 255.6,176.8
            255.3,177.8 256.1,177.3 257.1,176.7 258.1,176.5 257.1,176.8 256.6,177.7 255.7,178.2 255.8,178.2 255.8,178.2 255.8,178.2
            255.8,178.2 254.9,178.8 254.3,179.6 254.9,180.3 255.7,181 256.9,180.9 258.1,180.6 258.9,179.9 259.8,179.4 260.7,179
            259.7,179.5 259.1,180.4 258.8,181.4 258,182.1 257.6,183 256.8,183.7 255.9,184.1 257.1,184.1 258.3,183.6 259.4,183.3
            260.5,183.4 261.6,183.6 262.3,184.4 262.9,183.6 264.1,183.1 263.5,184.1 264.7,183.9 265.8,183.6 266.8,183.2 267.8,182.7
            266.9,183.2 266,183.9 264.7,184.5 263.9,185.3 263,186 262.6,187 261.5,187 260.4,187.5 258.7,188.7 257.7,188.8 256.5,189.3
            255.5,189.5 254.4,189.9 253.4,190.5 252.8,191.5 252,191 250.9,191.1 249.7,191.4 248.7,191.7 246.6,192.8 245.7,193.1
            244.9,193.9 246,193.7 246.8,192.9 247.6,193.4 248.6,192.8 249.4,192.2 250.4,192.2 249.5,192.6 250.7,192.3 251.8,192.3
            252.7,191.8 253.8,192 254.7,191.5 255.7,191.2 256.6,190.8 257.5,190.1 258.5,189.7 259.5,189.3 260.6,189.3 261.5,188.9
            262.5,188.6 263.5,188.2 264.2,187.4 265.4,187 266.4,186.6 267.4,186.4 268.3,185.9 269.3,185.6 270.4,185.3 271.1,184.7
            272.1,184.2 273.2,183.9 273.9,183.1 272.8,182.7 273.7,182.1 274.6,181.7 275.6,181.4 276.6,180.8 277.6,180.7 278.2,179.9
            279.4,179.6 280.3,179 281.3,178.6 282.2,178.1 283.2,177.8 284.3,177.4 285.6,177.3 287,176.9 288,176.7 286.8,177.1 286,177.8
            287.1,178.3 285.9,178 284.9,177.8 283.9,178.2 282.4,178.6 281.7,179.5 280.8,180.1 279.8,180.5 280.2,181.3 279.3,181.9
            278.5,182.5 279.6,182.6 280.5,182 281.6,181.9 282.6,181.3 283.6,180.9 284.7,180.7 285.7,180.7 286.7,180.3 287.5,179.6
            288.5,179.2 287.7,178.6 288.6,178.4 289.5,177.8 290.5,177.4 289.9,178.4 290.9,178 291.9,177.9 293.1,177.8 291.8,178.4
            292.9,178.4 291.9,178.7 292.8,179.3 293.8,179.5 294.9,179 294.7,180.1 295.7,180.5 296.9,180.3 298.4,180.3 299.4,180.5
            300.5,181 301.5,181.3 302.6,181.1 303.7,180.7 304.8,180.9 303.6,181.3 302.5,181.7 303.7,182.4 304.7,183 305,183.9 305.7,184.7
            306.8,184.7 307.9,184.5 307.6,183.6 306.5,183.6 307.6,183.4 308.6,183.6 308.1,184.5 308.9,185 309.9,184.3 310.2,183.4
            310.9,182.6 310.7,183.7 310.4,184.7 311.4,184.9 312.4,184.4 311.5,185 311.8,186 312.2,187 311.2,187 311.2,188.2 312,188.8
            311.8,189.9 312.8,190.4 311.8,190.5 311.2,191.6 312,191.1 312.5,191.1 312.5,191.1 312.9,191.1 312.9,191.1 312.5,191.1
            311.6,191.6 311.3,192.7 312,191.9 311.8,192.9 312.6,192.2 312.9,191.1 313,191.1 312.9,192.2 312.6,193.1 312.1,194.1 	'
              ></polygon>
              <path
                id='AlaskanAndEastCoastIslands'
                class={style.st2}
                d='M353.3,273.1l-0.1-1.2v1v1.1L353.3,273.1z M360.8,264.8L360.8,264.8L360.8,264.8l-0.8,0.7L360.8,264.8z
                M417.8,232.9l1.1-0.3l1-0.3l0.9-0.5l-0.9,0.3l0.9-0.6l-0.3,0.1l-0.9,0.4l-1.2,0.1l-1,0.2l-0.6,0.9L417.8,232.9z M960.1,203
               l-1.1-0.4l0.1,0.2L960.1,203l0.9,0.3L960.1,203z M242.3,194.5h1.3l1-0.2l-0.5-0.9h-0.2l-1,0.3l-1,0.3l-0.6,1L242.3,194.5z
                M309.9,192.9l-0.9-0.4l0.9-0.7l-0.3-1l-0.7-0.8l0.1,0.9l-0.1,1l-0.4,0.9l0.8,0.8L309.9,192.9z M308.4,189.6l0.5-0.9l-0.3-0.1
               l-0.8,0.8l-0.2,1L308.4,189.6z M310.6,189.3l0.3-0.9l-1.2-0.3l-0.5,0.6l0.3,1L310.6,189.3z M307.4,188.9l0.4-0.9l-0.6-0.9
               l-0.3,0.8l-0.4,0.9l0.3,0.9L307.4,188.9z M269.1,188.2l1.1-0.3l0.9-0.3l0.9-0.5h1.1l0.4-1l-1-0.1l-0.6,0.3l-1-0.2l-0.8,0.7
               l-0.3,0.9l-0.6-0.9l-1,0.4l-0.4,0.9l1.1,0.1l-0.7,0.8L269.1,188.2z M307.1,186.3l1,0.2l-0.7-0.8l0.9,0.6l0.9-0.9l-1-0.3l-0.5,0.1
               l-1,0.2l-0.4,0.9l0.5,0.9L307.1,186.3z M309.3,187.7l0.9-0.7l0.7-0.8l0.2-1l-1-0.5l-0.3,0.7l-0.6,0.9l-0.4,0.9l-0.5,0.9
               L309.3,187.7z M274.6,185.2l0.9-0.3l-0.9-0.3l-0.5,0.1l-0.5,0.9L274.6,185.2z M288.8,180.3l1-0.7h-0.1l-0.9,0.5l-0.9,0.8
               L288.8,180.3z M251.1,180.8l1-0.3l0.1-1v-0.1l-1,0.2l-1,0.3l-1,0.5l0.9,0.4H251.1z M256,179l0.9-0.5l-1-0.3l1,0.1l1.4,0.5
               l-1.2-0.1L256,179z M253.2,172.5l1.4-0.3l-1.2-0.2l-0.6-0.3l-0.9-0.5l-1.2,0.2l-0.9-0.3l-0.4,1l1-0.3l0,0l1,0.3l0.8,0.8
               L253.2,172.5z'
              ></path>
              <path id='Hawaii' class={style.st2} d='M983.1,390.3L983.1,390.3l-0.5-0.9l0.1,1.3l0.5,0.9L983.1,390.3z'></path>
              <path
                id='Mexico'
                class={style.st2}
                d='M360,306.6l0.3-1.1l0.2-1.3l0.7-1l0.6-0.9h1.4h1.3l0.6-0.9l-0.7-1l-0.9-0.7l0.6-2.3l1.2-0.1h1.1h1l2-0.4
               l0.6-0.9l0.6-0.6h0.2l0.6-0.1l0.4,0.9l0.3-1l0.4-0.9l0.1-1.2l0.2-1.1l0.6-0.9l0.3-0.9l0.9-0.9l0.6-0.9l0.2-1l-0.6-0.9l-1,0.3
               l-1-0.3l-1.1,0.1l-1,0.4l-1,0.2l-1.3,0.2l-1.1,0.5l-0.8,1.1l-0.3,1.2l-0.2,1.1l-0.6,0.9l-0.7,1.2l-1,0.8l-0.4,0.9l-0.9,0.4
               l-0.4-0.9l-1.2,0.2l-0.9,0.5l-1.1,0.2l-1.1,0.3l-1,0.3l-1,0.1l-0.6-1l-1.1-0.4l-1.1-0.4l-0.5-1l-0.4-0.9l-0.2-1.1l-0.6-1l-0.6-1
               l-0.3-1.1l-0.3-1l-0.1-1.2l0.6,0.9l-0.5-0.9l-0.3-1.7l0.4-1.2l0.2-1.1l0.3-1.1l0.3-1.3l0.3-1.2l0.9-1.9l0.8-1.2l0.4-1l-0.6,0.3
               l-0.9-0.6l-1-0.1l-0.9-0.5L349,273l-0.1-1l-0.3-1l0.1-1l-0.7-0.9l-0.3-0.9l-0.4-1.1l-0.3-1l-0.3-1l-0.7-0.8l-0.7-0.7l-1-0.1
               l-1.3,0.1l-0.8,0.7l-0.4,0.9l-1,0.7l-0.9-0.6l-0.9-0.6l-0.7-0.8l-0.2-1l-0.1-1l-0.3-0.9l-0.9-0.8l-0.5-0.9l-0.7-0.8l-0.9-0.6h-1.1
               h-1.1h-1l-0.4,1l-0.9,0.3h-1.1H328h-1.1h-1h-1.1l-1.1-0.3l-0.9-0.4l-1.7-0.7l-1.6-0.8l-1.7-0.7l-0.9-0.9l-1,0.1l-1.1,0.1l-1,0.1
               l-1,0.1l-1,0.1l0.2,0.9l0.3,1l-0.3,1l0.3,0.9l0.3,1.2l0.2,1.2l-0.1,1.1l0.4,0.9l0.9,0.6l0.5,0.9l0.5,0.9l0.3,1.1l-0.5,0.9v1.1
               l-1-0.4l-1.1,0.2l0.8,0.7l0.4,1l0.9,0.4l0.9,0.5l0.7-0.5v1.1l0.7,0.8l0.8,0.8l0.2,1l-0.3,1.3v1.1l0.6,0.9l0.7,0.9l0.8,0.8l0.6,0.9
               l0.7,1l0.1,1.1l0.9-0.4l0.8-0.7l-0.4-1v-1l-0.8-0.8l-1.1-0.1l0.1-1v-1l-0.3-1.1l-0.3-0.9l0.1-1.1l-0.2-1.2l-0.7-0.9l-0.4-0.9
               l-0.3-1l-0.6-0.9l0.1-1l-0.3-1l-0.7-0.8l-0.1-1.1l-0.7-1l-0.8-1l0.1-1l0.3-1.3l-0.2-1l0.9-1.8l0.9,0.7l1-0.1l0.6,0.9l0.9,0.4
               l-0.4,0.9l0.3,1l-0.1,1.1l0.3,1l0.4,1.1l0.1,1l0.4,1l0.7,0.9l0.5,0.9l1,0.3l-0.3,1.1l0.5,0.9l0.7,0.8l0.9,0.6l0.3,0.9l-0.7,0.9
               l0.3,1l0.9-0.3l-0.1,1.1l0.9,0.3l0.7,0.9l0.2,1l0.6,0.9l0.8,0.8l0.4,1.1l1,1.6l0.7,1l0.3,1.1l0.1,1.8l0.3,1.1l-0.6,0.9v1.1
               l-0.9,0.4l0.2,1.1l0.5,1l0.5,0.9l1,0.6l0.9,0.5l0.8,0.8l0.5,0.9l0.9,0.4l1,0.4l1.1,0.3l0.9,0.5l0.9,0.9l1.1,0.8l1,0.5l1.1,0.7
               l1.1,0.3l1,0.3l0.9,0.6l0.9,0.4l1,0.6l1.2,0.2l1.5,0.6l1-0.3l1-0.4l1.3-0.8l0.9-0.4l0.9,0.5l0.9,0.4l-0.8-0.7l0.9,0.7l1,0.9l1,1.2
               l0.6,0.9L360,306.6L360,306.6z'
              ></path>
            </g>
            <g id='SouthAmerica'>
              <path
                id='Guatemala'
                class={style.st2}
                d='M365.1,308.3l0.8-0.6l1.1-0.9l0.3-1l0.7-0.8l1-0.9l0.9-0.9l-0.4-0.3l-1.1-0.1l-0.7-0.9l0.2-1.3l0.3-1.4
               l0.2-1.3l-0.9-0.7h-1h-1.1l-1.2,0.1l-0.6,2.3l0.9,0.7l0.7,1l-0.6,0.9h-1.3h-1.4l-0.6,0.9l-0.7,1l-0.2,1.3l-0.3,1.1l-0.1,0.1
               l0.4,0.3l1,1l1.1,0.3l1.4,0.1l0.9,0.4L365.1,308.3z'
              ></path>
              <path
                id='Belize'
                class={style.st2}
                d='M368.6,302.4l0.8-0.7l0.6-0.9l0.3-1v-1l0.3-1.2l0.4-1l-0.4-1h-0.2l-0.6,0.6l-0.6,0.9l-1,1.1l-0.2,1.3
               l-0.3,1.4l-0.2,1.3l0.7,0.9L368.6,302.4z'
              ></path>
              <path
                id='ElSalvador'
                class={style.st2}
                d='M370.2,309.9l0.3-0.9l-0.8-0.7l-1,0.4l-0.8-0.8l-0.6-0.8l-1.4,0.6l-0.8,0.6l-0.3,0.6l0.3,0.3l0.9,0.4l1.2,0.3
               l1.2,0.3l1,0.3L370.2,309.9z'
              ></path>
              <path
                id='Honduras'
                class={style.st2}
                d='M372.2,310.8l0.5-0.9l0.1-1h1l0.7-0.9l1,0.2l0.8-0.7l0.6-0.9l0.7-0.8l0.9,0.5l1-0.3l1-0.3l0.9-0.5l0.6,0.1
               l-0.9-0.8l-0.8-0.7l-0.7-0.9l-1.1-0.3l-1-0.1h-1l-1-0.3l-0.1,0.4l-1.3,0.3L373,303l-1-0.2l-1-0.2l-1,0.4l-0.9,0.9l-1,0.9l-0.7,0.8
               l-0.3,1l0.3,0.3l0.6,0.8l0.8,0.8l1-0.4l0.8,0.7l-0.3,0.9l0.9,0.2l0.3,1L372.2,310.8z'
              ></path>
              <path
                id='Nicaragua'
                class={style.st2}
                d='M379.6,316.9h-0.2l-0.3-0.9l0.6-1.1v-1.4l0.5-1.1l0.1-1v-1.1l0.3-1.3l0.6-1l0.3-1.4l0.4-1.1l-0.6-0.1
               l-0.9,0.5l-1,0.3l-1,0.3l-0.9-0.5l-0.7,0.8l-0.6,0.9l-0.8,0.7l-1-0.2l-0.7,0.9h-1l-0.1,1l-0.5,0.9l-0.9,0.3l-0.9,0.3l0.8,0.8
               l0.7,0.8l0.8,1.1l0.6,0.9l0.8,0.8l0.7,0.8l1,0.1l1.2,0.1l1,0.1l0.9,0.7L379.6,316.9z'
              ></path>
              <path
                id='CostaRica'
                class={style.st2}
                d='M380.7,324.3l0.5-0.9l0.2-1l-0.3-1l0.9-0.6l-0.8-0.6l-0.7-0.9l-0.6-1l-0.3-1.3l-0.9,0.5l-0.9-0.7l-1-0.1
               l-1.2-0.1l-1-0.1l-0.3,0.7l-0.1,1l-0.1,1l0.9,0.7l0.8,0.9l0.3-1l-0.6-0.9l0.9,0.6l0.4,1l0.9,0.5l0.9,0.5l0.6,1l-0.3,1l0.9-0.3
               l0.4,0.9l0.6,0.9L380.7,324.3z'
              ></path>
              <path
                id='Panama'
                class={style.st2}
                d='M387.1,326.9l0.9-0.5l-0.7-1l0.1-1.1l0.9-0.4l0.5-0.9l0.9-0.6l1,0.1l1,0.9l1,0.3l0.6,0.9l-1-0.1l-0.3,1
               l0.4,0.9l0.7,0.9l0.3-1l1-0.3l0.3-1.1l-0.4-1l0.2-0.6l-0.8-0.6l-0.6-0.9l-0.9-0.4l-1-0.3l-1-0.2l-1-0.1l-0.9,0.6l-0.9,0.5
               l-1.1,0.8l-1,0.3l-1-0.5l-1,0.1l-0.6-0.9l-0.4-0.9l-0.9,0.6l0.3,1l-0.2,1l-0.5,0.9l0.3,0.8l0.1-0.1l0.8-0.6l1.4,0.3l0.8,0.9h1
               l0.5,0.9l0.3,1L387.1,326.9z'
              ></path>
              <path
                id='Columbia'
                class={style.st2}
                d='M412.2,358.1l0.2-1.4l0.2-1.4l0.3-1.4l0.2-1.4l0.2-1v-1l-0.5-0.9l-0.4-0.9l-0.7-0.9l0.1-1.2l0.7-0.8l1-0.1
               l0.3-1l-1.1-0.2l-0.3-1.1l0.7-0.9l1.4,0.1h1.4h1.1l0.9-0.9l1,0.8l0.1,1.5l0.6,0.1l-0.1-0.2l-0.2-1L419,342l-0.2-1.1l-0.7-0.7
               l-0.9-0.5l0.7-0.9l0.7-0.9L418,337l-0.3-0.9l-0.2-1V334v-1l0.5-0.9l0.3-1v-1l-1,0.1l-1.1,0.1h-1l-1,0.3l-0.9-0.7l-0.7-1.1
               l-0.9-0.6l-1.1-0.4l-1.1,0.3l-1-0.3h-1l-0.6-0.9l-0.3-1l0.3-0.9l-0.4-1.1l-0.4-0.9l-0.5-0.9l-0.5-0.9l0.6-0.9l0.2-1l0.3-1.1
               l0.5-0.9l0.8-0.8l0.7-0.9l0.9-0.8l1.1-0.3l0.3-0.3v-1l-0.9-0.4l0.1,0.2l-0.9,0.4l-0.6,0.9l-0.9,0.5l-0.9,0.5l-0.9,0.8l-1,0.1h-1.2
               l-0.7,0.8l-1.1-0.2l-0.8,0.7l-0.7,0.8l-0.3,1l-0.3,1l-0.3,1.1l-0.9,0.4l-0.5,0.9l-0.9,0.7l-0.4,0.9l-0.3,1.3l-0.3-0.9l-0.7-1.1
               l-0.2,0.6l0.4,1l-0.3,1.1l-1,0.3l-0.3,1l0.2,0.3l0.5,0.9l0.5,0.9l-0.3,1l0.5,0.9l-0.6,0.9l0.3,1l0.1,1.1l-0.2,1l0.4,1v1l-0.7,0.9
               l-0.6,0.9l-0.3,0.9l-0.9,0.6l-1,0.1l-0.3,1l-0.6,0.9l0.2,1l0.3,0.4l0.5,0.3l0.9,0.9l1,0.5l0.7,0.9l1,0.3l1,0.2l1.2-0.3l0.9,0.7
               l0.9,0.6l0.4-0.1l0.9,0.7l0.9,0.7l0.3,1.1l0.9,0.6l0.9,0.7l0.3,0.9l0.7,0.8l0.4,0.9l1,0.1l1.1-0.2l1-0.4l0.9,0.5l1-0.3l1,0.7
               l0.9,0.4l-0.3,1l-0.6,1l-0.5,1.2l1.2,0.4L412.2,358.1z'
              ></path>
              <path
                id='Venezuela'
                class={style.st2}
                d='M421.5,345.4l0.9-0.5l1,0.1l0.8-0.9l0.9-0.5l0.9-0.4l0.3-1l1.1-0.5l-0.7-0.9l-0.4-1l-0.5-0.9l0.1-1l-0.8-1
               l-0.6-0.9l1.3,0.1l0.9,0.5l1,0.1l1,0.8l0.6-0.9l0.9-0.7l1.1,0.1l0.9-0.3l0.8-0.8l0.9-0.5l0.8-0.7l-1-1.7l-0.8-0.9l0.5-0.9l-0.1-1
               l0.8-0.8l1.2-0.3l-0.5-0.9l0.2-1l0.8-0.8l0.8-0.8l-0.1-1h-1.1l-1-0.1l-0.9,0.5l-0.8-0.7h1l0.3-1.1l0.9-0.9l-0.9-0.7l-0.9-0.8
               l-1-0.1l-1.1-0.1l-0.7-0.9l-0.4-0.9l1.5-0.3l1-0.3l-1.1,0.2l-1-0.2l-1.1,0.1l-1.2,0.3l-1,0.5l-0.9,0.5l-0.9,0.5L424,319l-1.2-0.3
               l-0.6-0.9l-1.1-0.2l-1.5,0.3l-1.1,0.1h-1.3l-0.2-1l-0.5-0.9l-0.9-0.7l-0.9-0.3h-1.1l-0.2-1.1v-0.3l-1.1,0.1l0.1,1l0.9,0.6
               l-1.1,0.2l-0.9,0.3l-0.9,0.4l-0.9,0.5l-0.3,1l0.4,0.9l0.6,0.9l-0.1,1l-0.5,1.1l-1.1-0.1l-0.5-0.9l-0.3-1l0.5-0.9l0.7-0.9l-0.1-1.1
               l-0.3-1v-1.2l1.4-0.6l-1.1,0.3l-0.9,0.8l-0.7,0.9l-0.8,0.8l-0.5,0.9l-0.3,1.1l-0.2,1l-0.6,0.9l0.5,0.9l0.5,0.9l0.4,0.9l0.4,1.1
               l-0.3,0.9l0.3,1l0.6,0.9h1l1,0.3l1.1-0.3l1.1,0.4l0.9,0.6l0.7,1.1l0.9,0.7l1-0.3h1l1.1-0.1l1-0.1v1l-0.3,1l-0.5,0.9v1v1.1l0.2,1
               l0.3,0.9l0.6,0.9l-0.7,0.9l-0.7,0.9l0.9,0.5l0.7,0.7l0.2,1.1l0.3,0.9l0.2,1l0.1,0.2l1.1,1.2h1.1V345.4z'
              ></path>
              <path
                id='Guyana'
                class={style.st2}
                d='M440.1,343.2l0.9-0.2l1.2-0.5l0.9-0.5l1,0.3l0.5-0.1l-0.5-0.2l-0.5-0.9l-0.4-0.9l-0.3-1l-0.3-0.9l-0.9-0.6
               l-0.5-0.9l-0.1-1l0.3-1l0.3-0.9l0.9-0.3l0.3-1.1l0.2-0.3l0.1-1l-0.5-0.9l-0.8-0.7l-0.8-0.8l-1-0.2l-0.4,0.9l0.3-1.4v-1l-0.7-0.9
               l-0.9-1l-1.1-0.5l-0.2-0.1l-0.8-0.8l0.1,1l-0.8,0.8l-0.8,0.8l-0.2,1l0.5,0.9l-1.2,0.3l-0.8,0.8l0.1,1l-0.5,0.9l0.8,0.9l1.8,0.9
               h0.3l0.3,1l-0.3,0.9l1,0.3l0.3,1.1l-0.3,1l-0.3,1l-0.3,1v1l0.6,0.9l-0.1,1l0.8,0.8l1,0.8l1.1,0.2L440.1,343.2z'
              ></path>
              <path
                id='Suriname'
                class={style.st2}
                d='M445.7,341.4l1-0.6l1.1-0.3l1,0.3l0.3,0.3l0.8-0.8l0.3-0.9l0.3-1.1l-0.1-1.1l-0.6-0.9l-0.2-1.3l-0.1-1.1
               l0.4-0.9l0.3-0.3l0.3-0.9l-0.7-0.8l-1-0.2l-1.2,0.2l-1-0.2l-0.6,0.9l-0.8-0.6l-1.1-0.3l-1,0.3l-0.3,1l-0.3,1.1l-0.9,0.3l-0.3,0.9
               l-0.3,1l0.1,1l0.5,0.9l0.9,0.6l0.3,0.9l0.3,1l0.4,0.9l0.5,0.9l0.5,0.2l1.1,0.3L445.7,341.4z'
              ></path>
              <path
                id='Ecuador'
                class={style.st2}
                d='M390.2,360.9l0.6-0.9l0.3-1l0.3-1l0.5-0.9l0.6-0.9l0.9-0.5l1.3-0.5l1.1-0.5l0.9-0.8l0.8-0.9l0.7-0.9l0.3-1
               l0.6-0.9l-0.3-1.3l0.3-0.9l-0.9-0.6l-0.9-0.7l-1.2,0.3l-1-0.2l-1-0.3l-0.7-0.9l-1-0.5l-0.9-0.9l-0.5-0.3l-0.3-0.4l-0.3,0.8l-1,0.3
               l-0.9,0.3l-0.9,0.6l0.3,1l-0.3,1l-0.7,0.8l0.3,1l-0.8,0.8l-0.8,0.7l0.2,1.1l0.3,1l-0.7,0.9l0.9,0.6l0.9,0.6l0.9-0.3l0.2-1.3v1v1.1
               l-0.3,1.1l-0.9,0.5l0.2,0.3l0.2,0.9l-0.8,0.7l0.5,1h1.1l0.9,0.6l0.5,0.9L390.2,360.9z M361,350.2l-0.2-1.1l-0.6-0.9l-0.4-1
               l-0.6,0.5l0.6,0.8l0.5,1l-0.3,1L361,350.2z'
              ></path>
              <path
                id='Peru'
                class={style.st2}
                d='M414.4,399.1l0.3-1l0.6-1.1l-0.2-1l0.7-0.9l0.3-0.9l-0.5-0.9l-0.5-1l0.1-1l-0.2-1.1l0.2-1.2l0.4-0.9V387v-1.1
               l-0.2-1.3l0.4-0.9l-0.2-1.2l-0.6-1l-0.5-1l-0.6-1l-0.3-0.7l-1.5,0.3l-1-0.1l-0.1-1.3l-0.1-1.1v-1l0.2-1l-0.9,0.7l-0.9,0.8
               l-1.3,0.2l-0.9-0.4l-0.5-0.9l-1.1-0.3l-0.4-1.1l-0.7-0.9l-0.8-0.9l-0.4-1l-0.8-1.2l0.5-0.9l-0.1-1l0.7-0.7l0.8-0.8l-0.2-1l0.4-0.9
               l0.2-1l0.7-0.8l0.7-0.8l0.9-0.7l1-0.3l1-0.3l0.9-0.5l1.1,0.3l0.1-1l-1.2-0.4l0.5-1.2l0.6-1l0.3-1l-0.9-0.4l-1-0.7l-1,0.3l-0.9-0.5
               l-1,0.4l-1.1,0.2l-1-0.1l-0.4-0.9l-0.7-0.8l-0.3-0.9l-0.9-0.7l-0.9-0.6l-0.3-1.1l-0.9-0.7l-0.9-0.7l-0.4,0.1l-0.3,0.9l0.3,1.3
               l-0.6,0.9l-0.3,1l-0.7,0.9l-0.8,0.9l-0.9,0.8l-1.1,0.5l-1.3,0.5l-0.9,0.5l-0.6,0.9l-0.5,0.9l-0.3,1l-0.3,1l-0.6,0.9l-0.9,0.7
               l-0.5-0.9l-0.9-0.6h-1.1l-0.5-1l0.8-0.7l-0.2-0.9l-0.2-0.3l-0.7,0.7l-0.9,0.9l-0.7,0.9l-0.1,1l0.5,0.9l0.2,1.1l0.5,1.1l-0.5,1
               l0.9,0.6l1,0.7l0.9,0.8l0.7,0.9l0.3,0.9l0.8,1.3l0.8,0.9l0.6,1l0.3,1l0.5,0.9l0.4,0.9l0.5,1.2l0.4,0.9l0.6,0.9l0.4,0.9l0.5,0.9
               l0.7,0.9l0.2,0.9l0.8,0.8l0.5,1l0.5,0.9l0.6,0.9l0.3,1l-0.1,1.1l0.4,0.9l0.6,0.9l0.9,0.6l0.8,0.8l0.7,0.9l0.9,0.5l1.3,0.8l0.9,0.6
               l0.9,0.4l1,0.6l1.4,0.6l0.9,0.6l0.9,0.7l0.9,0.4l0.4,0.9l0.8,0.7l0.9,0.7l0.8,0.7L414.4,399.1z'
              ></path>
              <path
                id='Bolivia'
                class={style.st2}
                d='M422.5,411.9l0.5-0.9l0.7-0.8l0.8-1.2l0.8,0.4l1,0.4h1.2l1,0.3l0.5,0.9l0.8-0.8l0.9-0.7l1.6-0.1l0.9,0.6
               l0.1,0.1l0.1-0.7l0.2-1.1l0.2-1.2v-1.2l0.2-1.1l0.5-1l0.5-1.1l1.1-0.3l1.5-0.3l1.2-0.3h1l1.4,0.3l0.9,0.6l0.9,0.6l0.9,0.5
               l-0.7-0.9l0.3-0.9l0.3-1.3l0.3-1.2l-0.2-1.3l-0.5-1.4l-0.9-0.3l-0.7-0.9l-0.2-1l-0.3-1l-1.4-0.1h-1.4l-1.2-0.3l-0.2-1l-0.2-1v-0.1
               l-0.9-1l0.6-0.9l-0.3-0.9l-0.2-1l-0.6-0.9l-0.9-0.5l-1.1-0.1h-1l-0.7-0.8l-0.9-0.5l-1-0.4l-0.9-0.6l-0.9-0.6l-1,0.1l-1-0.4
               l-0.8-0.7l-0.8-0.9l-0.5-0.9l-0.4-0.9l-0.1-1.1l-0.1-1l0.2-1.1l-0.9-0.7l-1-0.1l-1,0.3l-0.9,0.6l-1,0.6l-0.7,0.8l-1,0.3l-0.6,0.9
               l-1,0.3l-0.9-0.4l-1.1-0.1l0.3,0.7l0.6,1l0.5,1l0.6,1l0.2,1.2l-0.4,0.9l0.2,1.3v1.1v1.1l-0.4,0.9l-0.2,1.2l0.2,1.1l-0.1,1l0.5,1
               l0.5,0.9l-0.3,0.9l-0.7,0.9l0.2,1l0.1,0.4l0.4,0.5l0.5,1l0.4,1l0.3,1l0.7,0.8l0.5,0.9l0.1,1.2l-0.3,0.9l0.6,1l0.3,1l0.7,1l0.3,1.3
               l0.4,1l0.3,0.9l0.4,1.2L422.5,411.9z'
              ></path>
              <path
                id='Brazil'
                class={style.st2}
                d='M459.8,442.2l0.9-1.2l0.3-1.2l0.3-1.2l-0.2-1.2l0.3-0.9l0.8-0.8l0.3-0.9l0.3-1l-0.1-1.2l0.7,0.8l1-0.3l-0.2,1
               l-0.6,1.1l-0.3,0.9l-0.8,0.9l-0.6,0.8l1.1-0.9l0.9-1l0.6-1.1l0.7-1.8l0.5-2l0.6-1.1l1-1.2l0.6-0.8l0.3-1.1l-0.2-0.9v-1.1l-0.3-1
               l-0.2-1l-0.1-1.1l0.2-1l0.1-1.1l0.9-0.5l0.4-1.1l0.8-0.5l-0.8,0.7l0.9-0.7h-0.1l0.1-0.1l0.9-0.7l0.7-0.8l1.2-0.5l0.8-0.7l1-0.2
               l0.9-0.6l0.9-0.5l0.7-0.9l1,0.3l1.1-0.2l0.9-1l0.5,0.9l1.2-0.2l1.1-0.4l0.1-1l0.8-0.6l1.1-0.6l-0.2-1.1l0.1-1.2l0.4-0.9l0.8-0.7
               l0.3-1l0.3-1l0.6-0.8l0.1-1.4l-0.1-1.2l0.2-1.4l0.8-1.1l0.2-1v-1l0.1-1.2l0.3-1.4l0.1-1.1l-0.3-1l-0.1-1.3l-0.2-1.2l-0.1-1.1
               l0.1-1l0.1-1.2l0.3-0.9l0.3-1l0.9,0.6l0.7-0.9l0.6-1.2l0.4-1l0.3-0.9l0.6-0.9l0.5-0.9l0.9-0.6l0.7-0.9l0.6-0.9l0.7-0.8l0.7-1
               l0.5-0.9l0.3-1.2l0.3-1.3l-0.1-1l0.3-1l-0.3-1.4l-0.2-1l-0.3-0.9l-0.3-1.3l-0.3-1.1l-0.8-0.7h-1.2h-1.1l-0.9-0.5l-0.9-0.7
               l-0.9-0.5l-0.8-0.8l-0.8-0.9l-0.9-0.8l-0.8-0.8l-0.9-0.5l-0.9-0.5l-0.9-0.5l-1-0.1l-1.1,0.2l-1.2,0.2l-0.9-0.5l-1.1,0.2l-0.9-0.4
               l-0.9-0.6l-1.2,0.3h-1l-0.9,0.6l0.5-1l-0.8,0.8l-0.6,1.1l0.1-1.1l-0.4-1.1l0.7-0.8l-0.8-0.8l-0.7-0.9l-0.7,0.9l-0.3-1l-1-0.3
               l-0.9-0.4l-0.9-0.5l-0.9-0.5l-1,0.3l-0.9-0.5l-0.9,0.5l-0.3,0.9l-0.3,1l-1.1,0.3l-0.8,0.7l-0.4,0.9l-0.9,1.3l0.4-0.9l-0.3-1
               l-0.9-0.3l-1,0.5l-0.6-0.9l-0.2-0.5l0.5,0.9l1.3-0.3h1l0.9-0.5l1-0.5l0.3-1l0.4-0.9l0.2-1.1l-1-0.2l-1-0.2l-1,0.3l-1-0.3l-0.7-0.1
               l-0.9,0.8l-0.2,0.9l-0.1,1.1l0.1,0.9l-0.1-0.3l-0.4-1l-0.7,0.8l-0.9,0.5l-0.5,0.1l0.8-0.4l0.8-0.9v-1.1l-0.9,0.3l-0.3,1.3
               l-0.4,0.9h0.1l-0.2,0.1l0.1-0.1l-0.9,0.3l-1,0.1l0.9-0.4l1-0.3l0.5-0.9l0.3-1.1l0.6-0.9l0.7-0.8l0.9-0.9l0.6-1.1l0.7-0.8l0.6-1
               l-0.3-1.1l-1.2-0.4l-0.4-0.9l-0.3-0.9l-0.2-1l-0.3-1l-0.3-1.2V337l-0.8-0.9l-0.4,1l-0.3,0.5l-0.6,0.9l-0.6,0.9l-0.3,1l-0.5,1.2
               l-0.8,0.8l-1-0.4h-1.1l-0.9,0.4l-0.9-0.5l-0.3-0.3l-1-0.3l-1.1,0.3l-1,0.6l0.1,1.1L445,343l-0.5,0.1l-1-0.3l-0.9,0.5l-1.2,0.5
               l-0.9,0.2l-0.6,0.9l-1.1-0.2l-1-0.8l-0.8-0.8l0.1-1l-0.6-0.9v-1l0.3-1l0.3-1l0.3-1l-0.3-1.1l-1-0.3l0.3-0.9l-0.3-1h-0.3l-0.8,0.9
               l-0.8,0.7l-0.9,0.5l-0.8,0.8l-0.9,0.3l-1.1-0.1l-0.9,0.7l-0.6,0.9l-1-0.8l-1-0.1l-0.9-0.5l-1.3-0.1l0.6,0.9l0.8,1l-0.1,1l0.5,0.9
               l0.4,1l0.7,0.9l-1.1,0.5l-0.3,1l-0.9,0.4l-0.9,0.5l-0.8,0.9l-1-0.1l-0.9,0.5h-1l-1.1-1.2l-0.6-0.1l-0.1-1.5l-1-0.8l-0.9,0.9H416
               h-1.4l-1.4-0.1l-0.7,0.9l0.3,1.1l1.1,0.2l-0.3,1l-1,0.1l-0.7,0.8l-0.1,1.2l0.7,0.9l0.4,0.9l0.5,0.9v1l-0.2,1l-0.2,1.4l-0.3,1.4
               l-0.2,1.4l-0.2,1.4l-0.9,1.6l-1.1-0.3l-0.9,0.5l-1,0.3l-1,0.3l-0.9,0.7l-0.7,0.8l-0.7,0.8l-0.2,1l-0.4,0.9l0.2,1l-0.8,0.8
               l-0.7,0.7l0.1,1l-0.5,0.9l0.8,1.2l0.4,1l0.8,0.9l0.7,0.9l0.4,1.1l1.1,0.3l0.5,0.9l0.9,0.4l1.3-0.2l0.9-0.8l0.9-0.7l-0.2,1v1
               l0.1,1.1l0.1,1.3l1,0.1l1.5-0.3l1.1,0.1l0.9,0.4l1-0.3l0.6-0.9l1-0.3l0.7-0.8l1-0.6l0.9-0.6l1-0.3l1,0.1l0.9,0.7l-0.2,1.1l0.1,1
               l0.1,1.1l0.4,0.9l0.5,0.9l0.8,0.9l0.8,0.7l1,0.4l1-0.1l0.9,0.6l0.9,0.6l1,0.4l0.9,0.5l0.7,0.8h1l1.1,0.1l0.9,0.5l0.6,0.9l0.2,1
               l0.3,0.9l-0.6,0.9l0.9,1v0.1l0.2,1l0.2,1l1.2,0.3h1.4l1.4,0.1l0.3,1l0.2,1l0.7,0.9l0.9,0.3l0.5,1.4l0.2,1.3l-0.3,1.2L444,403
               l-0.3,0.9l0.7,0.9l-0.5,0.9l0.3,0.9l0.5,0.9v1.1v1v1.2l1,0.2l1,0.2l1.1,0.1h1l1,0.3l0.5,0.9l0.2,1l0.3,1l0.3,1l1,0.7l0.9-0.5
               l0.9,0.7l0.1,1l-0.1,1.2l-0.1,1.1l-0.2,1l1-0.2l0.9,0.7l0.3,1l0.2,1.1l0.2,1l-0.5,0.9l-0.9,0.7l-0.9,0.3l-0.7,0.8l-0.8,0.7
               l-0.7,0.8l-0.7,1.1l-0.5,0.9l-0.6,0.9l-0.5,0.9l-0.6,0.8l-0.6,0.6l0.3,0.3l1-0.4l1,0.3l0.8,0.8l0.8,0.8l0.6,0.9l0.9-0.3l0.8,0.9
               l0.9,0.5l0.9,0.7l0.9,0.6l0.9,0.5l0.5,0.9l0.9,0.6v1l-0.3,1.2l0.5,0.9L459.8,442.2z M461.1,346.9l-0.7-0.1l-0.9,0.4l0.9,0.4
               L461.1,346.9z'
              ></path>
              <path
                id='Chile'
                class={style.st2}
                d='M439.8,501.9l1.1-0.2l-1-0.1l-1.2,0.2l-1,0.3h0.1h3L439.8,501.9z M442.9,501.5l-1-0.1l-0.5,0.7h0.1h1.8
               L442.9,501.5z M440,501.2l-2-5.8l-1,0.1l-0.9-0.5l-0.6,0.9l-0.9,0.4l0.3,1h1l1.4,0.3l-1.4,0.8l0.6,0.9l1,0.4l0.9,0.5h-1.2l-1-0.3
               l-1-0.2l0.6,0.9l-0.8-0.8l-0.9,0.3l-1,0.3l1,0.3l1-0.1l0.9,0.5l1,0.1l0.9-0.4l0.9,0.6L440,501.2z M431.7,498.5l-1-0.3l-0.9-0.4
               h-0.9l1.1,0.4l-1.1,0.2l1.2,0.3l0.9,0.6L431.7,498.5z M433.9,497.6l-0.2-1.1l0.4-0.9l1.1-0.5l0.8-0.8l1.2,0.2l0.9,0.3l-0.9-0.4
               l-1.4-0.3l-1.1-0.3h-1.1h-1.4l-1.4-0.1l-0.9-0.7l-0.7-0.7l-0.4-1l-0.3-1l-1-0.3l-1-0.2l-0.5-0.9l-0.8-0.9l-0.3-1l0.7-0.8l0.4-0.9
               v-1.2l0.2-1.1l-0.5-0.9l0.3-1l0.2-1l0.1-1.2l-0.4-0.9l-0.2-1l0.2-1l-0.3-1l-0.9-0.5l1-0.4l-0.1-1l-1.1-0.1l-0.3-1.2l-0.3-1
               l-0.7-0.8l-0.7-0.8l-0.3-1l-0.3-1l0.6-0.9l-0.6-1l-0.2-1l-0.3-1l-0.3-1l-0.2-1v-1.1l0.3-0.9l-0.2-1.2l0.7-0.9l-0.3-1.4l-0.4-1
               l-0.3-0.9l-0.3-1.1l-0.3-0.9l0.3-1l0.6-0.8l0.3-1.1l-0.3-0.9l-0.1-1.1v-1l0.3-1l0.3-1l-0.3-1.2l-0.3-0.9l-0.3-1L420,440l-0.5-0.9
               l-0.2-1l-0.8-0.9l-0.2-1.1l0.3-1.1l0.3-1l0.5-0.9L419,432l-0.4-1l0.4-1v-1.6l0.3-1l0.6-0.9l0.2-1.1l0.3-1.2l1-0.3l-0.5-1.2l-0.2-1
               l-0.1-1.2l-0.4-1l0.3-0.9l-0.5-1l0.5-0.9l0.9-0.7l0.9-0.5l0.4-1l0.2-1.2l-0.4-1.4l-1.3,0.3l-0.4-1.2l-0.3-0.9l-0.4-1l-0.3-1.3
               l-0.7-1l-0.3-1l-0.6-1l0.3-0.9l-0.1-1.2l-0.5-0.9l-0.7-0.8l-0.3-1l-0.4-1l-0.5-1l-0.4-0.5l-0.1-0.4l-0.6,1.1l-0.3,1l-1,0.3
               l0.3,0.9l0.2,1l0.3,1.3l0.3,1l0.2,1l-0.1,1l0.3,1l0.3,1l-0.1,1v1l0.1,1.1v1l-0.6,0.9l0.1,1.1l0.4,0.9l0.1,1.2l0.1,1l0.3,1l0.3,1.1
               l-0.3,1l0.3,0.9v1.1v1.1l-0.3,1l0.3,1l-0.3,1.1v1l-0.1,1l-0.3,1l0.5,0.9l0.3,1v1l-0.5,0.9l0.2,1.3l0.3,1.1l0.3,1l0.3,1.1l0.3,1
               l0.3,1.1l-0.3,1l0.1,1v1.1l-0.3,1l0.2,1l-0.2,1.1l-0.3,1.3v1l-0.3,1l0.1,1l-0.4,1l0.2,1l-0.9,0.6l0.4,1.1l0.6,0.9l0.2,1.4l0.6,0.9
               l0.5,1v1.1L417,460l0.2,1l0.1,1.1v1l0.5,0.9l0.5,0.9l1.1,0.1l0.9-0.7l0.9,0.5l-0.9,0.6l1,0.2l-0.5,0.9l0.2,1.1l0.2,1l-0.2,1
               l0.5,0.9l-0.3,0.9l0.9,0.5l0.8,0.7l-0.5,0.9l-0.1,1l0.9,0.6l-1-0.3l0.1,1l0.3,0.9l-0.8,0.8l-0.8-0.7l-0.8-0.8l-0.8,0.7l-0.7,0.8
               l-0.4,0.9h1l1.1,0.2l1,0.4l0.5,0.9l-0.5,0.9l0.9,0.7h-1.1l1,0.1h1.1l0.5,0.9l-0.9-0.3l-1,0.2l0.9,0.6l0.7,0.8l-0.8,0.7l0.4,0.9
               l0.7,0.8v-1l0.2,1l0.9,0.6l-0.9,0.5l0.6,0.9l-1.1-0.1l1,0.3l0.4,0.9l1.1,0.3l-0.5,0.9l0.9,0.4l0.2,1l0.8,0.7l0.9,0.6l0.2-1l1,0.1
               l-0.9-0.6l0.9,0.5l0.3,1.1l-1.1-0.2l-0.8,0.7l0.3,0.9l1-0.3l-0.8,0.7l1.2,0.3v-1l1-0.1l1.1,0.4l-1.1,0.3l-0.9,0.6l0.8,0.7
               l-1.1-0.1l1,0.4l1,0.3l-0.8-1.3l1.2-0.2l0.9-0.7l0.4,1l-1,0.3l-0.9,0.3l0.8,0.8l1.1,0.4l1.1,0.3L433.9,497.6z M428.3,496.8l-1-0.3
               l-0.9-0.2l-0.9-0.6l0.6,0.6l1.1,0.3l1,0.4l1,0.3L428.3,496.8z M422.7,486.7l-0.4-1.1l-0.7-0.9l-0.5,0.5l0.6,0.9l-0.8,0.7l0.9,0.5
               l0.4-0.9l-0.2,1l1,0.2L422.7,486.7z M421.7,473l-0.3-0.1l-0.6,0.9l1,0.3L421.7,473z M418.9,469.5l0.3-1.1l-0.7-0.8l0.4-0.9l-0.5-1
               l-1-0.1l-0.2,0.1v1l0.3,1l0.1,1l0.2,1L418.9,469.5z'
              ></path>
              <path
                id='Paraguay'
                class={style.st2}
                d='M450.3,424.6l1.1,0.2l0.3-1l0.9-0.6l0.5-0.9l0.1-1.1l-0.2-1.1l0.1-0.3l0.2-1l0.1-1.1l0.1-1.2l-0.1-1l-0.9-0.7
               l-0.9,0.5l-1-0.7l-0.3-1l-0.3-1l-0.2-1l-0.5-0.9l-1-0.3h-1l-1.1-0.1l-1-0.2l-1-0.2v-1.2v-1v-1.1l-0.5-0.9l-0.3-0.9l-0.5-1.4
               l-0.9-0.6l-0.9-0.6l-1.4-0.3h-1l-1.2,0.3l-1.5,0.3l-1.1,0.3l-0.5,1.1l-0.5,1l-0.2,1.1v1.2l-0.2,1.2l-0.2,1.1l-0.1,0.7l0.8,0.7
               l0.6,0.9l0.8,0.9l0.8,0.7l0.9,0.6l0.7,0.8l0.9,0.3l1,0.2l1.2,0.6l0.9,0.7l0.9,0.5l1.3,0.6l1.1,0.5l0.9,0.7l0.2,1l-0.4,0.9
               l-0.5,0.9l0.2,1l-0.7,0.9l-0.1,1l1.1-0.2l1,0.2l0.9,0.3l1.2,0.1l1,0.3L450.3,424.6z'
              ></path>
              <path
                id='Uruguay'
                class={style.st2}
                d='M454.4,445.8l1,0.3l1.2-0.1l0.8-0.7l0.9-0.5l0.6-1l0.3-1l-0.5-0.9l0.3-1.2v-1l-0.9-0.6l-0.5-0.9l-0.9-0.5
               l-0.9-0.6l-0.9-0.7l-0.9-0.5l-0.8-0.9l-0.9,0.3l-0.6-0.9l-0.8-0.8l-0.8-0.8l-1-0.3l-1,0.4l-0.3-0.3l-0.4,0.9l0.3,1l-0.2,1v1.2
               l-0.2,1l0.1,1v0.2l0.1,0.3l0.4,0.9l-0.6,0.9l0.1,1.1l0.3,1l0.9,0.7l0.7,0.9l1.1-0.1l0.9,0.4l1,0.4l1,0.4L454.4,445.8z'
              ></path>
              <path
                id='Argentina'
                class={style.st2}
                d='M445.7,501.4l0.9-0.7l-1-0.1l-1.4-0.6l-0.9-0.5l-0.9-0.4l-1.2-0.8l-1.3-1.2l-0.6-0.9l-0.3-0.3l-0.8-0.7
               l0.5,1.4l0.4,1.4l0.5,1.4l0.9,1.6l1.2,0.1l1.3,0.1l1.5,0.3L445.7,501.4z M437.8,494.1l-0.8-0.7l-0.7-0.8l-1-0.2l0.9-0.2l-0.7-1
               l-0.3-1.1l0.2-1l0.9-0.6l-0.8-0.8l0.9,0.8l0.9-0.8l-0.1-1.1l-0.3-1l0.8-1.1l0.8-0.6l0.9-0.7l0.3-0.9h-1l1-0.3l-0.1-1.1l-0.7-0.7
               h-1l-1-0.4l-0.9-0.7l-0.8-0.7l-0.5-0.9l0.3-1l0.3-0.9l0.9-0.5l0.8-0.7l0.9-0.8l0.7-0.9l-0.3-1l-0.4-1l0.4-1l0.5-0.9l-0.6-0.9
               l1-0.3l0.6,0.9l1-0.1v-1.1l-0.9-0.9l-0.3,0.9l-1.3-0.5l-0.9-0.4l-0.3-1.3l-0.4-1l-0.3-1.3l1.1,0.1l1.1,0.4l1,0.4h1l1.1-0.3
               l0.9-0.5l-0.3-1.1l-0.2-1l0.3-1v-1l-0.9-0.6l-0.2-1l1.2,0.5h1.1h1.1l1.1-0.2l1.5-0.3l1.4-0.3l1.1-0.4l0.9-0.4l0.9-0.9l0.2-1
               l0.6-0.9l0.4-1v-1.4l-0.9-0.7l-0.9-0.7l-0.3-1l0.3-1l-1-0.9l-1.1-0.6l-1-0.7l-0.3-0.9l-0.3-1l-0.3-0.9l0.1-1.1l0.3-0.9l-0.2-1.1
               v-0.2l-0.1-1l0.2-1V436l0.2-1l-0.3-1l0.4-0.9l0.6-0.6l0.6-0.8l0.5-0.9l0.6-0.9l0.5-0.9l0.7-1.1l0.7-0.8l0.8-0.7l0.7-0.8l0.9-0.3
               l0.9-0.7l0.5-0.9l-0.2-1l-0.2-1.1l-0.3-1l-0.9-0.7l-1,0.2l-0.1,0.3l0.2,1.1l-0.1,1.1l-0.5,0.9l-0.9,0.6l-0.3,1l-1.1-0.2l-0.6,0.9
               l-1-0.3l-1.2-0.1l-0.9-0.3l-1-0.2l-1.1,0.2l0.1-1l0.7-0.9l-0.2-1l0.5-0.9l0.4-0.9l-0.2-1l-0.9-0.7l-1.1-0.5l-1.3-0.6l-0.9-0.5
               l-0.9-0.7l-1.2-0.6l-1-0.2l-0.9-0.3l-0.7-0.8l-0.9-0.6l-0.8-0.7l-0.8-0.9l-0.6-0.9l-0.8-0.7l-0.1-0.1l-0.9-0.6l-1.6,0.1l-0.9,0.7
               l-0.8,0.8l-0.5-0.9l-1-0.3h-1.2l-1-0.4l-0.8-0.4l-0.8,1.2l-0.7,0.8l-0.5,0.9l0.4,1.4l-0.2,1.2l-0.4,1l-0.9,0.5l-0.9,0.7l-0.5,0.9
               l0.5,1l-0.3,0.9l0.4,1l0.1,1.2l0.2,1l0.5,1.2l-1,0.3l-0.3,1.2l-0.2,1.1l-0.6,0.9l-0.3,1v1.1l-0.4,1l0.4,1l0.4,1.1l-0.5,0.9l-0.3,1
               l-0.3,1.1l0.2,1.1l0.8,0.9l0.2,1l0.5,0.9l0.6,0.9l0.3,1l0.3,0.9l0.3,1.2l-0.3,1l-0.3,1v1l0.1,1.1l0.3,0.9l-0.3,1.1L420,451l-0.3,1
               l0.3,0.9l0.3,1.1l0.3,0.9l0.4,1l0.3,1.4l-0.7,0.9l0.2,1.2l-0.3,0.9v1.1l0.2,1l0.3,1l0.3,1l0.2,1l0.6,1l-0.6,0.9l0.3,1l0.3,1
               l0.7,0.8l0.7,0.8l0.3,1l0.3,1.2l1.1,0.1l0.1,1l-1,0.4l0.9,0.5l0.3,1l-0.2,1l0.2,1l0.4,0.9l-0.1,1.2l-0.2,1l-0.3,1l0.5,0.9
               l-0.2,1.1v1.2l-0.4,0.9l-0.7,0.8l0.3,1l0.8,0.9l0.5,0.9l1,0.2l1,0.3l0.3,1l0.4,1l0.7,0.7l0.9,0.7l1.4,0.1h1.4h1.1l1.1,0.3l1.4,0.3
               l0.9,0.4L437.8,494.1z'
              ></path>
              <path
                id='FalklandIslands'
                class={style.st2}
                d='M458.1,493.4l1-0.3l1-0.2l-0.6-0.9l-0.9,0.4l-0.9-0.6l-0.5-0.2l0.3,1.1l-0.3,0.9L458.1,493.4z M455.5,493.7
               l0.8-0.7l0.3-1.1l-0.3,0.2l-1.2,0.2l-1.2-0.3l0.9,0.6l0.1,1.1l-1,0.3l0.9,0.4L455.5,493.7z'
              ></path>
              <path
                id='Bahamas'
                class={style.st2}
                d='M404,281.9l-0.3-1l-0.1,0.1L404,281.9l0.4,0.9L404,281.9z M398.1,277.8l-0.4-1.1l-0.4,0.9l-0.5,0.9l1.2,0.3
               L398.1,277.8z M402,276.4l-0.9-0.9l-0.3,0.4l0.9,0.3l0.3,1.1V276.4z M398.8,272.2l-1.1,0.1l-0.4-0.3l-0.9,0.3l1.1,0.3L398.8,272.2
               z M400.8,273.2l-0.5-0.9l-0.3-0.2l0.3,0.9l-0.1,1L400.8,273.2z'
              ></path>
              <path
                id='Cuba'
                class={style.st2}
                d='M398,291.6l1-0.3l1.1-0.1l1.2,0.2l1.1,0.1l0.2-0.2h0.1l0.1,0.2h0.2l0.9-0.4l0.7-0.8l-0.6-0.8l-1-0.3l-0.9-0.5
               l-0.8-0.7l-0.9-0.5l-1-0.3l-0.8-0.7l-0.9-0.4l-0.9-0.6l-0.8-0.7l-1-0.3l-1-0.1l-0.7-0.7l-0.5-0.7l-1.1-0.1l-0.9-0.5l-1,0.1v-0.3
               l-1,0.1l-1-0.1l-1,0.3l-1,0.2l-1,0.3l-0.9,0.4l-1,0.5l-0.8,0.7l-0.5,0.9l-0.9,0.6l1.1,0.1l1.1-0.8l0.9-0.3l0.9-0.4l0.9-0.6
               l0.9-0.3h1.1l1,0.4l-0.6,0.9l1,0.3l1.1,0.2l1.2,0.1l0.8,0.8l0.9,0.3l1,0.3l1-0.3l0.3,1l0.3,0.9l1,0.7l1.1,0.1l0.7,0.8l-0.9,0.7
               l-0.4,0.9L398,291.6z M385.5,286.7l-0.5-0.9l-0.6,0.3l0.3,1L385.5,286.7z'
              ></path>
              <path
                id='Haiti'
                class={style.st2}
                d='M405.9,296.2l1.1,0.2h1.1l1-0.1l0.9,0.5l-0.1-1l0.4-1l0.3-1v-1.2l-0.1-0.5h-0.3l-1-0.1l-1-0.5h-0.9l-0.8,0.8
               l1,0.2l0.3,1l0.3,1l0.3,0.9l-1.1,0.3l-1.1-0.2l-1.3-0.2l-1-0.3l0.3,1l0.7,0.8L405.9,296.2z'
              ></path>
              <path
                id='DominicanRepublic'
                class={style.st2}
                d='M411.7,296.8l0.8-0.8l0.9,0.3l1-0.5l1-0.1l1,0.1l0.9,0.6l0.8-0.6l-0.3-1.2l-1-0.4l-0.6-0.9l-1-0.1l-0.6-0.9
               l-1-0.3l-1-0.4l-0.9,0.2l-1,0.3l0.1,0.5v1.2l-0.3,1l-0.4,1l0.1,1h0.1l0.9,0.8L411.7,296.8z'
              ></path>
              <path
                id='Jamaica'
                class={style.st2}
                d='M397.4,297.2h1l1-0.1l-0.8-0.8l-0.9-0.5l-1.1-0.2l-0.3-0.1l-1.1,0.1l-0.9,0.7l0.9,0.5l1,0.5L397.4,297.2z'
              ></path>
              <path
                id='TrinidadTobego'
                class={style.st2}
                d='M433.1,319.3l1-0.2l0.2-1l0.4-1l-0.5,0.1l-0.9,0.6l-0.3,1l-0.9,0.5H433.1L433.1,319.3z'
              ></path>
              <path id='PuertoRico' class={style.st2} d='M424.3,296.8l0.4-0.9l-1-0.3h-1l-1.1-0.1L421,296l0.3,1.1l1-0.1l1,0.1L424.3,296.8z'></path>
            </g>
            <g id='GreenlandScandinavia'>
              <path
                id='Greenland'
                class={style.st2}
                d='M496,180.3l-1.7-0.3l-0.2,0.1l0.9,0.6L496,180.3z M494.1,180.1h1l1,0.2l-0.4-0.9l-1.2-0.3l1.4,0.1l0.9-0.6
            l-1-0.2l1.4,0.1l-0.5-0.8h1l-0.9-0.3l1-0.1l-1-0.3l1.3-0.5l0.4-1l0.7-0.9l-0.9-0.5l1.1,0.3l-0.9-0.7l1-0.3l0.7-0.9l1,0.2l0.4-0.9
            l0.9-0.6l1.1-0.1l-0.7-0.9l-0.9-0.6h1.1l0.7-0.9l-0.3-1h1.2l0.9-0.3l0.5-0.9l0.9-0.3l1,0.3l1-0.1l0.7-0.8v-1l1,0.1l0.5,0.1
            l-0.7,0.9l1,0.2l1-0.6l1.1,0.2l0.4-0.9l1,0.1l1-0.1l0.9-0.4l0.8-0.9l0.8-0.7l0.7-0.8l1-0.4l0.9-0.4l0.9-0.8l-0.4-1l0.6,0.9l1,0.3
            l1,0.2l1.1-0.1l0.1-0.4l1-0.1l1-0.2h1l1-0.2l0.9-0.3l1-0.1l0.4-0.1l1.2-0.4l1.1-0.3l0.9-0.4l1-0.4l0.9-0.3l0.8-0.8h1.1l0.9-0.4
            l0.9-0.4h-2.3l-1-0.2l-1.1-0.3l-1-0.2l-1,0.3l-1.1,0.2h-1l-0.9,0.3l-1.3-0.2h1.1l1.3-0.4l1-0.4l-1.2,0.1h-0.6l-1,0.1l0.2-0.2
            l0.9,0.1l1.8-0.2h1l1-0.6l-0.3-0.7l-1.2,0.3l-1.5,0.1l-1,0.5l-0.7,0.4h-0.9h-1.1l1.4-0.2l0.4-0.9l1.3,0.1h1.2l1.6-0.3l0.5-0.9h-1.4
            l-1-0.3h-1.2h-1.2l1.1-0.1l1-0.3l-1.4-0.6l0.9,0.3l1.1,0.7h1.1l1,0.1l0.9,0.4l1,0.3l0.5,0.9l0.9,0.7l1.2,0.3l1.1-0.4l0.9,0.6
            l0.9-1.4l-1-0.3l0.5-0.9l-1,0.3l0.3-1l-1.1,0.2l1-0.4l-1-0.3l-1.6-0.5l-1.1-0.3h-1l-1.1-0.1l2.3-0.3l-0.9-0.5l-1.1,0.2l-1.1-0.2
            l-1-0.1l1.4,0.1l1-0.3l1.3-0.4l-1.6-0.3l-1.4,0.3l-1.2-0.2l1.1-0.3l-1-0.3l1,0.3l1.2,0.3l1.2-0.3l0.9-0.4l-0.7-0.8l0.9,0.7l1.4-0.4
            l1,0.2l1.3,0.3l1.1-0.1l-1.2,0.2l-1.3,0.2l1,0.3h1.7l1.3-0.3l1.2-0.9l-2.2-0.3l-0.8-0.7l0.9-0.7l0.9,0.3h0.4l0,0l0,0l0,0l-1.3,0.5
            l1.5,0.3l1.3-0.3l-1-0.4h-0.5h0.8l1,0.3l1,0.2l0.4-1l-1,0.1l-0.9-0.3l-0.8-0.8l-1.2,0.3l-0.9-0.5l1.3,0.3l1.4-0.5l-0.9-0.4
            l-1.6-0.2h1.4l-1.1-0.3l1.6,0.4l1,0.4l1.2,0.3l1-0.3l0.3-1l-0.9-0.4h-1.1l-1.7-0.1h2.2l1-0.3l-1-0.2h-1h-1.3l-0.6-0.9l-1-0.1l1-0.3
            l1.1,0.3l1-0.3l-1-0.1h1.2l1-0.1l1.4,0.2l1,0.2l0.9-0.5l-0.9-0.6h-1.1l-1.2-0.3l-0.9-0.5l1.4-0.1l1.1-0.1l-1.1-0.2l-1.5-0.3
            l-1.4,0.3l0.9-0.7l0.3-1l1-0.3h1.2l0.9-0.5l0.9-0.3h-1l0.3-1.1l-0.9-0.6l1.2-0.3l1.3,0.2h1.3h1.3l1.2-0.3l1-0.3l-1-0.1l-1.4-0.1
            l-1.3,0.1l-1.2-0.2l-0.9-0.1l-1.3,0.1l1.2-0.1h0.1l0.3-0.1l1,0.2l1.3-0.1l1.3-0.1l1.3,0.1l1.7-0.7l1.2-0.1l0.9-0.5l1.5-0.2l1.7-0.4
            l1.3-0.3l-1-0.3l-1.6-0.1l-1.4-0.3h-2.1l-1.1,0.1l-1.1,0.3l-0.9,0.3h-1.1l-1-0.2l-1-0.2l-1.2,0.1l-1.7,0.5l-1,0.3l-1.5,0.3
            l-1.1,0.3l-1.1,0.2l0.9-0.4l1.2-0.3l1.1-0.4l0.9-0.3l0.1-1l-3,0.2l-1,0.4l-1.3,0.2l-1.8,0.2l-1-0.1l2.9-0.7l-5.3-0.1h-1.2l-1.1,0.3
            l-1.3-0.1l-1.1,0.1l-1.4,0.1l-1.4-0.1l1.3-0.2l1.2-0.1l1.3-0.3h0.8l9.7-0.3l2.5-0.3l1.3-0.4l-1.2-0.2h-1.1h-1.1l-1-0.2l-1.8,0.2
            l0.8-0.7l-2.2,0.2h-1.4h-1.2h-3.2l-2,0.2h-1.1H530l-1.5,0.1h-2.4l4.9-0.3l-1.4-0.2l1.9,0.2l2.3-0.1l8.1-0.3l-4.3-0.4h-1.1l-2.3-0.1
            h-1.5l-1.1-0.1l-2.2,0.3l-1.1-0.2l-1.1,0.1l-2.5,0.2l-1.7,0.1l-1.2,0.3l1.3,0.1h1.1l-3,0.2h2.3l-2.4,0.4l-1-0.3l-2-0.2h0.9l0,0
            l0.9,0.2l0.2-0.2h-1.3l-0.9-0.2l-0.2,0.2l-0.7-0.1l-1-0.2h-1.3l-1.8,0.1l1.7,0.2l-1.4-0.1L511,128l2.4,0.2l-2.5-0.1l-1.4-0.2
            l-1,0.3h1.4l1.4,0.1l6.4,0.2l0.9,0.6l-1-0.3l-1-0.2l-1.2-0.1l-5.4,0.1l1.1,0.3l1,0.3l1.6,0.2l-2.3-0.1l-0.7,0.9h-1.4l-1-0.3
            l-2.3-0.3l-2-0.4l-3,0.4l1,0.6l-1.9-0.1l0.9,0.3l-1.5-0.3l-1.7-0.2l-1.1,0.4l-1.2,0.1l0.7-0.8l-0.9-0.3l-1.5,0.2l-1.7,0.1H491
            l-1.1,0.1l-1.1,0.1l-1.3,0.3l1,0.5l1.2,0.2l1,0.4l-1-0.3l-1-0.3l-1.9-0.5l-1-0.1h-0.9l-0.9,0.8l-0.9,0.7l-1,0.2l-1.9-0.3l-0.9,0.7
            l-1.1-0.3l-1,0.1l-1.2,0.4l-0.9,0.3l-1.1,0.1l-1.4,0.3l-1,0.6l1,0.1l1,0.2l1-0.2l1.6-0.1h1l-1,0.1l-1.3,0.3l-0.5,0.9l-1,0.3
            l-0.9,0.4l-1.3-0.1l-1.1,0.1l-1.4,0.1l-2.4,0.5l-1.2,0.1l-1,0.2l-1.4,0.1l-1.4,0.3l-1.3,0.3l-0.8,0.7l1,0.3l1,0.3l1.9-0.2l-0.9,0.3
            l1.6,0.1l-1.1,0.2l1.6,0.3l1.5-0.1l2.3-0.4l1.3,0.5l-1.1,0.2h-1h-1.6l-1.4,0.3l1.4,0.1l0.4-0.1l2.6,0.3h-1.1l-1.3-0.2h-1.4
            l-1.4-0.1h-0.3l-2.2,0.7l0.5,0.3l1.7-0.4l-0.9,0.5l1.6,0.3l1.4,0.2l-1.5,0.1l-1.4,0.3l1.1,0.4l1,0.3l2-0.4l1.1,0.2l1,0.3l1.3-0.3
            l1.2-0.3l1,0.3l1-0.4l1.1,0.3l1.1,0.1l1,0.1l1.2,0.3l1.1,0.5l1,0.2l1.1,0.1l-0.3,1l0.9,0.5l0.9,0.7l0.9,0.4l-1.1,0.1l1,0.2
            l-1.1,0.3l-1.2,0.3l2.1-0.3l0.2,1l0.3,0.7l0.2,1l0.9,0.6l-0.9,0.6h1l-1.1,0.1l-1.2,0.2l0.6,0.2l0.3-0.2L483,151h-0.1h-0.1l-0.8,0.4
            l0.9-0.4l0.3,0.2l-1,0.8l1.4-0.3l-1.5,0.6l-1,0.3l1,0.5l1.1,0.2l1-0.2l0.8-0.8l0.2-0.9l0.4,0.9l1-0.3l-1,0.5l1.2,0.1h1.6l-1.1,0.2
            l-1.4,0.3h1.6h1.3h-1.1l-0.9,0.6l1.2,0.2l1.2,0.3l-1,0.2l1,0.5h-1.1l1.2,0.2l-0.9,0.3l0.9,0.3l-1-0.2l-1.1-0.2l-0.9-0.5l-1.3-0.2
            l-1.4-0.1l-1.1,0.4l0.9,0.6l1,0.1l1.6,0.7l1.1,0.2l1.2,0.1l1,0.3l-0.6,0.8l-0.8,0.8h1.1l-0.8,0.6l-1-0.3l-0.6,0.9l1.1-0.3l-0.9,0.6
            l-1.1,0.2l-1.1-0.1l-1.1,0.3l-1,0.3l1,0.1l1.1,0.1l1.1-0.4l1.1-0.1l-0.8,0.7l0.9,0.6l-1-0.3h-1.3l-1.1-0.1h-1.1l0.9,0.3l-1,0.2
            l-0.8,0.7l1-0.4l1-0.3l1.2-0.1l-1,0.3l1.4-0.3l1,0.3h1l-0.9,0.4l1-0.2l-1,0.3l1,0.3l-1.4-0.3l-1.3-0.1l-1.1-0.3l-1,0.3l-1,0.3
            l-0.8,0.8l1.1-0.2l1-0.2h1.2h0.1h-0.1l-1.1,0.2l-1.1,0.2l-0.8,0.8h1.1l1.3,0.2l-1,0.3l-1.2,0.3l1.1,0.1l-1.1,0.1l-0.3,1l1.4-0.6
            l1.7-0.8l1.4-0.6l1.1-0.3l1-0.2l-1,0.6l-1.3,0.1l-0.9,0.3l-0.9,0.5l-1.1,0.4l-1.2,1l1.3-0.2l1.2-0.2l-1,0.3l-1,0.5l0.9,0.6l0.9-0.6
            l1.4-0.3l1.3,0.3l-1-0.2l-1.6,0.5l-0.9,0.5l0.5,0.9l1-0.2l-0.9,0.4l-0.9,0.5l-0.2,1l0.9-0.5l0.9-0.4l1-0.4l0.3-1l-0.1,1l1.1-0.2
            l-0.3,1l-1.1,0.1l-1,0.4l-1,0.3l1.7-0.3l1,0.1l-1,0.1l-1,0.2l-0.9,0.7l1.1-0.3l-0.9,0.9h1l-0.9,0.4l1,0.3h1l-0.7,0.9l1-0.3
            l-0.8,0.7l-0.3,1l0.7,0.9l1.1-0.2l-1,0.6l1-0.2v1l1.1-0.1l-1.4,0.3l0.8,0.6h1.1l-0.9,0.3l1,0.1l-0.9,0.4l1-0.3l1-0.1l1-0.1l1-0.3
            l1-0.2l-0.9,0.6l1.1-0.2l-0.9,0.6l1-0.3l-0.5,0.9l1.1,0.1l0.9-0.7l-0.7,0.8l-0.8,0.8l1-0.4l-0.9,0.5l1,0.3L494.1,180.1z
             M485.9,162.7l-1.3-0.5l0.7,0.1l1,0.3h1.1L485.9,162.7z M484.7,157.4l1-0.3l0.9-0.8l-0.9-0.3l-0.9-0.7l-1.1-0.3l-1.2-0.1l-0.7,0.8
            l-0.5,0.9l1.4,0.1l0.5,0.3l-1,0.3l1.1,0.2L484.7,157.4z M542.4,150.5l-1-0.4l1.2-0.1l-1-0.3l-0.9-0.5l0.8,0.3l0.9-0.7l-1.7-0.2
            l-1,0.1l-1.4,0.3l2.2,0.1l0.2,0.1l-1.4-0.1l-0.8,0.7l1.1,0.3l1.8,0.6L542.4,150.5z M540.9,148.5l-1.2-0.1l-1-0.1l-1-0.2l-1.3,0.3
            l1,0.1l1,0.3L540.9,148.5z M538.7,147.9L538.7,147.9l-1,0.3l0,0l0,0l0,0l3.1,0.2L538.7,147.9z M550.9,143.6l-0.9,0.1l-1-0.1
            l0.7,0.8L550.9,143.6z M552.5,134.2l0.9-0.4l-0.7-0.3l-1.4,0.2l-0.9,0.4l1,0.3L552.5,134.2z M500,129.9l-1.8-0.4l-1,0.3l1.4,0.2
            L500,129.9L500,129.9z M511.2,129.2l-1.1-0.3l-1.4-0.3l-0.9,0.1h-1.4l1.1,0.5l2.7,0.3L511.2,129.2z M519.9,127.8l-1-0.2l-0.7,0.1
            l1.2,0.2h1.5L519.9,127.8z'
              ></path>
              <path
                id='Iceland'
                class={style.st2}
                d='M546.4,171.5l0.9-0.5l1-0.1l1.2-0.2h0.3l0.9-0.5l0.9-0.4l1-0.1l1-0.3l0.8-0.8l1.2-0.5l-1-0.3l1.4-0.2l-0.9-0.3
            l0.6-0.9l-1-0.3l-1-0.2l-0.3-1l0.1-0.4l-0.9,0.3l-0.8-0.8h-1.1l-0.5,0.9l-1.1,0.2l-1-0.1l-0.9,0.4l-0.9-0.6l-0.9,0.3l-1,0.5l-1-0.3
            l-0.1,1l-1-0.1l-1,0.3l0.4-0.9l-0.8-0.8l-0.9-0.5h-1.1l0.4,0.9l-1-0.3l-1-0.1l-0.2,1l-1.2,0.5l1.1,0.1l1.1-0.2l1-0.1l0.9,0.4
            l-0.9,0.6l1,0.3l-1-0.1l-1.1,0.2l-1,0.1l-0.9,0.5l1-0.2h1.2l0.9,0.7l0.9,0.5l-0.8,0.7l-0.9,0.6l1.1-0.1h0.5l1-0.1l0.9,0.3l0.9,0.5
            l1.2,0.1l1.1,0.3L546.4,171.5z'
              ></path>
              <path
                id='Norway'
                class={style.st2}
                d='M598.2,185.4l0.9-0.5l0.7-0.8l0.8-0.8l0.9-0.4h1l0.1-1l0.4-1v1.1l0.9,0.6l0.9,0.5l0.4,0.2l0.2-1.1l0.3-1.4
               l1-0.4l0.2-1.1l-0.8-1.2l0.9-0.4l-0.3-1l-0.9-0.4l0.2-1.3l-0.5-0.9l0.2-1l-0.2-1.4l0.9-0.9l1-0.3l1.7-0.3l-1-0.9l0.9-1l0.5-1
               l0.2-1.1l0.7-0.8l1-1l1.4-1.1l-0.2-1.2l0.6-0.9l0.9-0.5l1.2,0.3l0.3-1l1-0.2l1.4,0.2l1.4-0.6l0.5-0.9l1.2-0.6l1.4,0.7l0.8,0.7
               l1.2,0.1h1.1h1.3l1.1,0.3l0.8-0.8l0.6-1l0.5-0.9l1-0.5l1-0.1l0.9-0.3l1,0.5l1.4,0.4l-0.3,1l0.3,0.5l0.4-0.1l0.9-0.7l0.8-0.7l1-0.4
               H638l-1,0.3l-0.9-0.5l-0.9-0.3l-1-0.3l1,0.1h2l0.8-0.7l-0.9-0.4l-1.2-0.3l-1-0.3l-1,0.2l-1.4,0.3l0.3-1l-1,0.2l-0.3,0.9l-1.1,0.3
               l-0.1-1.1l-0.9,0.8l-0.7,0.8l-1,0.3l0.2-1.5l0.9-0.7h-1.1l-1.1,0.1l-0.3,0.9h-1l-0.3,1.1l-1-0.3l-1,0.2l1-0.3l-1,0.2l-1.1-0.2
               h-1.1l1,0.3l0.8,0.8l-0.9-0.4l-1-0.1l-0.3,1.1h-1l-0.6-0.9l-0.6,0.9l-0.7-0.7l-0.2,0.9h-1l-1,0.2l-0.1,0.2l-0.5-0.8l-0.8,0.3
               l-0.8,0.9l1.1-0.3h0.9l-0.2,0.8l-1,0.3l-0.9,0.7l1.1-0.1l1,0.3l-0.9,0.4l-0.9-0.4l-1.1,0.1l0.9,0.5l-1-0.2l0.6,0.9l-0.9-0.9
               l-0.9,0.3l1,0.1l-1.1,0.3l-0.9,0.7l0.9-0.5l0.3,1h-1l-1.2,0.3l1.2-0.1l0.9,0.5l-1-0.3l-0.9,0.3l-1,0.2l-0.4,0.9l-0.5,0.9l1,0.2
               l-1-0.2l-1.1,0.4l0.9,0.5h-1l0.3,0.9l-0.7,0.8l1-0.3l-1.1,0.5l-1,0.3l-1,0.6l1-0.3l-0.4,0.9l-1-0.1l-1,0.4l-0.4,0.9l-0.5,0.9h1
               l0.9-0.9l1-0.2l-0.8,0.7l-0.9,0.7l-1,0.2l-1-0.1l-1,0.5l-0.9,0.9l-0.9-0.3l0.9,0.7l-0.9-0.6l-1,0.1l-0.9,0.5l1.6,0.4l-1.2-0.2
               l-0.9,0.4l0.5,0.9l-0.8-0.5l-0.5,0.9l-1-0.3l-0.8,0.7l1,0.2l1,0.1l-1.1-0.1l-1-0.2l-0.8,0.8l1.2,0.4l-1,0.3l1.1-0.1l-1.3,0.3
               l0.9,0.3l1.1-0.2l1-0.3l0.9,0.4l1.2-0.2l-1,0.6l-0.9-0.5l-1,0.1l-1.2,0.1l-1,0.3l0.4,0.9l1,0.3l-0.7,0.8l1,0.1l0.7-0.8l1,0.1
               l-0.9,0.8l-0.5,0.9l-1.1,0.1l-0.1,1.1l0.9-0.6l0.9,0.6l-0.3,1l1.1-0.4l-1,0.5l-1.1-0.2l0.3,1l0.9,0.5l0.8,0.6l1.1,0.2h1
               L598.2,185.4z M611.6,159.6l0.3-1l-0.8,0.8l0.2-1l-0.4,0.5l-0.3,0.9L611.6,159.6z M623.5,154.5l0.9-0.7l-0.9,0.3l-0.9,0.6
               L623.5,154.5z M566.5,153.6l0.9-0.5l0,0l-1,0.3l-0.9,0.5L566.5,153.6z M621.6,139.2l0.9-0.4l0.9-0.6h-1l-1.2-0.8l-0.5-0.1
               l-1.1,0.1h-1.1l-1.4,0.3l1.4,0.3l-0.3,0.9l1.4-0.1l0.9,0.6L621.6,139.2z M611,139.9l0.8-0.9l1.2-0.3l0.1-1l0.7-0.9l1.7-0.3l1-0.2
               l1.1-0.3l-2-0.3l-1-0.3l-1.3-0.4l-0.9-0.5l-0.9,0.3l-0.5-0.9h-1.2l-1-0.3l-0.5,0.9l0.4,0.9l-0.9-0.4l-0.8-0.7l-1.2-0.2l-0.8,0.7
               l-1-0.3h-1.2l1.1-0.3l1-0.3l-1,0.1l-1.3,0.1h-1l-1,0.1l-0.1,1l1,0.3l0.9,0.3l-1.4-0.1l0.9,0.6l1,0.3h1.3l-1,0.3l1,0.4l1.8-0.3
               l0.9-0.6l1.1,0.4h1.5l1,0.3l-2,0.3l-1.1,0.2l-1,0.3l-0.9,0.5l1.1-0.1l1.1-0.1l1.1-0.1l1.9,0.1l-1,0.1l-2.1,0.3l1.5,0.2H607l-1,0.3
               l0.9,0.5l1.2,0.2l1,0.2l1,0.2l-1,0.2l1.1,0.3l0.9,0.3L611,139.9z M618.8,136.6h-1l-0.6,0.1l-0.9,0.4l1,0.3L618.8,136.6z
                M601.8,136.9l-1-0.2l-0.1-0.2l-1-0.3l0.9,0.6h1.2V136.9z M630.2,135.9h1.2h-1l-0.5-0.1l-1,0.3L630.2,135.9z M622.7,135.1h1
               l0.9-0.6l1.1-0.3l1-0.3l-0.8-0.5l-1.9-0.1l-1.1-0.1h-1.2l-1,0.2l-0.9-0.4h-0.6l-0.6,0.9l-0.9-0.6l-1.1,0.1l-1.4-0.4l-1.2,0.4
               l-1.1-0.2l1.1,0.3l-1.1-0.1l-1,0.3h1.1l-1,0.3l0.9,0.3h1.2l1.1-0.2l2.8,0.2l-2.2,0.1l1.1,0.2l-1.4-0.1h-1.1l1,0.3l1,0.2h1.5l2,0.3
               L622.7,135.1z M635.8,133.5l1.3-0.2h-1.2l-2,0.3L635.8,133.5z'
              ></path>
              <path
                id='Sweden'
                class={style.st2}
                d='M615.9,189.3l0.3-0.9l0.3-1l-0.2,0.1l-0.3,1l-0.6,0.9l0.2,1L615.9,189.3z M620,187.1l-0.2-1l-0.6,0.1
               l-0.6,0.9l0.7,0.8L620,187.1z M610.4,192.5l0.7-0.9l0.6-0.9l1-0.3h1l1-0.2l0.5-1.3l0.2-1l0.3-1l-0.4-1l0.6-0.9l-0.3-0.9l-0.9-0.2
               l1.4-0.1l0.9-0.5l1-0.2l0.3-0.9l-1.1,0.3l-0.9-0.4l-1-0.3h-1l1.1-0.1h1.1l0.9-0.4l0.9,0.6l0.9-0.3l0.9-0.7l-0.9-0.7l-0.9-0.6
               l-0.9-0.7l-1.1-0.1l-0.4-0.9l-0.3-1l0.8-0.7l-0.3-1l-0.1-1l0.9-0.4l-0.6-0.9l0.9,0.3l0.8-0.8l1-0.3l1-0.3l0.9-0.5l0.9-0.5l0.6-0.9
               l0.5-0.9l-0.2-1v-1l0.9-0.6l1-0.3l1,0.1l1-0.2h1l-0.6-0.9l-0.2-1l-0.3-1l-0.3-1l-0.7-0.9l-0.7-0.8l-0.8-0.7l-0.5-0.1l-1-0.2
               l-1-0.3l-1.2-0.6l-0.6-0.3l-0.5,0.9l-1.4,0.6l-1.4-0.2l-1,0.2l-0.3,1l-1.2-0.3l-0.9,0.5l-0.6,0.9l0.2,1.2l-1.4,1.1l-1,1l-0.7,0.8
               l-0.2,1.1l-0.5,1l-0.9,1l1,0.9l-1.7,0.3l-1,0.3l-0.9,0.9l0.2,1.4l-0.2,1l0.5,0.9l-0.2,1.3l0.9,0.4l0.3,1l-0.9,0.4l0.8,1.2
               l-0.2,1.1l-1,0.4l-0.3,1.4L605,183l-0.4-0.2l-0.3-0.3l-0.3,1l0.9,0.6l0.6,0.9l-0.2,1.1l0.8,0.7l0.4,1l0.8,0.7l0.7,0.8l-0.8,0.6
               l0.6,0.9l0.3,1l0.9,0.6L610.4,192.5z'
              ></path>
              <path
                id='Finland'
                class={style.st2}
                d='M628.7,180.6l1.1-0.1l0.9-0.3l1.1-0.1l1-0.3l0.9-0.3h1.1l1-0.3l1.1-0.1l0.7-0.6l1-0.8l0.9-0.7l0.9-0.8
               l0.9-0.9l0.9-0.8l0.7-0.9l-0.2-1l-0.9-0.6l-1.1-0.5l-0.5-0.9l0.3-1l-0.9-0.3l-0.2-1l-1-0.6v-1.1l0.5-0.9l-0.7-0.9l-1-1.1l-0.8-0.7
               l0.7-0.9l0.2-1.2l-0.9-0.8l-1.2-0.3l-0.6-0.9l0.7-1.1l-0.3-0.5l0.3-1l-1.4-0.4l-1-0.5l-0.9,0.3l-1,0.1l-1,0.5l-0.5,0.9l-0.6,1
               l-0.8,0.8l-1.1-0.3h-1.3h-1.1l-1.2-0.1l-0.8-0.7l-1.4-0.7l-1.2,0.6l0.6,0.3l1.2,0.6l1,0.3l1,0.2l0.5,0.1l0.8,0.7l0.7,0.8l0.7,0.9
               l0.3,1l0.3,1l0.2,1l0.6,0.9l0.9-0.2l0.8,0.6l0.7,0.8l-0.3,1l-1.1,0.5l-0.7,0.8l-0.8,0.8l-0.8,0.6l-1,0.5l-0.4,0.9l-1,0.2l-0.6,0.9
               l-0.4,0.9l0.5,0.9l0.4,0.9l0.3,1l-0.2,1l0.3,0.9l1,0.3l1,0.3l0.9,0.4l0.3,1L628.7,180.6z'
              ></path>
              <path
                id='Denmark'
                class={style.st2}
                d='M602,193.9l-0.3-1l0.1-1.1l0.9-0.5l0.7-0.8l0.4-0.9l-1-0.4l-1,0.2l1-0.3l-0.6-0.9l1-0.4l-0.3-1.1l-0.3,0.1
               l-1,0.4l-1,0.8l-1.2,0.7l0.9-0.4l1.2,0.1l-1,0.3l0.3,1L600,189l-0.7,0.8l-1-0.1l0.1,1.5l0.4,1l0.8,0.8v1h0.5l1,0.2l0.1,0.1
               L602,193.9z M604.1,192.6l-0.9-0.6h-0.3l-1,0.3l0.7,0.9l0.9,0.3L604.1,192.6z M607,193.4v-1.1l0.8-0.8l-0.6-0.9l-0.6,0.7h-1.1
               l-0.7,0.8l0.4,0.9l0.9,0.4l0.5,0.9L607,193.4z'
              ></path>
            </g>
            <g id='Europe'>
              <path
                id='Ireland'
                class={style.st2}
                d='M562.4,202.9l1-0.3l0.9-0.7l1-0.1l0.9-0.5l1.1-0.1l1.1-0.1l0.4-0.9l0.3-1l-0.2-1l-0.2-1v-1.1l-1-0.3h-1.1h-1
               l-0.8-0.8l0.9-0.3l0.6-0.9l0.4-0.4l0.3-0.5h-0.1l-1,0.1l-1,0.3l-0.6,0.9l0.1,1.1l-1,0.3l-1-0.1l-0.9,0.5l0.6,0.9l-0.7,1l0.9,0.5
               l1,0.3l-0.9,0.3l-0.4,0.9l1,0.1l-1.2,0.2l-0.9,0.7l-0.9,0.6l1.1-0.1l-0.4,1l1-0.3l-0.3,1L562.4,202.9z'
              ></path>
              <path
                id='Netherlands'
                class={style.st2}
                d='M594.6,204.9l0.3-1v-1.1l0.6-0.8l0.8-0.8l-0.3-0.9l0.8-0.9l0.3-1v-0.2l-0.8-0.5l-0.8-0.1l-1.2,0.2l-1,0.5
               l-0.8,0.7l-0.9,0.7l-0.4,1.3l-0.8,1.1l0.4,0.9l-1,0.1l1.1,0.2h0.1h0.1l0.1-0.1v-0.1l0,0l0,0h0.3l0,0h0.2l0,0l0.3-0.2h0.1v0.2
               l0.3-0.1l0,0l0.3,0.3v0.1l0.3,0.2h0.1l1,0.3h0.1l0.1,0.1l0,0l-0.2,0.6l-0.1,0.1l-0.1,0.2v0.1l0,0h0.1v0.1l0,0l0,0h0.1l0,0h0.3v0.1
               L594.6,204.9z M590.8,203.3L590.8,203.3h-1h-0.9l0.2,0.3l0,0h0.2v-0.1l1.1,0.1L590.8,203.3z'
              ></path>
              <path
                id='Serbia'
                class={style.st2}
                d='M630.1,228.4l1.1-0.3l0.1-0.1l0.1,0.1l0.5-0.5l-0.2-0.1l-0.1-0.4l0.1-0.1l-0.2-0.3l0.1-0.1h0.1v-0.1l0.7-0.3
               l0,0l0.3-0.3v-0.1L632,225l-0.6-0.8h-0.1l-0.1-0.3l-0.1-0.1l0.5-1l0,0l0,0l0.2-0.2l-0.2-0.3h-0.1l-0.3-0.4v-0.1l0.3-0.2l-0.9,0.2
               h-0.1l-0.1-0.1l-0.1-0.1l0,0l-0.2-0.3l-0.2-0.1l-1.2-0.3l0.3-0.3v-0.1l-0.3-0.1v-0.1h-0.1l0,0l0.3-0.3v0.4l-0.2-0.1l-1-0.4h-0.1
               l-0.2-0.3l-0.3-0.5l0,0l0.1-0.2v-0.2l-0.6-0.5h-0.1l-0.6-0.5l-0.5-0.1l-0.2-0.1h-0.6l-0.4,0.2l0,0l-0.2,0.1v0.1l-0.9,0.3l0,0h-0.2
               v0.4l0.1,0.1v0.1l0,0l0.4,0.9l0.7,0.3l0,0l-0.2,0.4l-0.1-0.1l-0.3,0.9l0.3,0.9l-0.1,0.1l0.6,0.9l0.6,0.3v0.1l-0.2,1l-0.2,0.3l0,0
               l-0.5,0.1l0.8,0.8l0.1,0.1h0.1l0.3,0.2h0.1l0.2,0.2h0.2l0.9,0.4l0.2,0.3l0.3-0.3v-0.1l0.6-0.9l0.2,0.1l0.9,0.6l0.3,0.4l0,0
               l0.9,0.5l-0.4,0.9l0.1,0.3H630.1z'
              ></path>
              <path
                id='Slovakia'
                class={style.st2}
                d='M622,213l0.8-0.7l1.1-0.2l1-0.1l0.9-0.8l1-0.3l0.2,0.1l1-0.1l0.9,0.6l0.7-0.2l0.3-0.9l0.3-0.9l-1.1-0.3
               l-0.9-0.5h-1h-1l-1,0.2l-1.1-0.2h-1.2l-0.7-0.3l-0.9,0.4l-0.7,0.9l-1,0.5l-1,0.2l-0.1,1.5l0.4,0.5l0.9,0.5l1.2,0.2L622,213z'
              ></path>
              <path
                id='Slovenia2'
                class={style.st2}
                d='M613.6,219l0.9,0.4l0.9-0.8l0.3-1l0.9-0.5l0.7-0.6l-0.2-0.9l-0.5-0.1l-0.9,0.4l-1.3,0.2l-0.9,0.6h-1.1
               l-0.9-0.3l-0.6,0.9l0.4,1l0.3,0.8l-0.3,0.3h1L613.6,219z'
              ></path>
              <path
                id='Poland'
                class={style.st2}
                d='M630.5,209l0.1-1.2l0.6-0.8l0.9-0.8l0.9-0.6l0.2-1l-0.8-0.9l-0.6-0.9l-0.1-1l-0.6-0.9l0.3-1l0.7-0.8l-0.4-1
               l-0.5-0.9l-0.3-0.9l-0.6-0.9l-1-0.3h-1l-1.1-0.1l-1.7-0.1h-1.2l-1.5-0.1l-0.4,0.2l-1.3-0.7l-1-0.5l-1.3,0.1l-1.5,0.5l-1,0.3
               l-1.1,0.6l-1.2,0.3l-1,0.3l-1.2,0.3v0.2l0.2,0.4l0.3,1l-0.4,1l0.7,0.7l0.3,1l0.3,1l0.1,1l0.7,0.8l-0.3,1l1,0.1l1,0.3l1,0.2l0.4,1
               l1,0.3l1-0.2l0.9,0.5l1,0.3l0.9,0.6l0.6,0.6l0.7,0.3h1.2l1.1,0.2l1-0.2h1h1l0.9,0.5l1.1,0.3V209z'
              ></path>
              <path
                id='Portugal'
                class={style.st2}
                d='M565.1,242.7l-0.3-1l0.6-0.9l0.8-0.8l-0.9-0.8l0.3-1l0.1-1l-0.4-0.9l0.9-0.7l0.2-1l0.3-1.1v-1l0.3-1l0.9-0.6
               l-0.5-0.9l-0.9-0.7l-0.9,0.3h-1.1l-0.7-0.6l-1,0.2l-0.2,0.1l0.1,0.7l-0.2,1l0.3,1l-0.3,1.2l-0.3,1l-0.3,1l-0.5,1l-0.4,1l0.6,0.9
               l0.8-0.7l-0.5,0.9l-0.5,0.9l0.9,0.4l-0.1,1.2v1.1l0.1,1h1l1.1,0.3L565.1,242.7z'
              ></path>
              <path
                id='Ukraine'
                class={style.st2}
                d='M657.1,222.1l0.7-0.7l1.1-0.2l0.7-0.8l1,0.3l1-0.3l-0.4-1l-1,0.1l-1,0.3l-1-1.2l-0.4-1.1l0.9-0.7l-0.4,1
               l0.7-0.9l0.9-0.7l1-0.1l1-0.1l0.9-0.7l0.9-0.3l1.1-0.1l-0.1-1.1l0.9-0.5l1.3-0.4l0.9-0.3v-1.1l-0.5-1l0.3-1.1v-1l-1-0.3l-1-0.6
               l-1.1-0.2l-0.9-0.3l-1.1-0.1l-0.7-0.7l-1.1-0.1l-1,0.2l-1.2-0.3l-1.1-0.2l-0.3-1l-0.7-0.9l-1-0.2l-1-0.4l0.3-1l-0.9-0.7l-0.6-0.8
               l-1-0.2l-1,0.3l-1-0.3l-0.8,0.8l-0.4-0.1l-1,0.1l-1,0.4l-0.3,1l-1.2,0.1l-1,0.1l-0.9-0.4l-0.9,0.5l-0.9-0.5l-1,0.4l-1-0.3
               l-0.9-0.4l-1.4-0.3l-1.1-0.2l-1-0.1l-1.2,0.2l-0.9,0.6l-1.1,0.2v0.3l0.6,0.9l0.8,0.9l-0.2,1l-0.9,0.6l-0.9,0.8L631,208l-0.1,1.2
               l-0.3,0.5l-0.3,0.9l-0.3,0.9l0.9,0.6l0.8,0.6l1-0.1l1.1,0.1l1.1,0.1l0.9,0.3l2-0.3l1-0.3l0.8-0.6l1.1-0.3l1.3-0.3l0.9,0.6l1,0.3
               l0.9,0.6l0.6,0.9l0.6,0.9l0.4,0.9l0.9,0.6l0.4,0.9l-1,0.2l-0.9-0.5l-0.3,1l-0.4,0.9l-0.6,0.9l0.4,0.8l1-0.3l1.1-0.1l0.3,0.5
               l-0.1-0.7l-0.2-1l1.4-0.2l0.8-1.1l0.6-0.9l0.9-0.5l0.9,0.4l0.2-1l0.3,1l1.1,0.1l-1.1,0.3l-1.1-0.2l0.9,0.7l1,0.6l1-0.1h1.2
               l0.8,0.7l-0.9,0.4l-0.4,1l0.9,0.6l0.9,0.6L656,222l0.9,0.6L657.1,222.1z'
              ></path>
              <path
                id='Rumania'
                class={style.st2}
                d='M638.8,224.1l0.9-0.7l1-0.2h1.1l1,0.4l1.1,0.5l0.9,0.1l0.1-0.8v-1l0.6-1l1.2-0.3v-1l-0.3-0.5l-1.1,0.1l-1,0.3
               l-1.2-1.6l-0.1-1l0.1-1l-0.3-0.9l-0.8-0.8l-0.7-0.8l-0.7-0.9l-0.6-0.9l-0.9-0.5H639l-0.8,0.6l-1,0.3l-2,0.3l-0.9-0.3l-1.1-0.1
               l-1.1-0.1l-1,0.1l-0.9,0.5l-0.8,0.7l-0.3,1l-0.5,0.9l-0.5,0.9l-0.8,0.7l-1.3,0.3l-0.1,0.1l0.6,0.5h0.1l0.6,0.5v0.2l-0.1,0.2l0,0
               l0.3,0.5l0.2,0.3h0.1l1,0.4l0.2,0.1v0.1l-0.3,0.3l0,0h0.1v0.1l0.3,0.1v0.1l-0.3,0.3l1.2,0.3l0.2,0.1l0.2,0.3l0,0l0.1,0.1l0.1,0.1
               h0.1l0.9-0.2l-0.3,0.2v0.1l0.3,0.4h0.1l0.2,0.3l0.9,0.4l1.7,0.7l1.2,0.2l1.4,0.1l1,0.3L638.8,224.1z'
              ></path>
              <path
                id='Italy'
                class={style.st2}
                d='M616.2,243.8l0.3-1l-0.3-1l0.3-1l0.7-1.1l-0.5,0.1l-1.2,0.2l-0.9,0.4h-1l-1.1-0.3l-1.1,0.2l-1.2,0.2l0.3,1
               l1,0.4l0.9,0.5l0.9,0.4l1,0.3l0.8,0.8L616.2,243.8z M602.3,236.9l1.1,0.1l0.2-1l0.1-1l-0.1-1l0.1-1.1l-0.3-0.9l-0.9-0.6l-0.3,0.3
               l-0.9,0.6l-1.1,0.2l0.3,1l0.3,0.9l0.3,1l-0.3,1l0.3,1l0.9,0.5L602.3,236.9z M618.4,239.9l0.9-0.8l0.2-1.2l1-0.3l-0.2-1l-0.8-0.8
               l-0.5-0.9l0.3-0.9l0.8-0.7l0.9,0.6l1,0.7l1,0.3l-0.2-1.1l-0.8-0.7l-0.9-0.5l-0.9-0.5l-1-0.5l-1.2-0.5l-0.9-0.7l0.3-1l-1.4,0.1
               l-1-0.3l-0.9-0.5l-0.8-0.7l-0.7-0.9l-0.5-1.3l-1.2-1.3l-1.4-0.9l-0.8-0.9l-0.2-1l0.1-1l-0.1-1.1l1-0.2l1-0.6l1,0.5l-0.3-0.8
               l-0.4-1l0.6-0.9l-1-0.1l-1-0.2l-1-0.4l-0.4-0.5l-1-0.1l-1,0.1l-0.9,0.5l-0.7-0.3l-0.7,0.8l0.1,1l-1.3-0.2l-0.9,0.4h-1.1l-0.4-0.9
               l-0.6,0.9l-0.9,0.6l-1.2,0.1l-0.3-0.1l-0.1,1.1l-0.2,1l0.3,1l-0.2,1l0.9,0.7l0.5,0.9v0.1h0.2l1.1-0.3l0.7-0.8l0.8-0.8l1.1,0.3
               l1,0.7l1,0.4l0.3,1.1l0.6,0.9l0.5,0.9l0.8,0.8l0.8,0.7l0.8,0.9l0.9,0.5l0.8,0.8l0.9,0.6l1,0.3l1.3,0.5l0.8,0.8l0.9,0.6l0.9,0.6
               l0.6,0.9l1,0.3l0.4,0.9l0.5,1l0.4,1.1l-0.8,0.7l-0.1,1v1L618.4,239.9z M609.3,223.8L609.3,223.8L609.3,223.8l-0.1-0.2l0.2-0.1v0.1
               L609.3,223.8z'
              ></path>
              <path id='Slovenia' class={style.st2} d='M609.4,223.7L609.4,223.7h-0.2v0.1v0.1h0.1L609.4,223.7z'></path>
              <path
                id='Croatia'
                class={style.st2}
                d='M622.7,227.6l-0.2-0.1l0,0v-0.1l-0.9-0.4l-0.1-0.1l-0.3-0.3H621l-0.1,0.1l-0.3-0.1l-1-0.3l0.5,0.2l1,0.4
               l0.9,0.5l0.9,0.6L622.7,227.6z M620.9,226.2l-0.4-0.3h-0.1v-0.1L620,225l-0.3-0.1l-0.8-0.7l-0.3-0.3l-0.1-0.1l-0.5-0.5l0,0h-0.1h0
               v-0.1h-0.1l-0.3-0.5v-0.1l-0.1-0.3l0,0l-0.6-0.9l-0.4-1l0.1-0.3l0.1-0.1l0.9,0.5l0.2,0.1l0.1-0.1l0.2-0.3l0.2-0.2h0.1h0.3l0.4-0.2
               h0.1l0,0v0.1l0,0l0,0l1,0.3h0.1h0.6l0.3,0.2h0.3l0.1-0.2l0.4,0.2l0,0l0.3-0.1h0.2l0.1,0.1h0.1l0.6,0.2h0.1l0.2,0.4l0,0h0.4
               l0.3-0.9l0.1,0.1l0.3-0.3l0,0l-0.7-0.3l-0.4-0.9l0,0v-0.1l-0.1-0.1v-0.4l-0.7,0.3l-1,0.2l-1-0.3l-0.9-0.5l-0.8-0.6l-1.2-0.7
               l-0.7,0.6l-0.9,0.5l-0.3,1l-0.9,0.8l-0.9-0.4l-1,0.4h-1l-0.2,0.1l0.3,1l0.7,0.8l0.5-0.9l1-0.2l0.5,1l0.6,0.9l0.1,0.3l-0.9-0.7
               l0.2,0.2l0.7,0.5l0,0l0.2,0.1l-0.2-0.1l0.2,0.7l0.8,0.8l0.9,0.6l0.9,0.3l0.9,0.3l1,0.7l0.9,0.7L620.9,226.2z'
              ></path>
              <path
                id='Bosnia2'
                class={style.st2}
                d='M622.7,227.5L622.7,227.5l0.3-0.2l-0.3-1l0.1-0.1h0.3l0.8-0.7l0,0l-0.1-0.4l0,0l-0.1-0.3l0.1-0.1l0.1,0.1h0.3
               l0.5-0.1l0,0l0.2-0.3l0.2-1v-0.1l-0.6-0.3l-0.6-0.9l0.1-0.1l-0.3-0.9h-0.4l0,0l-0.2-0.4h-0.2l-0.6-0.2h-0.1l-0.1-0.1h-0.2
               l-0.3,0.1l0,0l-0.4-0.2l-0.1,0.2h-0.2l-0.3-0.2H620h-0.1l-1-0.3l0,0l0,0v-0.1l0,0h-0.1l-0.4,0.2h-0.3H618l-0.1,0.3l-0.2,0.3
               l-0.1,0.1l-0.2-0.1l-0.9-0.5l-0.1,0.1l-0.1,0.3l0.4,1l0.6,0.9l0,0l0.1,0.3v0.1l0.3,0.5h0.1v0.1h0.1h0.1l0,0l0.5,0.5l0.1,0.1
               l0.3,0.3l0.8,0.7l0.3,0.1l0.4,0.8v0.1h0.1l0.4,0.3l-0.1,0.3l0.2,0.2l0.1-0.1h0.2l0.3,0.3l0.1,0.1l0.9,0.4v0.1l0,0h0.1V227.5z'
              ></path>
              <path
                id='Hungary'
                class={style.st2}
                d='M622.4,218.5l0.7-0.3h0.2l0,0l0.9-0.3v-0.1l0.2-0.1l0,0l0.4-0.2h0.6l0.2,0.1l0.5,0.1l0.1-0.1l1.3-0.3l0.8-0.7
               l0.5-0.9l0.5-0.9l0.3-1l0.8-0.7l0.9-0.5l-0.8-0.6l-0.9-0.6l-0.7,0.2L628,211l-1,0.1l-0.2-0.1l-1,0.3l-0.9,0.8l-1,0.1l-1.1,0.2
               l-0.8,0.7l-1,0.1l-1.2-0.2l-0.9-0.5l-0.5,0.9l-0.8,0.8v1l-0.8,0.4l0.5,0.1l1.4,1.5l0.8,0.6l0.9,0.5l1,0.3L622.4,218.5z'
              ></path>
              <path
                id='Czech'
                class={style.st2}
                d='M613.5,210.9l0.5-0.9l1-0.1l1,0.3l0.9,0.3h1.1l0.4-0.1l1-0.2l1-0.5l0.7-0.9l0.9-0.4l-0.6-0.6l-0.9-0.6l-1-0.3
               l-0.9-0.5l-1,0.2l-1-0.3l-0.4-1l-1-0.2l-1-0.3L613,205l-0.9-0.6l-0.8,0.6l-1,0.4l-0.9,0.4l-1,0.3l-0.6,0.9l0.4,0.9l0.6,0.9
               l0.9,0.5l0.8,0.7l0.9,0.6l0.9,0.5h1.2V210.9z'
              ></path>
              <path
                id='Brussels'
                class={style.st2}
                d='M593.9,208.3l0.3-0.1l0,0h0.2l0,0l0.1-0.1v-0.3l0,0l-0.2-0.2l0,0l-0.1-0.3l0,0l0,0l0.5-0.9l0.1,0.1l0,0
               l0.1-0.1l0.1,0.1v0.1l0.3-0.5h0.1l0,0l0,0h0.1l-0.3-0.6h-0.1h0.1h-0.1v-0.1l-0.3-0.5v-0.1l-0.3,0.1v-0.1h-0.3l0,0h-0.1l0,0l0,0
               v-0.1h-0.2l0,0v-0.1l0.1-0.2l0.1-0.1l0.2-0.6l0,0l-0.1-0.1h-0.1l-1-0.3h-0.2l-0.3-0.2V203l-0.3-0.3l0,0l-0.3,0.1v-0.2h-0.1
               l-0.3,0.2l0,0h-0.2l0,0h-0.3l0,0l0,0v0.1L591,203h-0.1h0h-0.1l-0.4,0.3l-1.1-0.1v0.1h-0.2l0,0l-0.2-0.3l-0.4,0.1l-1.3,0.7l0,0
               l0.2,0.5v0.1l0.3,0.2l0.1,0.1l0.3,0.2l0,0l0.2-0.1v-0.1h0.2l0,0h0.3v0.1l0.2,0.3l0.9,0.8l0.1,0.2V206l0.1-0.1l0,0h0.1l0.7,0.2l0,0
               l0.1,0.1l0.3,0.9l0.3,0.1l0.9-0.6l0,0l-0.2,0.4v0.1l0.2,0.1l0,0v0.1l0,0v0.3l0.9,0.4v0.1l0.1-0.1l0.1,0.1L593.9,208.3z'
              ></path>
              <path
                id='Estonia'
                class={style.st2}
                d='M636.4,186.7l1-0.1l0.3-0.9l-0.3-1l-0.4-0.9l0.6-0.9l0.3-1l-0.2,0.2l-1-0.1l-1.2-0.1l-1-0.3h-1.1l-0.8,0.3
               l-1.2,0.1l-1,0.1l-1,0.4l-0.5,0.9l0.5,1l0.9,0.5l1,0.2l-0.3,1l0.5-0.2l1-0.3l0.9,0.3l1,0.3l0.8,0.7L636.4,186.7z M627.8,185.1
               l0.3-0.9h-1l-1.1,0.9l0.8,0.6L627.8,185.1z M628.1,183.4l-0.9-0.5v0.1l-1,0.3l0.9,0.6L628.1,183.4z'
              ></path>
              <path
                id='Greece'
                class={style.st2}
                d='M640.5,248.8l1.1-0.6l-0.9-0.3h-1l-1-0.2l-1.2,0.1l-0.9-0.4l-0.7-0.1l0.6,0.9l1.1,0.2l1,0.3l0.9,0.3
               L640.5,248.8z M633.7,243.8l0.9,0.8l-0.3-1l-0.4-0.9l-0.4-1l1-0.1v-1l1.1,0.1l0.9,0.7l-0.2-0.7l-0.3-1l-0.9-0.6l-1-0.3l-0.8-0.8
               l-0.1-1h1.1l-0.8-0.8l-0.6-0.9l-0.5-0.9l0.5-0.9l0.9,0.6l1,0.1l0.9,0.6l-0.4-0.9l1,0.3l-1-0.5l-0.4-0.9l1.1-0.1l1-0.2l1-0.3l1,0.3
               l1,0.3h0.2l0.3-1l0.6-0.9l-0.8-0.9h-0.1l-0.3,1l-1,0.2h-1.1l-1-0.3l-0.9-0.3l-1,0.3l-1,0.1l-1,0.2h-0.3l-0.9,0.6l-1,0.3l-1,0.3
               l-1,0.2l0.1,1l-0.6,0.9l-0.8,0.8l-0.6,0.6l0.3,0.1l0.4,0.9l0.9,0.6l0.6,0.9l0.5,0.9l0.9,0.5l1-0.3l1-0.2l0.9,0.6l0.9,0.4l-1.1,0.1
               l-0.9-0.3l-1-0.4l-1,0.3l-0.3,1.1l0.9,0.7l0.1,1.1l0.9,0.5l0.9,0.6l0.5,0.9L633.7,243.8z M636.9,239.9l-0.3-1l-0.9-0.4l-0.9-0.6
               l-0.5,0.2l0.9,0.8l0.9,0.5l0.6,0.9L636.9,239.9z'
              ></path>
              <path
                id='Kosovo'
                class={style.st2}
                d='M628,229.3l0.7-0.7l1-0.2l-0.1-0.3l0.4-0.9l-0.9-0.5l0,0l-0.3-0.4l-0.9-0.6l-0.2-0.1l-0.6,0.9v0.1l-0.3,0.3
               l0.3,1.6l0.3,0.9v0.1L628,229.3z'
              ></path>
              <path
                id='Bulgaria'
                class={style.st2}
                d='M639.1,231.1l1-0.2l0.3-1h0.1l0.1-0.3l1-0.5l1-0.2l0.5,0.3l1-0.1l-0.3-0.5l-0.6-0.9l-0.3-0.3l0.7-0.8l0.2-1
               l0.9-0.4l0.2-1l-0.9-0.1l-1.1-0.5l-1-0.4h-1.1l-1,0.2l-0.9,0.7l-0.9,0.4l-1-0.3l-1.4-0.1l-1.2-0.2l-1.7-0.7l-0.9-0.4l-0.2,0.2l0,0
               l0,0l-0.5,1l0.1,0.1l0.1,0.3h0.1l0.6,0.8l0.7,0.8v0.1l-0.3,0.3l0,0l-0.7,0.3v0.1h-0.1l-0.1,0.1l0.2,0.3l-0.1,0.1l0.1,0.4l0.2,0.1
               l0.7,1.4l0.5,0.9l-0.1,1.1l1-0.2l1-0.1l1-0.3l0.9,0.3l1,0.3h1.2V231.1z'
              ></path>
              <path
                id='Lithuania'
                class={style.st2}
                d='M632.9,196.3l0.9-0.4l1.1-0.3l0.3-1l0.6-0.9l0.9-0.4l-0.3-1l0.1-0.4h-0.3l-1-0.7l-1.3-0.6l-1.2-0.7l-0.4,0.1
               l-1,0.3l-1-0.3l-1.2,0.1l-1-0.2l-1.1-0.1l-1,0.3l-0.9,0.6v0.1l0.3,1.1l0.3,1l0.4,0.1l0.1,0.1l1.1,0.3l1-0.1l0.6,0.9l0.1,1l1,0.3
               l0.6,0.9h1L632.9,196.3z'
              ></path>
              <path
                id='Luxembourg'
                class={style.st2}
                d='M595.4,207.5l-0.5-0.8v-0.1l-0.1-0.1l-0.1,0.1l0,0l-0.1-0.1l-0.5,0.9l0,0l0,0l0.1,0.3l0,0l0.2,0.2l0,0v0.3
               l-0.1,0.1l0,0l1,0.2l0.2,0.1L595.4,207.5z'
              ></path>
              <path
                id='Latvia'
                class={style.st2}
                d='M637.5,191.4h1l0.8-0.8l0.3-0.4l-0.6-0.9l-0.8-0.7l0.1-1.1l-2-0.8l-1.1,0.1l-0.8-0.7l-1-0.3l-0.9-0.3l-1,0.3
               L631,186l0.3,0.9l-0.3,1l-1.2,0.3l-0.9-0.6l-0.8-0.7l-0.9-0.7l-1.4,0.4l-0.6,0.9l-0.4,1l-0.2,1l0.3,1l0.9-0.6l1-0.3l1.1,0.1l1,0.2
               l1.2-0.1l1,0.3l1-0.3l0.4-0.1l1.2,0.7l1.3,0.6l1,0.7h0.3L637.5,191.4z'
              ></path>
              <path
                id='MoldovaEU'
                class={style.st2}
                d='M644.6,218.5l0.4-0.9l0.3-1l0.9,0.5l1-0.2l-0.4-0.9l-0.9-0.6l-0.4-0.9l-0.6-0.9l-0.6-0.9l-0.9-0.6l-1-0.3
               l-0.9-0.6l-1.3,0.3l-1,0.3l0,0h-0.1h0.1l0.9,0.5l0.6,0.9l0.7,0.9l0.7,0.8l0.8,0.8l0.3,0.9l-0.1,1l0.1,1l0.8,0.9L644.6,218.5z'
              ></path>
              <path
                id='Montenegro'
                class={style.st2}
                d='M624.7,228.5l0.6-0.9l1.6-0.9l-0.2-0.3l-0.9-0.4h-0.2l-0.2-0.2h-0.1l-0.3-0.2h-0.1l-0.1-0.1l-0.8-0.8h-0.1
               l-0.1-0.1l-0.1,0.1l0.1,0.3l0,0l0.1,0.4l0,0l-0.8,0.7h-0.3l-0.1,0.1l0.3,1l-0.2,0.2h-0.1v0.1l0.2,0.3l0.5,0.3l0.8,0.6l0.8,0.8
               L624.7,228.5z'
              ></path>
              <path
                id='Macedonia'
                class={style.st2}
                d='M629.7,232.2l1-0.3l1-0.3l0.9-0.6h0.3l0.1-1.1l-0.5-0.9l-1.2-0.9l-0.1-0.1l-0.1,0.1l-1.1,0.3l0,0h-0.3l-1,0.2
               l-0.7,0.7l-0.4,0.2l-0.2,1l0.3,1l0.9,0.7l0.1,0.2L629.7,232.2z'
              ></path>
              <path
                id='Austria'
                class={style.st2}
                d='M613.7,216.8l0.9-0.6l1.3-0.2l0.9-0.4l0.8-0.4v-1l0.8-0.8l0.5-0.9l-0.4-0.5l-0.3-1.4h-1.1l-0.9-0.3l-1-0.3
               l-1,0.1l-0.5,0.9h-1l-0.9-0.5l-0.7,0.5l-0.7,0.8l-0.3,1l-0.6,0.7l-1,0.2l-1.2,0.1l-0.9,0.4l-0.9-0.3l-1.2,0.4l-0.9-0.6l-0.5,0.9
               l0.9,1l1.2,0.1l0.7,0.3l0.9-0.5l1-0.1l1,0.1l0.4,0.5l1,0.4l1,0.2l1,0.1l0.9,0.3h0.8V216.8z'
              ></path>
              <path
                id='Armenia'
                class={style.st2}
                d='M686.7,237.9v-1l-0.3-0.9l-1-0.3l-0.8-0.8l-0.1-1l-1.1-0.9l-0.4-1l-0.9-0.8l-1,0.3l-1,0.1l-1.2,0.1l0.3,0.3
               l0.6,0.9l-0.1,1l0.8,0.8l1.3,0.2l0.9,0.8l0.6-0.2l0.7,0.6l1.1,0.1l0.4,0.9l0.6,0.9h0.6V237.9z'
              ></path>
              <path
                id='Albania'
                class={style.st2}
                d='M627.4,235l0.8-0.8l0.6-0.9l-0.1-1l-0.1-0.2l-0.9-0.7l-0.3-1l0.2-1v0l-0.3-0.9l-2-0.8l-0.6,0.9l0.3,1l0.5,0.4
               l-0.3,1.1l0.1,1v1l0.3,1l0.9,0.5l0.4,0.9L627.4,235z'
              ></path>
              <path
                id='Switzerland'
                class={style.st2}
                d='M598.7,218.2l0.9-0.6l0.6-0.9l0.4,0.9h1.1l0.9-0.4l1.3,0.2l-0.1-1l0.7-0.8l-1.2-0.1l-0.9-1l-0.6-1l-0.3-0.1
               l-1.3,0.3l-1,0.1l-1.4,0.2h-1l-0.3,1l-0.8,0.8l-0.7,0.9l-0.3,1.1l0.9-0.8l1,0.4l0.4,0.9l0.3,0.1L598.7,218.2z'
              ></path>
              <path
                id='Belarus'
                class={style.st2}
                d='M643.4,202.6l0.9,0.4l1-0.1l1.2-0.1l0.3-1l1-0.4l1-0.1l-0.5-0.7l-0.2-1l-0.8-0.9l1.1-0.2l1-0.2l0.1-1
               l-0.9-0.7l-0.9-0.8l-0.9-0.4l-0.5-1l-0.9-0.4l0.1-1.1l-0.2-1l-1-0.5l-1-0.2l-0.9,0.4l-0.8-0.9l-1.1-0.1l-1.3-0.2l-0.8,0.8h-1
               l-0.9,0.4l-0.1,0.4l0.3,1l-0.9,0.4l-0.6,0.9l-0.3,1l-1.1,0.3l-0.9,0.4l-1.2,0.1h-1l0.3,0.9l0.5,0.9l0.4,1l-0.7,0.8l-0.3,1l0.6,0.9
               l0.1,1v-0.3l1.1-0.2l0.9-0.6l1.2-0.2l1,0.1l1.1,0.2l1.4,0.3l0.9,0.4l1,0.3l1-0.4l0.9,0.5L643.4,202.6z'
              ></path>
              <path
                id='Britain'
                class={style.st2}
                d='M571,206.6l0.9-0.5l1.2-0.1l1,0.2l0.5-0.9l1.1-0.3l1.1,0.3l1.4-0.3l1-0.3l1,0.2l1.2-0.2h1.3l1.1-0.3l0.1-1.1
               l-1-0.4l0.8-0.7l1-0.5l0.7-0.9v-1.1l-0.9-0.6L583,199l-1.1,0.2l0.7-0.9l-0.9-0.9l-1-0.3l1-0.3l-0.3-0.9l-0.7-0.9l-1-0.3l-0.7-0.9
               l-0.4-0.9l-0.3-0.9l-0.8-0.8l-1-0.3l-0.9,0.3l-1-0.3l1-0.2l0.9-0.5l-1-0.3l1-0.3l0.9-0.9l0.4-0.9l0.1-1.3l-1.2,0.1h-1l-1.2,0.1
               l-1.3-0.1l0.9-0.4l-1-0.2l1-0.3l0.9-0.6l0.2-1.1l-0.2,0.1l-1.1,0.2h-1l-1.1,0.1l-0.7,0.8l0.3,1l-1,0.3l0.4,0.9l-0.5,0.9l-0.6,0.9
               l1,0.3l0.9-0.7l-0.4,1l-0.6,0.9l-0.1,1l-0.3,1l0.6-0.8l0.3-1.1l0.9-0.6l-0.6,0.9l0.8-0.8l0.8,0.7l-0.8,0.7v1l-0.7,0.8l0.9,0.4h1
               l1-0.2l1-0.2l-0.7,0.9l0.3,1l1,0.5l-0.2,0.9l0.2,1.1l-1-0.2l-1,0.2l-0.9,0.5l-0.7,0.8l1-0.2l0.1,1l-0.7,0.8l-0.9,0.5l-0.9,0.4
               l0.9,0.6l0.9-0.3l0.9,0.4l0.9,0.6l1.4-0.4l0.9-0.5l-0.9,0.7l-0.7,0.8l-1.1-0.1l-1.1,0.1l-0.9,0.7l-0.6,1l-0.8,0.7l-0.9,0.8
               L571,206.6z M569.9,195.6l0.8-0.6l-0.9-0.5l-0.2-1l-0.9-0.5l-0.7,0.2l-1,0.3l-0.4,0.4l-0.6,0.9l-0.9,0.3l0.8,0.8h1h1.1l1,0.3
               L569.9,195.6z M569.1,186.5L569.1,186.5l-0.8,0.9h1.2L569.1,186.5z M568.6,185.5l0.9-0.8l-0.1-0.3l-0.9,0.4l-0.9,0.5l0.5,0.9
               L568.6,185.5z'
              ></path>
              <path
                id='Germany'
                class={style.st2}
                d='M605.1,213.8l0.9,0.3l0.9-0.4l1.2-0.1l1-0.2l0.6-0.7l0.3-1l0.7-0.8l0.7-0.5l-0.9-0.6l-0.8-0.7l-0.9-0.5
               l-0.6-0.9l-0.4-0.9l0.6-0.9l1-0.3l0.9-0.4l1-0.4l0.8-0.6l0.9,0.6l0.2-0.3l0.3-1l-0.7-0.8l-0.1-1l-0.3-1l-0.3-1l-0.7-0.7l0.4-1
               l-0.3-1l-0.2-0.1l-0.9-0.7l-0.9-0.4l-0.8-0.8h-1.1l-0.9,0.7l-1.4,0.7l-1-0.1l-0.4-0.9l-1.1-0.4l-0.5-0.8l-1.1-0.1l-0.1-0.1l-1-0.2
               h-0.5l0.3,0.6l0.2,1.1l0.4,0.9l0.9,0.6l-0.9-0.5l-1,0.3l-0.6,0.9l-0.6-0.8l-1.6,0.2l0.1,1.1v0.2l-0.3,1l-0.8,0.9l0.3,0.9l-0.8,0.8
               l-0.6,0.8v1.1l-0.3,1v0.1l0.3,0.5v0.1h0.1h-0.1h0.1l0.3,0.6h-0.1l0,0l0,0h-0.1l-0.3,0.5l0.5,0.8l0.1,1h0.1l0.9,0.7l1,0.2l1,0.3
               l0.3,0.9l-0.3,1l-0.3,1l1,1.1l1-0.1l1.3-0.3l0.3,0.1l1.1,0.2l0.9,0.6L605.1,213.8z M611.4,196.4l-0.5-0.3l-0.3-0.1l0.9,0.6v-0.2
               H611.4z M610.3,195.2l-0.7-0.7l-0.3-0.1v1L610.3,195.2z'
              ></path>
              <path
                id='France'
                class={style.st2}
                d='M714.8,407.2l-0.8-0.7l-0.7,0.4l0.6,0.9L714.8,407.2z M451,341.1h1.1l1,0.4l0.8-0.8l0.5-1.2l0.3-1l0.6-0.9
               l0.6-0.9l0.3-0.5l0.1-0.5l-0.7-0.8l-0.9-0.7l-0.4-0.9l-0.9-1.1l-1.1-0.3l-0.4-0.2l-1-0.3l-0.5,1l-0.3,0.3l-0.4,0.9l0.1,1.1
               l0.2,1.3l0.6,0.9l0.1,1.1l-0.3,1.1l-0.3,0.9l-0.8,0.8l0.9,0.5L451,341.1z M588.8,227.9h0.3l-0.1-0.2l-0.3-1.1l0.5-0.9l0.9-0.6
               l1.1-0.1l1.2,0.1l1,0.3l1.1,0.5l1,0.2l0.9-0.5l0.9-0.7l0.9-0.6h0.2l0.1-0.2V224l-0.5-0.9l-0.9-0.7l0.2-1l-0.3-1l0.2-1l0.1-1.1
               l-0.4-0.9l-1-0.4l-0.9,0.8l0.3-1.1l0.7-0.9l0.8-0.8l0.3-1h1l0.3-1.3l0.3-1l0.3-1l-0.3-0.9l-1-0.3l-1-0.2l-0.9-0.7h-0.1l-0.2-0.1
               l-1-0.2h-0.2l0,0l-0.3,0.1l0,0l-0.5-0.3l-0.1-0.1l-0.1,0.1V208l-0.9-0.4v-0.3l0,0v-0.1l0,0l-0.2-0.1V207l0.2-0.4l0,0l-0.9,0.6
               l-0.3-0.1l-0.3-0.9l-0.1-0.1l0,0l-0.7-0.2h-0.2l0,0l-0.1,0.1v0.1l-0.1-0.2l-0.9-0.8l-0.2-0.3v-0.1h-0.3l0,0h-0.2v0.1l-0.2,0.1l0,0
               l-0.3-0.2l-0.1-0.1l-0.3-0.2v-0.1l-0.2-0.5l0,0h-0.1l-1.2,0.3L585,205l-0.1,1.1l-0.8,0.8l-1.4,0.3l0.2,1.2l-1,0.4l-1.4-0.1
               l-1.1-0.1l-0.6-0.9l-1-0.1l0.3,1l0.4,0.9l0.2,1l-1.1,0.3l-1-0.3l-1-0.3l-1-0.2l-0.9,0.5l-1-0.1l-0.5,0.9l0.3,0.9l1.1,0.3l0.9,0.4
               l0.9,0.4l1,0.2l0.9,0.7l0.9,0.2l-1.1,0.2l0.4,1l0.7,0.9l0.9,0.4l0.4,0.9l0.3,1l0.6,0.8l0.3,1.1l-0.4-0.9l-0.9-0.6l-0.1,1v1.3
               l-0.3,1l-0.3,1.7l-0.8,0.8l0.9,0.7l0.9,0.6l0.9,0.5l1,0.2l1-0.1l0.9-0.3l1.2,0.3l0.3,0.3l1.6,0.5l1,0.3L588.8,227.9z M602.6,230.2
               l0.3-1l0.3-0.9l-0.3-1l-0.1-0.9l-0.7,0.8l-0.9,0.6l-0.2,1l0.5,0.7l0.1,1L602.6,230.2z'
              ></path>
              <path
                id='SpainPortugal'
                class={style.st2}
                d='M543.6,267.9l0.6-0.9l-0.8,0.3l-1.1,0.4l0.6,0.9L543.6,267.9z M569.6,245.6L569.6,245.6l0.8-0.9l1-0.3
               l0.9-0.5h1.2l1-0.1l1.4,0.1h1l0.7-0.9l0.5-0.9l0.9-0.6l1.1-0.1l0.3-1l0.3-0.9l0.9-0.7l0.4-0.9l-0.7-0.8l-0.2-1l0.5-0.9l0.7-0.9
               l0.7-0.9l0.5-0.9l0.8-0.7l1-0.3l1.1-0.3l0.9-0.8l1.1-0.5l0.7-0.9l-0.1-1h-0.3l-1,0.3l-1-0.3l-1.6-0.5l-0.3-0.3l-1.2-0.3l-0.9,0.3
               l-1,0.1l-1-0.2l-0.9-0.5l-0.9-0.6l-0.9-0.7l-0.7,0.3l-1-0.3l-1.1,0.1l-1-0.3l-1,0.1l-1,0.2l-1-0.3l-1-0.3l-1-0.3l-1,0.3h-1h-1.1
               l-0.9-0.4l-0.9,0.2l-0.9,0.9l-0.9,0.3l-0.6,0.9l0.8,0.8l0.4,0.9l-0.3,1l0.2-0.1l1-0.2l0.7,0.6h1.1l0.9-0.3l0.9,0.7l0.5,0.9
               l-0.9,0.6l-0.3,1v1l-0.3,1.1l-0.2,1l-0.9,0.7l0.4,0.9l-0.1,1l-0.3,1l0.9,0.8l-0.8,0.8l-0.6,0.9l0.3,1l0.2-0.1l1.4,0.3l0.7,1.2
               l0.5,0.9l0.8,0.7L569.6,245.6z M589.7,235.6l-0.7-0.8l-1,0.4l-0.9,0.7l0.9,0.4l1,0.2L589.7,235.6z'
              ></path>
            </g>
            <g id='Russia'>
              <path
                id='Georgia'
                class={style.st2}
                d='M680,231.5l1-0.1l1-0.3l1.2-0.4l0.9,0.6l1,0.2l-0.1-1.1l-0.8-1.3l-0.9-0.5l-0.3-0.9l-1.1-0.4l-1-0.1l-1,0.2
               l-1,0.1l-0.8-0.7l-1.4-0.8l-1-0.3l-1,0.1l-1.1-0.3l-1.1-0.3l-1.2-0.3l-1,0.3l0.7,0.5l0.9,0.3l1,0.5l0.9,0.6l0.5,1l0.6,1.2
               l-0.4,1.1l0.5,0.2l1-0.1l1-0.3l1,0.7l0.9,0.6H680z'
              ></path>
              <path
                id='Russia'
                class={style.st2}
                d='M688.8,230.9l0.9-0.6l0.3-0.8l-0.8-0.7l-0.6-0.8l-0.9-0.8l-0.6-0.9l-0.3-1.1l-0.2-1.1l-0.6-0.9l-0.8-0.8
            l-0.6-0.9l0.7-0.9l0.3-1l-0.3-1l1,0.1l0.9-0.5l0.9-0.5l0.7-0.7l-0.9-0.4l-0.9-0.5l1-0.2l-0.8-1l-0.5-0.9l-0.9-0.8l-1.2-0.2h-1
            l-0.3-1l-1.5-0.8l0.3-1.4l-0.1-1.1l-0.2-1.4l0.8-0.7l0.3-1l0.9,0.6l0.7,0.8l1.2,0.2l-0.3-1.2l0.4-0.9l0.6-0.9l1.1-0.6l0.9-0.5
            l0.8-0.7l1,0.2l1-0.2l1,0.1l0.9,0.3l1.1,0.1l0.9,0.6l1,0.4l0.9,0.6l0.4,0.9l-0.2-1l1.2,0.1l0.9,0.7h1.2l0.7-0.9l0.9-0.3l1.1,0.3
            l0.9-0.5l1-0.1l0.8,0.8l0.9,0.5l1.1,0.1l0.9-0.5l1.8,0.5l1.3-0.4v-1.1l-0.8-0.7l-1.7-0.4l-0.9-0.7l0.9-0.8v-1l0.2-1l1-0.1l1-0.1
            l-0.9-0.6l-1.1-0.2l-0.6-0.9v-1h1.1l1.4-0.1l1.2-0.2l1-0.3l1-0.3l1-0.2l1,0.2l0.8-0.9l1.7-0.2l1.1-0.2l1.2-0.3l1-0.3l0.7-0.8l1-0.3
            h1l1.3,0.5l1-0.4l0.8,0.8l0.7,0.8l0.4,1l0.8,0.6l1-0.3l1.1,0.3l1,0.1l0.9,0.4l0.5,1.1l1-0.3l1,0.5l0.5-0.9l1.2-0.5l1-0.6l1-0.3
            l0.3,1l1,0.5l0.9,0.4l1.2,0.7l0.9,0.7l1,0.9l0.9,0.8l0.9,0.9l0.8,0.8l1.1,1l0.9,0.9l0.8,0.9l0.9-0.5l1-0.4l1,0.6l0.9,0.6l1.1,0.1
            l0.9-0.3l1.2-0.6l0.9,0.3l1.1,0.7l0.6,0.9l1,0.4l0.9,0.3l0.6,0.9l1,0.3l1,0.3l0.9-0.5l0.9,0.5l0.8,0.7l0.8,0.4l0.9-0.3l0.9-0.9
            l1.1,0.1l0.9-0.4l0.6-0.9l0.9-0.6l0.9-0.6l1-0.4l1-0.3l1.2-0.1l1,0.3l1,0.1h1.5l0.6,0.9l0.9,0.7l1.1,0.2l1,0.2l0.9-0.3l1.1,0.3
            l1,0.1l0.9,0.4l0.9-0.6l0.3-0.9l-0.8-0.9l-0.7-0.8l-0.4-0.9l0.2-1.1l0.8-0.8h1l1.2,0.3l1,0.3l2,0.5l1.1,0.2l1,0.3l0.8,0.8l0.6,0.9
            l0.9,0.7l1.1,0.5l1,0.3h1.1l1-0.2l1-0.3l1.5-0.1l1,0.3h1.1l0.9,0.3l0.9,0.6h1.1l0.8,0.7l0.9,0.5l0.9,0.6h1.1l1,0.2l1,0.2h1.1h1.1
            l0.9-0.4l1.1-0.2l1-0.3l0.9-0.7l0.9-0.9l0.9-0.5l1.6,0.3l1.1,0.7l1.4-0.3l1.2,0.3l1.4,0.7l1.8,0.4l0.8-0.7l0.9-0.6l0.5-0.9
            l-0.5-0.9l0.1-1v-1l0.2-1l0.4-0.9l-0.7-0.9l-0.9-0.5l-1.1-0.3l0.7-0.9l0.9-0.4l1.2-0.4l1-0.1l1.2-0.2l1-0.1l1.1,0.3l0.9,0.3l1,0.3
            l1.6,0.3l0.9,0.6l0.9,0.3l0.8,0.8l0.9,0.6l1.3,1.4l0.8,0.7l0.7,0.8l0.9,0.8l0.8,0.7l0.7,0.8l0.9,0.7l0.5,0.9l0.9,0.5l1.1,0.2h1
            l0.9,0.5l1,0.2l0.9,0.5l1.2,0.6l1,0.1l0.4,0.9l0.8,0.6l0.7,1l0.8,0.7l1-0.1h1.1h1l0.6-0.9l1-0.2l0.9-0.6l1.1,0.1l0.6,0.9l0.7,0.8
            l-0.6,0.9l0.3,1l0.4,0.9l0.3,0.9l-0.1,1l-0.2,1.1l0.3,1l-2.6-0.3l-0.8,0.7l0.9,2.6l0.3,0.9l0.7,0.9l0.1,1.1l-1,0.6l0.4,0.8l0.8,0.6
            v-0.1l-0.3-0.9l1,0.2l0.1-1l0.4-0.9l1,0.3l0.7,0.8l1,0.4l1,0.3l0.9-0.4l0.9-0.7l0.8-0.6l0.6-1l0.3-1l0.2-1l0.3-1l0.4-0.9l0.4-1
            l0.4-1.1l0.2-1.1l-0.1-1.1l-0.1-1l0.3-1l-0.1-1l0.4-1.1l0.1-1.2l-0.4-1l-0.3-0.9l-0.5-1l-0.5-0.9l-0.7-0.9l-0.7-0.9l-0.2-1
            l-0.4-0.9l0.1-1.1l-0.1-1l-0.9-0.4l-0.4-0.9l-0.9-0.5l-0.9-0.6l0.9-0.4l-1.1-0.5l-1-0.3l-0.9-0.5l-0.8-0.7l-1-0.3h-1l-1.1,0.3
            l-0.3,0.9l-0.9,0.5l-1-0.1v-1l-1.3-0.7l0.1,1.2l-0.7-0.9l-0.5-0.9l-0.8-0.6l-1,0.2l-1.1-0.2l-1.4-0.8l0.9-0.7l0.4-1l0.9-0.6
            l0.7-0.9v-1l0.3-1l0.5-0.9l0.8-0.7l0.5-1l-0.1-1.2l0.8-0.6l0.3-1.1l1-0.6l1.1-0.2l1.1-0.2l1.4,0.1h1l1-0.1l0.9,0.7l1-0.6l1.2,0.2
            l1.1-0.2l1.4,0.3h1l-0.6-1.1l1.1-0.2l1.6,0.3l1,0.2h1.1l0.9,0.4l0.9,0.6l-0.9,0.5l1,0.3l1.4-0.4l0.9,0.3l0.7-0.8l1,0.3l1-0.3
            l0.9-0.4l-1-0.3l-1.1-0.3l-0.6-1l0.1-1.1l0.8-0.8v-1v-1l0.8-0.8h1.4l1-0.2l1.1,0.2h1.2l0.9,0.3l0.3,1l0.6,0.9l1-0.1l0.9,0.6
            l0.7-0.9l0.6-0.9l0.8-0.7h1l-0.9-0.3l-0.6-0.9l-0.4-1l1.1-0.3h1l1,0.3l1,0.5l-1-0.3l-0.8,0.7l0.7,0.8l0.4,0.9l0.9,0.7l-0.3,1
            l-0.9,0.5l-0.2,1l-0.4,1l-0.6,0.9l-0.4,0.9l0.3,1l-0.5,0.9l-0.6,0.9l-0.9,0.5l-1,0.3l0.9,0.8v1.2l-0.3,1l0.9,1.6l0.6,0.9l0.6,0.9
            l1.4,1.4l1.5,1.4l1,0.9l0.7,0.8l0.9,0.9l1.1,1.2l1.2,1l1.2,1.2l0.9,1.3l0.9,0.7l0.9,0.7l0.4-0.9l0.5-1v-1l-0.5-0.9L913,200
            l-0.8-0.8l1.1-0.1l1-0.4l1,0.3l-0.8-0.6l-0.8-0.8l-0.6-0.9l0.2-1l0.9-0.6l1,0.3l0.5-1l-0.9-0.5l-0.9-0.8l-0.7-0.9l-0.3-1l0.4-1
            l0.6,0.9l1,0.3l-0.4-0.9l-0.8-0.8l-1-0.2l-0.7-0.8l-0.5-1.1l-0.5-0.9l-1.1-0.3l-1,0.3L908,186l-0.5-0.9l0.3-1.2v-1l-0.7-0.9l-0.2-1
            l0.9,0.4l1-0.3l1,0.3l-0.8-0.8l1-0.3l0.9-0.6l0.5,0.9l1-0.1l0.9-0.8l1-0.3h1l1.3,0.1l1.3,0.5l0.9,0.6l1.1,0.1l-0.5-1.1l0.9-0.5
            l0.1-1l1-0.3l0.4-0.9l0.8-0.8l1-0.3l0.9-0.6l0.9-0.5l0.3-1l0.9,0.5l1.5,0.2l1.2,0.3l1.4,0.2l-0.2-0.9l-1-0.4l-0.8-0.8l-1-0.3
            l-1.2-0.6l-0.8-0.7l-0.9-0.6l-1.5-0.3l-1.2-0.3l-0.9-0.4l-0.9-0.6l-1,0.2l-1-0.3l-1,0.1h-1.1l-1,0.3l1.3-0.3l0.9-0.4l1,0.3l1-0.3
            l-1.1-0.1h1.1l0.9,0.4l1.1,0.3l1,0.2l1.4-0.3l0.8-0.7l-11.6-9.5l-1.2-0.3l1.1,0.3l-2.1-0.7l-1.4-0.3l-1.3-0.1l-1.3-0.3l-1-0.2
            l-1.3-0.3l-1-0.3l-1-0.2l-1.1,0.1H900l-1,0.1h-1l-1.4-0.3l-1.4-0.1l-1.4-0.1l-1,0.3l0.9,0.7l0.9,0.4l0.9,0.6l1,0.3l-0.9,0.5
            l-1.2,0.2l-0.9-0.5l-0.9-0.3l-1.1-0.2l-1.3-0.3l-0.9-0.6L888,157l-1.2,0.4l-2-0.2l-2.8-0.3h-1.3h-1l-1.2,0.1l-0.6,0.9l0.9,0.7v0.1
            l-0.9-0.6l-0.9-0.8l-0.1,0.3l0.9,0.8l0.9,0.3l0,0l0,0l0,0l0.3,0.9l-0.5-1l-1.1-0.2l-0.8-0.8l-0.9-0.5l-1.2-0.2l-1-0.3l-0.8-0.6
            l-0.7-0.9l-1.3-0.6l-1.3-0.3l-1.6-0.3l-1.2-0.2l-1.4-0.1h-1.9l-1.1,0.2l-1-0.1l-1.3,0.3l-1.1,0.1l-1.1,0.2l-1.2-0.2l-1.3-0.6
            l-1.2-0.4h-1.7l-1-0.4l-1.2-0.3l-1.5-0.3l0.9-0.5l-1-0.3l-1.4-0.3l-1.1-0.2l-1.3-0.1l-1,0.1l-0.9,1.3l-1,0.2l-0.9-0.6l1-0.5
            l0.8,0.8l0.6-1.1l-1.6,0.1l-1.8,0.1l-1-0.1l1-0.3l1,0.1l1.4-0.1l-1.2-0.2l-1.2-0.2l-1.1-0.2l-1-0.1l-1.4-0.1h-1.1h-1.4l-1.8-0.3
            h-1.3l0.7,0.8l-1.2,0.6l1.3,0.9l0.6,0.9l-1.2-0.2l-1.1-0.1l-0.3,1l-1-0.4l-1-0.2l-1.1-0.3l-1.2-0.2l-1.1,0.2l-0.9,0.4l-1.1-0.1
            l-1.1-0.2l-1.4-0.6l-1,0.2l0.3,1l-0.1,1.1l-1-0.3l-1.1-0.1l-1.1-0.4l-1.1-0.4l-0.9-0.5l-0.8-0.7l-1.1-0.2l-0.9-0.5l-1.1-0.4l-1-0.3
            l1.1,0.3l1.4,0.4l1.1-0.3l-0.9-0.5h-1.2l-1.5-0.4l1.2-0.2h1l-2.2-0.2l0.5-0.9l-1-0.1l-1-0.4l-1.3-0.2l-1.3,0.3l-0.9-0.4l-1,0.1
            l-1-0.3l-1-0.2l-1.1-0.1l-0.5,0.9l0.5,1l-1.2,0.1l-1-0.2h-1.2l-1.1-0.1l-1.1,0.1l-1.2-0.3l-1.1-0.2l-0.7-0.9l-1.1-0.2h-1.3
            l-1.3-0.1l-1.7-0.2h-1.4l-1.1,0.3l-1.7,0.3l0.9,0.8l0.2,0.3h-0.2l-0.7-0.9l-0.8-0.7l-1-0.5l-0.9,0.4l-1.1-0.1l-1.1-0.7l-1.5,0.1
            l-1,0.7h1.1h1.1l-1.4,0.5l-1.2,0.3l0,0.2l-1.2,0.1l-1.3,0.1l-1.3,0.8H769l0.9-0.7l0.9-0.5l0.8-0.7h1.2l0.9-0.9l0.9-0.7l0.9-0.3
            l1.2-0.5l1.4-0.4l1.1-1l-0.9-0.3l-1.1-0.3l-0.9-0.5l1.2,0.4l1.1-0.4l-1-0.3l-1-0.1l-1.2-0.7l-1.1-0.3l-1.1-0.3l-1.1-0.2l-1.1-0.2
            l-1,0.2l-1-0.2l-1,0.1l-1,0.4h-1l-1.2-0.1l0.7-0.8l-1-0.3h-1l-1.2-0.2l-1.2,0.1l-1.3-0.1l1-0.2l0.9-0.7h-1l-1.1-0.3l-1-0.2l-1,0.2
            l-1,0.2l-1.4,0.6l-0.6,1l0.8,0.6l1.6,0.3l-1.1-0.1l-1-0.1h-1.5L752,141l1,0.3l0.9,0.6l-0.9-0.3l-1.3,0.1l-0.9,0.4l-1.3,0.2l-1-0.1
            l-1-0.4l-1.4,0.1h-1.2l-1.1,0.1l-0.9,0.3l1.1,0.2l-1.1,0.2l-1,0.1l-1.2,0.3h-1.8l-1,0.3l-1.1,0.1l-0.9,0.5l-0.9,0.3h-1.5l1.3,0.3
            l-0.7,0.9l-1.1-0.3l-1,0.2l1,0.1l1.1,0.3l0.9,0.2l-1-0.1l-1,0.3l1.5,0.3l0.9,0.4l1,0.3l-1.1,0.5l-1.1,0.3l-0.2-0.2l0.9-0.9
            l-1.5,0.3l-1.4,0.3h-1.1l-1.4,0.1h-1.6h-1.1l-1.2,0.1l-0.9,1l1,0.4l0.4,1l1.2,0.4l1.4,0.2l1,0.5l1,0.3l1.2,0.2l0.6,0.9l0.5,0.9
            l0.8,0.7l0.8,0.7l-0.7,1l-0.6-0.9l-0.4-1.1l-1-0.1l-0.3,1l-0.3-1l-0.6-0.9l0.7-1h-1.1l-1.1,0.1l-1.1-0.4l-1.9-0.7l-1.4-0.3
            l-1.5-0.3h-1.1l-0.9,0.6l1,0.6l-1.4-0.1l-1.1-0.3l-0.5,0.9l0.9,0.4l1,0.2l1.1,0.2l1,0.2l0.9,0.8h1l-1,0.1l-1-0.2l-0.9-0.5l-1.1,0.3
            l-0.9-0.3l-1.4-0.1l-0.9-0.8l-0.7-0.9l-0.2-1.1l-0.8-0.8l-1.3-0.1l0.9,0.6l0.3,1l-1.1,0.3l-1.1,0.4l0.3,1l0.9,0.5l0.9,0.6l1,0.8
            l-0.6,0.9l0.2,0.9l0.9,0.6l0.5,0.9l1.1-0.1l1.2-0.1l1.1-0.2l1.2,0.2l1.1,0.3l1.4,0.3l0.8,0.8l0.3,1l0.3,1l1,0.1l1.1,0.3h-1.2
            l-1-0.3l-0.9-1.1l-0.4-0.9l-1-0.4l-1.3-0.7l-1.3,0.2l-1.2,0.3l-0.4,1.1l0.9,0.6l0.5,1l-0.9,0.6l-0.1,1.1l-0.9,0.5l-1,0.3l-0.7,0.9
            l-1-0.3l-1.1-0.1h-1.1l-1.4-0.3l-0.6-0.6l0.8,0.1l-0.9-0.2l0.1,0.1h-0.3l-0.1-0.1h0.3l-0.1-0.1h1l0.9,0.6h1.2l1.1-0.3l-0.9-0.6
            l0.9-0.4l0.7-0.9l0.8-0.7l-0.4-0.9l0.3-1l-1.9-0.7l-0.7-1l-0.6-0.9l-0.5-0.9l-0.1-1l-0.4-1l-0.8-0.6l-1.8-0.9l0.5-0.9l0.3-1
            l-0.5-0.9l-1-0.2l-1.3-0.2l-1.1-0.1h-1.5l-1.4-0.2l-0.5,0.9l0.2,1l-0.3,1l-1,0.7l-0.9,0.7l0.9,0.6l0.6,0.9l0.6,0.9l-0.5,0.9l1,0.2
            l1,0.3l0.9,0.7l0.9,0.6l1.2,0.9l-0.4,0.9l-0.9-0.5l-1.1-0.3l-0.9-0.4l-1-0.3l-1.5-0.3l-1.1-0.3l-1.1-0.4l-1.2-0.3l-1.1-0.3
            l-1.2-0.3l-2-0.1l-1.5-0.2l-1.1-0.1l-0.9,0.7l0.9,0.5l0.9,0.5l-0.4,0.9l-1.1,0.9l-0.9-1l-1.2-0.6l-1.5,0.6l-1.4,0.5l-1.1-0.2
            l-1.4,0.1l-1.1,0.3l-0.9,0.3l-1.2,0.2l-1.1-0.2l0.9-0.6l-0.7-0.9l1.6-0.3l-1.2,0.1l-1.5,0.4l-1,0.8h-1l-1.1,0.3l-1,0.3l-1,0.5
            l-1.1,0.4l-1.1-0.2l0.9,0.6h-1.3l-0.6,0.8l-0.1,1.1l-1.1,0.1l-1.2,0.1l-1-0.3l-0.9-0.7l-1.2-0.4l0.7-0.9l1.1-0.2l1.1-0.3l-0.8-0.6
            l-0.9-0.7l-1.9-0.3l-1.9,0.1l-1.3-0.2l2,1.4l-0.1,1l0.7,1l0.8,0.7l-0.1,1.1l-0.1,1l-0.9-0.6l-0.9-0.4h-1.2l-1.1,0.1l-0.9,0.8
            l-1.1,0.3l-0.9,0.7l-0.4,1.1l1,0.7l0.3,1l-1.2,0.1l-1.3-0.3l-1-0.3l-1.1-0.2l-1.3-0.7l-1.1-0.2l-0.7,0.8l0.9,0.6l1.2,0.7l1.1,0.1
            l0.1,1l-1.3,0.1l-1.3-0.2l-0.9-0.6l-0.9-0.4l-1.2-0.1l-0.4-0.9l-0.7-0.9l-0.7-0.9l0.4-0.9l-0.8-0.7l-1.3-0.3l-1-0.8l-1-0.4
            l-0.9-0.6l-1-0.3h1.1l1,0.7l1.4,0.4l1,0.2l1,0.1l1.6,0.5l1.2,0.2l1,0.1l1.1,0.2l1.1,0.3h2.1l1.2-0.3l1-0.4l0.9-0.6l0.7-0.8
            l-0.6-0.9l-0.5-0.9l-1-0.2l-0.9-0.6l-1-0.1l-1.3-0.6l-1.1-0.3l-0.9-0.4l-1.2-0.5l-1-0.3l-1.1-0.3l-1-0.3l-1.5-0.1l-1-0.2l-1.5,0.1
            l-0.9,0.5l0.6-0.9l-1-0.1l-0.9-0.4l1.1-0.2l-1.1-0.2h-1l-1,0.2l-1-0.2l-1,0.4l-0.8,0.7l-0.9,0.7l-0.4,0.1l-0.7,1.1l0.6,0.9l1.2,0.3
            l0.9,0.8l-0.2,1.2l-0.7,0.9l0.8,0.7l1,1.1l0.7,0.9l-0.5,0.9v1.1l1,0.6l0.2,1l0.9,0.3l-0.3,1l0.5,0.9l1.1,0.5l0.9,0.6l0.2,1
            l-0.7,0.9l-0.9,0.8l-0.9,0.9l-0.9,0.8l-0.9,0.7l-1,0.8l-0.7,0.6h0.7l1,0.4l0.9,0.5l1.2-0.1l1,0.9l-1.3-0.3l-1,0.4l-1,0.1l-0.7,0.8
            l-0.3,1l-0.6,0.9l0.4,0.9l0.3,1l-0.3,0.9l0.9,0.9l-0.1,1.1l0.8,0.7l0.6,0.9l0.9,0.6l1.1,0.1l0.8,0.9l0.9-0.4l1,0.2l1,0.5l0.2,1
            l-0.1,1.1l0.9,0.4l0.5,1l0.9,0.4l0.9,0.8l0.9,0.7l-0.1,1l-1,0.2l-1.1,0.2l0.8,0.9l0.2,1l0.5,0.7l0.4,0.1l0.8-0.8l1,0.3l1-0.3l1,0.2
            l0.6,0.8l0.9,0.7l-0.3,1l1,0.4l1,0.2l0.7,0.9l0.3,1l1.1,0.2l1.2,0.3l1-0.2l1.1,0.1l0.7,0.7l1.1,0.1l0.9,0.3l1.1,0.2l1,0.6l1,0.3v1
            l-0.3,1.1l0.5,1v1.1l-0.9,0.3l-1.3,0.4l-0.9,0.5l0.1,1.1l0.5-0.2l1-0.3l0.8,0.7l-1,0.3l-1,0.4l-0.9,0.7l0.9,0.4l0.8,0.8l-1-0.1
            l-0.4,0.9l-0.3,1.1l-1-0.1l-0.9,0.7l1.1,0.3l0.7,0.7l1,0.2l0.8,0.7l1,0.3l0.9,0.5l0.9,0.7l0.8,0.7l0.9,0.7l1-0.3l1.2,0.3l1.1,0.3
            l1.1,0.3l1-0.1l1,0.3l1.4,0.8l0.8,0.7l1-0.1l1-0.2l1,0.1l1.1,0.4l0.3,0.9l0.9,0.5l1.9,0.8l0.9,0.6l0.8,0.8L688.8,230.9z
             M902.9,223.3l0.3-1l-0.6,0.2l-0.2,1l0.3,1L902.9,223.3z M905.5,221l0.7-0.8l1-0.3l-0.9,0.1l-1,0.1l-0.5,0.9l0.3,1L905.5,221z
             M909,218.4l0.1-1l-0.7,0.8l-0.3,1L909,218.4z M913.5,205.7l-0.8-0.1l-0.8,0.7l1,0.3L913.5,205.7z M891.3,217.1l0.2-1.1l0.9,0.3
            l1,0.5l0.9,0.9l-0.5-1.1l-0.9-0.6l-0.8-0.6l-0.8-0.9l-0.9-0.5l-1-0.9l-0.6-1.2l-0.3-0.9l-0.3-1.3l0.5-0.9l1.4,0.3l0.9,0.6l1,0.4
            l-0.9-0.5l-1.1-1l-0.7-0.8l-0.8-0.8l-0.9-0.8l-1-1.2l-0.8-0.8l-0.8-0.8l-0.9-0.6l-0.6-0.9l-0.9-0.7l-0.3-1l-0.7-1.1l-0.7-0.8
            l-0.9-0.7l-0.8-0.8l-0.7-0.8l-0.8-0.8h-1l0.9,0.6l1,0.9l-0.3,1h-1.1l0.8,0.8l0.5,0.9l0.3,1l0.6,0.9l0.6,0.9l0.9,0.5l1,0.8l0.6,1
            l0.4,1l0.8,0.8l0.9,1.2l0.5,1l0.5,0.9l0.3,1l1,0.9l0.7,0.8l0.3,1.2l0.5,0.9l0.7,0.9l0.3,1.3l0.7,0.9l1,0.9L891.3,217.1z
             M929.9,194.8l-1-0.4l-1-0.3l1.1,0.5L929.9,194.8L929.9,194.8z M629.1,195.3l-0.1-1l-0.6-0.9l-1,0.1l-1.1-0.3l-0.1-0.1l-0.4-0.1
            l-0.4,0.9l-1-0.3l0.7-0.8l-0.8,0.8l-1,0.6l-0.7,0.9l1.5,0.1h1.2l1.7,0.1l1.1,0.1L629.1,195.3L629.1,195.3z M867,193.5l-1.1-0.3v0.8
            l0.9,0.5L867,193.5z M925.5,193.7l-0.9-0.6l-1-0.5l-0.1,0.3l1,0.5l0.9,0.5l1.1,0.3L925.5,193.7z M912.4,183.3l-0.8-0.7h-0.1
            l-0.9,0.4l0.9,0.7L912.4,183.3z M249.9,168.9l0.8-0.7l1.1-0.1l1.4-0.9v-1l0.9,0.6l1.5-0.2l0.9-0.3l1.1-0.1l0.9-0.5l1.2-0.8
            l-0.7-0.8l-0.3-1h-1.1l-1.1,0.1l-0.9-0.5l-1.3,0.3l-0.7,0.8l-0.9,0.5l-0.8,0.4l0.3-1l0.9-0.6l0.3-1l0.7-0.8l-0.9-0.6l-0.9-0.6
            l-0.9-0.5l-0.7-0.9l-1-0.3l-1.1,0.4l-0.9,0.7l-0.9,0.8l-0.9,0.8l-0.9,0.7l-0.9,0.8l-0.9,0.8l-0.9,0.8l-0.9,0.7l-0.9,0.8l-0.9,0.8
            l-0.9,0.8l-0.8,0.4l0.9-0.3l0.9-0.5l0.9-0.5l0.3-0.9l1-0.3l1.2-0.2l1-0.3l-0.9,0.5l-0.9,0.4l0.1,1h1.5l1.3-0.3l1,0.8l-0.3,1.4
            l1.4,0.3l0.8,0.8l1.1,0.2L249.9,168.9z M673.9,158.5l0.8-0.8l-0.3-0.2l-1-0.3l-1.5-0.3l-0.9,0.6l0.3,0.9l1,0.3L673.9,158.5z
             M892.6,156.4l-0.7-0.3l-1.4-0.2l-1,0.4l1,0.3L892.6,156.4z M692.3,155.9l-1.3-0.4l-0.9-0.4l-0.7-0.3l-0.4,0.9l1.3,0.4l0.9,0.5
            L692.3,155.9z M686.6,154.1l-1.2-0.3l-1-0.5l-1.3-0.7l-0.9-0.6l-0.8-0.8l-0.3-1l-0.4-0.9l1.2-0.4h-1.1l1.2-0.6l-2.1-0.3l-0.3-0.1
            l-1.3,0.2l-1.1,0.1l-0.3,0.9l-1.1,0.4l1.1,0.1l-0.7,0.8l-0.9,0.5l-1,0.3l0.6,0.9h1l1.1,0.2h1l1,0.7l1,0.2l-0.8,0.7l1,0.2l1,0.1
            l1,0.1h1.1l1.1-0.1l1,0.1L686.6,154.1z M908.3,153.5l-1.9-1.1l-0.3,1.3l1,0.1L908.3,153.5z M257.5,153.5h1.1l1.3-0.3l0.3-1
            l-1.4-0.1h-1.1l-1,0.5l-0.2,1L257.5,153.5z M721.1,150.4l0.8-0.4l-1.2-0.3l-0.7,0.8L721.1,150.4z M735.9,148.6l-1-0.3l-0.2-0.2
            l0.2,0.1L735.9,148.6z M683.1,147.7l-0.9-0.4l1.4,0.2l-0.9-0.5l-1.1-0.1l2.3-0.1l-0.9-0.6l1,0.4l0.7-0.9l-1-0.4l1.9-0.3l1-0.1
            l1.1-0.4l-1.1-0.2l1.1-0.3l1.2-0.3l1-0.3l1.2-0.3l0.9-0.2l1.1-0.1l1.4-0.3l2.3-0.4l1-0.3l1.3-0.3l0.9-0.5l-0.9-0.7l-1-0.2l-0.3-0.1
            l-1.2,0.1l-1,0.2l-0.7,0.8l-1.7,0.1l-1,0.3h-1.1l-0.9,0.3l-1.1-0.1l-1.4-0.1l-1,0.3l-1.7,0.4l-1.3,0.2l-1.1,0.7l-1,0.4l-1.1,0.6
            l-1.1,0.3l1.3,0.1l-1,0.3l1.2,0.3l-0.9,0.5l-0.9,0.4l1.2,0.3l-1.3-0.2l-1,0.5l-0.9,0.5l1,0.3l1.1-0.1l-0.9,0.8l1-0.3l1.1,0.3h1.1
            l0.9,0.2L683.1,147.7z M836.4,148.2l-0.9-0.6l-1.4-0.3l-1.4-0.4l-0.5-0.1l-1.8,0.3L830,148l1.1-0.3l2.2,0.4h1.6h1.5V148.2z
             M707.2,148.7l1.2-0.4l-0.9-0.6h-0.9l-0.4,1.1L707.2,148.7z M820.4,146.2l-0.9-0.2l1,0.4l1,0.1L820.4,146.2z M780.8,145.8l-0.9-0.3
            l-1.2-0.1l-1.1,0.5l1.2,0.3l1.1,0.2L780.8,145.8z M843.9,144l-1-0.2h-2.6l-0.9-0.4l-2,0.2l-1.2-0.3l-0.3,0.6l1.1,0.2l1.1,0.3
            l1.4,0.3l1,0.3l2,0.1L843.9,144z M825.4,144.4l1.4,0.3l1.1-0.2l1.4-0.1l1.4,0.1l1-0.3l-1.2-0.1l-1.3-0.4l-0.8-1.1l0.6,0.9l1.1,0.3
            l1,0.3l1.1,0.1l1.2-0.3l-0.3-0.9l-1.1-0.3l-1-0.1l-1.4-0.3l-1,0.1l-1.1-0.2l-1.3-0.3l-1.4-0.3l1,0.3l-1,0.3l0.9,0.7l-1.3-0.4
            l-1.1-0.2l-1-0.3l-1.4-0.5l-1.3,0.3l0.1,0.1l-0.6,0.9l0.3,0.6l1,0.5l1.6,0.4l1,0.3l1.3,0.2L825.4,144.4z M751,137.8l1-0.4h1.4h1
            l1-0.1l1.6-0.2l1-0.6l-0.9-0.4l-1.3-0.3l-1.1-0.3l-1.1,0.1l-0.9-0.4l-0.6-0.2l-1.2,0.1l-1,0.3l0.6,0.9l-0.9,0.6l0.3,1L751,137.8z
             M748,135.7l-0.9-0.5l-0.2-1l-1-0.3l-1-0.3l-1.5,0.3l-1-0.4l-0.3-0.1l-1,0.1l-1.3,0.1h-1.6l-0.9,0.6l-1.3,0.5h1.1l1,0.1l1,0.6
            l1.1,0.3l1.1,0.1h1l1.1,0.1l1.1,0.3h1.4h1.2L748,135.7z M710.2,134.7l-2.4-0.2v0.1l1,0.2L710.2,134.7z M736,133.9l-2.1-0.3h-1.4
            l-1.3-0.1l0.8,0.5l1.7,0.3h1.2L736,133.9z M677.8,133h1.1l-0.2-0.1l-1.3-0.2l-1.8,0.5l1,0.3L677.8,133z M737.8,133.5l3.2-0.4
            l-0.5-0.9l-1.1-0.2l-1-0.3l-1.4-0.3l-1,0.1l-1.1,0.2l-1.4,0.1l-0.9,0.6h1.1l-1.9,0.9l1.8,0.3l1,0.1l2,0.1L737.8,133.5z
             M658.7,132.5l0.9-0.3l1.2-0.1h-1.1h-1l-1,0.2l-2.6,0.2l1,0.2l1.1,0.2L658.7,132.5z M680.8,132.9l1.2-0.2l1-0.6l-1.1-0.1l-2.2,0.1
            l-1,0.6l0.8,0.2H680.8z M673,132.2l-1.1-0.2h-1.1l-1,0.2l1.5,0.2L673,132.2z M661.8,133l2-0.3l1.6-0.5l-1-0.2h-1.2l0.7-0.1l-1,0.1
            l-1,0.3l1,0.1l-1.2,0.3l-1.1-0.1l-1.1,0.2l1.3,0.3h-1.1l-1.2,0.1l1.3,0.1l1.2,0.2L661.8,133z M675.7,132.1l-2-0.3l-1.8-0.1
            l-0.4-0.1l-1.1,0.1l1,0.1l1.1,0.3l1.8,0.2L675.7,132.1z M676.5,131.9l-1.1-0.2l-1.4-0.2h-1.1l0,0l1.5,0.3l1,0.2L676.5,131.9z
             M686.8,132.3l0.8-0.7l-1-0.2l-0.8,0.4l-2,0.3l1.9,0.2H686.8z M674.6,131.2l-1.1-0.1l-0.3,0.3l-1,0.1h1.3L674.6,131.2z M676,130.9
            l-1-0.2l-0.5,0.1l-1.1,0.1l1.5,0.1L676,130.9z M684.2,130.5h-1.4v-0.1l-1,0.1l1,0.2L684.2,130.5z'
              ></path>
            </g>
            <g id='MiddleEast'>
              <path
                id='NorthCyprus'
                class={style.st2}
                d='M659.7,247.9l0.9-0.7l-0.6,0.1l-1,0.4l-1,0.2l-0.3,0.4h0.1l0.6,0.1l1.1,0.2v0.1l0,0L659.7,247.9z'
              ></path>
              <path
                id='SouthCyprus'
                class={style.st2}
                d='M658.2,249.7l0.9-0.8l0,0l-0.5-0.2v0.1l-1-0.3l-0.9-0.1h-0.1l-0.5,0.9l0.9,0.6h0.5L658.2,249.7z'
              ></path>
              <path
                id='Lebanon'
                class={style.st2}
                d='M663.7,253.8l0.6-0.5l0.3-0.9l0.8-0.8l0.4-0.9l-0.5-0.9l-0.9-0.1l-0.1,0.5l-0.6,0.9l-0.2,1l-0.4,1l-0.3,1
               L663.7,253.8z'
              ></path>
              <path
                id='Israel'
                class={style.st2}
                d='M663.4,264.2V264l0.2-1l0.1-1.1l0.2-1l0.3-1v-1.1l-0.3,0.1l-1,0.1l0.4-1.1l-0.4-0.9l0.3-1l0.9,0.3l0.4-0.9
               l0.1-0.3l-0.2-1.1v-0.4l-0.6,0.5l-0.9,0.4l-0.1,0.3l-0.3,1l-0.1,1l-0.3,0.9l-0.3,1l-0.4,1l0.4,1.2l0.3,1l0.5,0.9l0.4,1l0.2,0.7
               L663.4,264.2z'
              ></path>
              <path id='Israel2' class={style.st2} d='M659.5,248.6L659.5,248.6L659.5,248.6l-0.4,0.3l0,0l0.4,0.1V248.6z'></path>
              <path id='IsraelWestBank' class={style.st2} d='M663.7,258.9l0.3-0.1v-1.2v-1l-1-0.7l-0.3,1l0.4,0.9l-0.4,1.1H663.7z'></path>
              <path
                id='Jordan'
                class={style.st2}
                d='M666.9,264.4l0.6-1l1-0.3l0.9-0.9l0.8-0.7l-0.7-0.8l-0.9-1l-0.4-1.2l1.1-0.3l1-0.3l1-0.3l1.2-0.6l-0.3-0.5
               l-0.5-2l-0.4-1.1l-0.5,0.4l-1.3,0.9l-1.1,0.8l-0.9,0.7l-1.1,0.2l-0.9-0.4l-0.9-0.7l0,0l-0.4,0.9l0.1,0.4v1v1.2v1.1l-0.3,1l-0.2,1
               l-0.1,1.1l-0.2,1v0.2v0.6l0.5,0.1l1.1,0.2l1,0.2L666.9,264.4z'
              ></path>
              <path
                id='Yemen'
                class={style.st2}
                d='M712.4,312.9l0.9-0.6l-1-0.3l-1.2-0.2l-0.2,0.2l0.4,0.9L712.4,312.9L712.4,312.9z M689.8,311.8l0.9-0.5
               l0.9-0.6l0.9-0.7l1-0.1l1-0.1l1.1-0.4l1.2-0.9l1-0.4h1l0.9-0.6l0.6-0.9l0.9-0.5l1.4-0.3l0.9-0.6l1.2-0.3l1.1-0.5l0.9-0.6l0.7-0.9
               l0.1-1l0.8-0.7l1.1-0.5l-0.5-1.1l-0.6-0.9l-0.5-1.1l-0.4-1.1l-0.5-1l-0.5-1.1l-0.1-0.3l-1.1,0.2l-1.2,0.2l-1.1,0.3l-1.1,0.2
               l-1.2,0.2l-1,0.2l-1,0.6l-1.2,0.7l-0.7,1.1l-0.6,0.9l-0.6,1.1l-1-0.1l-0.9-0.5l-1.4-0.1l-1.1-0.1l-1.1-0.3l-1,0.1h-1l-1,0.1
               l-1.2-0.1l-0.1,1.1l-0.1,1l-0.7,0.8l0.1,0.1v0.3l-0.1,1.1l0.2,1l0.3,1l0.3,1l0.2,1l0.4,1.2l0.3,1.1v1l0.6,1l0.9,0.5l1,0.1
               L689.8,311.8z'
              ></path>
              <path
                id='Turkey'
                class={style.st2}
                d='M664.7,245.9l0.4-0.9l-0.1-1l1,0.3l1.3-0.2l1.1-0.5l1.3,0.4l1.2,0.2l1.4-0.2l1.3-0.8l1.1-0.3l1.4,0.1l1.1-0.3
               l0.5-0.3l0.3,0.4l0.9-0.7l0.3,0.1l1.1,0.1l1,0.3l1,0.1h1.1l0.1,0.1l-0.6-0.9l-0.4-0.9l-0.3-0.9l-0.3-1l-0.3-1.1l-0.4-0.9l0.6-0.8
               l0.9-0.4l-0.1-0.2l-0.9-0.8l-1.3-0.2l-0.8-0.8l0.1-1l-0.6-0.9l-0.3-0.3l-0.9-0.6l-1-0.7l-1,0.3l-1,0.1l-0.5-0.2l-0.2,0.3l-0.9,0.6
               l-0.9,0.5l-0.9,0.3l-1.1-0.3l-1-0.1l-1.1,0.3h-1l-1.1-0.4l-1-0.1l-1-0.6l-1.1,0.1l-0.6-0.9h-1.1l-0.9-0.4l-0.6-0.9h-0.1l-1,0.3h-1
               l-1.1-0.1l-1.1,0.3l-1,0.3l-0.9,0.6l-0.9,0.6l-1,0.9l-1-0.1l-1.3-0.1l-1.2-0.1l-1.2,0.2l0.6,0.9l1,0.3l-1,0.2l-0.9,0.8h-1.1
               l-1.4,0.2l-1-0.3l-1,0.1l-0.8,0.8l-0.5,0.9l0.6,0.9l1,0.2l0.3,0.9l-0.3,1l0.3,1l-0.9-0.4l0.3,1l1,0.3l0.8,0.8l-0.1,1.1l1,0.3
               l-0.9,0.7l1,0.1l1.1,0.2l-0.9,0.5l1,0.3l1-0.3l0.9,0.3l0.4,1l1,0.4l1-0.2h0.9l0.2-1l0.9-0.7l1,0.3l0.9,0.4l0.9,0.5l0.7,0.9l1,0.2
               l1-0.3l1-0.3l0.9-0.6l0.8-0.9l1.1,0.3l1.3-0.1l0.9-0.6l0.4,0.9l-0.7,0.9l0.3,1L664.7,245.9z M641.3,233.9l0.8-0.7l0.9-0.6l0.9-0.8
               l1-0.2l1,0.3l0.8-0.8l-0.9-0.4l-1.2-0.6l-0.3-1l-1,0.1l-0.5-0.3l-1,0.2l-1,0.5l-0.1,0.3l0.8,0.9l-0.6,0.9l-0.3,1h-0.2l0.7,0.4
               l1.1,0.1l-0.9,0.4l-0.4,1L641.3,233.9z'
              ></path>
              <path
                id='Syria'
                class={style.st2}
                d='M667.3,256.1l0.9-0.7l1.1-0.8l1.3-0.9l0.5-0.4l0.9-0.6l0.9-0.6l0.9-0.7l0.9-0.6l1-0.5l0.3-0.9l-0.2-1.2
               l0.3-1.1l-0.3-1.2l-0.1-1l1-0.6l0.8-0.9l0.3-0.6l-0.3-0.4l-0.5,0.3l-1.1,0.3l-1.4-0.1l-1.1,0.3l-1.3,0.8l-1.4,0.2l-1.2-0.2
               l-1.3-0.4l-1.1,0.5l-1.3,0.2l-1-0.3l0.1,1l-0.4,0.9l-0.9,0.3l-0.2,0.4l0.3,1v1.1l0.3,1l0.9,0.1l0.5,0.9l-0.4,0.9l-0.8,0.8
               l-0.3,0.9v0.4l0.2,1.1l-0.1,0.3l0,0l0.9,0.7l0.9,0.4L667.3,256.1z'
              ></path>
              <path
                id='SaudiArabia'
                class={style.st2}
                d='M685.3,300.8l0.1-1l0.1-1.1l1.2,0.1l1-0.1h1l1-0.1l1.1,0.3l1.1,0.1l1.4,0.1l0.9,0.5l1,0.1l0.6-1.1l0.6-0.9
               l0.7-1.1l1.2-0.7l1-0.6l1-0.2l1.2-0.2l1.1-0.2l1.1-0.3l1.2-0.2l1.1-0.2l1-0.4l1.2-0.5l1.2-0.4l1.2-0.5l1.2-0.5l1.1-0.4l0.2-1
               l0.3-1.3l0.3-1.4l0.3-1.1l-0.1-1.2l-0.7-1.1l-0.4-0.6l-1.1,0.1l-1.4-0.2l-1.4-0.1l-1.4-0.3l-1-0.2l-0.7-0.8l-0.7-0.9l-0.8-0.9
               l-0.6-0.9l-0.1-0.4l-0.4-0.1l-0.5-0.9l-0.9-0.3l-0.2-0.1l-0.6-0.9l-0.5-1l-0.6-0.9v-1.1l-0.5-0.9l-0.4-1l-0.8-0.7l-0.8-0.7
               l-0.8-0.7l-0.6-0.9l-0.5-0.9l-0.6-0.9h-1l-0.9-0.6l-0.7-0.8l-1.3-0.2h-0.5h-1.2l-1.1-0.2l-1.2-0.1l-1-0.3l-0.9-0.8l-0.9-0.7
               l-0.9-0.7l-0.8-0.6l-1-0.8l-0.9-0.8l-0.9-0.8l-1-0.6l-1.1-0.7l-1.1-0.6l-0.9-0.5l-1.1-0.3h-0.2l-1.4-0.3l-0.4-0.1l-1.2,0.6l-1,0.3
               l-1,0.3l-1.1,0.3l0.4,1.2l0.9,1l0.7,0.8l-0.8,0.7l-0.9,0.9l-1,0.3l-0.6,1l-0.9,0.9l-1-0.2l-1.1-0.2l-0.5-0.1l-0.1,0.4l-0.1,1.1
               l-0.1,1l0.3,1l0.9,0.4l0.7,0.9l0.5,0.9l0.8,1.1l0.7,0.9l0.5,0.9l0.8,0.9l0.5,0.9l0.5,0.9l0.4,0.9l0.3,1.1l0.5,0.9l1,0.3l0.9,0.7
               l0.6,0.8l0.6,1.1l0.5,0.9l0.4,1l0.1,1.1l-0.1,1l0.4,0.9l0.2,1l0.5,0.9l0.6,0.9l0.9,0.9l0.9,0.5l0.7,0.8l0.7,0.8l0.5,0.9l0.3,0.9
               l0.5,0.9l0.4,0.9l0.5,0.9l0.9,0.7l0.7,0.8l0.5,1l0.5,0.9l0.3,1L685.3,300.8z'
              ></path>
              <path
                id='Oman'
                class={style.st2}
                d='M710.5,300.5l0.9-0.5l0.9-0.3l1.1,0.2l0.8-0.6v-1l0.6-0.9l1-0.2l1.2-0.3l0.3-1l0.3-1l0.9-0.4l1.4-0.5l-0.2-1
               l-0.1-1.2l0.1-1l0.5-0.9l1,0.3l0.3-1l0.5-0.9l0.9-1l0.4-1l0.3-0.9l-0.3-1l-0.9-0.6l-0.7-0.8l-0.6-0.9l-0.8-0.9l-1-0.2l-0.9-0.3
               l-1.1-0.3l-1-0.7l-0.7-0.9l-0.6-0.9l-0.3-0.7h-1l-0.3,1l0.1,1.1l-0.7,0.9l0.2,1.1l-0.4,1.2v1.2l0.4,0.6l0.7,1.1l0.1,1.2l-0.3,1.1
               l-0.3,1.4l-0.3,1.3l-0.2,1l-1.1,0.4l-1.2,0.5l-1.2,0.5l-1.2,0.4l-1.2,0.5l-1,0.4l0.1,0.3l0.5,1.1l0.5,1l0.4,1.1l0.5,1.1l0.6,0.9
               l0.5,1.1L710.5,300.5z M714.5,275.4L714.5,275.4l0.1-1.1l-0.2-1l0,0l-0.5,0.9l0.3,0.3l0.2,0.9L714.5,275.4L714.5,275.4z'
              ></path>
              <path
                id='Bahrain'
                class={style.st2}
                d='M703.4,277.9l0.3-1.1l-0.4-1.2v-1l-0.8-0.7l-0.6,0.9l-0.4,0.9l0.2,1.1l0.3,1l0.9,0.3L703.4,277.9z'
              ></path>
              <path
                id='Kuwait'
                class={style.st2}
                d='M695.1,267.1L695.1,267.1l-0.4-0.9l-0.5-0.9l-0.9-0.7l0.4-0.9l-0.3-0.6l-1.3-0.3l-0.8,0.9l-0.3,1l-0.6,0.9
               h0.5l1.3,0.2l0.7,0.8l0.9,0.6h1.3V267.1z'
              ></path>
              <path
                id='Iraq'
                class={style.st2}
                d='M690.5,265.6l0.6-0.9l0.3-1l0.8-0.9l1.3,0.3l0.3-0.2l1,0.3l-0.6-0.9l-0.9-2l-0.9-1.2l0.3-1l-0.7-0.9l-0.5-0.9
               l-1.6-0.9l-1-0.6l-0.6-0.9l-0.8-0.7l-0.7-0.9l-0.3-0.9l-0.3-1l0.4-0.9l0.7-0.8l-0.3-1.1l0.7-0.9l-1.1-0.2l-0.9-0.5l-0.6-0.9
               l-0.6-0.9l-0.5-0.9l-0.3-0.6l-0.1-0.1h-1.1l-1-0.1l-1-0.3l-1.1-0.1l-0.3-0.1l-0.9,0.7l-0.3,0.6l-1,1.1l-1,0.6l0.1,1l0.3,1.2
               l-0.3,1.1l0.2,1.2l-0.3,0.9l-1,0.5l-0.9,0.6l-0.9,0.7l-0.9,0.6l-0.9,0.6l0.4,1.1l0.5,2l0.3,0.5l0.4,0.1l1.4,0.3h0.2l1.1,0.3
               l0.9,0.5l1.1,0.6l1.1,0.7l1,0.6l0.9,0.8l0.9,0.8l1,0.8l0.8,0.6l0.9,0.7l0.9,0.7l0.9,0.8l1,0.3l1.2,0.1l1.1,0.2h1.2V265.6z'
              ></path>
              <path
                id='Iran'
                class={style.st2}
                d='M713,272.4l0.9-0.7l-0.1-0.3l-0.9,0.6l-0.9,0.7L713,272.4z M727.1,275.4l0.2-1l0.6-0.9l0.8-0.7l1-0.3l0.8-0.8
               l-0.3-0.9l-0.9-0.6l-0.3-1l-0.6-1.2l-0.9-0.6l-1-0.5l-0.8-0.9l-0.6-0.9l-0.7-0.9l-0.8-0.9l0.8-1l0.6-1.1l0.4-0.9l-0.3-1L724,259
               l-1-0.2l-0.3-1.1l-0.1-1l-0.4-1l-0.5-1.1l0.3-1l-0.7-0.8l-0.2-1l0.5-0.9l0.2-1l0.2-1.1v-1l0.1-0.5l-0.5-1l-0.2-1l-0.6-0.9h-1
               l-1-0.7l-0.9-0.7l-1.1-0.6l-0.8-0.8l-1.1-0.2l-1-0.3l-1-0.3l-1-0.3l-0.5-0.9h-1l-0.9,0.5l-1.2-0.1l-1,0.3l-0.9,0.7l-0.5,0.9
               l-1.4,0.3l0.3,1.2l-1.1,0.1l-1.1,0.3l-1.1,0.3l-1.6,0.3l-1.3-0.3l-1.2-0.4l-0.9-0.5l-0.7-0.8l-1-0.4l-1.6-0.5l-0.6-0.9l-0.3-1.3
               l-1-0.3l-0.9-0.7l-0.1-1.1l-0.4-1.4l-1,0.4l-0.9,0.6l-0.7,0.8l-0.4,0.4h-1l-1.2-0.3l-0.9-0.6l-0.8-0.7l0,0l-0.5-0.6l-0.9,0.4
               L681,237l0.4,0.9l0.3,1.1l0.3,1l0.3,0.9l0.4,0.9l0.6,0.9l0.3,0.6l0.5,0.9l0.6,0.9l0.6,0.9l0.9,0.5l1.1,0.2l-0.7,0.9l0.3,1.1
               l-0.7,0.8l-0.4,0.9l0.3,1l0.3,0.9l0.7,0.9l0.8,0.7l0.6,0.9l1,0.6l1.6,0.9l0.5,0.9l0.7,0.9l-0.3,1l0.9,1.2l0.9,2l0.6,0.9l0,0
               l0.9-0.6l0.1-1l0.6,0.9l0.9,0.4l1.2-0.2l0.6,0.9l0.9,0.8l0.2,1.1l0.6,0.8l0.6,0.9l0.6,1.2l0.9,0.8l1.2,0.2l1,0.5l0.6,0.9l0.9,0.6
               l0.9,0.4l0.8,0.7h1.1l0.9,0.5l1-0.3l0.9-0.3l0.9-0.7l0.9-0.5l1.1,0.2l0.7,0.8l0.4,1.4l0.3,1l0.9,0.7l1,0.2l1,0.2l1,0.2l0.9,0.4
               l1.1,0.1h1.4l0.9,0.3l1,0.3h1.4L727.1,275.4z'
              ></path>
              <path
                id='Azerbaijan'
                class={style.st2}
                d='M692.1,238.2l0.6-0.9l0.3-1l-0.2-1v-1l0.9-0.6l1,0.3l-0.9-0.9l-1-0.2l-0.7-0.8l-0.8-1.1l-0.7-0.8l-0.8-0.8
               l-0.3,0.8l-0.9,0.6l-1,0.3l-0.8-0.8l-0.9-0.6l-1.1,0.5l0.1,1.1l-1-0.2l-0.9-0.6l-0.3,1.2l0.4,1l1.1,0.9l0.1,1l0.8,0.8l1,0.3
               l0.3,0.9v1l0.4-0.4l0.7-0.8l0.9-0.6l1-0.4l0.4,1.4l0.1,1.1l0.9,0.7l1,0.3L692.1,238.2z M685.3,237l-0.4-0.9l-1.1-0.1l-0.7-0.6
               l-0.6,0.2l0.1,0.2l0.5,0.6l0,0l0.8,0.7l0.9,0.6l1.2,0.3L685.3,237z'
              ></path>
              <path
                id='Eritrea'
                class={style.st2}
                d='M685.3,312.3l0.7-0.4l0,0l-0.9-0.6l-0.7-0.8l-0.5-0.9l-0.8-0.8l-0.9-0.6l-0.6-0.9l-0.6-0.9l-1-0.3l-0.9-0.7
               l-0.9-0.6l-0.6-0.9l-0.3,0.9l-0.3-1l-0.6-0.9L676,302l-0.2-1.1l-0.3-1.1l-0.3-1l-0.5-1l-0.5-0.9l-0.3,0.5l-0.9,0.9l-0.9,0.5
               l-0.6,0.9l-1,0.3l-0.1,1l0.1,1l-0.3,1.1l-0.3,1.1l-0.4,0.9l0.2,1.2l0.2,1.2l0.9-0.2l1-0.3l0.6,0.9l0.3-1l0.9-0.6h0.3l0.7,0.8
               l1-0.3l1.1,0.2l1-0.1l1.1,0.2l0.9,0.7l0.9,0.6l0.7,1l0.9,0.7l0.7,0.8l0.9,0.9l0.6,0.9L685.3,312.3z'
              ></path>
              <path
                id='UAE'
                class={style.st2}
                d='M712.8,283.6v-1.2l0.4-1.2l-0.2-1.1l0.7-0.9l-0.1-1.1l0.3-1h1l-0.1-0.9l-0.3-0.9h-0.2l-0.2-0.9l-0.3-0.3
               l-0.1,0.6l-0.8,0.8l-0.8,0.9l-0.5,0.8l-0.9,0.7l-0.3,1l-0.9,0.7l-1.1,0.3l-0.9-0.3h-1.1l-1,0.4h-1l-0.9-0.8l0.1,0.4l0.6,0.9
               l0.8,0.9l0.7,0.9l0.7,0.8l1,0.2l1.4,0.3l1.4,0.2l1.4,0.2L712.8,283.6z'
              ></path>
            </g>
            <g id='MiddleEastStans'>
              <path
                id='Kazakhstan'
                class={style.st2}
                d='M735.4,231.7l0.5-0.9l0.9-0.6l0.9-0.5l0.7-0.8l0.9-0.4v-0.9l1-0.5h1.1l1,0.3l1,0.3l1-0.3v-1l0.9-0.5l1.1,0.2
               l0.9,0.4l1,0.3l1.4-0.3l1.2,0.2l0.9-0.3l1,0.3h1l1.1,0.1l0.9,0.2l1.2,0.2l0.8,0.8l1.1,0.1l0.5-0.3l0.5-0.9l0.2-1.1l-1.2-1.9
               l-0.5-0.9l-0.3-1l-0.9-0.5l0.6-0.9l1.1-0.1l1.2-0.4l1,0.4h1l-0.4-0.9l-0.2-1.1l-0.1-1l0.1-1.3l-0.1-1.1l1.2,0.2l1.1,0.3h1.1l1,0.3
               l0.9-0.6l-0.1-1.1l-0.6-1.3l-0.1-1.2l1.1-0.2l0.8-0.8l-0.2-1.1l1,0.1l-0.8-0.4l-0.8-0.7l-0.9-0.5l-0.9,0.5l-1-0.3l-1-0.3l-0.6-0.9
               l-0.9-0.3l-1-0.4l-0.6-0.9l-1.1-0.7l-0.9-0.3l-1.2,0.6l-0.9,0.3l-1.1-0.1l-0.9-0.6l-1-0.6l-1,0.4l-0.9,0.5l-0.8-0.9l-0.9-0.9
               l-1.1-1l-0.8-0.8l-0.9-0.9l-0.9-0.8l-1-0.9l-0.9-0.7l-1.2-0.7l-0.9-0.4l-1-0.5l-0.3-1l-1,0.3l-1,0.6l-1.2,0.5l-0.5,0.9l-1-0.5
               l-1,0.3l-0.5-1.1l-0.9-0.4l-1-0.1l-1.1-0.3l-1,0.3l-0.8-0.6l-0.4-1l-0.7-0.8l-0.8-0.8l-1,0.4l-1.3-0.5h-1l-1,0.3l-0.7,0.8l-1,0.3
               l-1.2,0.3l-1.1,0.2l-1.7,0.2l-0.8,0.9l-1-0.2l-1,0.2l-1,0.3l-1,0.3l-1.2,0.2l-1.4,0.1H709v1l0.6,0.9l1.1,0.2l0.9,0.6l-1,0.1
               l-1,0.1l-0.2,1v1l-0.9,0.8l0.9,0.7l1.7,0.4l0.8,0.7v1.1l-1.3,0.4l-1.8-0.5l-0.9,0.5l-1.1-0.1l-0.9-0.5l-0.8-0.8l-1,0.1l-0.9,0.5
               l-1.1-0.3l-0.9,0.3l-0.7,0.9h-1.2l-0.9-0.7l-1.2-0.1l0.2,1L697,205l-0.9-0.6l-1-0.4l-0.9-0.6l-1.1-0.1l-0.9-0.3l-1-0.1l-1,0.2
               l-1-0.2l-0.8,0.7l-0.9,0.5l-1.1,0.6l-0.6,0.9l-0.4,0.9l0.3,1.2l-1.2-0.2l-0.7-0.8l-0.9-0.6l-0.3,1l-0.8,0.7l0.2,1.4l0.1,1.1
               l-0.3,1.4l1.5,0.8l0.3,1h1l1.2,0.2l0.9,0.8l0.5,0.9l0.8,1l-1,0.2l0.9,0.5l0.9,0.4l0.4-0.5l0.9-0.3l0.9-0.6l1-0.6l1-0.2l0.9,0.5
               l1,0.1l1-0.2l0.9,0.5l0.3,1l0.2,1l-0.5,1l0.5,0.9l-1.1-0.2h-1.3l-0.9,0.6l-0.4,0.9l0.6,1l-1-0.4l-0.7,0.9l1.2,0.4l0.6,1l0.8,0.8
               l0.2,1l1,0.3l1,0.5l1,0.3l0.3,0.9l-0.3,1l0.3,0.9l0.3-0.4l1-0.8l1-0.3h1.2l1.1,0.5l0.9,0.8l0.7,0.9l0.9,0.8l0.9-0.2l-0.3-1.3
               l-0.3-1.3l-0.3-1.3l-0.3-1.3l-0.3-1.3l-0.3-1.3l-0.3-1.3l-0.3-1.3l1.1-0.3l1.1-0.3l1-0.3l1-0.3l1-0.3l0.9,0.5l1.4,0.7l0.9,0.5
               l1,0.5l1.2,0.7l0.9,0.8l0.9,0.7l0.8,0.7l0.9,0.7l0.8-0.1l1-0.2l1.1-0.1l1.3,0.1h1.4h1.1l0.9,0.6l0.8,0.8l1.1,0.3l0.2,1.6l0.3,1.1
               l1.2,0.3l0.4,1.1l0.9,0.9l1.3-0.1l1.1,0.1l0.4,1l1.1,0.1L735.4,231.7z M720.4,219.2l-1-0.3l-0.7-0.9l-0.1-0.3l0.7-0.9l1.1,0.2
               l0.8,0.9l-0.3,1L720.4,219.2z'
              ></path>
              <path
                id='Kyrgyzstan'
                class={style.st2}
                d='M745.2,236.5l1.1,0.1l1.1-0.3l0.2-1l0.7-0.9l0.9-0.6l1-0.3h1l1,0.2l0.9-0.5l0.2-1l1-0.2l1.2-0.2l0.9-0.5
               l0.6-0.9l0.9-0.5l0.9-0.4l1.1-0.4L759,228l-1.1-0.1l-0.8-0.8l-1.2-0.2l-0.9-0.2l-1.1-0.1h-1l-1-0.3l-0.9,0.3l-1.2-0.2l-1.4,0.3
               l-1-0.3l-0.9-0.4l-1.1-0.2l-0.9,0.5v1l-1,0.3l-1-0.3l-1-0.3h-1.1l-1,0.5v0.9l0.5,0.3l-1,0.9l-0.6,0.9l1.1,0.2l0.8,0.8l1.1,0.2
               l0.9-0.6l0.9,0.6l0.9,0.4l0.8,0.9l-0.7,0.8l-0.9,0.4l-1.1-0.1l-1,0.1l-0.3,0.2l-1,0.3l-0.9-0.3l-1,0.3l-0.3,1l1,0.4h1.1h1l1,0.3
               l1-0.3l0.7,0.8l1,0.2L745.2,236.5z'
              ></path>
              <path
                id='Afghanistan'
                class={style.st2}
                d='M729.1,264.5l1-0.1h1.4l1.4-0.2l1.2-0.2l1-0.3l1-0.4l-0.2-1l-0.3-1l0.5-1l1-0.8h1l0.5-0.9l0.9-0.6h1l1,0.2
            l0.2-1.1l-0.3-1.1l0.3-1l0.8-0.6l0.8-0.9l-0.5-0.9l-0.5-0.9l1.6,0.3l0.9-0.7l-0.5-1l0.5-0.9l0.6-0.8l-0.3-1l-0.6-0.9l-0.5-0.9
            l0.7-0.9l0.9-0.5l0.9-0.5l1.1-0.2l1.3-0.1l1.1,0.3l0.9-0.6l-0.3-0.4l1-0.2h-0.1l-1-0.4l-1,0.3l-0.9-0.4l-1.1,0.4l-0.8,0.8l-1.1,0.4
            l-1.4,0.1l-0.3-0.9l-0.2-1.1l-0.6-0.9l-0.2-0.9l-0.9-0.6l-0.1-0.1L741,240l-0.4,0.9l-0.6,0.9l-1,0.4l-0.1,1l-0.9-0.7l-1,0.5
            l-0.9,0.6l-0.7-0.7l-0.9-0.1l-0.9-0.5l-1.1,0.1l-0.9-0.1l-0.9-0.5l-0.3,0.9l-1.5,0.8l-0.2,1l-0.2,1l-0.8,0.7l-1,0.2l-0.9,0.4v1
            l-0.9,0.6l-1.1-0.3l-1-0.2l-1.1-0.6l-0.1,0.5v1l-0.2,1.1l-0.2,1l-0.5,0.9l0.2,1l0.7,0.8l-0.3,1l0.5,1.1l0.4,1l0.1,1l0.3,1.1l1,0.2
            l1.1,0.3l0.3,1l-0.4,0.9l-0.6,1.1l-0.8,1l0.3,0.1l1,0.3l1,0.3l1,0.3l1,0.2L729.1,264.5z'
              ></path>
              <path
                id='Turkmenistan'
                class={style.st2}
                d='M725.9,247.6v-1l0.9-0.4l1-0.2l0.8-0.7l0.2-1l0.2-1l1.5-0.8l0.3-0.9l0.9,0.5l0.9,0.1l-0.2-0.9l-0.4-1
               l-0.9-0.5l-1-0.1l-1.4-0.8l-1.2-0.7l-1.3-0.6l-1.2-0.8l-0.9-0.7l-1.6-0.9l-0.9-1.1l-0.7-0.8l-0.6-0.9l-0.6-0.9l-1.1-0.3h-1
               l-1.1,0.1l-0.9-0.6l0.2-1l-0.9-0.5l-0.4-0.9l-1.1-0.1l-0.9-0.5l-0.9-0.8h-0.1l-0.9,0.5l0.7,0.7l-0.9-0.3l-0.6,0.9l-0.9,0.4
               l-0.2,1.1l0.3,0.9l-1-0.1l-1.4-0.1l-0.9,0.2l-0.9-0.8l-0.7-0.9l-0.9-0.8l-1.1-0.5h-1.2l-1,0.3l-1,0.8l-0.3,0.4l0.3,0.3l0.7,0.8
               l-0.2-1l0.6-0.9l1.1-0.2l0.7,0.8l0.4,0.9l0.9,0.7l0.8,0.7l-0.4,0.9l-1,0.2l-0.8-0.6l-1,0.3l-0.7-0.9l0.1,1.3L700,234l0.9,0.7
               l0.9,0.6l0.1,1.2l0.9,0.5l0.8,0.8l-0.1,1l0.2,1.1l0.2,1l0.4,1.2l1.4-0.3l0.5-0.9l0.9-0.7l1-0.3l1.2,0.1l0.9-0.5h1l0.5,0.9l1,0.3
               l1,0.3l1,0.3l1.1,0.2l0.8,0.8l1.1,0.6l0.9,0.7l1,0.7h1l0.6,0.9l0.2,1l0.5,1l1.1,0.6l1,0.2l1.1,0.3L725.9,247.6z'
              ></path>
              <path
                id='Uzbekistan'
                class={style.st2}
                d='M735.7,241.6l0.3-1l-0.1-1.2l-0.5-0.9l-0.7-0.9l-0.9-0.6l-0.3-1h1.4l1-0.3l0.2-1l0.9-0.5l-0.3-1l1-0.3l1-0.6
               l0.6-0.3l0.7,0.8l-0.7,0.7l0.9,0.7l0.7-0.1l1-0.1l1.1,0.1l0.9-0.4l0.7-0.8l-0.8-0.9l-0.9-0.4L742,231l-0.9,0.6l-1.1-0.2l-0.8-0.8
               l-1.1-0.2l0.6-0.9l1-0.9l-0.5-0.3l-0.9,0.4l-0.7,0.8l-0.9,0.5l-0.9,0.6l-0.5,0.9l-0.3,0.9l-1.1-0.1l-0.4-1l-1.1-0.1l-1.3,0.1
               l-0.9-0.9l-0.4-1.1l-1.2-0.3l-0.3-1.1l-0.2-1.6L727,226l-0.8-0.8l-0.9-0.6h-1.1h-1.3l-1.3-0.1l-1.1,0.1l-1,0.2l-0.8,0.1l-0.9-0.7
               l-0.8-0.7l-0.9-0.7l-0.9-0.8l-1.2-0.7l-1-0.5l-0.9-0.5l-1.4-0.7l-0.9-0.5l-1,0.3l-1,0.3l-1,0.3l-1.1,0.3l-1.1,0.3l0.3,1.3l0.3,1.3
               l0.3,1.3l0.3,1.3l0.3,1.3l0.3,1.3l0.3,1.3l0.3,1.3l1.4,0.1l1,0.1l-0.3-0.9l0.2-1.1l0.9-0.4l0.6-0.9l0.9,0.3l-0.7-0.7l0.9-0.5h0.1
               l0.9,0.8l0.9,0.5l1.1,0.1l0.4,0.9l0.9,0.5l-0.2,1l0.9,0.6l1.1-0.1h1l1.1,0.3l0.6,0.9l0.6,0.9l0.7,0.8l0.9,1.1l1.6,0.9l0.9,0.7
               l1.2,0.8l1.3,0.6l1.2,0.7l1.4,0.8l1,0.1l0.9,0.5l0.4,1l0.2,0.9l1.1-0.1l0.9,0.5l0.9,0.1v-1.2H735.7z'
              ></path>
              <path
                id='Tajikistan'
                class={style.st2}
                d='M745.7,243.5l1.1-0.4l0.8-0.8l1.1-0.4l0.9,0.4l1-0.3l1,0.4h0.1l-0.3-1l-0.3-1.1l-0.5-0.9l-0.9-0.8l-1.2,0.2
            l-0.7-0.8l-0.4-1l-0.2-0.8l-1.1,0.3l-1.1-0.1l-0.9,0.4l-1-0.2l-0.7-0.8l-1,0.3l-1-0.3h-1h-1.1l-1-0.4l0.3-1l1-0.3l0.9,0.3l1-0.3
            l0.3-0.2l-0.7,0.1l-0.9-0.7l0.7-0.7l-0.7-0.8l-0.6,0.3l-1,0.6l-1,0.3l0.3,1l-0.9,0.5l-0.2,1l-1,0.3h-1.4l0.3,1l0.9,0.6l0.7,0.9
            l0.5,0.9l0.2,1.1l-0.3,1v1.1l0.7,0.7l0.9-0.6l1-0.5l0.9,0.7l0.1-1l1-0.4l0.6-0.9l0.4-0.9l0.9-0.6l0.1,0.1l0.9,0.6l0.2,0.9l0.6,0.9
            l0.2,1.1l0.3,0.9L745.7,243.5z'
              ></path>
            </g>
            <g id='SouthEastAsia'>
              <path
                id='NorthPakKashmir'
                class={style.st2}
                d='M759.6,247.4l-0.9,0.1l-0.1-0.1l-0.3-0.1H758l-0.2-0.1l-0.4-0.1l-0.1-0.1l-0.1-0.1H757l0.9,1.5L759.6,247.4z'
              ></path>
              <path
                id='Pakistan'
                class={style.st2}
                d='M744.3,280.1l0.1-1l1,0.3l1.2,0.2l1-0.3l1-0.3l1,0.2l-0.1-1L749,277l-0.7-0.9l-0.8-0.7l-0.8-0.7v-1l-0.9-0.9
            l-0.9-0.5l0.1-1.2l0.6-0.9l0.5-1.2h1l1,0.4l0.9-0.3l0.9-0.7l0.5-1l0.6-1.1l0.9-0.8l0.4-1.2l0.1-1.1l1.2-0.8l0.1-1l0.4-0.9l0.3-1.1
            l-0.3-1.2l0.6-0.9l0.9-0.3l-0.7-0.9l-1-0.3l-0.9-0.6l-0.7-0.9l-0.1-1.1l-0.5-0.9l-0.3-1l-0.5-0.9l0.9-0.8l1.7,0.4l1.4,0.3l0.9-0.5
            l1-0.2l0.9-0.6l0.1-0.4l-0.9-1.5l-0.7-0.7l-1-0.2l-0.6-0.9l-0.4-0.9l-1.1-0.3l-0.8-0.7l-0.9-0.2h-0.6l-0.9,0.6l-1.1-0.3l-1.3,0.1
            l-1.1,0.2l-0.9,0.5l-0.9,0.5l-0.7,0.9l0.5,0.9l0.6,0.9l0.3,1l-0.6,0.8l-0.5,0.9l0.5,1l-0.9,0.7l-1.6-0.3l0.5,0.9l0.5,0.9l-0.8,0.9
            l-0.8,0.6l-0.3,1l0.3,1.1l-0.2,1.1l-1-0.2h-1l-0.9,0.6l-0.5,0.9h-1l-1,0.8l-0.5,1l0.3,1l0.2,1l-1,0.4l-1,0.3l-1.2,0.2l-1.4,0.2H730
            l-1,0.1l-1.2,0.2l-1-0.2l-1-0.3l-1-0.3l-1-0.3l-0.3-0.1l0.8,0.9l0.7,0.9l0.6,0.9l0.8,0.9l1,0.5l0.9,0.6l0.6,1.2l0.3,1l0.9,0.6
            l0.3,0.9l-0.8,0.8l-1,0.3l-0.7,0.5l-0.6,0.9l-0.2,1l0.1,1.2h0.1l1,0.3l1-0.4l1,0.1h1l0.9-0.4l1.2,0.3l1-0.1h1.1l1-0.1l0.9-0.3
            l1.1-0.2l0.6,0.9l0.1,1l1.1,0.2l0.4,0.9l0.3,1.1l0.9,0.6h1.1L744.3,280.1z'
              ></path>
              <path
                id='India'
                class={style.st2}
                d='M806,313.5l0.3-0.9l-0.1-1l0.2-1.1l-0.3-1l-0.3,0.5v1.1l-0.2,1.1l-0.1,1.3l0.4,1.2L806,313.5L806,313.5z
             M770.4,324.5l0.8-1.1l0.2-1l1-0.6l0.9-0.3l-0.3-1l0.5-0.9l0.8-0.9l0.6-0.9v-1.1l-0.3-1.2l-0.1-1l0.3-1l0.5-0.9l0.2-1v-1.2l-0.3-1
            l-0.3-1.1v-1.1l-0.3-1.1l-0.2-1.2l0.3-1l0.8-0.9h1l0.4-0.9l0.9-0.4l1-0.3l0.6-0.9v-1l0.8-0.7l0.9-0.5l0.6-0.9l0.7-0.7l0.8-0.8
            l0.6-0.8l0.5-1.3l0.8-0.7l0.3-1.1h1l1-0.3l0.8-0.8l0.5-1l0.3-1l-0.4-1.2l0.8-0.9l1-0.3l0.7-0.9l-0.3-0.9l0.7,0.8l0.2,1l1,0.1l0.2-1
            l0.8,0.7l-0.1-1l-0.3-1l-0.3-1l-0.2-1l-0.6-0.8l-0.6-0.9l0.1-1l-1.4-0.9l-0.2-1.1l0.5-0.9h1.1l-0.4-0.9l-0.9-0.4l-0.9-0.5l0.3-1
            l-0.1-1.1l0.4,0.3l0.8,0.7l1,0.4l1-0.3l0.6,0.9l0.2,1.1l0.9,0.9l1.1,0.1l1.3-0.1l1,0.1h1.1l0.9,0.5l-0.3,0.9l-0.5,0.9l-0.9,0.7
            l-0.5,0.9l0.3,1l0.4,0.9l0.8-0.7l0.2-1.1l1,0.6l0.4,1.3l0.4,0.9l0.5,1.5l0.1,0.5l0.7,0.1l0.6-0.9l-0.3-0.9l-0.1-1l0.4-0.9l-0.1-1
            l-0.3-1l0.9,0.3l0.9-0.6l0.2-1.1l0.4-1l-0.4-0.9l0.5-1l0.3-1l-0.3-1l0.4-1l0.9-0.8l0.8-0.9l1.3-0.3l0.9,0.4l-0.7-0.9l0.5-0.9l0.1-1
            l-0.9-0.3l-1-0.3l-0.2-1l-1-0.3l0.3-1l-0.9-0.6l-0.9,0.6l-1,0.4l-1.1-0.5l-0.9,0.5l-0.6,0.9l-1,0.4l-0.6,0.9l-0.9,0.7l-0.5,0.9
            l-1,0.3l-0.2,0.9l0.8,0.7l-0.1,1l-1-0.1l-1,0.2l-1.3-0.1l-1.1,0.3l-1.1-0.2l-1-0.1l-0.8-0.7v-0.7l-0.4-1l-0.2-0.9l-1.4,0.1
            l-0.1,0.3l0.2,0.3l-0.2,1l0.2,1l0.6,0.9l-0.2,1h-1.1l-1.2-0.3h-1l-0.9-0.4l-1,0.1l-0.7-0.8h-1l-1-0.4l-0.6-0.9l-1.6-0.1l-1-0.3
            l-1.1,0.1l-0.9-0.7l-1-0.3l-1.2-0.3l-0.9-0.7l-0.8-0.7l-1-0.4l-1-0.3l-0.9-0.6l0.3-1l0.1-1.1l0.3-1l0.9-0.7l-0.9-0.6l-1.1-0.4
            l-0.8-0.9l-1-0.3l-0.9-0.6l-0.6-0.8l-0.9-0.7l-0.4-1l-0.8-0.9h1.1l1-0.7l-0.4-1l-0.7-0.7l-0.8-0.8l-0.3-0.9l0.2-1.2l-0.9-0.9
            l-0.9-0.3l-0.6-1l-0.5-0.9l-0.3-0.3L760,248l-1.5,1.1l-0.1,0.4l-0.9,0.6l-1,0.2l-0.9,0.5l-1.4-0.3l-1.7-0.4l-0.9,0.8l0.5,0.9l0.3,1
            l0.5,0.9l0.1,1.1l0.7,0.9l0.9,0.6l1,0.3l0.7,0.9l-0.9,0.3l-0.6,0.9l0.3,1.2l-0.3,1.1l-0.9,0.4l-0.1,1l-1.2,0.8l-0.1,1.1l-0.4,1.2
            l-0.9,0.8l-0.6,1.1l-0.5,1l-0.9,0.7l-0.9,0.3l-1-0.4h-1l-0.5,1.2l-0.6,0.9l-0.1,1.2l0.9,0.5l0.9,0.9v1l0.8,0.7l0.8,0.7l0.7,0.9
            l0.5,1.2l0.1,1l-1-0.2l-1,0.3l-1,0.3l-1.2-0.2l-1-0.3l-0.1,1l-1.1,0.3l0.3,0.8l0.9-0.6l-0.3,1l0.7,0.9l1.1,0.7l1.1,0.3l1.1-0.4
            l1-0.3l-0.5,0.9l-0.9,0.7l-0.9,0.3l-1.1,0.3l0.8,0.8l0.9,0.9l0.8,0.8l0.9,0.9l0.8,0.7l1,0.4l1.2-0.5l0.9-0.5l0.7-0.8l0.2-1
            l-0.5-0.9l0.9-0.6h1l-0.6,0.9l0.5,0.9l-0.1,1l0.5,0.9l0.2,1.1l-0.2,1.1v1.1l0.3,1.1l0.6,0.9l-0.2,1l0.3,1l0.3,1l0.4,0.9l0.3,1
            l0.3,1l0.2,1.1l0.5,1l0.5,0.9l0.4,1l0.5,0.9l0.3,0.9l0.8,0.8l0.3,1l0.4,0.9l0.3,1.1l0.3,1l0.3,1l0.6,1.2l0.6,0.9l0.6,0.9l0.6,1.1
            l0.5,1.3l0.4,0.9l0.3,0.9l0.3,1l0.3,1.4l0.4,0.9l0.6,0.9l0.7,0.9l0.8,0.7L770.4,324.5z'
              ></path>
              <path
                id='SriLanka'
                class={style.st2}
                d='M778.2,330.6l1-0.4l0.9-0.7l0.3-1v-1.4L780,326l-0.6-0.9l-0.3-1l-0.6-0.9l-0.6-0.9l-0.5-0.9l-0.8-0.7
               l-0.9-0.2l0.9,0.4l-1-0.3l0.2,1.1l-0.4,1.1l-0.1,1l-0.1,1.3l-0.1-1l-0.1,1l0.2,1.1l0.3,2l0.3,1.2l0.4,0.9l0.9,0.5L778.2,330.6z'
              ></path>
              <path
                id='Bangladesh'
                class={style.st2}
                d='M801.2,287.6l0.9-0.6l-0.3-1.4l-0.1-0.5l-0.5-1.5l-0.4-0.9l-0.4-1.3l-1-0.6l-0.2,1.1l-0.8,0.7l-0.4-0.9l-0.3-1
            l0.5-0.9l0.9-0.7l0.5-0.9l0.3-0.9l-0.9-0.5h-1.1l-1-0.1l-1.3,0.1l-1.1-0.1l-0.9-0.9l-0.2-1.1l-0.6-0.9l-1,0.3l-1-0.4L790,273
            l-0.4-0.3l0.1,1.1l-0.3,1l0.9,0.5l0.9,0.4l0.4,0.9h-1.1l-0.5,0.9l0.2,1.1l1.4,0.9l-0.1,1l0.6,0.9l0.6,0.8l0.2,1l0.3,1l0.3,1
            l0.2,0.7l0.9,0.4l0.2-1l0.3,1l0.3-1l0.1-1l0.7,0.8l0.2,1.1l0.5-1l-0.3-0.9v-1.1l0.1-1l-0.9-0.6l1-0.3v1.1l0.5,1.1l0.9,0.5l1-0.3
            l0.9,1.2l0.2,0.5l0.5,0.9l0.1,1.1l0.9,0.9L801.2,287.6z'
              ></path>
              <path
                id='Indonesia'
                class={style.st2}
                d='M873.9,375.5l-0.8-0.6l-0.7-0.9l-0.5,0.1h-1.4l-0.8,0.6l0.9,0.4l0.9,0.4l0.7,0.8l1.1,0.2L873.9,375.5z
                M882,376.5l0.9-0.3l0.9-0.8l0.9-1l-0.3-1.2v-0.3l-0.2,0.2l-1,0.4l-0.3,0.6l-0.8-0.1H882l-0.8,0.7l-0.4,1l0.1,1.1L882,376.5z
                M859,371.1l0.3-1.1l-0.8-0.8l-1,0.1l-1,0.1l-1-0.4l-0.3-1.1l-0.6-0.9l-1-0.2l-1-0.4h-1.1l-0.9-0.4l-0.4,0.9l-1,0.2l-1-0.1h-1.4
               l-1.1-0.2l-0.5-1l-1.2-0.5l-1.1-0.3l-0.9-0.6l-1.1,0.3h-1.2L839,365l-0.7,1l-0.2,1.1l1.1-0.1l1.2,0.5l0.1,1.1l1.6,0.2l1,0.5l1,0.3
               l1-0.3l1.2,0.1l1,0.2l1.1,0.3l0.9,0.4l0.9,0.5l1.1,0.3l1,0.2l1-0.1l1,0.2l1.1,0.1l1.1-0.3l0.9,0.6l1,0.3l1,0.2L859,371.1z
                M864.3,372.2l0.3-0.8l-0.8-0.8l-0.8,0.9l0.3,1.2L864.3,372.2z M861.8,371.7l0.1-1.1h-1.3l-1.1,0.5l0.9,0.4l0.6,0.9L861.8,371.7z
                M876.4,372.4h1l1.1-0.2l0.9-0.8l0.5-0.9l-0.6,0.5l-0.9,0.7l-1.1-0.4l-1,0.3l-0.9-0.3l-1-0.3l-1.1-0.2l-1,0.4l-0.2,1.1l1.1,0.1
               l1,0.1l1.1,0.1L876.4,372.4z M866.4,373.1l1.1-0.3l0.9-0.4l1-0.1l1-0.3l-0.9-0.6l-1-0.2l-0.8-0.8l-0.3,0.9l0.8,0.7l-1.1,0.1
               l-0.8-0.8l-1,0.2l-0.5,0.9l0.4,0.9L866.4,373.1z M923.3,372.4l0.2-1l0.1-1.4l0.1-1l0.2-1.6l-0.1-1.2l0.3-1l0.1-1.2l0.1-1.3v-1
               l0.1-1.1l0.1-1.7v-1.1l0.1-1.7l0.1-1.1h-0.2l-1-0.4l-1-0.3l-1.4-0.3l-0.9-0.6l-1.1-0.7l-1-0.3l-0.9-0.5l-0.9,0.4l-0.6,1l-0.9,0.6
               l-1.1,0.1l-0.4,0.9l-0.8,0.7l-0.5,0.9l-0.9,0.7l-1-0.3l-0.6-0.9l-0.8-0.7l-0.4-1l-0.2-1l-0.1-1l0.4-0.9l-0.3-1.3l-1-0.3l-1.2-0.4
               l-0.9-0.5l-1.2-0.3l-0.7,0.3l-0.7,0.7l-1.2,0.3l-0.3,0.9l-0.7,0.9h1l1,0.2l0.3,1l0.7,0.9l1,0.3l1-0.1l1.1-0.1l0.9,0.6l-0.9,0.4
               l-1-0.3l-0.7,0.9l-1.1-0.2l-0.8,0.8l1,0.3l0.8,0.7l-0.3,1.1l0.6,1.2l1-0.5l0.4-1l0.3-0.9l0.1,1.2l0.9,0.3l0.9,0.7l0.8,0.8l0.9,0.5
               l1,0.1l1.1,0.3l0.9,0.4l0.9,0.5l1.1,0.2l0.9,0.6l0.9,0.6l0.7,0.8l-0.1,1l0.3,1l0.7,0.9l0.9,0.6l-0.9,0.5l1,0.1l-0.9,0.5l0.1,0.6
               l-0.5-0.6l-1.1,0.3l-0.8,0.8l-0.6,0.9l0.6,0.9l1.3-0.3l0.9-0.7l-0.1-0.1l0.3-0.9l0.1,0.1l0.2,1l1,0.2l1,0.1l0.9,0.9l0.5,0.9
               l0.8,0.9L923.3,372.4z M889.1,369.2l-0.8-0.1l-0.9,0.7l0.9,0.2L889.1,369.2z M901.1,368.8l0.1-1l-0.3,0.2l-0.8,0.8l0.3,0.9
               L901.1,368.8z M857.6,367.6l0.9-0.7L858,367l-1.8-0.1l-0.6,0.9l1,0.2L857.6,367.6z M908.9,364.9l0.1-1l-0.3-1l-0.2,0.1l-0.4,1.1
               l-0.2,1L908.9,364.9z M904.9,363.5l0.3-1l-0.4,1l-0.3,0.9L904.9,363.5z M880.1,361.6l-0.4-0.9l-0.6,0.9l0.7,0.9L880.1,361.6z
                M881,362.5l-0.5-0.9l0.6-0.9l-0.9-0.1l-0.3,1.4l0.2,1L881,362.5z M861.4,358.9l1.3-0.6l1-0.7l0.5-0.9l0.4-0.9l0.4-1l-0.2-1.2
               l-0.3-1l0.7-0.9l0.9-0.5l0.6-0.9l0.9-0.5l-0.3-1l0.1-1v-1l0.4-1l0.8-0.8l0.9,0.4h1l-0.2-1l-0.8-0.6l-0.9-0.7l-0.7-1.1l0.5-0.9
               l-0.7-0.8l-0.3-1l-0.8-0.7l0.3-1.2l1.2-0.4l-0.6-1l-0.1-0.1l-0.9-0.4h-1.4l-0.2-0.1l-1.1,0.1l-0.9,0.4l-0.2,1l-0.1,1.2l-0.2,1
               l-0.9,0.7l-0.1,1.1l-0.7,0.8l-0.2,1l-0.6,0.7l-1.4,0.4l-1.3-0.1l-0.9-0.6l-1,0.1l-0.8,0.7l-0.8,0.9h-1.2h-1l-1,0.4l-0.7-0.7
               l-0.8-0.9l-0.7-0.8v-1.2l-0.7,0.8l-0.7,0.9l-0.3,0.9l-0.2,1l0.3,1.1l0.5,0.9l-0.3,1l0.4,0.9l0.7,0.9l0.9,0.7l0.3,0.9v1.4l0.2,1
               l0.2,1l0.9,0.6l0.9,0.6l0.9-0.4l1.1-0.3l0.1,1l0.2,1l0.8-0.7l1.1,0.2l1.1-0.9l0.9,0.3l0.8,0.8l1.1-0.1l0.8,0.8l0.1,1.4
               L861.4,358.9z M864.1,358.9l0.4-1l-0.3-1l-0.4,1L864.1,358.9L864.1,358.9z M899.3,357l-0.6-0.9l-1.1-0.1l-0.9-0.5l-0.6,0.3
               l-1.1-0.1h-1.1l-0.9,0.6l-0.2,1l0.9-0.6l0.9,0.3h1l1.1-0.2l1.1,0.5l0.9,0.4l1.1,0.1L899.3,357z M890.5,358.1l0.6-1l-0.9-0.7
               l-0.4-0.2l-1.4,0.1v1.1l0.8,0.8L890.5,358.1z M844.8,356.4l0.2-1l-1-0.6l0,0l-0.6,0.9l0.1,1.1L844.8,356.4z M836.5,363.6l1.2-0.3
               h1.1l0.1-1.1l0.1-1.2v-1v-1v-1l0.6-1.4l-0.6-0.9l-0.4-0.9l-0.9-0.6h-1l-0.6,0.9l0.4-0.9l-0.3-0.9l-0.3-1l-0.2-1.1l-0.9-0.6l-1-0.3
               l-0.5-0.9l-0.3-1l0.9-0.5l0.1-1l-0.9-0.6l-0.9,0.6l-1.2,0.2l1-0.3l0.1-1l-1.1-0.2l-0.7-0.9l-0.4-0.9l-1.3-0.7l-0.5-1h-1.3
               l-0.9-0.7l-0.8-0.8l-0.3-1l-0.7-0.8l-0.9-0.6l-1-0.8l-1-0.9l-0.9-0.3v-1.1l-0.6-0.9l-0.8-0.8l-1.1-0.3l-1-0.3l-1.2,0.1l-0.9-0.6
               l-1-0.4h-0.3l-0.3,0.9l0.4,0.9l0.6,0.9l0.7,0.9l0.9,0.9l0.7,0.9l1.2,0.6l0.8,1.4l0.8,1l0.5,1.2l0.9,0.4l0.9,0.6l0.5,1.2l0.3,0.9
               l0.5,1.5l0.6,0.9l0.9,0.7l0.3,0.9l0.7,0.9l0.5,1l0.5,0.9l0.4,1l0.3,1.2l0.4,1l0.7,0.8l0.6,1.2l0.7,0.7l0.9,0.7l0.3,1l0.8,0.7
               l0.9,0.8l0.8,0.8l0.8,0.6l0.7,0.9l0.7,0.9l0.6,0.9L836.5,363.6z M898.7,352.4l-0.4,0.1l-0.9,0.6l0.9,0.3L898.7,352.4z
                M885.4,353.1l1-0.2l-0.9-0.5l-1-0.2l-0.1,0.1l-0.1,1L885.4,353.1z M914.3,352.6l-1-0.1l-1.1-0.2l-1-0.2l0.1,0.3l1,0.3l1,0.3
               L914.3,352.6z M840.9,354.7l-0.9-0.9l-0.2-1.1l-0.6-0.9l-0.2,0.9l-1-0.1l-0.3,0.9l1,0.2l0.6,0.9l0.3,1.1l1.3,0.2L840.9,354.7z
                M882.2,351.6l-0.9-0.8l-0.1,0.1l-0.9,0.7l0.9,0.6L882.2,351.6z M823.2,352.2l-0.4-0.9l-0.4-1l-0.3,0.1l-0.4,0.9l0.5,0.9H823.2z
                M912.1,349.6l-1-0.1l-0.1-0.1l0.9,0.5l0.9,0.5L912.1,349.6z M873.3,363.3h1l-0.3-1l0.2-1l0.2-1l-0.1-1l0.2-1.3v-1.1l-0.3-1
               l0.9-0.7l1,0.5v1l-0.4,0.9l1,1.1l0.4,1l-0.1,1.1l0.9,0.4l0.6-0.9l1-0.3l0.9-0.6l-0.5-0.9l-0.9-0.6v-1l0.1-1.1l-0.7-0.9l-0.5-0.9
               l-0.3-1l0.8-0.8l0.9-0.4l0.7-0.9l0.7-0.9l1.2,0.3l-0.1-1l-1.1,0.3h-1.2l-1.2,0.6l-1-0.3l-0.7,0.9l-0.9,0.6l-0.7-0.8l-0.9-0.6
               l-0.4-1.1l-0.1-1l0.3-1l0.9-0.9l1.3,0.3l1-0.2l1,0.2l1.1-0.2h1.2l1,0.5l1.1,0.1l0.9-0.3l1-0.3l0.6-1l0.7-0.9v-1.4l-0.1-0.2
               l-0.6,0.9l-0.9,0.7l-0.7,0.7l-1.3,0.2l-1-0.1l-1,0.1l-0.9-0.3l-1.1-0.3l-1.4-0.4l-1.1-0.2l-0.4,0.9l-1.1,0.1l-0.5,1.2L873,347v1.2
               l-0.3,1.4l-0.5,0.9l-0.4,1l0.2,1.2l-0.5,0.9l-0.3,1l-0.7,0.9l0.1,1.1l0.7,0.9l0.9,0.6l0.3,0.9l-0.1,1l-0.3,1.4l-0.4,1l0.5,0.9
               L873.3,363.3z M892.7,349.7l-0.5-0.9h-0.8l0.3,1L892.7,349.7z M899.9,348.3l1-0.3l-1-0.4l-0.2,0.2l-0.8,0.6L899.9,348.3z
                M893,348.3l-0.2-1l0.3-0.9l1,0.2l0.9,0.3l-0.7-0.9l-0.9-0.6l0.8-0.6l0.4-1.1l-1-0.2l-0.4,0.9l-1.1,0.3l0.8-0.9v-1l-0.3-1.1
               l-0.7,0.9l-0.2,1.1l-0.3,1l0.4,0.9l0.1,1l0.3,1.1l0.4,1.1l0.9,0.9L893,348.3z M819.3,344.3l-0.8-0.9l-0.3,0.8l0.6,0.9l1,0.3
               L819.3,344.3z'
              ></path>
              <path
                id='EastTimor'
                class={style.st2}
                d='M883.2,373.4L883.2,373.4l-1,0.5l0.8,0.1L883.2,373.4z M885.4,373.8l1.1-0.3l0.9-0.5l0.9-0.3l1-0.5l0.4-0.9
               l-0.6,0.1l-1.1,0.1l-1,0.3l-1.1,0.1l-1,0.3l-0.6,0.9v0.3l0.3,1.2L885.4,373.8z'
              ></path>
              <path
                id='Malaysia'
                class={style.st2}
                d='M851.5,344.8h1h1.2l0.8-0.9l0.8-0.7l1-0.1l0.9,0.6l1.3,0.1l1.4-0.4l0.8-0.8l0.2-1l0.7-0.8l0.1-1.1l0.9-0.7
               l0.2-1l0.1-1.2l0.2-1l0.9-0.4l1.1-0.1l0.2,0.1h1.4l0.9,0.4l0.1,0.1l0.4-0.5l1,0.1l0.8-0.8v-1l1.1-0.3l0.4-1.2l-1.1-0.3l-0.9-0.6
               l-0.9-0.7l-1-0.3v-1l-0.9-0.4l-0.7-0.8l-0.5,0.9l0.1-1.1l-0.6,0.5l-0.3,1l-0.7,0.8l-0.3,1l-0.6,0.9l-0.9,0.4l0.4,0.9l-0.9,0.4
               l0.2,0.3l0.3,1.1l-0.7-0.9V334l-0.2-0.2v0.2l-0.4,1l-0.2,1.2l-0.9-0.6l-0.6-0.9l-0.2-0.1l-0.1,0.9l-0.5,0.9l-0.9,1.2l-0.6,0.9
               l-0.9,0.6l-1,0.3l-1.6,0.3l-0.5,1v0.1l-0.3-0.9l0.3,0.9l0,0v0.1l0,0v-0.1l-0.3,0.9l-0.3,1l-0.1,1l-0.9-0.4l-0.9-0.3l-1-0.3
               l-0.6-0.9v1.2l0.7,0.8l0.8,0.9l0.7,0.7L851.5,344.8z M834.3,343.5l0.9-0.6l-0.4-0.9l-0.4-1l-0.6-0.9l-0.6-0.9v-1.1l-0.3-1l0.1-1.1
               l0.1-1l-0.1-1l-0.5-1l-0.9-1l-0.9-0.9l-1.1-1l-0.3,0.7l-0.9,0.3l-1.4,0.3l-0.3-1.2l-0.9-0.6l-0.9-0.7l-0.2,0.8v0.2l0.5,0.9l0.1,1
               l0.3,1.2l0.2,1l0.4,1l0.3,1.1l0.4,1l0.6,0.9l0.6,1l0.3,1l0.9,0.7l0.8,0.7l1.2,0.9l0.9,0.6l0.9,0.7h1.2V343.5z'
              ></path>
              <path
                id='Brunei'
                class={style.st2}
                d='M861.7,334.1l-0.2-0.3l-0.3,0.3v0.3l0.7,0.9L861.7,334.1z M860.7,335.1l0.4-1v-0.2l-0.1-0.3l-0.9,0.9
               l-1.3,0.3l0.2,0.1l0.6,0.9l0.9,0.6L860.7,335.1z'
              ></path>
              <path
                id='Philippines'
                class={style.st2}
                d='M886.9,330.9l-0.1-1.1l-0.7-0.8l0.2-1l0.9-0.5l0.5,1l0.4,0.9l0.1-1.4l0.7-0.9l-0.2-1.1l-0.4-1l-0.3-1l-0.4-1
               l-0.2-1l-0.6-0.9l-0.9-0.6l-0.5,0.2l0.3,1l-0.8,0.9l-1-0.2l0.1,1l-1,0.1l-0.3,1l-1,0.3l0.1-1.2l-1-0.4l-0.9,0.4l-0.3,1l-1.2,0.3
               l-0.5,0.9l-0.1,1l0.4,0.9l0.3-1.2l0.6-0.9l0.9,0.6l1-0.1l0.9-0.6l0.9,0.7l-0.1,1.2l0.1,1.1l0.3,1l0.8,0.7l1.1,0.6l1-0.1l0.4,0.9
               L886.9,330.9z M883.4,319.9l-0.7-0.9l-0.6,0.3l-0.5,0.9l1,0.3L883.4,319.9z M880.1,320.7v-1l0.2-1.1l0.3-1l-0.7-0.8l-0.8,0.3
               l-0.2,1v1.2l-0.9,0.6l0.4,1l0.9,0.6l1,0.2L880.1,320.7z M881.1,319.3l0.7-0.8v-1l-0.2-1.1h-0.1l-0.2,1l-0.4,1l-0.4,1l0.3,0.9
               L881.1,319.3z M866.7,323.4l0.8-0.6l0.6-0.9l0.9-0.7l0.4-0.9l0.7-0.8l0.6-0.9l0.6-0.9l-0.4-0.9l-0.1-1l-0.2,0.3l-0.3,1l-0.2,1
               l-0.7,0.9l-0.4,0.9l-0.7,0.9l-0.7,0.8l-0.8,0.7l-0.6,0.9l-0.3,1L866.7,323.4z M884.8,318.4l-0.5-0.9l-0.2-1.1l-0.5-0.9l-1.1-0.1
               v0.4l0.6,0.9l0.5,0.9l0.2,1l0.6,0.8L884.8,318.4z M877.8,317.6l1-0.6l0.7-0.8l-0.3-1l-1.1-0.1l-0.9-0.6l-0.4,0.3v1.2l-0.1,1.3
               l0.2,1L877.8,317.6z M881.2,313.5l-0.8-0.7l-0.9-0.5l-0.2,0.7l-0.1,1.1l0.7-0.9l0.7,0.8l0.9,0.5L881.2,313.5z M885.4,315.6l-0.4-1
               l-0.2-1l-0.5-0.9l-1-0.3l-0.1,0.1h-1.4l0.6,1.1l0.8,0.6l0.7,0.8l0.2,1l0.9,0.5l1,0.2L885.4,315.6z M875,312.3l-0.1-1.1l-0.5-1.1
               l-0.9-0.6l-0.5,0.2l-1,0.3l0.9,0.6l0.4,0.9l0.5,0.9l0.9,0.9L875,312.3z M881.4,310.9l-1-0.1l-0.3-0.9l0.3-1.1l-1-0.4l-1.1-0.1
               l-0.7-0.9h-1l-0.2,1.1l-1.2-0.7l-0.5-1l-0.3-1.3l-0.6-0.9l0.3-1.1l0.1-1l0.9-0.4l0.3-1.1l0.3-1l-0.3-1.1l-0.8-0.9l-0.2-1.2
               l-0.5-0.7l-1-0.3l-0.9-0.5l-1.1-0.2l-0.3,0.9l-0.2,1.1v1l0.3,1l-0.1,1l0.2,1l-0.2,1.2l-1-0.3l0.4,0.9l0.2,1.1l0.6,0.9l0.6,0.9
               l0.7,0.9l0.3-0.9l0.7,0.9l-0.6,0.9l0.9,0.7l0.7,0.8l0.9-0.7l1,0.1l0.9,0.7l1,0.4v-1l0.7,0.8l0.8,0.7l0.7,0.9l1,0.1l0.7,0.8
               L881.4,310.9z'
              ></path>
            </g>
            <g id='ChinaAsia'>
              <path
                id='Taiwan'
                class={style.st2}
                d='M869,283.8l0.5-0.9l0.1-1v-1.5l0.1-1.1l-0.1-1v-1.1l-0.9-0.6l-0.2-0.2l-0.9,0.6l-0.4,1l-0.4,0.9l-0.3,1
               l-0.3,1l0.2,1l0.3,1l0.5,1l0.9,0.8l0.9,0.8L869,283.8z'
              ></path>
              <path
                id='Bhutan'
                class={style.st2}
                d='M794.4,272l1.3,0.1l1-0.2l1,0.1l0.1-1l-0.8-0.7l-0.9-1.5h-1l-1.1-0.3l-0.9-0.6H792l-0.8,0.7l-0.5,1l-0.3,1v0.7
            l0.8,0.7l1,0.1l1.1,0.2L794.4,272z'
              ></path>
              <path
                id='China'
                class={style.st2}
                d='M845.3,295.4l0.8-0.7v-1l0.3-0.9l0.3-1.1l-0.9-0.8l-0.3,0.3l-1,0.2l-1-0.1l-0.7,0.8l-0.9,0.7l-0.4,0.9l0.3,1
            l0.5,0.9l1,0.4h1.2L845.3,295.4z M845.2,290.3l-0.3-1l-0.8-0.8l0.3-1l1-0.2l1-0.3l1-0.1l0.4-0.9l1-0.3l1,0.1l0.9-0.4l-0.2-1
            l0.9,0.5l-0.3-0.9l-0.3-1.1l0.8,0.8l0.9,0.6l0.2-0.1h0.2l0.2-0.1h1l1-0.6l1-0.3l1,0.2l0.9-0.5l0.3-1l1-0.9l0.8-0.8l0.7-0.8l0.1-1.1
            l1,0.2l0.3-0.9l0.6-0.9l0.6-0.9l-0.3-0.4l-0.2-1L862,274h1l0.3-1l-0.6-0.8l0.9,0.5l0.4-0.9l-0.1-1.1l0.5,0.2l0.1-1l0.3-1.1l0.9-0.4
            l0.2-1l-0.9-0.7l0.9-0.6l-0.8-0.7l0.9-0.3l-0.8-0.7l0.1-1l-0.9-0.6l-1.2-0.4l-0.9,0.5l-0.8-0.8l1.1-0.5l0.8-0.6l0.9-0.7l-1.1-1
            l-1-0.8l-0.9-0.3l-0.8-0.7l-1,0.3l-0.8-0.9l0.7,0.8l1-0.4l1.2,0.7l1,0.1l1,0.3l-0.7-0.9l-0.9-0.6l-0.8-0.7l-0.9-0.5l-0.3-1
            l-0.7-0.8l-0.7-0.9l-0.5-0.9l-0.7-0.9l-0.9-0.5l-1-0.4l-1.1-0.4l0.1-1.1l0.2-1.2l0.6-0.9l-0.1-1l1.1-0.1l0.4-0.9l0.9-0.5l0.8-0.7
            l1-0.3l-0.3-0.9l-1.3-0.1l-1.3-0.4l-1-0.3l-1-0.2l-0.7,0.8l-0.6,1h-1l-1-0.7l-0.3-1l-0.7-0.9l-1-0.2l-1.3-0.3l-0.9-0.6l-0.5-1
            l0.3-1.1l1,0.4l1-0.3l0.8-0.8v-1l0.8-0.7l1-0.9l0.3-1l0.9-0.7l1-0.2l0.8,0.8l0.7,0.8l-0.3,0.9l-0.7,0.9l0.3,0.9l1.2,0.3l-0.4,0.9
            l-0.5,0.9l0.8-0.7l0.8-0.7l0.8-0.8l0.9-0.5l1-0.3l0.7-0.1l0.7-0.8l0.5-0.6l0.7-0.8l0.8-0.7l0.8-0.8l0.4-1.1l0.8-0.7l0.8,0.6l1,0.2
            l0.9-0.6l-0.4-1h1.2l0.5-0.9l0.9-0.6v-1.2l0.9,0.7l0.9,0.6l-0.4-0.8l1-0.6l-0.1-1.1l-0.7-0.9l-0.3-0.9l-0.9-2.6l0.8-0.7l2.6,0.3
            l-0.3-1l0.2-1.1l0.1-1l-0.3-0.9l-0.4-0.9l-0.3-1l0.6-0.9l-0.7-0.8l-0.6-0.9l-1.1-0.1l-0.9,0.6l-1,0.2l-0.6,0.9h-1h-1.1l-1,0.1
            l-0.8-0.7l-0.7-1l-0.8-0.6l-0.4-0.9l-1-0.1l-1.2-0.6l-0.9-0.5l-1-0.2l-0.9-0.5h-1l-1.1-0.2l-0.9-0.5l-0.5-0.9l-0.9-0.7l-0.7-0.8
            l-0.8-0.7l-0.9-0.8l-0.7-0.8l-0.8-0.7l-1.3-1.4l-0.9-0.6l-0.8-0.8l-0.9-0.3l-0.9-0.6l-1.6-0.3l-1-0.3l-0.9-0.3l-1.1-0.3l-1,0.1
            l-1.2,0.2l-1,0.1l-1.2,0.4l-0.9,0.4l-0.7,0.9l1.1,0.3l0.9,0.5l0.7,0.9l-0.4,0.9l-0.2,1v1l-0.1,1l0.5,0.9l-0.5,0.9l-0.9,0.6
            l-0.8,0.7l-1.8-0.4l-1,0.5l0.2,2l0.3,1.4l0.1,1l0.9,0.6l1-0.4l1.3,0.1h1l1.1-0.6l1,0.3l1.1,0.6l1,0.6l0.9,0.7l0.9,0.7l-0.1,1
            l-1-0.1l-1.1-0.1l-1.1,0.2l-1,0.2l-0.9,0.5l-0.9,0.8l0.1,1.1l-0.9,0.7l-1,0.2l-1.3,0.3l-0.5,0.9l-1,0.4l-1.7-0.3l-1.1-0.5l-1.2,0.3
            v1.1l0.3,0.9l0.9,0.7l0.5,0.9l-0.9,0.7l-0.9,0.8l-0.3,1l-1,0.4l-1.1,0.6l-1,0.1L822,228l-3,0.5l-2.5,1.3l-0.9,0.5l-1.2-0.7
            l-1.6,0.3l-1.7-0.7l-2.6-0.6l-0.8-0.7l-1.2-0.2l-3-0.4l-0.9,0.3l-5.3-0.6l-2.1-0.4l-1.4-1l-0.7-0.9l-1.2-1.2l-0.9-0.6l-1-0.3
            l-1-0.6l-1-0.3l-1.3-0.7l-1.1-0.2h-1.1l-1.1-0.1l-1.4-0.2l-1.2-0.3l-0.9-0.8l0.2-1.6l-0.4-0.9l-0.4-1l-0.8-0.7l-0.9-1l-0.7-0.8
            l-1-0.4l-1-0.5l-1,0.1l-0.9-0.5l-0.9-0.6l-1-0.4l-0.8-0.7l-0.3-1l-0.9,0.3l-1-0.1l0.2,1.1l-0.8,0.8l-1.1,0.2l0.1,1.2l0.6,1.3
            l0.1,1.1l-0.9,0.6l-1-0.3h-1.4l-1.1-0.3l-1.2-0.2l0.1,1.1l-0.1,1.3l0.1,1l0.2,1.1l0.4,0.9h-1l-1-0.4l-1.2,0.4l-1.2-0.1l-0.6,0.9
            l0.9,0.5l0.3,1l0.5,0.9l1.2,1.9l-0.2,1.1l-0.5,0.9l0.4,1.4l-1.1,0.4l-0.9,0.4l-0.9,0.5l-0.6,0.9l-0.9,0.5l-1.2,0.2l-1,0.2l-0.2,1
            l-0.9,0.5l-1-0.2h-1l-1,0.3l-0.9,0.6l-0.7,0.9l-0.2,1l0.2,0.8l0.4,1l0.7,0.8l1.2-0.2l0.9,0.8l0.5,0.9l0.3,1.1l0.3,1l-1,0.2l0.3,0.4
            h0.6l0.9,0.2l0.8,0.7l1.1,0.3l0.4,0.9l0.6,0.9l1,0.2l0.9,0.7l0.1,0.1l0.1,0.1l0.4,0.1l0.2,0.1h0.3l0.3,0.1l0.1,0.1l0.9-0.1l0.2,0.2
            l0.3,0.3l0.5,0.9l0.6,1l0.9,0.3l0.9,0.9l-0.2,1.2l0.3,0.9l0.8,0.8l0.7,0.7l0.4,1l-1,0.7h-1.1l0.8,0.9l0.4,1l0.9,0.7l0.6,0.8
            l0.9,0.6l1,0.3l0.8,0.9l1.1,0.4l0.9,0.6l0.9,0.2l0.9-0.7l0.8,0.4l0.9,0.6l0.9,0.7l1,0.4l0.9,0.7l0.9,0.4l0.9,0.3l0.7,0.7l0.9,0.6
            l1,0.2l0.6,0.9h1l0.7,0.8l1,0.2l1.1-0.2l0.9,0.5h1.2l1-0.1l0.1-0.3l1.4-0.1l0.2,0.9l0.4,1l0.3-1l0.5-1l0.8-0.7h1.1l0.9,0.6l1.1,0.3
            h1l1.1,0.7l1-0.3l0.5-0.9l0.9-0.7l0.6-0.9l1-0.4l0.6-0.9l0.9-0.5l1.1,0.5l1-0.4l0.9-0.6l0.9,0.6l-0.3,1l1,0.3l0.2,1l1,0.3l0.9,0.3
            l0.8-0.8l0.9,0.8l0.6,1.1l0.9,0.5l0.6,0.9l0.3,1.2l0.2,1l-0.1,1.3L814,275l-0.6,0.9l-0.7,0.9l-0.3,1l0.5,1.2l0.7,0.8l1.3-0.3l1,0.1
            l0.1,1.1l0.3,1l0.8,0.7l0.8,0.7l0.1,1.1l0.3,0.9l1.1,0.2l0.3,1l0.9,0.7l1-0.2l1.1-0.1l0.1,0.1l0.4,0.9l0.9-0.5l-0.2-1.2l-0.7-0.9
            l0.2-1h1.3l1-0.5l0.9,0.6l0.7-0.8l1,0.3l1-0.3l1-0.3l0.3-0.9l1-0.3l0.8,0.7l1,0.2l1,0.2l0.3,0.9l0.4,1.2l0.8,0.7l0.9,0.6l0.9,0.3
            l1,0.3l0.1-0.2l1.2-0.9l0.8,0.7l0.8,0.7l0.9-0.6l0.6,0.9l-0.1,1l0.6,0.9l0.6,0.9L845.2,290.3z'
              ></path>
              <path
                id='Japan'
                class={style.st2}
                d='M882.5,273.1l0.9-0.8l-0.4-0.1l-0.7,0.9l0.2,1V273.1z M885.4,259.1l0.8-0.8l-0.3-1.4l0.1-1l0.3-1l-0.7-0.8
            l-0.9-0.4l-0.2-1.1l-1,0.2l-0.6-0.9l-0.2-0.1l-0.9,0.6l-0.7,0.7l-0.8,0.8l0.5,0.9l0.7,0.9l1.1-0.3l-0.2-1l0.7,0.8l0.2,1l-0.5,0.9
            l0.4,0.9l0.2,1l1.1,0.2l-0.2-1.1l0.5,0.9l0.3,1.1L885.4,259.1z M888.6,254.4l0.3-1l0.9-0.3l1.1-0.2l0.3-1l-0.7-0.9l-1-0.4l-0.2,0.1
            l-0.9,0.3l-0.6,0.9l-0.9-0.5l-0.2,1l-0.3,1l0.4,0.9l0.9,0.9L888.6,254.4z M894.6,252.1l0.4-1l0.7-0.8l-0.9-0.4l0.8-0.8l0.7,0.9
            l1-0.2l1.4-0.3l0.9-0.8l0.9,0.4l-0.3-1l0.9-0.3l0.6-0.9l-0.1,1l0.9,0.6l0.3-0.9l0.4-1.2l-1-1.2l-0.2-1l0.2-1.1l-0.3-1l-0.8-1.3
            l-0.4-1.1l0.9-0.5l-0.3-1l0.3-0.9l-0.3-1l-0.4-0.9l-0.8-0.8l-0.7-0.9l-0.9-0.6l-0.7-1.1l-0.9-1l-1-0.4l0.5,0.9l0.9,0.4l-0.9,0.5
            l-1.1-0.3l-0.5,0.9l0.7,0.8v1.2l0.9,0.4l0.4,1l0.1,1l-0.1,1.4l0.3,1.1l-0.8,0.7l-0.2,1.1l-0.6,0.9l-0.9,0.4l-1.1,0.6l-0.9-0.8v-1.1
            l-0.3,1.1l0.3,1.1l-0.4,1.2l-0.1,1l-0.1,1h-1l-1.1-0.3l-1,0.1l-1,0.3h-1l-1-0.2l-0.7,0.9l-0.7,0.8l-0.5,0.9l-0.7,0.8l-1,0.1l0.3,1
            l1,0.3l1-0.3l1,0.2l0.1-1.1l1,0.2h1l1-0.3l0.7-0.9l1-0.1l0.9,0.4l1,0.3l-0.3,1l0.4,0.9l0.9,0.8l1,0.2L894.6,252.1z M894.6,229.9
            l0.9-0.4l-0.9-0.5l-0.9-0.3l-0.3-1.1l1,0.4l0.9-0.4l1.1,0.1l1.1,0.5l1.4,0.6l1.4,0.6l-0.3-1l0.3-1.3l1-0.5l1.2-0.1l0.3-1l-0.8-0.8
            l-0.6-0.9l-0.3-0.9l-0.5,0.9l-1-0.2l-1-0.3l-1.1-0.4l-1.1-0.5l-0.9-0.6l-1.4-1l-1.7-1l-0.8,0.4l0.8,0.8l0.6,0.9l0.3,0.9l0.4,1.1
            l0.1,1l0.2,1.1l-1-0.3l-1-0.1l0.6,0.9l-0.8,0.7l0.3,1l0.9,0.6l0.6,0.9l0.4,0.9L894.6,229.9z'
              ></path>
              <path
                id='Cambodia'
                class={style.st2}
                d='M835.5,318l0.5-1l1.2-0.2l1-0.3l-0.5-1l0.6-0.9l0.9-0.6l1-0.3l1-0.5l0.3-0.9l-0.4-1.2l0.3-1.1l-0.5-1.1
            l-0.4-0.9l0.3-1.2v-0.4l-0.7,0.6l-0.9,0.5l-0.9-0.4l-0.9,0.5l-0.2,1l-0.9-0.7l-0.9-0.5l-0.4,0.3l-1.1-0.4l-1,0.1l-1-0.1l-1,0.1
            l-0.9,0.8l-0.4,0.9l-0.7,0.9l0.4,1.1l0.2,1l0.6,0.9l0.3,1l0.4,1l0.3,0.2l0.2,1l0.4,0.9l0.6-0.9l0.7,0.9l-0.2,1l1-0.2l0.9,0.3h0.1
            L835.5,318z'
              ></path>
              <path
                id='SouthKorea'
                class={style.st2}
                d='M873.4,250.2l0.9-0.3l0.3-1.1l1,0.3l0.9-0.5l0.9-0.7v-1l-0.6-1l-0.3-0.9l-0.3-1l-0.5-0.9l-0.8-0.9l-0.8-0.8
            l-0.7-0.7l-1.2-1.2l-0.8-0.8v0.6l-1,0.3l-1.2-0.1l-0.6,0.9l-0.1,0.4l0.1,0.5l0.5,0.9l0.3,1l-0.9,0.4l0.9,0.5l0.5,0.9l0.7,0.8
            l-0.2,1l0.2,1l0.4,0.9l0.3,1l0.9,0.4L873.4,250.2z'
              ></path>
              <path
                id='Laos'
                class={style.st2}
                d='M837.1,307.4l0.9-0.5l0.9,0.4l0.9-0.5l0.7-0.6v-0.5l0.2-1l-0.9-0.7l-0.6-0.9l-0.2-1l-0.8-0.8l-0.9-0.6
            l-0.4-0.9l-0.7-0.8l-0.9-1l-0.8-0.8l-0.7-0.8l-0.8-0.8l-0.6-0.9l-0.9-0.4l-0.9-0.6l-0.7-0.8l0.7-0.8l1-0.3l0.3-0.9l-0.8-0.8
            l-0.5-0.9l-0.9-0.6l-1,0.2l-1,0.2l-0.9-0.6l-0.7-0.8l0.2-1l-0.9-0.6l-0.8-0.9l-1.8-1l-0.2,1l0.7,0.9l0.2,1.2l-0.9,0.5l-0.4-0.9
            l-0.1-0.1l-0.1,0.2l-0.9,0.6v1.2l-0.9,0.5l-0.1,1l0.9,0.3l0.1,1l0.5,1l0.9-0.3l0.7,0.9l0.3,1.1l-0.1,1l0.2,1l-0.3,1.1l0.7,0.8
            l0.8-0.8l0.8-0.7h1.1l1,0.3l0.8-0.9l0.9-0.6l1.1,0.3l0.7,0.9l0.8,1l0.9,0.8l-0.1,1l0.3,1.1l0.6,0.9l0.8,0.8l0.6,0.8l0.5,0.9
            l-0.1,1.1l0.1,1.1L835,307l0.9,0.5l0.9,0.7L837.1,307.4z'
              ></path>
              <path
                id='Myanmar'
                class={style.st2}
                d='M820.8,318.5l-0.1-0.9l0.7-0.9l0.5-0.9l0.3-1l0.1-1l-0.4-0.9l-0.4-0.9l-0.4-0.9v-1l-0.3-1l-0.5-0.9l-0.8-0.7
            l-0.7-0.9l-0.6-0.9l-0.3-1l0.9-0.6l-0.1-1.1l0.5-0.9l-0.6-0.9l-0.4-1.2l-0.8-0.8l-0.7-0.9l-0.8-0.8l-0.3-1l-0.7-0.8l0.6-0.9l-0.3-1
            v-1.2l0.4-1l1,0.3l1.1-0.2l0.3-1l0.8-0.7l1.2-0.1l0.3,0.2l0.1-1l0.9-0.5v-1.2l0.9-0.6l0.1-0.2l-1.1,0.1l-1,0.2l-0.9-0.7l-0.3-1
            l-1.1-0.2l-0.3-0.9l-0.1-1.1l-0.8-0.7l-0.8-0.7l-0.3-1l-0.1-1.1l-1-0.1l-1.3,0.3l-0.7-0.8l-0.5-1.2l0.3-1l0.7-0.9l0.6-0.9l0.1-1.1
            l0.1-1.3l-0.2-1l-0.3-1.2l-0.6-0.9l-0.9-0.5l-0.6-1.1l-0.9-0.8l-0.8,0.8l-0.1,1l-0.5,0.9l0.7,0.9l-0.9-0.4l-1.3,0.3l-0.8,0.9
            l-0.9,0.8l-0.4,1l0.3,1l-0.3,1l-0.5,1l0.4,0.9l-0.4,1l-0.2,1.1l-0.9,0.6l-0.9-0.3l0.3,1l0.1,1l-0.4,0.9l0.1,1l0.3,0.9l-0.6,0.9
            l-0.7-0.1l0.3,1.4l-0.9,0.6l0.3,0.7l0.1,0.3l0.6,0.9l0.9,0.4l0.3,1l0.3-1.2l0.3,1l0.3,0.4l0.9,0.4l0.7,0.9l0.3,1l-0.9-0.5l0.5,0.9
            h1l0.3,1l0.8,1.1l0.3,1l0.4,1.2l0.1,1l-0.2,1l-0.2,1l0.6,1l0.4-1l0.5,0.9l0.5,0.9l0.7-0.8v1l0.9-0.8l0.7-0.8l0.5-0.9l1-0.3l0.3-1
            l-0.2-1l0.9,1l0.4,0.9l0.9,0.7v1l0.4,0.9l0.3,0.9l0.2,1.1l0.3,1l0.4,1l0.3,0.9l0.3,1l0.1-1l0.3,1l0.5,0.9l0.4,1.1l0.1,1.1l0.1,1
            l0.5,0.9l-0.2,1.1l0.2,1l-0.5,0.9l0.2,1l0.2,1L820.8,318.5z'
              ></path>
              <path
                id='Mongolia'
                class={style.st2}
                d='M816.4,229.6l2.5-1.3l3-0.5l1.4,0.2l1-0.1l1.1-0.6l1-0.4l0.3-1l0.9-0.8l0.9-0.7l-0.5-0.9l-0.9-0.7l-0.3-0.9
            v-1.1l1.2-0.3l1.1,0.5l1.7,0.3l1-0.4l0.5-0.9l1.3-0.3l1-0.2l0.9-0.7l-0.1-1.1l0.9-0.8l0.9-0.5l1-0.2l1.1-0.2l1.1,0.1l1,0.1l0.1-1
            l-0.9-0.7l-0.9-0.7l-1-0.6l-1.1-0.6l-1-0.3l-1.1,0.6h-1l-1.3-0.1l-1,0.4l-0.9-0.6l-0.1-1l-0.3-1.4l-0.2-2l-0.3-1.2l-1.2-0.3
            l-1.4,0.3l-1.1-0.7l-1.6-0.3l-0.9,0.5l-0.9,0.9l-0.9,0.7l-1,0.3l-1.1,0.2l-0.9,0.4h-1.1h-1.1l-1-0.2l-1-0.2h-1.1l-0.9-0.6l-0.9-0.5
            l-0.8-0.7h-1.1l-0.9-0.6l-0.9-0.3h-1.1l-1-0.3l-1.5,0.1l-1,0.3l-1,0.2h-1.1l-1-0.3l-1.1-0.5l-0.9-0.7l-0.6-0.9l-0.8-0.8l-1-0.3
            l-1.1-0.2l-2-0.5l-1-0.3l-1.2-0.3h-1l-0.8,0.8l-0.2,1.1l0.4,0.9l0.7,0.8l0.8,0.9l-0.3,0.9l-0.9,0.6L789,207l-1-0.1l-1.1-0.3
            l-0.9,0.3l-1-0.2l-1.1-0.2l-0.9-0.7l-1.1-0.4h-1.5l-1-0.1l-1-0.3l-1.2,0.1l-1,0.3l-1,0.4l-0.9,0.6l-0.9,0.6l-0.6,0.9l-0.9,0.4
            l-1.1-0.1l-0.9,0.9l0.3,1l0.8,0.7l1,0.4l0.9,0.6l0.9,0.5l1-0.1l1,0.5l1,0.4l0.7,0.8l0.9,1l0.8,0.7l0.4,1l0.4,0.9l-0.1,1.9l0.7,0.7
            l1.2,0.3l1.4,0.2l1.1,0.1h1.1l1.1,0.2l1.3,0.7l1,0.3l1,0.6l1,0.3l0.9,0.6l1.2,1.2l0.7,0.9l1.4,1l2.1,0.4l5.3,0.6l0.9-0.3l3,0.4
            l1.2,0.2l0.8,0.7l2.6,0.6l1.7,0.7l1.6-0.3l1.2,0.7L816.4,229.6z'
              ></path>
              <path
                id='Nepal'
                class={style.st2}
                d='M789,272.2l-0.6-0.9l-0.2-1l0.2-1l-0.2-0.3l-1,0.1H786l-0.9-0.5l-1.1,0.2l-1-0.2l-0.7-0.8h-1l-0.6-0.9l-1-0.2
            l-0.9-0.6l-0.7-0.7l-0.9-0.3l-0.9-0.4l-0.9-0.7l-1-0.4l-0.9-0.7l-0.9-0.6l-0.8-0.4l-0.9,0.7l-0.9-0.2l-0.9,0.7l-0.3,1l-0.1,1.1
            l-0.3,1l0.9,0.6l1,0.3l1,0.4l0.8,0.7l0.9,0.7l1.2,0.3l1,0.3l0.9,0.7l1.1-0.1l1,0.3l1.6,0.1l0.6,0.9l1,0.4h1l0.7,0.8l1-0.1l0.9,0.4
            h1l1.2,0.3h1.1L789,272.2z'
              ></path>
              <path
                id='NorthKorea'
                class={style.st2}
                d='M866.5,240.5l0.9,0.3l1,0.2l0.2-1l0.9-0.6h1.1l0.8-0.9l-0.8-0.4l-1-0.7l-0.8-0.7l-0.5-0.9l0.4-0.9l1-0.3
            l0.7-0.9l0.6-0.9l0.4-1l-0.4-1l-0.3-0.9l0.3-0.9l0.9-0.6l-0.8-0.6l-0.9-0.6l-0.9-0.7v1.1l-0.9,0.6l-0.5,0.9h-1l0.4,1l-0.9,0.6
            l-1-0.2l-0.8-0.6l-0.8,0.7l-0.4,1.1l-0.8,0.8l-0.8,0.7l-0.7,0.8l-0.5,0.6l0.2,0.5l0.9,0.5l1,0.3l1,0.3l0.3,1v1l0.9,0.3l-0.9,0.6
            l-0.3,1l1.1,0.6l0.9,0.6L866.5,240.5z'
              ></path>
              <path
                id='Thailand'
                class={style.st2}
                d='M828.4,331l0.9-0.3l0.3-0.7l-0.1-0.2l-0.9-1l-1.2-0.5l-1-0.3l-1.2-1v-1l0.4,1l-0.3-1.4l-0.3-1.4l-0.9-0.9
            l-0.1-1l-0.6-0.9l-0.9-0.3l-0.5-0.9l-0.1-1l0.1-1.1l0.4-0.9l0.3-1.1l0.1-1l0.3-1l0.3-0.9l-0.2-1.1l0.3-1.1v-1.1l1-0.3l1,0.4l-0.1,1
            l0.1,1l1,0.1l1.1-0.2l0.8,0.6l0.8,0.9l1,0.4l0.4,1.1l-0.4-1l-0.3-1l-0.6-0.9l-0.2-1l-0.4-1.1l0.7-0.9l0.4-0.9l0.9-0.8l1-0.1l1,0.1
            l1-0.1l1.1,0.4l0.4-0.3l0.6-0.9l-0.1-1.1l0.1-1.1l-0.5-0.9l-0.6-0.8l-0.8-0.8l-0.6-0.9l-0.3-1.1l0.1-1l-0.9-0.8l-0.8-1l-0.7-0.9
            l-1.1-0.3l-0.9,0.6l-0.8,0.9l-1-0.3h-1l-0.8,0.7l-0.8,0.8l-0.7-0.8l0.3-1.1l-0.2-1l0.1-1l-0.3-1.1l-0.7-0.9l-0.9,0.3l-0.5-1l-0.1-1
            l-0.9-0.3l-0.3-0.2l-1.2,0.1l-0.8,0.7l-0.3,1l-1.1,0.2l-1-0.3l-0.4,1v1.2l0.3,1l-0.6,0.9l0.7,0.8l0.3,1l0.8,0.8l0.7,0.9l0.8,0.8
            l0.4,1.2l0.6,0.9l-0.5,0.9l0.1,1.1l-0.9,0.6l0.3,1l0.6,0.9l0.7,0.9l0.8,0.7l0.5,0.9l0.3,1v1l0.4,0.9l0.4,0.9l0.4,0.9l-0.1,1l-0.3,1
            l-0.5,0.9l-0.7,0.9l0.1,0.9l-0.2,0.9l-0.3,1l-0.2,1.1l-0.1,1l-0.2,1l0.3,1l1-0.1l0.8,0.9l0.6,0.8l0.4,1l0.9,0.5l0.3,1l0.7,0.9
            l0.2-0.8l0.9,0.7l0.9,0.6l0.3,1.2L828.4,331z'
              ></path>
              <path
                id='Vietnam'
                class={style.st2}
                d='M837.2,322.4l0.8-0.7l1-0.8l-0.4-0.9l1.3,0.6l-0.5-0.9l-0.7-0.9l1.2,0.9l-0.7-0.8l1.1,0.3l-0.5-0.9l0.9-0.5
            l0.9,0.5l0.9-0.6l0.8-0.7l0.9-0.6l0.9-0.6l0.6-0.8l0.2-1.1l-0.3-0.9l0.4-0.9l-0.3-1l-0.4-1l0.1-1l-0.3-1l-0.3-1l-0.4-1l-0.4-0.9
            l-0.7-0.8l-0.6-0.9l-0.7-0.9l-0.7-0.8l-0.9-0.6l-0.8-0.7l-0.8-0.9l-1.2-0.9l-0.6-0.9l-0.3-1l-0.9-0.5l-0.8-0.9l-0.6-0.9l0.3-1
            l-0.2-1l0.5-0.9l0.8-0.7l0.2-1.1l0.3-0.9l1-0.2l0.6-0.9l0.9-0.6l-1-0.3l-0.9-0.3L836,286l-0.8-0.7l-0.4-1.2l-0.3-0.9l-1-0.2l-1-0.2
            l-0.8-0.7l-1,0.3l-0.3,0.9l-1,0.3l-1,0.3l-1-0.3l-0.7,0.8l-0.9-0.6l-1,0.5l0.5,1l0.8,0.9l0.9,0.6l-0.2,1l0.7,0.8l0.9,0.6l1-0.2
            l1-0.2l0.9,0.6l0.5,0.9l0.8,0.8l-0.3,0.9l-1,0.3l-0.7,0.8l0.7,0.8l0.9,0.6l0.9,0.4l0.6,0.9l0.8,0.8l0.7,0.8l0.8,0.8l0.9,1l0.7,0.8
            l0.4,0.9l0.9,0.6l0.8,0.8l0.2,1l0.6,0.9l0.9,0.7l-0.2,1v0.5v0.4l-0.3,1.2l0.4,0.9l0.5,1.1l-0.3,1.1l0.4,1.2l-0.3,0.9l-1,0.5l-1,0.3
            l-0.9,0.6l-0.6,0.9l0.5,1l-1,0.3l-1.2,0.2l-0.5,1l-0.9,0.3h-0.1l0.3,0.3l0.9,0.6l0.1,1v1.2l0.1,1.3l0.9,0.7L837.2,322.4z'
              ></path>
            </g>
            <g id='AustralAsia'>
              <path
                id='Fiji'
                class={style.st2}
                d='M978.1,409.6l-0.6-0.9l-0.9-0.6l-0.7-0.9l-0.5-0.9l-0.8-0.7l-0.6-0.9l-1-0.7l-0.2,0.5l0.4,0.9l0.6,1l0.3,0.9
               l0.7,0.9l0.9,0.4l0.8,0.7l0.7,0.8l0.9,0.3L978.1,409.6z'
              ></path>
              <path
                id='Fiji2'
                class={style.st2}
                d='M974.1,377.2l-1-0.3l-0.9-0.6l-0.2,0.2l0.6,0.9l0.9,0.6l0.9,0.3L974.1,377.2z M970.8,375l-0.7-0.9l-1,0.1
               l-0.8-0.2l0.3,1l1,0.2L970.8,375z M972.4,373.4l-0.5-0.9l-0.8-0.7l0.2,1.1l0.5,0.9L972.4,373.4z M968.2,370.1l-0.9-0.5l-0.9-0.6
               l0.2,0.7l0.7,0.7l0.9,0.6l1,0.1L968.2,370.1z M962.6,366.9l-0.9-0.6l-0.3,0.3l0.7,0.9l1,0.3L962.6,366.9z'
              ></path>
              <path
                id='PapuaNewGuinea'
                class={style.st2}
                d='M946,377.5l0.5-1l-1-0.3l-0.9-0.5l0.4-1l-1-0.1l-0.9-0.6l-0.5-0.9l-1-0.4l-0.5-0.9l-0.1-1l-0.7-0.9l-0.8-0.7
               l-0.8-0.8l-0.2-0.9l0.3-1l1,0.1l0.8-0.8l-0.4-0.9l-0.9-0.5l-0.9-0.3l-0.9-0.6l-1-0.3l-0.6-0.9v-1.1l-0.6-0.8l-1-0.5l-0.6-0.9
               l-0.9-0.7h-1.1l-0.9-0.7l-0.9-0.5l-1-0.3l-0.9-0.4l-1-0.5l-0.9-0.4l-1.1-0.6h-0.3l-0.1,1.1l-0.1,1.7v1.1l-0.1,1.7l-0.1,1.1v1
               l-0.1,1.3l-0.1,1.2l-0.3,1l0.1,1.2l-0.2,1.6l-0.1,1l-0.1,1.4l-0.2,1l-0.1,0.9l1.1,0.1l1,0.3l1-0.2l1,0.3l0.9-0.5l0.9-0.5l-0.5-1.2
               l-1-0.5l-1-0.4l1.1,0.3l1.2,0.1l0.8-0.9h1l-0.2-1l1,0.1l1-0.3l0.8,0.7l1.1,0.4l1.1,0.3l0.7,0.8l0.4,0.9l0.3,1l0.8,0.9l0.8,0.8
               l0.4,1l0.9,0.6l1,0.1l1.1,0.1l1.1,0.2l1,0.3l0.8,0.8L946,377.5z M960.2,366.5l-0.4-1.1l-0.8-0.8l-0.5-0.9l-0.8-0.7l-0.2,0.2
               l-0.1,1l0.5,0.9l0.4,0.9l0.9,0.9L960.2,366.5z M946.3,365.2l1-0.2l0.9-0.5l0.9-0.5l0.7-0.8l1-0.2l-0.1-1.1l0.9-0.6l0.2-1l-0.3-0.9
               l-0.7,0.3l-1.1-0.3l0.3,1v1l-1,0.3l-0.6,0.8l-0.9,0.7l-1,0.1l-0.8-0.8l-0.9,0.6l-1.1,0.1l-1-0.1l-0.6,0.9l0.9,0.5l1.1,0.2l0.9,0.7
               L946.3,365.2z M953.8,359.5l-0.6-0.9l-0.9-0.7l-0.7-0.7l-0.9-0.8l-0.9-0.7l-0.9-0.5l-0.5,0.2l1,0.5l0.8,0.6l0.9,0.8l0.9,0.7
               l0.6,0.9l0.1,1.2l0.9,0.3L953.8,359.5z M940,353.3l-1.1-0.2h-0.1l-0.8,0.7h1L940,353.3z'
              ></path>
            </g>
            <g id='AustraliaNewZealand'>
              <g id='WestAustralia'>
                <path id='KangarooIsland' class={style.st5} d='M900.1,447.6v0.1l0.3-0.2L900.1,447.6z'></path>
                <polygon
                  id='KangarooIsland2'
                  class={style.st2}
                  points='895.8,448.1 894.7,448.3 893.8,449.3 894.9,449.2 895.8,449 896.9,448.7 896.1,448.1 		'
                ></polygon>
                <polygon id='KangarooIsland3' class={style.st2} points='943.4,418.1 942.8,418.9 942.5,420 943.3,418.8 943.8,417.4 		'></polygon>
                <polygon id='TiwiIslands' class={style.st2} points='896.9,379.9 896.4,379.8 896.3,380.9 897.4,380.8 		'></polygon>
                <polygon
                  id='TiwiIslands2'
                  class={style.st2}
                  points='898.2,381.2 899.2,380.5 899.4,379.3 898.4,379.5 897.3,379.4 897,379.6 897.4,380.6 		'
                ></polygon>
                <path
                  id='WesternAustralia'
                  class={style.st2}
                  d='M899.2,382.2l-1-0.1l-0.3,1l-0.9-0.7l-0.7,0.9l-0.7,0.9l0.1,1.1l-1,0.6l-0.3,1l-0.8,0.7v1l0.7,0.9l-1,0.3
               l-0.8,0.7l-0.5-0.9l-1.1-0.2l-0.7,0.8l-0.5,0.9l0.3-0.9l0.4-1l-0.7-0.8l-0.6-0.9l-0.8-0.6l-0.9-0.4l-0.9,0.6l-0.9,0.6l-0.4,0.9
               l-1,0.3l0.3-1l-0.7,0.8l-0.8,0.7l0.2,1l-1.1-0.1l0.4,0.9l-1.1-0.1l-0.7,0.7l0.3,1l-0.2,1l-0.9-0.4l-1,0.3l-0.6,0.9l0.8,0.7
               l-0.7,0.8L878,397l-0.3-0.9l-0.3-1l-0.3-1l-0.6,0.9l-0.9,0.5l-0.8,1.1l-0.1,1.1l0.2,1l-0.9,0.7l-0.8,0.8l-0.6,0.9L872,402
               l-0.9,0.9l-1.3,0.7l-1.1,0.4l-1,0.2l-1.1,0.1l-1,0.3l-1,0.6l-1,0.2l-1.4,0.9h-1l-1-0.2l-0.9,0.6l-0.9,0.5l-0.8,0.7l-1,0.8
               l-1.4,0.5l-0.9,0.8l-0.6,0.9l-0.9,0.7l0.1-1l0.4-1l-0.8,0.8l-0.5,0.9l-0.3,1l-0.2,1.1l-0.2,0.9l-0.8,0.9l-0.4,1.1l-0.3,1l0.3,1.2
               l0.3,1l0.3,1l0.1,1l-0.9,0.7l-0.2-1l-0.5-0.9v1l0.5,1.1l-0.9,0.7l-0.5-1l0.3,1l0.4,1l0.3,1.4l-0.2,1.1v1l0.4,0.9v1.1l0.3,1.4
               l-0.3,1l-0.2,1.1l-0.2,1l0.1,1l0.2,1.2l0.2,1.4l-0.3,1l-0.3,1l-0.5,0.9l-0.2,1.1l-0.8,0.9l-1.2,0.1l-0.4,1v1.3l0.9,0.3l0.8,1.1
               l1.1,0.5l1,0.1l1,0.3l1-0.3l1-0.3l0.8-0.6l0.9-0.7l1.1-0.2l0.8-0.8l0.9-0.4h1l1.2-0.3l1.1-0.1l1.3,0.1l1,0.2h1.2h1l1-0.3l0.8-0.7
               l0.6-0.9l0.8-0.8l1.1-0.3l0.9-0.5l1.2-0.5l0.9-0.6l1.1-0.2l1.2,0.2l1.1-0.1l1.2-0.4l1.2-0.3l1.5-0.7l1.4-0.3l1-0.1l1.4-0.1h1.2
               l1.3-0.2l1,0.9l0.9,0.5l1.1-0.1l0.9,0.6l1.1,0.2l0.7,0.8l-0.5,0.9v1.1l0.9,0.4l0.1,1.1l0.2,1l-0.5,0.9l0.3,0.9l0.9,0.6l0.3-1
               l0.8-0.8l0.9-0.9l0.9-0.5l0.9-0.4l0.9-0.7l0.6-0.8l0.9-0.7l0.7-0.7l-0.1,1l-0.7,0.8l-0.6,0.9l-0.7,0.7l-0.6,0.9l-0.3,1l-0.6,0.9
               l-1.1,0.2l-0.6,0.9l1-0.3h1l0.7-0.9l0.5-0.9l1-0.5l0.2,1.3l-0.5,0.9l-0.6,0.9l-0.8,0.8l1-0.2l1-0.3l0.7-0.2c0-17.9,0-50.7,0-65.5
               L899.2,382.2z'
                ></path>
                <path
                  id='Adelaide'
                  class={style.st2}
                  d='M899.4,448.2l0.5,1L899.4,448.2l0.4,1l0.2,1.1l-0.6,1.4l-0.4,1l0.5,1.4l0.5,0.9h0.1c0-2,0-4.5,0-7.4L899.4,448.2z'
                ></path>
              </g>
              <g id='EastAustralia'>
                <path
                  id='EasternAustralia'
                  class={style.st2}
                  d='M941.6,417.4l-0.5-0.9l-0.8-1.1l-0.7-0.8l-0.5-0.9l0.3-1.1l0.3-1l-0.9-0.5l-0.9-0.8l-0.2,1.1l-0.6-0.8l-0.2-1
            l0.2-1.1l-0.3-1l-0.3-0.9l-0.5-0.9l0.5-0.9l-1-0.3l-0.8-0.7l-0.7-0.9l-0.9-0.5l-0.9-0.6l-0.8-0.8l0.3-1l-0.6-0.9l0.4-1l0.2-1
            l-0.1-1l-0.4-1.2l-0.5-0.9l0.3-1l-0.1-1v-1.1l0.3-1.1l-0.8-0.8l-0.6-0.9l-1-0.3l-0.9,0.5l-0.1-1.1l-0.2-1l0.1-1l-0.3-1.3v-1l-0.3-1
            l-0.4-0.9l0.1-1v-1.2l-0.4-0.9h-0.3l-0.8,0.7l-0.2,1.1l-0.9,1.8l-0.6,0.9l0.1,1.2l-0.6,0.9l-0.2,1.1l-0.5,0.9l0.2,1.3l-0.3,0.9
            l0.1,1.1l-0.3,1l-0.5,0.9l-0.3,1.3l-0.5,1l-0.5,0.9l-0.6,0.9l-0.5,1l-0.9,0.5l-1,0.3l-1.1-0.6l-0.9-0.6l-0.3-1.1l-1-0.3l-1-0.4
            l-0.6-0.9l-0.9-0.5l-0.8-0.7l-0.9-0.4l-0.6-0.9l-0.9-0.7l-0.9-0.8l0.4-1l0.9-0.9l0.5-0.9l-0.2-1l0.6-0.9l1-0.1l0.2-1.1l0.8-0.7
            l0.4-0.9l-0.7-0.8l-0.6,0.9l-0.9,0.5l0.3-1l-0.9,0.6l0.3-1l-0.9,0.5l-0.9,0.3l-0.9-0.5l-1-0.3l-0.8-0.6h-1l-0.8-0.7l-1-0.2
            l-0.8-0.8l-0.9,0.5l1,0.3l0.5,0.9l0.2,1l-1,0.3h-1.1h-0.2c0,14.9,0,47.6,0,65.5l0.3-0.1l-0.3,0.2c0,2.9,0,5.4,0,7.4l1,0.2l1,0.3
            l0.9,0.4l1,0.3l0.9,0.7l1.1-0.1l0.9-0.6l0.9-0.5l1-0.9h1.2l-0.3,1l0.9-0.5l-0.3,1l0.5,0.9l1,0.4l0.4-1l1-0.2l1-0.7l1.4-0.9l0.9-0.4
            l1-0.3l1.1-0.1h1.3l1-0.4l0.9-0.7l0.5-0.9l0.5-0.9l0.6-0.9l0.5-1l0.7-0.8l0.8-0.8l0.6-0.8l0.9-0.5l0.4-0.9l0.6-0.9l0.9-0.8l0.7-0.9
            l0.6-0.9l0.8-0.7l0.7-0.8l0.9-0.7l0.9-0.5l0.9-0.7l0.4-0.9l0.8-0.7l0.7-0.9l0.6-0.9l0.4-1l0.6-1l0.6-0.9l0.6-0.9l0.5-0.9l0.5-0.9
            l0.7-0.9l0.3-1l0.3-1l0.2-1l-0.2-1.1l0.4-0.9l0.2-1.1l0.3-1.1l0.3-1.3l0.2-1.1l-0.5-0.9L941.6,417.4z'
                ></path>
              </g>
              <polygon
                id='Tasmania'
                class={style.st2}
                points='911.6,462.9 910.5,463.4 909.4,463.5 908.4,463.8 907.3,463.4 906.6,462.7 905.5,462.6 905,463
            904.6,464.1 904.4,465.2 904.6,466.3 904,467.2 903.8,468.2 904,469.2 904.8,469.8 904,470.4 905,470.4 906.1,470.4 906.7,469.4
            907.7,469.1 908.6,468.5 908.5,469.5 909.1,468.6 909.7,467.8 910.4,466.9 911.3,466.5 911.8,465.5 912.3,464.6 912.7,463.7
            912.5,462.7 	'
              ></polygon>
              <path
                id='NewZealand'
                class={style.st2}
                d='M946.3,480.2l0.1-1l-0.7,0.4l-0.4,0.9L946.3,480.2z M950.4,478.8l0.9-0.6l1.2-0.6l0.9-0.5l0.9-0.7l0.9-0.7
               l0.9-0.8l0.9-0.7l0.6-0.9l0.6-0.9l0.9-0.7l1,0.1l0.8-1.1l1.4,0.4l1.2-0.1l-0.4-1l0.9-0.7l1.2-0.5l0.9-0.6l0.9-0.8l1-0.6l0.8-0.7
               l0.7-1h0.1l0.7-0.9l0.4-0.9l-0.9,0.6l0.6-0.9l-1,0.4h-1l-0.2-1l0.8-0.7l-1.1,0.3l-1,0.7l-0.7,0.8l-0.9,0.9l-0.8,0.7l-1,0.4
               l-0.9,0.8l-0.9,0.9l-1,0.8l-0.9,0.5l-1,0.5l-0.9,0.4L957,470l-1,0.4l-1.1,0.3l-0.9,0.5l-1.2,0.3l-0.9,0.5l-0.9,0.6l-1.1,0.7
               l-0.9,0.5l-1,0.6l-0.9,0.6l-0.7,0.9l-0.7,0.8v1l1-0.3l0.9,0.6l0.9,0.6l1.2,0.2L950.4,478.8z M973.3,464.6l0.9-0.5l1-0.9l0.9-0.7
               l0.9-0.6l0.9-0.7l0.8-0.7l0.6-0.9l0.3-0.6l0.8-0.7l0.9-0.4l0.9,0.4l0.7-1l0.9-0.6l0.9-1.1l0.7-0.9l0.3-1.1l-1.1,0.3l-0.9,0.6
               l-0.9,0.3l-1-0.3l-0.9-1.1l0.2-1l-0.3-0.9l-1,0.4l-0.9-0.3l0.6-0.9l0.5-0.9l-0.3-1.2l0.9-0.6v-1l-0.9-0.5l-1-0.4l-0.6-1l0.3,1.1
               l-0.5,0.9l1,0.3L978,448l0.6,1.4l0.5,0.9l-0.7,0.9l-0.2,1.2l-0.2,1l-0.5,1.1l-0.7,0.9l-0.6,0.9l-0.8,1l-1.1,0.4l-1.2,0.5l-0.3,1.1
               l0.8,0.8l0.9,0.6l-0.3,1l-0.7,0.8l-0.9,0.8l-0.7,0.9l0.4,0.9L973.3,464.6z'
              ></path>
            </g>
            <path
              id='PortAuxFrancais'
              class={style.st2}
              d='M729.8,487.1l1-0.2l0.9,0.5l-0.4-0.9l1.1,0.3l0.3-1l-1.1,0.3l-0.9-0.3l-0.8-0.6l0.2-0.4l-0.6,0.9l-0.3,1
               l-0.3,1L729.8,487.1z'
            ></path>
            <path id='SandwichIslands' class={style.st2} d='M507.5,500.6l-0.8-0.8l-0.9-0.5l-1.2-0.2l-1-0.2v0.4l1,0.3l1,0.3l0.9,0.5h1V500.6z'></path>
          </g>
        </g>
        <g id='FlottecLocations'>
          <g id='flottec-usa'>
            <g id='flottec-usa-logo' class={style.logoFlotUsa} onMouseOver={() => inFlotUsa()}>
              <a href='#' target='_blank' rel='noopener noreferrer'>
                <image x='85' y='30' width='150' height='75' href={logoFlottecUsa}></image>
              </a>
            </g>
            <line class={`${style.stUSAFat} ${style.allLines}`} x1='210' y1='100' x2='355' y2='260'></line>
          </g>
          <g id='chemiqa'>
            <g id='chemiqa-logo' class={style.logoChemiqa} onMouseOver={() => inChemiqa()}>
              <a href='//flottec.ca' target='_blank' rel='noopener noreferrer'>
                <image x='380' y='0' width='150' height='75' href={logoChemiqaLite}></image>
              </a>
            </g>
            <line class={`${style.stCaFat} ${style.allLines}`} x1='450' y1='70' x2='417' y2='210'></line>
          </g>
          <g id='flottec-chili'>
            <g id='flottec-chile-logo' class={style.logoFlotChil} onMouseOver={() => inFlotChil()}>
              <a href='//flottec.cl' target='_blank' rel='noopener noreferrer'>
                <image x='130' y='485' width='160' height='50' href={logoFlottecChile}></image>
              </a>
            </g>
            <line class={`${style.stClFat} ${style.allLines}`} x1='300' y1='500' x2='418' y2='445'></line>
          </g>
          <g id='flottec-mexico'>
            <g id='flottec-mexico-logo' class={style.logoFlotMex} onMouseOver={() => inFlotMex()}>
              <a href='//flottec.mx' target='_blank' rel='noopener noreferrer'>
                <image x='0' y='380' width='160' height='50' href={logoFlottecMexico}></image>
              </a>
            </g>
            <line class={`${style.stMxFat} ${style.allLines}`} x1='170' y1='405' x2='343.4' y2='290.2'></line>
          </g>
        </g>
      </svg>
    </div>
  );
};
