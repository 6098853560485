.mdsSdsDiv {
  flex: 1;
  min-height: 75vh;
  width: 100%;
  padding: 10vh 5vw;
  font-size: calc(6px + 1vw);
  background: white;
  .allProdsDiv {
    width: 100%;
    .catDivHide {
      max-height: calc(20px + 1.5vw) !important;
    }
    .catDiv {
      padding-bottom: calc(8px + 1.3vw);
      max-height: 4000px;
      overflow: hidden;
      transition: max-height 0.5s ease-in;
      .catTitle {
        white-space: nowrap;
        user-select: none;
        cursor: pointer;
        h3 {
          display: inline-block;
          margin: 0;
          padding: 0;
          font-size: calc(8px + 1.5vw);
        }
        .catArrow {
          display: inline-block;
          color: #555;
          margin-left: 1.2vw;
          transition: transform 1s;
        }
        .catArrowRotate {
          transform: rotate(90deg);
        }
      }
      .subcatDiv {
        padding: 1.5vh 5vh 0;
        xxxmax-height: 1200px;
        overflow: hidden;
        transition: max-height 0.5s ease-in;
        .subcatTitle {
          white-space: nowrap;
          user-select: none;
          cursor: pointer;
          h4 {
            display: inline-block;
            margin: 0;
            padding: 0;
            color: #666;
            margin-bottom: 1vh;
            font-weight: 200;
          }
          .subcatArrow {
            display: inline-block;
            margin-left: 1.2vw;
            color: #555;
            transition: transform 1s;
          }
          .subcatArrowRotate {
            transform: rotate(90deg);
          }
        }
        .prodsList {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          .prodDiv {
            min-width: 17.5%;
            width: 300px;
            padding: 1vh 0;
            margin-right: 5px;
            text-align: center;
            white-space: nowrap;
            border: 1px solid #55555511;
          }
        }
      }
      .prodsList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: calc(5px + 1vw) 0 0;
        .prodDiv {
          min-width: 17.5%;
          width: 300px;
          padding: 1vh 0;
          margin: 5px;
          text-align: center;
          white-space: nowrap;
          border: 1px solid #55555511;
          .twofer {
            display: flex;
            font-size: 0.6em;
            & > div {
              flex: 1;
            }
            .prodPage {
              text-align: left;
            }
            .prodPdf {
              text-align: right;
              .mustEmailDiv {
                color: #3f51b5;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}
.firstProdsList {
  display: block !important;
}
.mustEmailDlg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  font-size: 5rem;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .mustEmailInner {
    position: relative;
    max-width: 60vw;
    padding: 10vh;
    font-family: 'Segoe UI Light', 'Segoe UI', Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    background: white;
    .meCloseX {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 2rem;
      padding: 0 15px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      &:hover {
        color: #3f51b5;
        border: 1px solid #3f51b5;
      }
    }
    .meBody {
      text-align: center;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.subcatDivHide {
  max-height: calc(20px + 1.5vw);
  overflow: hidden;
}
.prodWarn {
  // color: #3f51b5;
  cursor: pointer;
  &:hover {
    color: #004;
  }
}
.catPDF {
  display: none !important;
  padding: 0 2vw;
  font-size: 0.8em;
  a {
    color: #3f51b5;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.pubPdfDiv {
  padding: 1vh 2vw;
  display: inline-block;
}
.lookLikeAnchor {
  color: #3f51b5;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.inlineLinkDiv {
  display: inline-block;
  cursor: pointer;
  color: #3f51b5;
  border-bottom: 1px solid transparent;
  &:hover {
    color: #223392;
    border-bottom: 1px solid #223392dd;
  }
}
.msgEmailOrLogin {
  touch-action: inherit;
}
.prodDivA {
  color: #444;
  text-decoration: none;
  &:hover {
    color: #115;
    text-decoration: none;
  }
  a {
    color: #444;
    text-decoration: none;
    &:hover {
      color: #115;
      text-decoration: none;
    }
  }
}
