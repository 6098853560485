.backdropDiv {
  position: relative;
  xtop: 58px;
  left: 0;
  min-height: 77vh;
  width: 100vw;
  /*background: url(../../../user_uploads/img/bgFlot02.webp) center center / cover no-repeat;*/
  .productDiv {
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 77vh;
    padding: calc(15px + 5vw) calc(10px + 10vw) 0;
    color: #444 !important;
    font-size: 1.3rem;
    background: rgba(230, 230, 230, 0.85);
    h1 {
      margin: 0;
      text-align: center;
    }
  }
}
.prodLabel {
  font-size: calc(8px + 1vw);
  font-weight: bold;
}
.prodData {
  color: #0a3c82;
  font-size: calc(8px + 1vw);
}
.prodTable {
  display: grid;
  grid-row-gap: 1.3ch;
  grid-template-columns: repeat(1fr);
  grid-template-areas:
    'title'
    'cat'
    'desc'
    'pdf';
}

.prodTitle {
  grid-area: title;
  font-size: calc(8px + 1vw);
  span {
    color: #0a3c82;
    font-size: calc(14px + 1vw);
  }
}
.prodCat {
  grid-area: cat;
}
.prodDesc {
  grid-area: desc;
}
.prodPdf {
  grid-area: pdf;
  .prodData {
    a {
      color: #0a3c82;
      font-size: calc(6px + 1vw);
      line-height: calc(10px + 1vw);
      text-decoration: none;
      &:hover {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
.mustEmailDlg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  font-size: 5rem;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .mustEmailInner {
    position: relative;
    max-width: 60vw;
    padding: 10vh;
    font-family: 'Segoe UI Light', 'Segoe UI', Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    background: white;
    .meCloseX {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 2rem;
      padding: 0 15px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      &:hover {
        color: #3f51b5;
        border: 1px solid #3f51b5;
      }
    }
    .meBody {
      text-align: center;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.subcatDivHide {
  max-height: 8vh !important;
  overflow: hidden;
}

.prodWarn {
  color: #3f51b5;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.mustEmailDiv {
  color: #3f51b5;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
@media (max-width: 999px) {
}
@media (max-width: 399px) and (min-height: 549px) {
  .backdropDiv .productDiv {
    padding-top: 60px;
  }
}
