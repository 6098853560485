.networkDiv {
  font-family: 'europa', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  .pageData {
    color: #444 !important;
    background: white;
    .topStuff {
      display: flex;
      padding: 7vh 0vw 0 12vw;
      .pageContent {
        width: 75%;
        font-size: calc(3px + 1vw) !important;
        .pageText {
          h1 {
            margin: calc(4px + 1vw) 0 0 0;
            color: rgb(0, 102, 204);
          }
          p {
            line-height: calc(12px + 1vw);
            font-size: calc(6px + 1vw);
          }
          p:last-of-type {
            padding-bottom: 0;
          }
        }
      }
      .clockDiv {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 25%;
        padding-left: 3vw;
      }
    }
    .svgMap {
      width: 80%;
      padding: 0 4vw 0 12vw;
    }
    .locationsDiv {
      padding: 5vh 12vw;
      .anchorDiv {
        margin-top: 2em;
        a {
          color: #0a3c82;
          text-decoration: none;
          &:hover {
            color: #888 !important;
          }
          &:visited {
            color: #0a3c82;
          }
        }
      }
      .furtherInfo {
        margin-top: 2em;
      }
    }
  }
}
.smaller {
  font-size: 0.8em;
}
.blurbText {
  color: #667;
  line-height: calc(12px + 1vw);
  font-size: calc(6px + 1vw);
}
.furtherInfo {
  font-style: italic;
}
.timedate {
  margin: 30px 0;
  padding: 20px;
  text-align: left;
  color: #2d2d2d;
  border-left: 3px solid #999;
  font: small-caps lighter 32px/130% 'Segoe UI', Frutiger, 'Frutiger Linotype',
    'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: calc(12px + 1vw);
}
.inlineDateDiv {
  display: inline-block;
  font-size: calc(4px + 1vw);
}
.dateDiv {
  font-size: calc(4px + 1vw);
}
@media (max-width: 1200px) {
  .blurbText,
  .pageText p {
    font-size: calc(9px + 1vw);
    line-height: calc(14px + 1vw);
  }
}
