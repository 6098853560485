import { h, FunctionalComponent, Fragment } from 'preact';
import style from './product.scss';
import { get } from '../../utils/ajax';
import { AppContext } from '../../store/store';
import { useEffect, useState, useContext } from 'preact/hooks';
/*  Called from index.tsx  */

interface ProductProps {
  api: string;
  catid: string;
  subcatid: string;
  prodid: string;
  onLogin(): void;
}

interface Category {
  id: number;
  sort_order: number;
  name_en: string;
  name: string;
  desc: string;
  pdf: string;
}

interface Prodface {
  cat_id: number;
  sort_order: number;
  name_en: string;
  name: string;
  desc: string;
  pdf: string;
  subcat_id: number;
  regions: string;
  class: string;
  family: string;
  mineral: string;
  desc_chem: string;
  prod_prefix: string;
}

export const Product: FunctionalComponent<ProductProps> = ({ api, catid, subcatid, prodid, onLogin }) => {
  const { userData, language: lang } = useContext(AppContext);
  const [allowProductDL, setAllowProductDL] = useState<string | undefined>(undefined);
  const [mustEmailLink, setMustEmailLink] = useState<string | undefined>(undefined);
  const [supportEmail, setSupportEmail] = useState('');
  const [pleaseClickBlurb, setPleaseClickBlurb] = useState('Please click below to request data sheet for ');
  const [sendEmailBlurb, setSendEmailBlurb] = useState('send email');
  const [emailForInformationBlurb, setEmailForInformationBlurb] = useState('Product data not available online. Please email for more information.');
  const [requestAccessBlurb, setRequestAccessBlurb] = useState('Solicitar acceso a la ficha técnica del producto');
  const [viewDatasheetBlurb, setViewDatasheetBlurb] = useState('View product datasheet');
  const [productData, setProductData] = useState<Prodface | undefined>(undefined);
  const [catData, setCatData] = useState<Category | undefined>(undefined);
  const handleMustLogin = () => {
    onLogin();
  };
  const handleMustEmailLink = (id: any) => {
    setMustEmailLink(id);
  };

  /* *********************************************************************** */
  useEffect(() => {
    get('/getScratchpadValue?key=allowProductDL').then((apd) => {
      setAllowProductDL(apd.value);
    });
    if (api === 'com') {
      //caller: Autocomplete.tsx
      get<Prodface>(`/getIndividualProduct?prodid=${prodid}&lang=${lang}`).then((prodFields) => {
        setProductData(prodFields);
      });
      get<Category>(`/getCatData?catId=${catid}&lang=${lang}`).then((catData) => {
        setCatData(catData);
      });
    } else if (api === 'sub' || api === 'pdts') {
      //caller: Subcategories.tsx -or- Products.tsx
      get<Prodface>(`/getIndividualProduct?prodid=${prodid}&lang=${lang}`).then((prodFields) => {
        setProductData(prodFields);
      });
      get<Category>(`/getCatData?catId=${catid}&lang=${lang}`).then((catData) => {
        setCatData(catData);
      });
    }
    setSendEmailBlurb(lang === 'en' ? 'send email' : 'enviar correo electrónico');
    setEmailForInformationBlurb(
      lang === 'en' ? 'Product data not available online. Please email for more information.' : 'Por favor, mándame un e-mail para más información.'
    );
    setPleaseClickBlurb(
      lang === 'en' ? 'Please click below to request data sheet for ' : 'Haga clic a continuación para solicitar la hoja de datos del '
    );
    setRequestAccessBlurb(lang === 'en' ? 'Request access to product datasheet' : 'Solicitar acceso a la ficha técnica del producto');
    setViewDatasheetBlurb(lang === 'en' ? 'View product datasheet' : 'Ver hoja de datos del producto');
  }, [lang, prodid]);
  /* *********************************************************************** */
  useEffect(() => {
    get('/getScratchpadValue?key=support_email').then((apd) => {
      setSupportEmail(apd.value);
    });
    //Do not remove below console log (more effort/risk to remove var than to leave in the clog):
    console.log(subcatid);
  }, []);
  /* *********************************************************************** */

  return (
    <div class={style.backdropDiv}>
      <div class={style.productDiv}>
        {productData !== undefined && (
          <Fragment>
            <h1>Product:</h1>
            <div class={style.prodTable}>
              <div class={style.prodTitle}>
                <span>{`${productData?.prod_prefix} ${productData?.name}`}</span>
              </div>

              <div class={style.prodCat}>
                <div class={style.prodLabel}>{lang === 'en' ? 'Category' : 'Categoria'}</div>
                <div class={style.prodData}>{catData?.name}</div>
              </div>

              {productData?.desc !== undefined && productData?.desc.length > 0 && (
                <div class={style.prodDesc}>
                  <div class={style.prodLabel}>{lang === 'en' ? 'Description' : 'Descripción'}</div>
                  <div class={style.prodData}>{productData?.desc}</div>
                </div>
              )}

              <div class={style.prodPdf}>
                <div class={style.prodLabel}>{lang === 'en' ? 'MSDS/SDS' : 'HDS/HDSDM'}</div>
                <div class={style.prodData}>
                  {allowProductDL !== undefined && productData?.pdf !== null && productData?.pdf !== 'null' && productData?.pdf !== '' ? (
                    allowProductDL === 'always' ? (
                      <a href={`/uploads/files/${productData?.pdf}`} target='_blank' rel='noopener noreferrer'>
                        {viewDatasheetBlurb}
                      </a>
                    ) : allowProductDL === 'never' ? (
                      <div class={style.mustEmailDiv} onClick={() => handleMustEmailLink(productData?.name_en + '')}>
                        {requestAccessBlurb}
                      </div>
                    ) : userData?.authenticated ? (
                      <a href={`/uploads/files/${productData?.pdf}`} target='_blank' rel='noopener noreferrer'>
                        {viewDatasheetBlurb}
                      </a>
                    ) : (
                      <div class={style.prodWarn} onClick={handleMustLogin}>
                        {viewDatasheetBlurb}
                      </div>
                    )
                  ) : productData?.pdf !== null && productData?.pdf !== 'null' && productData?.pdf !== '' ? (
                    <div class={style.mustEmailDiv} onClick={() => handleMustEmailLink(productData?.name_en + '')}>
                      {emailForInformationBlurb}
                    </div>
                  ) : (
                    <div class={style.mustEmailDiv} onClick={() => handleMustEmailLink(productData?.name_en + '')}>
                      {emailForInformationBlurb}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {mustEmailLink !== undefined && (
              <div class={style.mustEmailDlg}>
                <div class={style.mustEmailInner}>
                  <div class={style.meCloseX} onClick={() => setMustEmailLink(undefined)}>
                    {' x '}
                  </div>
                  <div class={style.meBody}>
                    {`${pleaseClickBlurb} ${mustEmailLink}`}
                    <div class={style.meEmail}>
                      <a
                        href={`mailto:${supportEmail}?subject=MSDS/SDS Request for product ${mustEmailLink} [${lang}]&body=I am writing to request access to the ${
                          lang === 'en' ? '' : '[ Spanish ] '
                        }MSDS/SDS datasheet for product #${mustEmailLink}.%0D%0A%0D%0ATo verify my request, I can be contacted at:%0D%0A%0D%0A[ Telephone ]%0D%0A[ Email ]%0D%0A[ Twitter ]%0D%0A%0D%0AThank you,%0D%0A[ Full Name ]%0D%0A[ Job Title ]%0D%0A[ Company ]`}
                      >
                        {sendEmailBlurb}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};
export default Product;
