import { h, FunctionalComponent } from 'preact';
import style from './footer.scss';
// import { useLocation } from 'wouter-preact';
import { useEffect, useState } from 'preact/hooks';
import { get } from '../../utils/ajax';

interface FooterProps {
  onContact(contactType: string): void;
}
export const Footer: FunctionalComponent<FooterProps> = ({ onContact }) => {
  // const [currLoc] = useLocation();
  const [chemiqaAddress, setChemiqaAddress] = useState<string[] | undefined>(undefined);
  // if (currLoc === '/cms' || currLoc === '/CMS') {
  //   return null;
  // }
  /* *********************************************************************** */
  useEffect(() => {
    get<string>('/getFooterAddress').then((data: any) => {
      // console.log('Footer.tsx useEffect /getScratchpadValue');
      const chemAddress = JSON.parse(data);
      setChemiqaAddress(chemAddress.chemiqa.split('|'));
    });
  }, []);
  /* *********************************************************************** */
  return (
    <div id='footerDiv' class={style.footerDiv}>
      <div class={style.footLeft}>
        <div class={style.title}>Partners</div>
        <div class={style.logos}>
          <div class={style.logo}>
            <a href='http://flottec.com' target='_blank' rel='noopener noreferrer'>
              <img src={'/img/Flottec_LOGO_USA_175.png'} />
            </a>
          </div>
          <div class={style.logo}>
            <a href='http://flottec.mx' target='_blank' rel='noopener noreferrer'>
              <img src={'/img/Flottec_LOGO_Mexico_175.png'} />
            </a>
          </div>
          <div class={style.logo}>
            <a href='https://flottec.cl' target='_blank' rel='noopener noreferrer'>
              <img src={'/img/Flottec_LOGO_Chile_175.png'} />
            </a>
          </div>
        </div>
      </div>
      <div class={style.footMiddle}>
        <div class={style.title}>Site Map</div>
        <div class={style.sitemapDiv}>
          <div class={style.sitemapRow}>
            <div class={style.siteLink}>
              <a href={'/'}>Home</a>
            </div>
            <div class={style.siteLink}>
              <a href={'/products'}>Products</a>
            </div>
            <div class={style.siteLink}>
              <a href={'/services'}>Services</a>
            </div>
            <div class={style.siteLink}>
              <a href={'/sds'}>MSDS/SDS</a>
            </div>
            <div class={style.siteLink}>
              <a href={'/network'}>Network</a>
            </div>
            <div class={style.siteLink}>
              <a href={'/company'}>Company</a>
            </div>
            <div class={style.siteLink} onClick={() => onContact('contact')}>
              <a href='#'>Contact&nbsp;Us</a>
            </div>
          </div>
          <div class={style.sitemapRow}>
            <div class={style.siteLink}>
              <a href={'http://flottec.com'}>Flottec USA</a>
            </div>
            <div class={style.siteLink}>
              <a href={'http://flottec.mx'}>Flottec Mexico</a>
            </div>
            <div class={style.siteLink}>
              <a href={'http://flottec.cl'}>Flottec Chile</a>
            </div>
          </div>
        </div>
      </div>
      <div class={style.footRight}>
        <div class={style.title}>
          <a href='#' onClick={() => onContact('contact')}>
            <span class={style.atagContact}>Contact</span>
          </a>
        </div>
        <div class={style.contactDiv}>
          {chemiqaAddress !== undefined &&
            chemiqaAddress.map((d) => {
              return <div>{d}</div>;
            })}
        </div>
      </div>
      {/* console.log('<!-- HEY!!!!! *** REMEMBER *** to `npm run build` before publish -->') */}
      <div class={style.version}>0.02402111347</div>
      {/* console.log('<!-- HEY!!!!! *** REMEMBER *** to `npm run build` before publish -->') */}
    </div>
  );
};
