import { h, FunctionalComponent, Fragment } from 'preact';
import { useContext } from 'preact/hooks';
import style from './header.scss';
import { ActiveLink } from '../ActiveLink/ActiveLink';
import { IconSignOut } from '../IconSignOut';
import { AppContext, Language } from '../../store/store';
import { IconSignIn } from '../IconSignIn';
import { useLocation, Link } from 'wouter-preact';
import { Autocompleter } from '../Autocompleter/Autocompleter';

interface HeaderProps {
  onLogin(): void;
  onLangChange(lang: Language): void;
}

export const Header: FunctionalComponent<HeaderProps> = ({ onLogin, onLangChange }) => {
  const [location] = useLocation();
  const { userData, language: lang } = useContext(AppContext);
  const handleChangeLang = (value: Language) => {
    onLangChange(value);
  };
  return (
    <Fragment>
      <header class={style.navHeader}>
        {location !== '/' ? (
          <div class={style.logoLeft}>
            <Link href='/'>
              <a class={style.clientLogoALink}>
                <img height='35' src='/img/Flottec_LOGO_White_Canada_175.png'></img>
              </a>
            </Link>
            <Autocompleter />
          </div>
        ) : (
          <Autocompleter />
        )}
        <nav>
          <ActiveLink href='/products' exact>
            {lang === 'en' ? 'Products' : 'Productos'}
          </ActiveLink>
          <ActiveLink href='/services' exact>
            Services
          </ActiveLink>
          <ActiveLink href='/sds' exact>
            {lang === 'en' ? 'MSDS/SDS' : 'HDS/HDSDM'}
          </ActiveLink>
          <ActiveLink href='/network' exact>
            {lang === 'en' ? 'Network' : 'Red Global'}
          </ActiveLink>
          <ActiveLink href='/company' exact>
            {lang === 'en' ? 'Company' : 'Compañía'}
          </ActiveLink>
          {/* {authenticated && (
            <ActiveLink href='/cms' exact>
              <span class={style.btnCms}>CMS</span>
            </ActiveLink>
          )} */}
          {userData?.authenticated ? (
            <a href='/logoff' title='Sign Out'>
              <IconSignOut size={2} />
            </a>
          ) : (
            <a title='Sign In' onClick={() => onLogin()}>
              <IconSignIn size={2} />
            </a>
          )}
        </nav>
        {location !== '/cms' && (
          <div class={style.flagsLangDiv}>
            <div class={style.flagsDiv}>
              <a href='https://flottec.com'>
                <img src='/img/flag-big-usa.png' />
              </a>
              <a href='https://flottec.mx'>
                <img src='/img/flag-big-mx.png' />
              </a>
              <a href='https://flottec.cl'>
                <img src='/img/flag-big-cl.png' />
              </a>
            </div>
            <div class={style.langDiv}>
              <span class={style.langDivText}>Language:&nbsp;</span>
              <select class={style.langSelect} onChange={(e: Event) => handleChangeLang((e.srcElement as HTMLSelectElement).value as Language)}>
                <option value='en'>English</option>
                <option value='es'>Espanol</option>
              </select>
            </div>
          </div>
        )}
      </header>
    </Fragment>
  );
};
