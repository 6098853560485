import { h, FunctionalComponent } from 'preact';
import style from './company.scss';
import Markup from 'preact-markup';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState, useContext } from 'preact/hooks';
import { get } from '../../utils/ajax';
import { AppContext, CMSContent } from '../../store/store';

export const Company: FunctionalComponent = () => {
  const { language: lang } = useContext(AppContext);
  const [pageContent, setpageContent] = useState<string | undefined>(undefined);

  /* *********************************************************************** */
  useEffect(() => {
    const companyTab = 'Company';
    const editCompany = 'flotUsa';
    get<CMSContent[]>(
      `/getPageHTML?tab=${companyTab}&company=${editCompany}&lang=${lang}`
    ).then((companyContent) => {
      setpageContent(companyContent[0].html);
    });
  }, [lang]);
  /* *********************************************************************** */

  return (
    <div class={style.companyDiv}>
      {pageContent !== undefined && (
        <Typography>
          <Markup
            className={style.pageText}
            markup={pageContent}
            trim={false}
            type='html'
          />
        </Typography>
      )}
    </div>
  );
};
export default Company;
