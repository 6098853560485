@import '../../utils/color-scheme.scss';

$lighten-amount: 5;

.button {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: white;

  &[data-background='regular'] {
    background-color: $mxDarkerGold;
    background-image: linear-gradient(
      -180deg,
      $mxLightGold,
      darken($mxLightGold, $lighten-amount) 90%
    );
    &:hover {
      background-color: lighten($color: $mxLightGold, $amount: $lighten-amount);
      background-image: linear-gradient(
        -180deg,
        lighten($color: $mxLightGold, $amount: $lighten-amount),
        $mxLightGold 90%
      );
    }
  }
  &:disabled {
    color: rgba(211, 210, 210, 0.781);
    background-color: dimgray;
    background-image: linear-gradient(-180deg, dimgray, rgb(83, 83, 83) 90%);
    cursor: no-drop;
    &:hover {
      color: rgba(211, 210, 210, 0.781);
      background-color: dimgray;
      background-image: linear-gradient(-180deg, dimgray, rgb(83, 83, 83) 90%);
    }
  }
  &[data-background='green'],
  &[data-background='bubble-green'] {
    color: white;
    background-color: #32cd32;
    background-image: linear-gradient(-180deg, #32cd32, #1e7b1e 90%);
    &:hover {
      background-color: #28a428;
      background-image: linear-gradient(-180deg, #84e184, #1e7b1e 90%);
    }
  }
  &[data-background='bubble-green-blink'] {
    animation: buttonGreenBlink 0.2s infinite;
  }
  &[data-background='red'] {
    background-color: $warnRed;
    background-image: linear-gradient(
      -180deg,
      lighten($warnRed, $lighten-amount * 2),
      darken($warnRed, $lighten-amount * 2) 90%
    );
    &:hover {
      background-color: lighten($warnRed, $lighten-amount);
      background-image: linear-gradient(
        -180deg,
        lighten($warnRed, $lighten-amount * 2),
        $warnRed 90%
      );
    }
  }
  &[data-size='slim'] {
    padding: 2px 8px;
  }
  &[data-size='large'] {
    padding: 30px 20px;
    border-radius: 5px;
  }
}

.depressed {
  outline: none;
  text-shadow: 1px 1px transparent, -1px -1px black;
  border-style: solid;
  border-width: 1px;
  border-color: #333 #999 #999 #333;
  color: #d9c5a1;
  background-color: $mxDarkBlue;
  background-image: none;
  &:hover {
    background-color: $mxDepressedBgBlue;
    background-image: none;
    border: 1px solid #ddc;
  }
}

@keyframes buttonGreenBlink {
  from {
    background-color: #d87634;
    background-image: linear-gradient(-180deg, #f59f65, #b86127 90%);
  }
  to {
    background-color: #32cd32;
    background-image: linear-gradient(-180deg, #84e184, #1e7b1e 90%);
  }
}
