/* eslint-disable max-len */
import { h, VNode } from 'preact';
import { OcticonProps } from './types';

export const IconSignIn = ({ size, color, ...props }: OcticonProps): VNode => (
  <svg
    class='octicon octicon-sign-in'
    xmlns='http://www.w3.org/2000/svg'
    width={15 * size}
    height={12 * size}
    viewBox='3 1 18 22'
    stroke-width='0'
    fill={color}
    {...props}
  >
    <path
      fill-rule='evenodd'
      d='M7.5 7.25C7.5 4.58 9.422 2.5 12 2.5c2.079 0 3.71 1.34 4.282 3.242a.75.75 0 101.436-.432C16.971 2.825 14.792 1 12 1 8.503 1 6 3.845 6 7.25V9h-.5A2.5 2.5 0 003 11.5v8A2.5 2.5 0 005.5 22h13a2.5 2.5 0 002.5-2.5v-8A2.5 2.5 0 0018.5 9h-11V7.25zm-3 4.25a1 1 0 011-1h13a1 1 0 011 1v8a1 1 0 01-1 1h-13a1 1 0 01-1-1v-8z'
    />
  </svg>
);

IconSignIn.defaultProps = {
  size: 1,
  color: 'currentColor',
};
