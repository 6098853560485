.footerDiv {
  z-index: 2;
  flex-grow: 1;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 2fr 2fr 1.25fr;
  grid-template-areas: 'colLeft colMiddle colRight';
  width: 100%;
  max-height: 25vh;
  padding: 1vh 2%;
  color: rgba(200, 200, 200, 0.7);
  font-size: calc(5px + 0.5vw);
  background: #000;
  font-family: europa, 'Segoe UI light', Calibri, Helvetica, Arial, sans-serif !important;
  .footLeft {
    background: #000;
    padding: 1%;
    grid-area: colLeft;
    .logos {
      display: flex;
      .logo {
        width: 50%;
        img {
          width: 9vw;
        }
      }
    }
  }
  .footMiddle {
    background: #000;
    border-left: 1px solid rgba(200, 200, 200, 0.3);
    padding: 1%;
    grid-area: colMiddle;
    .title {
      text-align: center;
    }
    .sitemapDiv {
      xxxpadding: 0 100px;
      .sitemapRow {
        display: flex;
        margin-top: 0.5vw;
        text-align: center;
        line-height: calc(8px + 1vw);
        .siteLink {
          flex: 1;
          // margin-left: 20px;
          color: yellow;
          a {
            text-decoration: none;
            color: rgba(200, 200, 200, 0.7);
            font-size: calc(5px + 0.5vw);
            &:visited {
              text-decoration: none;
              font-size: calc(5px + 0.5vw);
            }
            &:hover {
              xxxtext-decoration: underline;
              color: #666;
              font-size: calc(5px + 0.5vw);
            }
          }
        }
      }
    }
  }
  .footRight {
    background: #000;
    border-left: 1px solid rgba(200, 200, 200, 0.3);
    padding: 1%;
    grid-area: colRight;
    .title {
      text-align: right;
      a,
      a:visited,
      a:hover {
        color: whitesmoke;
      }
    }
    .atagContact {
      cursor: pointer !important;
      border-bottom: 1px solid transparent;
      &:hover {
        border-bottom: 1px solid #cccccccc;
      }
    }
    .contactDiv {
      div {
        text-align: right;
        font-size: calc(5px + 0.5vw);
      }
    }
  }
  .title {
    padding-bottom: 10px;
    font-size: 1.25vw;
    cursor: default;
    color: whitesmoke;
  }
}
.version {
  position: absolute;
  bottom: 0;
  right: 0;
  color: transparent;
  font-size: 1.2rem;
}
@media (max-width: 999px) {
  .footerDiv {
    display: block !important;
  }
  .footerDiv .footLeft .logos .logo {
    display: flex;
    justify-content: space-evenly;
  }
  .footMiddle,
  .footRight {
    border-left: none !important;
  }
}
