import { h, FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks'; //useContext,
import style from './login.scss';
import { Button } from '../Button/Button';
import { InputText } from '../InputText/InputText';
import { get, post } from '../../utils/ajax';
import { AppState } from '../../store/store';
/*  Called from Index.tsx  */

interface LoginProps {
  onSuccess(data: AppState): void;
  onDoCreateAccount(): void;
  onClose(): void;
}

export const Login: FunctionalComponent<LoginProps> = ({
  onSuccess,
  onClose,
  onDoCreateAccount,
}) => {
  // const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [pw, setPw] = useState<string | undefined>(undefined);
  const [error, setError] = useState(false);
  const [supportEmail, setSupportEmail] = useState('support@flottec.com');

  const handleLoginSubmit = async () => {
    try {
      const auth = await post<AppState>('/login', { un: username, pw });
      onSuccess(auth);
    } catch {
      //A 400 status is "caught" here
      setError(true);
    }
  };

  /* *********************************************************************** */
  useEffect(() => {
    get('/getScratchpadValue?key=support_email').then((data: any) => {
      if (data.value !== undefined && data.value !== '') {
        setSupportEmail(data.value);
      }
    });
  }, []);
  /* *********************************************************************** */

  return (
    <div class={style.overlay}>
      <div class={style.loginModal}>
        <div class={style.logmodTop}>
          <div class={style.logmodCloseX} onClick={onClose}>
            {' X '}
          </div>
          <div class={style.logmodTopText}>Login</div>
        </div>
        <div class={style.logmodBody}>
          <div class={style.logmodBodyLeft}>
            <div class={style.logmodBodyTitle}>Login to your account</div>
            <div class={style.logmodFieldRow}>
              <div class={style.logmodFRLInput}>
                <InputText
                  class={style.formControl}
                  placeholder='Email'
                  aria-label='Email'
                  value={username}
                  onType={(username) => setUsername(username)}
                />
              </div>
            </div>
            <div class={style.logmodFieldRow}>
              <div class={style.logmodFRLInput}>
                <InputText
                  class={style.formControl}
                  type='password'
                  placeholder='Password'
                  aria-label='Password'
                  value={pw}
                  onType={(pw) => setPw(pw)}
                  onConfirm={handleLoginSubmit}
                />
              </div>
            </div>
            <div class={`${style.logmodFieldRow} ${style.forgotPasswordDiv}`}>
              <a
                href={`mailto:${supportEmail}?subject=Forgot Chemiqa Login Password&body=Please create a new password for my [ Chemiqa ] account.%0D%0A%0D%0AThe email address that I use to login is: [ ${username} ]%0D%0A%0D%0AMy preferred password is: [ ? ] (If not provided, we will create one for you)%0D%0A%0D%0AThank you, %0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A(Please note: the above text is a template only. Feel free to change/delete sentences as you see fit.)`}
              >
                Forgot Password?
              </a>
            </div>
            <div class={`${style.logmodFieldRow} ${style.logmodBtnLoginDiv}`}>
              <Button class={style.ibutton} onClick={handleLoginSubmit}>
                Log in
              </Button>
            </div>
          </div>
          <div class={style.logmodBodyRight}>
            <div class={style.logmodBodyTitle}>Why create an account</div>
            <div class={style.logmodFieldRow}>
              <ul class={style.logmodUL}>
                <li>Gain access to MSDS/SDS datasheets</li>
                <li>View additional product data</li>
                <li>View miscellaneous products data</li>
                <li>View R&amp;D white papers</li>
              </ul>
            </div>
            <div class={`${style.logmodFieldRow} ${style.logmodBtnRight}`}>
              <button class={style.ibutton} onClick={onDoCreateAccount}>
                Create account
              </button>
            </div>
          </div>
        </div>

        {error && (
          <div class={style.error}>
            The entered username/password combination is incorrect.
          </div>
        )}
      </div>
    </div>
  );
};
