import { h, render, FunctionalComponent, Fragment } from 'preact';
import { useState } from 'preact/hooks'; //, useEffect
// import style from './index.scss';
// import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { Switch, Route, useRoute } from 'wouter-preact';
import { Header } from './Components/Header/Header';
// import { get } from './utils/ajax';
import { Wrapper } from './Components/Wrapper/Wrapper';
import { Footer } from './Components/Footer/Footer';
import { Home } from './Routes/Home/Home';
import { Contact } from './Components/Contact/Contact';
import { Product } from './Routes/Product/Product';
import { Products } from './Routes/Products/Products';
import { Catalog } from './Routes/Catalog/Catalog';
import { Services } from './Routes/Services/Services';
import { Network } from './Routes/Network/Network';
import { RandD } from './Routes/RandD/RandD';
import { MsdsSds } from './Routes/MsdsSds/MsdsSds';
import { Company } from './Routes/Company/Company';
import { Categories } from './Routes/Categories/Categories';
import { Subcategories } from './Routes/Subcategories/Subcategories';

// import { Contact } from './Components/Contact/Contact';
import { Login } from './Components/Login/Login';
import { Register } from './Components/Register/Register';
import { VisibleModal } from './Components/types';
import { AppContext, UserData, AppState, Language } from './store/store'; //, Auth
import { BlacklistedDomain } from './Components/Register/Components/BlacklistedDomain';
import { UserRegistrationReceived } from './Components/Register/Components/UserRegistrationReceived';
import { EmailAlreadyRegistered } from './Components/Register/Components/EmailAlreadyRegistered';
import { AuthorizeUser } from './Routes/AuthorizeUser/AuthorizeUser';

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
          // backgroundColor: 'blue',
          // backgroundImage: `url(${bg01}) center center/cover fixed`,
          backgroundColor: 'white',
        },
        body: {
          // backgroundImage: 'url(/img/bg01.jpg)',
          // backgroundColor: 'transparent',
          // color: 'ghostwhite',
          backgroundColor: 'white',
          color: '#333',
          height: '100%',
          fontFamily: 'Calibri, Arial, Helvetica, sans-serif',
          backdropFilter: 'grayscale(0.9) blur(5px) opacity(95%)',
        },
      },
    },
  },
});

export const App: FunctionalComponent = () => {
  const [language, setLanguage] = useState<Language>('en');
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const [contactType, setContactType] = useState('contact');

  const [visibleModal, setVisibleModal] = useState<VisibleModal | undefined>(
    undefined
  );
  const handleLoginClose = () => {
    setVisibleModal(undefined);
    useRoute('/');
  };

  const handleLogin = (data: AppState) => {
    setUserData(data.userData);
    setVisibleModal(undefined);
  };
  const handleRegistration = () => {
    setVisibleModal(undefined);
  };
  const handleContactClick = (contactType: string) => {
    setContactType(contactType);
    setVisibleModal('contact');
  };

  return (
    <Fragment>
      <AppContext.Provider value={{ userData, language }}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Wrapper>
              <Header
                onLogin={() => setVisibleModal('login')}
                onLangChange={setLanguage}
              />
              <Switch>
                <Route path='/'>
                  <Home onContact={handleContactClick} />
                </Route>
                <Route path='/products'>
                  <Products onContact={handleContactClick} />
                </Route>
                <Route path='/catalog'>
                  <Catalog />
                </Route>
                <Route path='/services'>
                  <Services onContact={handleContactClick} />
                </Route>
                <Route path='/rd'>
                  <RandD />
                </Route>
                <Route path='/sds'>
                  <MsdsSds
                    onLogin={() => setVisibleModal('login')}
                    onRegister={() => setVisibleModal('register')}
                  />
                </Route>
                <Route path='/network'>
                  <Network />
                </Route>
                <Route path='/company'>
                  <Company />
                </Route>
                {/* <Route path='/contact'>
                  <Contact onClose={() => setVisibleModal(undefined)} />
                </Route> */}

                <Route path='/accountProcessing/:email/name/:name'>
                  {(params) => (
                    <UserRegistrationReceived
                      email={params.email}
                      name={params.name}
                    />
                  )}
                </Route>
                <Route path='/newAccountProcessing/:email/name/:name'>
                  {(params) => (
                    <BlacklistedDomain
                      name={params.name}
                      email={params.email}
                    />
                  )}
                </Route>
                <Route path='/newAccountRegistration/:error/id/:email'>
                  {(params) => (
                    <EmailAlreadyRegistered
                      error={params.error}
                      email={params.email}
                    />
                  )}
                </Route>
                <Route path='/auth/:auth/bl/:bl/hash/:hash'>
                  {(params) => (
                    <AuthorizeUser
                      auth={params.auth}
                      bl={params.bl}
                      hash={params.hash}
                    />
                  )}
                </Route>

                <Route path='/categories/:id'>
                  {(params) => (
                    <Categories
                      catId={params.id}
                      onLogin={() => setVisibleModal('login')}
                    />
                  )}
                </Route>
                <Route path='/categories/:cat_id/subcategories/:subcat_id'>
                  {(params) => (
                    <Subcategories
                      catId={params.cat_id}
                      subCatId={params.subcat_id}
                      onLogin={() => setVisibleModal('login')}
                    />
                  )}
                </Route>
                <Route path='/api/:api/product/:prodid'>
                  {(params) => (
                    <Product
                      api={params.api}
                      catid={''}
                      subcatid={''}
                      prodid={params.prodid}
                      onLogin={() => setVisibleModal('login')}
                    />
                  )}
                </Route>
                <Route path='/api/:api/cat/:catid/sub/:subcatid/product/:prodid'>
                  {(params) => (
                    <Product
                      api={params.api}
                      catid={params.catid}
                      subcatid={params.subcatid}
                      prodid={params.prodid}
                      onLogin={() => setVisibleModal('login')}
                    />
                  )}
                </Route>

                <Route path='/'></Route>
              </Switch>
              <Footer onContact={handleContactClick} />
            </Wrapper>
          </ThemeProvider>
        </StylesProvider>
      </AppContext.Provider>

      {visibleModal === 'login' && (
        <Login
          onSuccess={handleLogin}
          onDoCreateAccount={() => setVisibleModal('register')}
          onClose={handleLoginClose}
        />
      )}
      {visibleModal === 'register' && (
        <Register
          onSuccess={handleRegistration}
          onDoLogin={() => setVisibleModal('login')}
          onClose={() => setVisibleModal(undefined)}
        />
      )}
      {visibleModal === 'contact' && (
        <Contact
          contactType={contactType}
          onClose={() => setVisibleModal(undefined)}
        />
      )}
    </Fragment>
  );
};

render(<App />, document.body);
