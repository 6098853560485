import { h, FunctionalComponent, Fragment } from 'preact';
import style from './subcategories.scss';
import { Typography } from '@material-ui/core';
import Markup from 'preact-markup';
import Button from '@material-ui/core/Button';
import { get } from '../../utils/ajax';
import { AppContext } from '../../store/store';
import { useEffect, useState, useContext } from 'preact/hooks';
import { useLocation } from 'wouter-preact';
/*  Called from index.tsx  */

interface SubcategoriesProps {
  onLogin(): void;
  catId: string;
  subCatId: string;
}

interface Product {
  id: number;
  cat_id: number;
  sort_order: number;
  name_en: string;
  name: string;
  desc: string;
  pdf: string;
  subcat_id: number;
  regions: string;
}

interface SubCategory {
  id: number;
  bg_img?: string;
  name_en: string;
  name: string;
  desc: string;
  pdf: string;
}

export const Subcategories: FunctionalComponent<SubcategoriesProps> = ({
  catId,
  subCatId,
  onLogin,
}) => {
  const { userData, language: lang } = useContext(AppContext);
  const [, setLocation] = useLocation();
  const [allowDLPublicDS, setAllowDLPublicDS] = useState<any | undefined>(
    undefined
  );
  const [mustEmailLink, setMustEmailLink] = useState<string | undefined>(
    undefined
  );
  const [supportEmail, setSupportEmail] = useState('');
  const [pleaseClickBlurb, setPleaseClickBlurb] = useState(
    'Please click below to request data sheet for '
  );
  const [sendEmailBlurb, setSendEmailBlurb] = useState('send email');

  const [subcatProducts, setSubCatProducts] = useState<Product[] | undefined>(
    undefined
  );
  const [subcatData, setSubCatData] = useState<SubCategory | undefined>(
    undefined
  );
  const [subcatPdfs, setSubCatPdfs] = useState<string[] | undefined>(undefined);
  const subCatProdBtnClick = (prodid: number) => {
    setLocation(`/api/sub/cat/${catId}/sub/${subCatId}/product/${prodid}`);
  };
  const handleMustEmailLink = (id: any) => {
    setMustEmailLink(id);
  };
  const handleMustLogin = () => {
    onLogin();
  };

  /* *********************************************************************** */
  useEffect(() => {
    get<Product[]>(
      `/getSubcatProducts?catId=${catId}&subcatId=${subCatId}&lang=${lang}&regions=C`
    ).then((catFields) => {
      setSubCatProducts(catFields);
    });
    if (subCatId === 'all') {
      get<SubCategory>(`/getCatData?catId=${catId}&lang=${lang}`).then(
        (subcat) => {
          setSubCatData(subcat);
          const pdf = subcat.pdf;
          if (pdf === undefined || pdf === '') {
            setSubCatPdfs(undefined);
          } else {
            const objPdfs = JSON.parse(pdf);
            setSubCatPdfs(objPdfs);
          }
        }
      );
    } else {
      get<SubCategory>(`/getSubcatData?subCatId=${subCatId}&lang=${lang}`).then(
        (subcat) => {
          setSubCatData(subcat);
          const pdf = subcat.pdf;
          if (pdf === undefined || pdf === '') {
            setSubCatPdfs(undefined);
          } else {
            const objPdfs = JSON.parse(pdf);
            setSubCatPdfs(objPdfs);
          }
        }
      );
    }
    setSendEmailBlurb(
      lang === 'en' ? 'send email' : 'enviar correo electrónico'
    );
    setPleaseClickBlurb(
      lang === 'en'
        ? 'Please click below to request data sheet for '
        : 'Haga clic a continuación para solicitar la hoja de datos del '
    );
  }, [lang, catId, subCatId]);
  /* *********************************************************************** */
  useEffect(() => {
    get('/getScratchpadValue?key=allowDLPublicDS').then((apd) => {
      setAllowDLPublicDS(apd.value);
    });
    get('/getScratchpadValue?key=support_email').then((apd) => {
      setSupportEmail(apd.value);
    });
  }, []);
  /* *********************************************************************** */

  return (
    <Fragment>
      <div class={style.backdropDiv}>
        <div class={style.subcategoriesDiv}>
          <div class={style.blurbDiv}>
            {/* {console.log({ subcatData })} */}
            {subcatProducts !== undefined && (
              <Fragment>
                <Typography>
                  <div class={style.name}>
                    <Markup
                      markup={subcatData?.name}
                      trim={false}
                      type='html'
                    />
                  </div>
                  <Markup markup={subcatData?.desc} trim={false} type='html' />
                </Typography>
              </Fragment>
            )}
          </div>
          {subcatPdfs !== undefined &&
            subcatPdfs.map((el) => {
              return (
                <div class={style.catPDF}>
                  {/* <a href={`/public/${el}`} target='_blank' rel='noopener noreferrer'>
                                {el}
                              </a> */}
                  {console.log(
                    '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~'
                  )}
                  {console.log('allowDLPublicDS: ', allowDLPublicDS)}
                  {console.log('el.length: ', el.length)}
                  {console.log('el el el: ', el)}
                  {console.log(
                    '~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~'
                  )}
                  {allowDLPublicDS !== undefined ? (
                    allowDLPublicDS === 'always' ? (
                      <a
                        href={`/public/${el}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {el}
                      </a>
                    ) : allowDLPublicDS === 'never' ? (
                      <div
                        class={style.mustEmailDiv}
                        onClick={() => handleMustEmailLink(el + '')}
                      >
                        <span class={style.lookLikeAnchor}>{el}</span>
                      </div>
                    ) : userData?.authenticated ? (
                      <a
                        href={`/public/${el}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {el}
                      </a>
                    ) : (
                      <div class={style.prodWarn} onClick={handleMustLogin}>
                        <span class={style.lookLikeAnchor}>{el}</span>
                      </div>
                    )
                  ) : (
                    <div
                      class={style.mustEmailDiv}
                      onClick={() => handleMustEmailLink(el + '')}
                    >
                      <span class={style.lookLikeAnchor}>{el}</span>
                    </div>
                  )}
                </div>
              );
            })}
          {subcatProducts !== undefined &&
            subcatProducts.length === 0 &&
            subcatData !== undefined &&
            subcatData.id <= 4 && (
              <div class={style.noProdsFoundDiv}>
                No products from this category are available in your region.
              </div>
            )}

          <div class={style.buttonsDiv}>
            {subcatProducts !== undefined &&
              subcatProducts
                .filter((el) => el.name !== 'NA')
                .map((el) => (
                  <Button onClick={() => subCatProdBtnClick(el.id)}>
                    {el.name}
                  </Button>
                ))}
          </div>
        </div>
      </div>
      {mustEmailLink !== undefined && (
        <div class={style.mustEmailDlg}>
          <div class={style.mustEmailInner}>
            <div
              class={style.meCloseX}
              onClick={() => setMustEmailLink(undefined)}
            >
              {' x '}
            </div>
            <div class={style.meBody}>
              {`${pleaseClickBlurb} ${mustEmailLink}`}
              <div class={style.meEmail}>
                <a
                  href={`mailto:${supportEmail}?subject=MSDS/SDS Request for datasheet ${mustEmailLink} [${lang}]&body=I am writing to request access to the ${
                    lang === 'en' ? '' : '[ Spanish ] '
                  }MSDS/SDS datasheet for [ ${mustEmailLink} ].%0D%0A%0D%0ATo verify my request, I can be contacted at:%0D%0A%0D%0A[ Telephone ]%0D%0A[ Email ]%0D%0A[ Twitter ]%0D%0A%0D%0AThank you,%0D%0A[ Full Name ]%0D%0A[ Job Title ]%0D%0A[ Company ]`}
                >
                  {sendEmailBlurb}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default Subcategories;
