import { h, FunctionalComponent, Fragment } from 'preact';
import style from './categories.scss';
import { Typography } from '@material-ui/core';
import Markup from 'preact-markup';
import Button from '@material-ui/core/Button';
import { get } from '../../utils/ajax';
import { AppContext } from '../../store/store';
import { useLocation, Redirect } from 'wouter-preact';
import { useEffect, useState, useContext } from 'preact/hooks';
//import { useState } from 'preact/hooks';
//import { Redirect } from 'wouter-preact';
/*  Called from index.tsx  */

/*
  @TODO - Set all products with SubCat_id == 8 (NA) to Null
  @TODO - Remove Category 8 (NA)
*/

/*
  IMPORTANT: DO NOT paste in Categories.tsx from Flottec. The Chemiqa site DOES NOT have
  background images. So, must first remove: .backdropDiv -> .prodsBG and .catBgDiv

  This Route only for Product Families that have Sub-Product (SubCategory) families.
  For those SubCategories that only have products inside, use Route: <SubCategories />

  From the PoV of the DB, this is really the SubCategories page. The SubCategories page lists
  the products themselves (so should be called the Products page), and the Products page is
  really the Categories page.
  However, when the user clicks PRODUCTS on the NavBar, they will expect to see the word
  PRODUCTS in the url (i.e. domain.com/Products not domain.com/Categories...)
*/

interface CategoriesProps {
  onLogin(): void;
  catId: string;
}

interface Category {
  id: number;
  bg_img?: string;
  sort_order: number;
  name_en: string;
  name: string;
  desc: string;
  pdf: string;
}

interface SubCategory {
  id: number;
  cat_id: number;
  sort_order: number;
  name_en: string;
  name: string;
  desc: string;
  pdf: string;
}

export const Categories: FunctionalComponent<CategoriesProps> = ({
  catId,
  onLogin,
}) => {
  const { userData, language: lang } = useContext(AppContext);
  const [, setLocation] = useLocation();
  const [catContent, setCatContent] = useState<Category | undefined>(undefined);
  const [catPdfs, setCatPdfs] = useState<string[] | undefined>(undefined);
  const [allowDLPublicDS, setAllowDLPublicDS] = useState<any | undefined>(
    undefined
  );
  const [mustEmailLink, setMustEmailLink] = useState<string | undefined>(
    undefined
  );
  const [supportEmail, setSupportEmail] = useState('');
  const [pleaseClickBlurb, setPleaseClickBlurb] = useState(
    'Please click below to request data sheet for '
  );
  const [sendEmailBlurb, setSendEmailBlurb] = useState('send email');

  const [subcatContent, setSubCatContent] = useState<Category | undefined>(
    undefined
  );
  const [subcategories, setSubCategories] = useState<SubCategory[] | undefined>(
    undefined
  );
  const subCatBtnClick = (subcatid: number) => {
    // console.log(`Clicked SubCat Num: ${subcatid}`);
    setLocation(`/categories/${catId}/subcategories/${subcatid}`);
  };
  const fakeInputClickHandler = () => {
    setCatContent(subcatContent);
    setSubCatContent(subcategories as any);
  };
  const handleMustEmailLink = (id: any) => {
    setMustEmailLink(id);
  };
  const handleMustLogin = () => {
    onLogin();
  };

  /* *********************************************************************** */
  useEffect(() => {
    // const lang = 'en';
    get<Category>(`/getCatHTML?catId=${catId}&lang=${lang}`).then(
      (catFields) => {
        console.log('catFields: ', catFields);
        setCatContent(catFields);
        const pdf = catFields.pdf;
        if (pdf === undefined || pdf === '') {
          setCatPdfs(undefined);
        } else {
          const objPdfs = JSON.parse(pdf);
          console.log(
            '**************  Categories.tsx useEffect catFields.pdf: {pdf}   ***************'
          );
          //  {pdf: "["Discover Its a Roomba.pdf"]"}
          console.log({ pdf });
          setCatPdfs(objPdfs);
        }
      }
    );
    get<SubCategory>(`/getSubCatHTML?name=Collectors&lang=${lang}`).then(
      (subcatContent) => {
        console.log({ subcatContent });
        setSubCatContent(subcatContent);
      }
    );
    get(`/getAllSubCategories?catId=1&lang=${lang}`).then((subcats) => {
      setSubCategories(subcats);
    });
    setSendEmailBlurb(
      lang === 'en' ? 'send email' : 'enviar correo electrónico'
    );
    setPleaseClickBlurb(
      lang === 'en'
        ? 'Please click below to request data sheet for '
        : 'Haga clic a continuación para solicitar la hoja de datos del '
    );
  }, [lang]);
  /* *********************************************************************** */
  useEffect(() => {
    get('/getScratchpadValue?key=allowDLPublicDS').then((apd) => {
      setAllowDLPublicDS(apd.value);
    });
    get('/getScratchpadValue?key=support_email').then((apd) => {
      setSupportEmail(apd.value);
    });
  }, []);
  /* *********************************************************************** */
  return (
    <Fragment>
      <div class={style.backdropDiv}>
        <div class={style.categoriesDiv}>
          {catId === '1' && (
            <div class={style.catTitle}>Collectors and Frothers</div>
          )}
          {/* {console.log({ catContent })} */}
          {catContent !== undefined && (
            <Typography>
              <Markup markup={catContent.desc} trim={false} type='html' />
            </Typography>
          )}

          {catPdfs !== undefined &&
            catPdfs.map((el) => {
              return (
                <div class={style.catPDF}>
                  {allowDLPublicDS !== undefined ? (
                    allowDLPublicDS === 'always' ? (
                      <a
                        href={`/public/${el[0]}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {el}
                      </a>
                    ) : allowDLPublicDS === 'never' ? (
                      <div
                        class={style.mustEmailDiv}
                        onClick={() => handleMustEmailLink(el + '')}
                      >
                        <span class={style.lookLikeAnchor}>{el}</span>
                      </div>
                    ) : userData?.authenticated ? (
                      <a
                        href={`/public/${el}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {el}
                      </a>
                    ) : (
                      <div class={style.prodWarn} onClick={handleMustLogin}>
                        <span class={style.lookLikeAnchor}>{el}</span>
                      </div>
                    )
                  ) : (
                    <div
                      class={style.mustEmailDiv}
                      onClick={() => handleMustEmailLink(el + '')}
                    >
                      NOTA<span class={style.lookLikeAnchor}>{el}</span>
                    </div>
                  )}
                </div>
              );
            })}
          {catId === '1' ? (
            <div class={style.buttonsDiv}>
              {subcategories !== undefined && console.log({ subcategories })}

              {subcategories !== undefined &&
                subcategories
                  .filter((el) => el.name !== 'NA')
                  .map((el) => {
                    return (
                      <Button onClick={() => subCatBtnClick(el.id)}>
                        {el.name}
                      </Button>
                    );
                  })}
            </div>
          ) : (
            <Redirect to={`/categories/${catId}/subcategories/all`} />
          )}
        </div>
        <input type={'hidden'} onClick={fakeInputClickHandler} />
      </div>
      {mustEmailLink !== undefined && (
        <div class={style.mustEmailDlg}>
          <div class={style.mustEmailInner}>
            <div
              class={style.meCloseX}
              onClick={() => setMustEmailLink(undefined)}
            >
              {' x '}
            </div>
            <div class={style.meBody}>
              {`${pleaseClickBlurb} ${mustEmailLink}`}
              <div class={style.meEmail}>
                <a
                  href={`mailto:${supportEmail}?subject=MSDS/SDS Request for datasheet ${mustEmailLink} [${lang}]&body=I am writing to request access to the ${
                    lang === 'en' ? '' : '[ Spanish ] '
                  }MSDS/SDS datasheet for [ ${mustEmailLink} ].%0D%0A%0D%0ATo verify my request, I can be contacted at:%0D%0A%0D%0A[ Telephone ]%0D%0A[ Email ]%0D%0A[ Twitter ]%0D%0A%0D%0AThank you,%0D%0A[ Full Name ]%0D%0A[ Job Title ]%0D%0A[ Company ]`}
                >
                  {sendEmailBlurb}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default Categories;
