/* eslint-disable max-len */
import { h, VNode } from 'preact';
import { OcticonProps } from './types';

export const IconSignOut = ({ size, color, ...props }: OcticonProps): VNode => (
  <svg
    class='octicon octicon-sign-out'
    xmlns='http://www.w3.org/2000/svg'
    width={15 * size}
    height={12 * size}
    viewBox='3 1 18 22'
    stroke-width='0'
    fill={color}
    {...props}
  >
    <path
      fill-rule='evenodd'
      d='M6 9V7.25C6 3.845 8.503 1 12 1s6 2.845 6 6.25V9h.5a2.5 2.5 0 012.5 2.5v8a2.5 2.5 0 01-2.5 2.5h-13A2.5 2.5 0 013 19.5v-8A2.5 2.5 0 015.5 9H6zm1.5-1.75C7.5 4.58 9.422 2.5 12 2.5c2.578 0 4.5 2.08 4.5 4.75V9h-9V7.25zm-3 4.25a1 1 0 011-1h13a1 1 0 011 1v8a1 1 0 01-1 1h-13a1 1 0 01-1-1v-8z'
    />
  </svg>
);

IconSignOut.defaultProps = {
  size: 1,
  color: 'firebrick',
};
