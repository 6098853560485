import { h, FunctionalComponent } from 'preact';
import style from './services.scss';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useContext } from 'preact/hooks';
import { AppContext } from '../../store/store';

interface ServicesProps {
  onContact(contactType: string): void;
}

export const Services: FunctionalComponent<ServicesProps> = ({ onContact }) => {
  const { language: lang } = useContext(AppContext);
  console.log({ lang });
  return (
    <div class={style.servicesDiv}>
      <div class={style.s1TopDiv}>
        <div class={style.s1TopBannerPhoto}>&nbsp;</div>
        <div class={style.s1TopOverlay}></div>
      </div>
      <div class={style.s2Overview}>
        <div class={style.s2OverviewInner}>
          <h3>Services</h3>
          <div class={style.s2List}>
            <ul class='fa-ul'>
              <li>Bulk liquid chemical&nbsp;</li>
              <li>Reagents available as bulk liquid, totes or drums</li>
              <li>Custom products and packaging</li>
              <li>Local storage and delivery</li>
              <li>Flotation applications expertise</li>
              <li>Flotation education and chemical safe handling seminars</li>
              <li>Vendor inventory management</li>
              <li>
                Sourcing to find the most competitive price for commodity
                products
              </li>
            </ul>
          </div>
          <div class={style.btnReqQuoteDiv}>
            <a href='#' onClick={() => onContact('quote')}>
              <Button className={style.btnChemiqa}>Request a Quote</Button>
            </a>
          </div>
        </div>
      </div>
      <div class={style.requestQuoteDiv}>
        <div class={style.reqQuoteInner}>
          <Typography variant='h3'>Request a Quote</Typography>
          <Typography variant='h5'>
            We&apos;re happy to answer any questions you may have.
          </Typography>
          <div class={style.btnLetsTalkDiv}>
            <a href='#' onClick={() => onContact('contact')}>
              <Button className={style.btnChemiqa}>
                Let&#39;s talk &#187;
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
