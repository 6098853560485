/* eslint-disable react/display-name */
import { h, FunctionalComponent, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import style from './home.scss';
import Markup from 'preact-markup';
import Typography from '@material-ui/core/Typography';
import { get } from '../../utils/ajax';
import Button from '@material-ui/core/Button';
import { ActiveLink } from '../../Components/ActiveLink/ActiveLink';
import { PageSection } from '../../Components/types';

interface HomeProps {
  onContact(contactType: string): void;
}

export const Home: FunctionalComponent<HomeProps> = ({ onContact }) => {
  const [pageContent1, setpageContent1] = useState<string | undefined>(undefined);
  const [pageContent2, setpageContent2] = useState<string | undefined>(undefined);
  const [pageContent3, setpageContent3] = useState<string | undefined>(undefined);
  const handleBtnProductsClick = () => {
    console.log('fn handleBtnProductsClick');
  };

  /* *********************************************************************** */
  useEffect(() => {
    get<PageSection[]>('/getPageHTML?tab=Home-1&company=chemiqa&lang=en').then((homeContent) => {
      setpageContent1(homeContent[0].html);
    });
    get<PageSection[]>('/getPageHTML?tab=Home-2&company=chemiqa&lang=en').then((homeContent) => {
      setpageContent2(homeContent[0].html);
    });
    get<PageSection[]>('/getPageHTML?tab=Home-3&company=chemiqa&lang=en').then((homeContent) => {
      setpageContent3(homeContent[0].html);
    });
    /* *********************************************************************** */
  }, []);
  return (
    <Fragment>
      <div class={style.homeWrap}>
        <div class={style.s1SplashDiv}>
          <div class={style.layerOne}>
            <img class={style.bgNoVideoImg} src={'./img/HomePagePoster.jpg'} />
          </div>
          <div class={style.layerTwo}>
            <div class={style.banner}>
              <video class={style.bgvid} poster={'./img/HomePagePoster.jpg'} autoPlay muted loop>
                <source src={'./img/bg2d.webm'} type={'video/webm'}></source>
                <source src={'./img/bg1b.mp4'} type={'video/mp4'}></source>
                <source src={'./img/bg3b.ogg'} type={'video/ogg'}></source>
              </video>
            </div>
          </div>
          <div class={style.layerThree}>
            <img src={'./img/bg01.webp'} />
          </div>
          <div class={style.layerFour}>
            <div class={style.cqLogo}>
              <img src={'./img/Flottec_LOGO_Canada.png'} />
            </div>
            <div class={style.s1BlurbDiv}>
              {pageContent1 !== undefined && (
                <Typography>
                  <Markup markup={pageContent1} trim={false} type='html' />
                </Typography>
              )}
            </div>
            <div class={style.s1BtnProdsDIV}>
              <ActiveLink href='/products' exact>
                <Button onClick={handleBtnProductsClick} className={style.btnChemiqa}>
                  Our Products &#187;
                </Button>
              </ActiveLink>
            </div>
          </div>
          <div
            class='under_maintenance'
            style={'display:none;z-index:5;position:fixed;top:20vh;left:0;width:100%;text-align:center;font-size:3rem;color:gold;'}
          >
            UNDERGOING MAINTENANCE TODAY
          </div>
        </div>
        <div class={style.s2GoalDiv}>
          <div class={style.s2GoalInner}>
            {pageContent2 !== undefined && (
              <Typography>
                <Markup markup={pageContent2} trim={false} type='html' />
              </Typography>
            )}
            <div class={style.s2GoalImgDiv}>
              <img src={'./img/rdc_drc.png'} />
            </div>
          </div>
        </div>
        <div class={style.s3HistoryDiv}>
          <div class={style.s3SideLeft}>&nbsp;</div>
          <div class={style.s3SideRight}>
            <h3>The Chemistry Behind Flottec</h3>
            <div class={style.logoDiv}>
              <img src={'./img/flottec-logo-basic.png'} />
            </div>
            {pageContent3 !== undefined && (
              <Typography>
                <Markup markup={pageContent3} trim={false} type='html' />
              </Typography>
            )}
            <a class={style.btnChemiqaA} href={'/services'}>
              {/* Replaced ActiveLink tag with normal A tag to fix jump to page middle */}
              <Button className={style.btnChemiqa}>Services &#187;</Button>
            </a>
          </div>
        </div>
        <div class={style.requestQuoteDiv}>
          <div class={style.reqQuoteInner}>
            <Typography variant='h2' className={style.homeReqQuote}>
              Request a Quote
            </Typography>
            <Typography variant='h5'>We&apos;re happy to answer any questions you may have.</Typography>
            <div class={style.btnLetsTalkDiv}>
              <a href='#' onClick={() => onContact('contact')}>
                <Button className={style.btnChemiqa}>Let&#39;s talk &#187;</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
