import { h, FunctionalComponent, JSX } from 'preact';
import style from './catalog.scss';
import Typography from '@material-ui/core/Typography';
import Markup from 'preact-markup';
import Button from '@material-ui/core/Button';
import { get } from '../../utils/ajax';
import { AppContext, CMSContent } from '../../store/store';
import { Link, useLocation } from 'wouter-preact';
import { useEffect, useState, useContext } from 'preact/hooks';
/*  Called by index.tsx   */
/*
  This page says PRODUCTS at top, and is displayed when user first clicks [Products] NavBar item
  From the PoV of the DB, this is really the Categories page. The Categories page is
  really the SubCategories page, and the SubCategories page lists the products themselves.
  However, when the user views this page (by clicking the PRODUCTS button on the NavBar), one wants
  the url to be domain.com/Products not domain.com/Categories...
*/

interface Category {
  id: number;
  sort_order: number;
  name_en: string;
  name: string;
  desc: string;
  pdf: string;
}
interface MysqlProductNames {
  id: number;
  cat_id: number;
  subcat_id: number;
  name_en: string;
}
export const Catalog: FunctionalComponent = () => {
  const { language: lang } = useContext(AppContext);
  const [, setLocation] = useLocation();
  const [pageContent, setPageContent] = useState<string | undefined>(undefined);
  const [categories, setCategories] = useState<Category[] | undefined>(
    undefined
  );
  const [srxText, setSrxText] = useState('');
  const [srxResults, setSrxResults] = useState<MysqlProductNames[] | undefined>(
    undefined
  );
  const [productsPageImage, setProductsPageImage] = useState<
    string | undefined
  >(undefined);

  const handleSrxUserInput = (
    e: JSX.TargetedEvent<HTMLInputElement, Event>
  ) => {
    const val = e.currentTarget.value;
    setSrxText(val);

    get<MysqlProductNames[]>(`/getSrxAutocompleteProdsEn?title=${val}`).then(
      (res) => {
        setSrxResults(res);
      }
    );
  };
  const handleSrxLiClick = (
    prodId: number,
    catId: number,
    subCatId: number
  ) => {
    setSrxText('');
    setLocation(`/api/pdts/cat/${catId}/sub/${subCatId}/product/${prodId}`);
  };

  /* *********************************************************************** */
  useEffect(() => {
    const companyTab = 'Products';
    const editCompany = 'chemiqa';
    get<CMSContent[]>(
      `/getCatalog?tab=${companyTab}&company=${editCompany}&lang=${lang}`
    ).then((productsContent) => {
      setPageContent(productsContent[0].html);
    });
    get(`/getAllCategories?lang=${lang}&caller='Catalog.tsx'`).then((cats) => {
      setCategories(cats);
    });
    get('/getScratchpadValue?key=productsPageImage&caller=Catalog.tsx').then(
      (value) => {
        const img = value.value;
        setProductsPageImage(img);
      }
    );
  }, [lang]);
  /* *********************************************************************** */

  return (
    <div class={style.backdropDiv}>
      <div class={style.prodsBG}>
        {productsPageImage !== undefined && productsPageImage.length > 1 && (
          <img src={`/img/${productsPageImage}`} />
        )}
      </div>
      <div class={style.productsDiv}>
        {pageContent !== undefined && (
          <Typography>
            <Markup markup={pageContent} trim={false} type='html' />
          </Typography>
        )}
        <div class={style.searchDiv}>
          <div class={style.srxLeft}>Find a product:</div>
          <div class={style.srxRight}>
            <div class={style.srxInputDiv}>
              <input
                class={style.srxInputField}
                type='text'
                value={srxText}
                onKeyUp={handleSrxUserInput}
                placeholder='type product name'
              />
            </div>
            <div class={style.srxResultsDiv}>
              <ul>
                {srxText.length > 0 &&
                  srxResults !== undefined &&
                  srxResults.length > 0 &&
                  srxResults.map((r) => {
                    return (
                      <li
                        onClick={() =>
                          handleSrxLiClick(r.id, r.cat_id, r.subcat_id)
                        }
                      >
                        {r.name_en}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div class={style.buttonsDiv}>
          {categories !== undefined &&
            categories.map((el) => {
              return (
                <Button>
                  <Link
                    href={
                      el.id === 1
                        ? `/categories/${el.id}`
                        : `/categories/${el.id}/subcategories/all`
                    }
                  >
                    {el.name}
                  </Link>
                </Button>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default Catalog;
