import { h, FunctionalComponent } from 'preact';
import style from './products.scss';
import Button from '@material-ui/core/Button';
import { ActiveLink } from '../../Components/ActiveLink/ActiveLink';
import { useState, useEffect } from 'preact/hooks';
import Markup from 'preact-markup';
import Typography from '@material-ui/core/Typography';
import { get } from '../../utils/ajax';
import { PageSection } from '../../Components/types';

interface ProductsProps {
  onContact(contactType: string): void;
}

export const Products: FunctionalComponent<ProductsProps> = ({ onContact }) => {
  const [pageContent1, setpageContent1] = useState<string | undefined>(
    undefined
  );
  const [pageContent2, setpageContent2] = useState<string | undefined>(
    undefined
  );
  const [pageContent3, setpageContent3] = useState<string | undefined>(
    undefined
  );
  const [pageContent4, setpageContent4] = useState<string | undefined>(
    undefined
  );
  const [pageContent5, setpageContent5] = useState<string | undefined>(
    undefined
  );
  const [pageContent6, setpageContent6] = useState<string | undefined>(
    undefined
  );
  const [pageContent7, setpageContent7] = useState<string | undefined>(
    undefined
  );
  // const handleBtnProductsClick = () => {};

  /* *********************************************************************** */
  useEffect(() => {
    get<PageSection[]>(
      '/getPageHTML?tab=Products-1&company=chemiqa&lang=en'
    ).then((homeContent) => {
      setpageContent1(homeContent[0].html);
    });
    get<PageSection[]>(
      '/getPageHTML?tab=Products-2&company=chemiqa&lang=en'
    ).then((homeContent) => {
      setpageContent2(homeContent[0].html);
    });
    get<PageSection[]>(
      '/getPageHTML?tab=Products-3&company=chemiqa&lang=en'
    ).then((homeContent) => {
      setpageContent3(homeContent[0].html);
    });
    get<PageSection[]>(
      '/getPageHTML?tab=Products-4&company=chemiqa&lang=en'
    ).then((homeContent) => {
      setpageContent4(homeContent[0].html);
    });
    get<PageSection[]>(
      '/getPageHTML?tab=Products-5&company=chemiqa&lang=en'
    ).then((homeContent) => {
      setpageContent5(homeContent[0].html);
    });
    get<PageSection[]>(
      '/getPageHTML?tab=Products-6&company=chemiqa&lang=en'
    ).then((homeContent) => {
      setpageContent6(homeContent[0].html);
    });
    get<PageSection[]>(
      '/getPageHTML?tab=Products-7&company=chemiqa&lang=en'
    ).then((homeContent) => {
      setpageContent7(homeContent[0].html);
    });
    /* *********************************************************************** */
  }, []);

  return (
    <div class={style.productsDiv}>
      <div class={style.s1TopDiv}>
        <div class={style.s1TopBannerPhoto}>&nbsp;</div>
        <div class={style.s1TopOverlay}></div>
      </div>
      <div class={style.s2Overview}>
        <div class={style.s2OverviewInner}>
          <h3>Products</h3>
          {pageContent1 !== undefined && (
            <Typography>
              <Markup markup={pageContent1} trim={false} type='html' />
            </Typography>
          )}
          <div class={style.s2List}>
            <ul class='fa-ul'>
              <li
                onClick={() => {
                  document
                    .querySelector('#collectrs')
                    ?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Collectors
              </li>
              <li
                onClick={() => {
                  document
                    .querySelector('#frothrs')
                    ?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Frothers
              </li>
              <li
                onClick={() => {
                  document
                    .querySelector('#procAids')
                    ?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Processing Aids
              </li>
              <li
                onClick={() => {
                  document
                    .querySelector('#surfacts')
                    ?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Surfactants &amp; Dewatering Aids
              </li>
              <li
                onClick={() => {
                  document
                    .querySelector('#advProds')
                    ?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Advanced Products
              </li>
            </ul>
          </div>
          <div class={style.btnReqQuoteDiv}>
            <a href='#' onClick={() => onContact('quote')}>
              <Button className={style.btnChemiqa}>Request a Quote</Button>
            </a>
          </div>
        </div>
      </div>
      <div id='collectrs' class={style.s3Collectors}>
        <div class={style.s3SideLeft}>&nbsp;</div>
        <div class={style.s3SideRight}>
          <ActiveLink href='/categories/1'>
            <h3>Collectors</h3>
          </ActiveLink>
          {pageContent2 !== undefined && (
            <Typography>
              <Markup markup={pageContent2} trim={false} type='html' />
            </Typography>
          )}

          <div class={style.s3List}>
            <ul class='fa-ul'>
              <li>
                <ActiveLink href='/categories/1/subcategories/6'>
                  Anionic Collectors
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href='/categories/1/subcategories/5'>
                  Dithio &amp; Monothiophosphates
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href='/categories/1/subcategories/6'>
                  Mercaptobenzothiazoles
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href='/categories/1/subcategories/4'>
                  Thionocarbamates
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href='/categories/1/subcategories/1'>
                  Xanthate and xanthate derivatives
                </ActiveLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id='frothrs' class={style.s4Frothers}>
        <div class={style.s4SideLeft}>
          <ActiveLink href='/categories/1'>
            <h3>Frothers</h3>
          </ActiveLink>
          {pageContent3 !== undefined && (
            <Typography>
              <Markup markup={pageContent3} trim={false} type='html' />
            </Typography>
          )}

          <div class={style.s4List}>
            <ul class='fa-ul'>
              <li>
                <ActiveLink href='/categories/1/subcategories/7'>
                  Alcohol based
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href='/categories/1/subcategories/7'>
                  Aromatic
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href='/categories/1/subcategories/7'>
                  Formulated coproduct based
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href='/categories/1/subcategories/7'>
                  Formulated frothers
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href='/categories/1/subcategories/7'>
                  Polypropylene glycols
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href='/categories/1/subcategories/7'>
                  Polyglycol ethers
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href='/categories/1/subcategories/7'>
                  Specialty alcohols
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href='/categories/1/subcategories/7'>
                  Triexthoxybutane based
                </ActiveLink>
              </li>
            </ul>
          </div>
        </div>
        <div class={style.s4SideRight}>&nbsp;</div>
      </div>
      <div id='procAids' class={style.s5ProcessingAids}>
        <ActiveLink href='/categories/4/subcategories/all'>
          <h3>Processing Aids</h3>
        </ActiveLink>
        {pageContent4 !== undefined && (
          <Typography>
            <Markup markup={pageContent4} trim={false} type='html' />
          </Typography>
        )}
        <div class={style.s5List}>
          <ul class='fa-ul'>
            <li>
              <ActiveLink href='/categories/4/subcategories/all'>
                Anti-scalants
              </ActiveLink>
            </li>
            <li>
              <ActiveLink href='/categories/4/subcategories/all'>
                Colorants
              </ActiveLink>
            </li>
            <li>
              <ActiveLink href='/categories/4/subcategories/all'>
                Dispersants
              </ActiveLink>
            </li>
            <li>
              <ActiveLink href='/categories/4/subcategories/all'>
                Dust Control
              </ActiveLink>
            </li>
            <li>
              <ActiveLink href='/categories/4/subcategories/all'>
                Anticaking Agents
              </ActiveLink>
            </li>
          </ul>
        </div>
      </div>
      <div id='surfacts' class={style.s6Surfactants}>
        <div class={style.s6SideLeft}>
          <ActiveLink href='/categories/3/subcategories/all'>
            <h3>Surfactants &amp; Dewatering Aids</h3>
          </ActiveLink>
          {pageContent5 !== undefined && (
            <Typography>
              <Markup markup={pageContent5} trim={false} type='html' />
            </Typography>
          )}
        </div>
        <div class={style.s6SideRight}>&nbsp;</div>
      </div>
      <div id='depresnts' class={style.s7Depressants}>
        <ActiveLink href='/categories/2/subcategories/all'>
          <h3>Depressant / Modifiers</h3>
        </ActiveLink>
        {pageContent6 !== undefined && (
          <Typography>
            <Markup markup={pageContent6} trim={false} type='html' />
          </Typography>
        )}
      </div>
      <div id='advProds' class={style.s8AdvancedProducts}>
        <div class={style.s8SideLeft}>&nbsp;</div>
        <div class={style.s8SideRight}>
          <h3>Advanced Products</h3>
          {pageContent7 !== undefined && (
            <Typography>
              <Markup markup={pageContent7} trim={false} type='html' />
            </Typography>
          )}
        </div>
      </div>
      <div class={style.requestQuoteDiv}>
        <div class={style.reqQuoteInner}>
          <Typography variant='h2'>Request a Quote</Typography>
          <Typography variant='h5'>
            We&apos;re happy to answer any questions you may have.
          </Typography>
          <div class={style.btnLetsTalkDiv}>
            <a href='#' onClick={() => onContact('contact')}>
              <Button className={style.btnChemiqa}>Get in Touch &#187;</Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
