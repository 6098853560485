.backdropDiv {
  position: relative;
  left: 0;
  min-height: calc(100vh - 266px);
  width: 100vw;
  /*background: url(../../user_uploads/img/bgFlot02.webp) center center / cover no-repeat;*/
  .prodsBG {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
    max-height: 100vh;
    min-height: calc(100vh - 270px);
  }
  .productsDiv {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: calc(100vh - 270px);
    padding: 5vh 12vw;
    color: #444 !important;
    background: rgba(230, 230, 230, 0.85);
    .buttonsDiv {
      position: absolute;
      z-index: 1;
      padding: 7vh 5vw;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
      button {
        width: 600px;
        margin: 10px;
        padding: 2vh 0;
        border: 1px solid rgba(100, 100, 100, 0.3);
        a {
          text-decoration: none;
          font-size: 1.3rem;
          color: #555;
        }
      }
    }
    .searchDiv {
      position: absolute;
      z-index: 2;
      width: 75%;
      display: flex;
      padding: 0 20vw;
      font-size: 1.3em;
      .srxLeft {
        flex-basis: 30%;
        text-align: right;
        padding-right: 30px;
      }
      .srxRight {
        flex-basis: 70%;
        .srxInputDiv {
          xxxborder: 1px solid blue;
          .srxInputField {
            width: 100%;
            outline: unset;
            padding: 3px 10px;
            font-size: 1.1em;
            border: 1px solid rgba(200, 200, 200, 0.5);
            border-radius: 3px;
            &:focus {
              border: 1px solid rgba(50, 100, 200, 0.5);
            }
            &::placeholder {
              color: #ccc;
              font-family: 'Segoe UI light', 'Segoe UI', Arial, Helvetica,
                sans-serif;
            }
          }
        }
        .srxResultsDiv {
          background: rgba(250, 250, 250, 0.9);
          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            li {
              padding: 0 10px;
              cursor: pointer;
              &:hover {
                background: #4774b354 !important;
              }
            }
          }
        }
      }
    }
    h3 {
      margin: 0;
      padding: 0;
    }
    & > p > div > p {
      padding-top: 0 !important;
    }
  }
}
