/**
 * Parses either a date string or Date Object to its UTS Equivalent
 * With JSON.stringify, it is required to subtract 2 times the tzOffset when it is included
 * @export
 * @param {(string | Date)} date
 * @param {number} [tzOffset]
 * @returns {Date}
 */

export function updateClock(tz: string | undefined) {
  function changeTimezone(date: Date, ianatz: string) {
    const invdate = new Date(
      date.toLocaleString('en-US', {
        timeZone: ianatz,
      })
    );
    const diff = date.getTime() - invdate.getTime();
    return new Date(date.getTime() - diff); // needs to substract
  }

  const date = new Date();
  const now = tz !== undefined ? changeTimezone(date, tz) : date;

  let sec = now.getSeconds() + '';
  sec = sec.length < 2 ? '0' + sec : sec;
  let min = now.getMinutes() + '';
  min = min.length < 2 ? '0' + min : min;
  let hrs = now.getHours() + '';
  hrs = hrs.length < 2 ? '0' + hrs : hrs;
  let dt = now.getDate() + '';
  dt = dt.length < 2 ? '0' + dt : dt;
  const yr = now.getFullYear() + '';

  const mo = now.getMonth();
  const dy = now.getDay();

  const months_en = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const days_en = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const corr = [days_en[dy], months_en[mo], dt, yr, hrs, min, sec];

  // const tags = ['day', 'mon', 'd', 'y', 'h', 'm', 's'];
  // for (let i = 0; i < tags.length; i++){
  //   let el = document.getElementById(tags[i]);
  //   if (el) const el2 = el.firstChild;
  //   if (el2) const el3 = el2.nodeValue;
  //   el3 = corr[i];
  // }
  // console.log({ tz });
  return corr;
}
