import { h, FunctionalComponent, Fragment } from 'preact';
import style from './rd.scss';
import Markup from 'preact-markup';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState, useContext } from 'preact/hooks';
import { get } from '../../utils/ajax';
import { AppContext, CMSContent, Language } from '../../store/store';

interface RDPresData {
  id: number;
  notok2dl: number;
  lang: Language;
  regions: string;
  img: string;
  title: string;
  blurb: string;
  pdf: string;
}
interface MngRDPresDb {
  rdPresData: RDPresData[];
  allowPresDL: string;
}
export const RandD: FunctionalComponent = () => {
  const { language: lang } = useContext(AppContext);
  const [pageContent, setpageContent] = useState<string | undefined>(undefined);
  const [tableData, setTableData] = useState<RDPresData[] | undefined>(
    undefined
  );
  const [allowPresentationDL, setAllowPresentationDL] = useState('login');
  const { userData } = useContext(AppContext);

  /* *********************************************************************** */
  useEffect(() => {
    // const pageId = `${lang}_flotUsa_Rd`;
    const companyTab = 'Rd';
    const editCompany = 'flotUsa';
    get<CMSContent[]>(
      `/getPageHTML?tab=${companyTab}&company=${editCompany}&lang=${lang}`
    ).then((rdContent) => {
      setpageContent(rdContent[0].html);
    });
    get<MngRDPresDb>('/getRndPresentations').then((d) => {
      const allowDL = d.allowPresDL;
      setTableData(d.rdPresData);
      setAllowPresentationDL(allowDL);
    });
  }, [lang, userData]);
  /* *********************************************************************** */

  return (
    <div class={style.rdDiv}>
      <div class={style.pageContentFromDB}>
        {pageContent !== undefined && (
          <Typography>
            <Markup markup={pageContent} trim={false} type='html' />
          </Typography>
        )}
      </div>
      <div class={style.rdPresentationsFromDB}>
        {tableData !== undefined &&
          tableData.map((el) => {
            return (
              <div class={style.singlePres}>
                <div class={style.presImg}>
                  <img
                    width='150'
                    height='100'
                    src={`/uploads/img/${el.img}`}
                  />
                </div>
                <div class={style.presData}>
                  <div class={style.presTitle}>{el.title}</div>
                  <div class={style.presBlurb}>{el.blurb}</div>
                  <div class={style.presPdf}>
                    {el.pdf === undefined ||
                      (el.pdf === '' && <div class={style.mtDiv}>&nbsp;</div>)}

                    {allowPresentationDL === 'always' &&
                      el.notok2dl === 0 &&
                      el.pdf !== undefined &&
                      el.pdf !== '' && (
                        <Fragment>
                          <div class={style.pdfAnchor}>
                            <a
                              href={`/uploads/public/${el.pdf}`}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {el.pdf}
                            </a>
                          </div>
                        </Fragment>
                      )}
                    {allowPresentationDL === 'login' &&
                      userData?.authenticated &&
                      el.notok2dl === 0 &&
                      el.pdf !== undefined &&
                      el.pdf !== '' && (
                        <Fragment>
                          <div class={style.pdfAnchor}>
                            <a
                              href={`/uploads/files/${el.pdf}`}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {el.pdf}
                            </a>
                          </div>
                        </Fragment>
                      )}

                    {allowPresentationDL === 'never' &&
                      el.pdf !== undefined &&
                      el.pdf !== '' && (
                        <Fragment>
                          <div class={style.pdfAnchor}>
                            <div class={style.pdfRequestOnly}>
                              Please{' '}
                              <a
                                href={`mailto:service@flottec.com?subject=DAR Access to Presentation Request&body=Requesting access to a presentation entitled [ ${el.title} ].%0D%0A%0D%0AMy contact information is:%0D%0A%0D%0A[ Full Name* ]%0D%0A[ Job Title ]%0D%0A[ Company* ]%0D%0A[ Telephone* ]%0D%0A[ Email* ]%0D%0A[ Reason for request ]`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                email Flottec support
                              </a>{' '}
                              to request this document.
                            </div>
                          </div>
                        </Fragment>
                      )}
                    {allowPresentationDL === 'login' &&
                      (userData?.authenticated === false ||
                        el.notok2dl === 1) &&
                      el.pdf !== undefined &&
                      el.pdf !== '' && (
                        <Fragment>
                          <div class={style.pdfAnchor}>
                            <div class={style.pdfRequestOnly}>
                              Please{' '}
                              <a
                                href={`mailto:service@flottec.com?subject=DAR Access to Presentation Request&body=Requesting access to a presentation entitled [ ${el.title} ].%0D%0A%0D%0AMy contact information is:%0D%0A%0D%0A[ Full Name* ]%0D%0A[ Job Title ]%0D%0A[ Company* ]%0D%0A[ Telephone* ]%0D%0A[ Email* ]%0D%0A[ Reason for request ]`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                email Flottec support
                              </a>{' '}
                              to request this document.
                            </div>
                          </div>
                        </Fragment>
                      )}
                    {allowPresentationDL === 'always' &&
                      el.notok2dl === 1 &&
                      el.pdf !== undefined &&
                      el.pdf !== '' && (
                        <Fragment>
                          <div class={style.pdfAnchor}>
                            <div class={style.pdfRequestOnly}>
                              Please{' '}
                              <a
                                href={`mailto:service@flottec.com?subject=DAR Access to MSDS Request&body=Requesting access to a presentation entitled [ ${el.title} ].%0D%0A%0D%0AMy contact information is:%0D%0A%0D%0A[ Full Name* ]%0D%0A[ Job Title ]%0D%0A[ Company* ]%0D%0A[ Telephone* ]%0D%0A[ Email* ]%0D%0A[ Reason for request ]`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                email Flottec support
                              </a>{' '}
                              to request this document.
                            </div>
                          </div>
                        </Fragment>
                      )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default RandD;
