import { h, FunctionalComponent } from 'preact';
import style from './network.scss';
import Markup from 'preact-markup';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState, useContext } from 'preact/hooks';
import { get } from '../../utils/ajax';
import { SvgMap } from './Components/SvgMap';
import { AppContext, CMSContent } from '../../store/store';
import { updateClock } from '../../utils/updateClock';

interface LaDateTime {
  yr: string;
  mo: string;
  dt: string;
  hrs: string;
  min: string;
  sec: string;
  day: string;
}

export const Network: FunctionalComponent = () => {
  const { language: lang } = useContext(AppContext);
  const [pageContent, setpageContent] = useState<string | undefined>(undefined);
  const [laDate, setLaDate] = useState<LaDateTime | undefined>(undefined);
  const [tz, setTz] = useState<string | undefined>(undefined);

  const handleChemiqaMouseOver = () => {
    setTz('America/Toronto');
  };
  const handleFlotUsaMouseOver = () => {
    setTz('America/New_York');
  };
  const handleFlotMexMouseOver = () => {
    setTz('America/Mexico_City');
  };
  const handleFlotChilMouseOver = () => {
    setTz('America/Santiago');
  };

  /* *********************************************************************** */
  useEffect(() => {
    const tick = (tz: string | undefined) => {
      const currTime = updateClock(tz);
      setLaDate({
        yr: currTime[3],
        mo: currTime[1],
        dt: currTime[2],
        hrs: currTime[4],
        min: currTime[5],
        sec: currTime[6],
        day: currTime[0],
      });
    };
    tick(tz);

    const interval = window.setInterval(tick, 2000);
    // Clear the interval if/when the component is removed from the DOM
    return () => window.clearInterval(interval);
  }, [tz]);
  /* *********************************************************************** */

  /* *********************************************************************** */
  useEffect(() => {
    const companyTab = 'Network';
    const editCompany = 'flotUsa';
    get<CMSContent[]>(`/getPageHTML?tab=${companyTab}&company=${editCompany}&lang=${lang}`).then((networkContent) => {
      setpageContent(networkContent[0].html);
    });
  }, [lang]);
  /* *********************************************************************** */

  return (
    <div class={style.networkDiv}>
      <div class={style.pageData}>
        {pageContent !== undefined && (
          <Typography>
            <div class={style.topStuff}>
              <div class={style.pageContent}>
                <Markup className={style.pageText} markup={pageContent} trim={false} type='html' />
              </div>
              <div class={style.clockDiv}>
                <div class={style.timedate}>
                  <a>{laDate?.day}</a>
                  <br />
                  <a>{laDate?.mo}</a> <a>{laDate?.dt}</a>, <a>{laDate?.yr}</a>
                  <br />
                  <a>{laDate?.hrs}</a> : <a>{laDate?.min}</a> : <a>{laDate?.sec}</a>
                </div>
              </div>
            </div>
            <div class={style.svgMap}>
              <SvgMap
                inChemiqa={handleChemiqaMouseOver}
                inFlotUsa={handleFlotUsaMouseOver}
                inFlotMex={handleFlotMexMouseOver}
                inFlotChil={handleFlotChilMouseOver}
              />
            </div>
            <div class={style.locationsDiv}>
              <div class={style.anchorDiv}>
                <a href='https://flottec.mx' target='_blank' rel='noopener noreferrer'>
                  PRODUCTOS QUIMICOS FLOTTEC
                </a>
              </div>
              <div class={style.blurbText}>Located in San Luis Potosi, Mexico.</div>

              <div class={style.anchorDiv}>
                <a href='https://flottec.cl' target='_blank' rel='noopener noreferrer'>
                  FLOTTEC CHILI <span class={style.smaller}>SPA.</span>
                </a>
              </div>
              <div class={style.blurbText}>Located in Santiago, Chile.</div>

              <div class={style.anchorDiv}>
                <a href='https://flottec.ca' target='_blank' rel='noopener noreferrer'>
                  FLOTTEC CANADA
                </a>
              </div>
              <div class={style.blurbText}>Located in Sudbury, ON, Canada.</div>

              <div class={style.furtherInfo}>
                For further information on Flottec and our alliance partners products in your area, click on the links or point to a region on the map
                to be directed to your local representative. Feel free to contact Flottec directly for more assistance.
              </div>
            </div>
          </Typography>
        )}
      </div>
    </div>
  );
};
export default Network;
