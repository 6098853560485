/* eslint-disable react/display-name */
import { h, FunctionalComponent, Fragment } from 'preact';
import { AppContext } from '../../store/store';
import { useEffect, useState, useContext } from 'preact/hooks';
import { get } from '../../utils/ajax';
import style from './msdssds.scss';
/*  Called by index.tsx   */
// import { Subcategories } from '../Subcategories/Subcategories';
// *** CHEMIQA *** import Link from '@material-ui/core/Link';

interface MsdsSdsProps {
  onLogin(): void;
  onRegister(): void;
}
interface AllowProductDL {
  allowProductDL: 'always' | 'never' | 'login';
}
interface GroupedCategory {
  id: number;
  name_en: string;
  name: string;
  pdf: any; //string | string[]
  subcategories?: GroupedCategory[];
  products?: ProdPdfGroup[];
}
interface ProdPdfGroup extends GroupedCategory {
  cat_id: number;
  subcat_id: number;
  sort_order: number;
}
interface ProductMsds {
  groupedCategory: GroupedCategory[];
  allowProductDL: AllowProductDL;
}
export const MsdsSds: FunctionalComponent<MsdsSdsProps> = ({
  onLogin,
  onRegister,
}) => {
  const { userData, language: lang } = useContext(AppContext);
  // const [productMsds, setProductMsds] = useState<string | undefined>(undefined); //, setProductMsds
  const [productMsds, setProductMsds] = useState<GroupedCategory[] | undefined>(
    undefined
  ); //, setProductMsds
  const [allowProductDL, setAllowProductDL] = useState<any | undefined>(
    undefined
  );
  const [allowDLPublicDS, setAllowDLPublicDS] = useState<any | undefined>(
    undefined
  );
  const [mustEmailLink, setMustEmailLink] = useState<string | undefined>(
    undefined
  );
  const [supportEmail, setSupportEmail] = useState('');
  const [pleaseClickBlurb, setPleaseClickBlurb] = useState(
    'Please click below to request data sheet for '
  );
  const [sendEmailBlurb, setSendEmailBlurb] = useState('send email');
  // const [productPage, setProductPage] = useState('product page');
  // const [ddatasheet, setDdatasheet] = useState('datasheet');
  const [hiddenDivs, setHiddenDivs] = useState([1, 2, 3, 4]);
  const [hiddenSubDivs, setHiddenSubDivs] = useState([1, 2, 3, 4, 5, 6, 7]);

  const handleProdLiClick = (id: any) => {
    console.log(`Clicked product: [${id}]`);
  };
  const handleMustLogin = () => {
    onLogin();
  };
  const handleMustRegister = () => {
    onRegister();
  };
  const handleMustEmailLink = (id: any) => {
    setMustEmailLink(id);
  };
  const handleCatDivTitleClick = (divNum: number) => {
    if (hiddenDivs.includes(divNum)) {
      setHiddenDivs(hiddenDivs.filter((d) => d !== divNum));
    } else {
      setHiddenDivs([...hiddenDivs, divNum]);
    }
  };
  const handleSubCatDivTitleClick = (divNum: number) => {
    if (hiddenSubDivs.includes(divNum)) {
      setHiddenSubDivs(hiddenSubDivs.filter((d) => d !== divNum));
    } else {
      setHiddenSubDivs([...hiddenSubDivs, divNum]);
    }
  };

  /* *********************************************************************** */
  useEffect(() => {
    console.log('**********   /getAllProductMsds   **********');
    get<ProductMsds>(`/getAllProductMsds?lang=${lang}`).then((results) => {
      // console.log({ outObj, allowProductDL });
      setProductMsds(results.groupedCategory);
      setAllowProductDL(results.allowProductDL);
      console.log(
        'MSDSSDS.TSX - useEffect - results.allowProductDL: ',
        results.allowProductDL
      );
    });
    // const dsheet = lang==='en' ? 'datasheet' : 'hojas HDS';
    // setDdatasheet(lang === 'en' ? 'datasheet' : 'hojas HDS');
    // setProductPage(lang === 'en' ? 'product page' : 'producto');
    setSendEmailBlurb(
      lang === 'en' ? 'send email' : 'enviar correo electrónico'
    );
    setPleaseClickBlurb(
      lang === 'en'
        ? 'Please click below to request data sheet for '
        : 'Haga clic a continuación para solicitar la hoja de datos del '
    );
  }, [lang]);
  /* *********************************************************************** */
  useEffect(() => {
    get('/getScratchpadValue?key=allowDLPublicDS').then((apd) => {
      setAllowDLPublicDS(apd.value);
    });
    get('/getScratchpadValue?key=support_email').then((apd) => {
      setSupportEmail(apd.value);
    });
  }, []);
  /* *********************************************************************** */

  return (
    <Fragment>
      <div class={style.mdsSdsDiv}>
        <h3 style={'font-weight:400;'}>
          {lang === 'en'
            ? 'The following products have safety datasheets that are available:'
            : 'Los siguientes productos tienen HDSDM que están disponibles:'}
        </h3>
        <div class={style.allProdsDiv}>
          {/* {console.log({ allowProductDL })} */}
          {console.log({ productMsds })}
          {productMsds !== undefined &&
            productMsds.map((c) => {
              return (
                <div
                  class={`${style.catDiv} ${
                    hiddenDivs.includes(c.id) ? style.catDivHide : ''
                  }`}
                >
                  <div
                    class={style.catTitle}
                    onClick={() => handleCatDivTitleClick(c.id)}
                  >
                    <h3>{c.name}</h3>
                    <div
                      class={`${style.catArrow} ${
                        hiddenDivs.includes(c.id) ? '' : style.catArrowRotate
                      }`}
                    >
                      &#10093;
                    </div>
                  </div>
                  {c.subcategories === undefined && c.pdf !== '' && (
                    <div class={style.catPDF}>
                      {allowDLPublicDS !== undefined ? (
                        allowDLPublicDS === 'always' ? (
                          <a
                            href={`/public/${c?.pdf}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {c?.pdf}
                          </a>
                        ) : allowDLPublicDS === 'never' ? (
                          <div
                            class={style.mustEmailDiv}
                            onClick={() => handleMustEmailLink(c.name_en + '')}
                          >
                            <span class={style.lookLikeAnchor}>{c?.pdf}</span>
                          </div>
                        ) : userData?.authenticated ? (
                          <a
                            href={`/public/${c?.pdf}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {c?.pdf}
                          </a>
                        ) : (
                          <div class={style.prodWarn} onClick={handleMustLogin}>
                            <span class={style.lookLikeAnchor}>{c?.pdf}</span>
                          </div>
                        )
                      ) : (
                        <div
                          class={style.mustEmailDiv}
                          onClick={() => handleMustEmailLink(c.name_en + '')}
                        >
                          <span class={style.lookLikeAnchor}>{c?.pdf}</span>
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    class={`${style.prodsList} ${
                      c.id === 1 ? style.firstProdsList : ''
                    }`}
                  >
                    {console.log('allowDLPublicDS: ', allowDLPublicDS)}
                    {c.subcategories !== undefined
                      ? c.subcategories
                          .filter((f) => {
                            return f.name_en !== 'NA';
                          })
                          .map((s) => {
                            return (
                              <div
                                class={`${style.subcatDiv} ${
                                  hiddenSubDivs.includes(s.id)
                                    ? style.subcatDivHide
                                    : ''
                                }`}
                              >
                                <div
                                  class={style.subcatTitle}
                                  onClick={() =>
                                    handleSubCatDivTitleClick(s.id)
                                  }
                                >
                                  <h4>{s.name}</h4>
                                  <div
                                    class={`${style.subcatArrow} ${
                                      hiddenSubDivs.includes(s.id)
                                        ? ''
                                        : style.subcatArrowRotate
                                    }`}
                                  >
                                    &#10093;
                                  </div>
                                </div>
                                {c.subcategories !== undefined && s.pdf !== '' && (
                                  <div class={style.catPDF}>
                                    {s.pdf.map((p: string) => {
                                      return (
                                        <div class={style.pubPdfDiv}>
                                          {allowDLPublicDS !== undefined ? (
                                            allowDLPublicDS === 'always' ? (
                                              <a
                                                href={`/public/${p}`}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                              >
                                                {p}
                                              </a>
                                            ) : allowDLPublicDS === 'never' ? (
                                              <div
                                                class={style.mustEmailDiv}
                                                onClick={() =>
                                                  handleMustEmailLink(p + '')
                                                }
                                              >
                                                <span
                                                  class={style.lookLikeAnchor}
                                                >
                                                  {p}
                                                </span>
                                              </div>
                                            ) : userData?.authenticated ? (
                                              <a
                                                href={`/public/${p}`}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                              >
                                                {p}
                                              </a>
                                            ) : (
                                              <div
                                                class={style.prodWarn}
                                                onClick={handleMustLogin}
                                              >
                                                <span
                                                  class={style.lookLikeAnchor}
                                                >
                                                  {p}
                                                </span>
                                              </div>
                                            )
                                          ) : (
                                            <div
                                              class={style.mustEmailDiv}
                                              onClick={() =>
                                                handleMustEmailLink(p + '')
                                              }
                                            >
                                              <span
                                                class={style.lookLikeAnchor}
                                              >
                                                {p}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                                <div class={style.prodsList}>
                                  {allowProductDL.value !== undefined ? (
                                    allowProductDL.value === 'always' ? (
                                      s.products !== undefined &&
                                      s.products.map((p) => {
                                        return (
                                          p.pdf !== undefined &&
                                          p.pdf !== '' && (
                                            <div class={style.prodDiv}>
                                              <div
                                                class={style.prodDivA}
                                                onClick={() =>
                                                  handleProdLiClick(p.id)
                                                }
                                              >
                                                <a
                                                  href={`/files/${p?.pdf}`}
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                >
                                                  {p.name}
                                                </a>
                                              </div>
                                            </div>
                                          )
                                        );
                                      })
                                    ) : allowProductDL.value === 'never' ? (
                                      <div class={style.msgEmailOrLogin}>
                                        Please{' '}
                                        <div
                                          class={`${style.emailLink} ${style.inlineLinkDiv}`}
                                          onClick={() =>
                                            handleMustEmailLink(s.name_en + '')
                                          }
                                        >
                                          email
                                        </div>{' '}
                                        to see this information. To register for
                                        an account,{' '}
                                        <div
                                          class={`${style.registerLink} ${style.inlineLinkDiv}`}
                                          onClick={handleMustRegister}
                                        >
                                          click here
                                        </div>
                                      </div>
                                    ) : userData?.authenticated ? (
                                      s.products !== undefined &&
                                      s.products.map((p) => {
                                        return (
                                          p.pdf !== undefined &&
                                          p.pdf !== '' && (
                                            <div class={style.prodDiv}>
                                              <div
                                                class={style.prodDivA}
                                                onClick={() =>
                                                  handleProdLiClick(p.id)
                                                }
                                              >
                                                <a
                                                  href={`/files/${p?.pdf}`}
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                >
                                                  {p.name}
                                                </a>
                                              </div>
                                            </div>
                                          )
                                        );
                                      })
                                    ) : (
                                      <div class={style.msgEmailOrLogin}>
                                        <div
                                          class={`${style.emailLink} ${style.inlineLinkDiv}`}
                                          onClick={() =>
                                            handleMustEmailLink(s.name_en + '')
                                          }
                                        >
                                          Email
                                        </div>
                                        {' or '}
                                        <div
                                          class={`${style.loginLink} ${style.inlineLinkDiv}`}
                                          onClick={handleMustLogin}
                                        >
                                          Login
                                        </div>{' '}
                                        to see this information. To register for
                                        an account,{' '}
                                        <div
                                          class={`${style.registerLink} ${style.inlineLinkDiv}`}
                                          onClick={handleMustRegister}
                                        >
                                          click here
                                        </div>
                                      </div>
                                    )
                                  ) : (
                                    <div class={style.whatever}>
                                      &nbsp;&nbsp;&nbsp;
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })
                      : allowProductDL.value !== undefined &&
                        (allowProductDL.value === 'always' ? (
                          c.products !== undefined &&
                          c.products
                            .filter((f) => {
                              return f.pdf !== null;
                            })
                            .map((p) => {
                              return (
                                <div class={style.prodDiv}>
                                  <div
                                    class={style.prodDivA}
                                    onClick={() => handleProdLiClick(p.id)}
                                  >
                                    <a
                                      href={`/files/${p?.pdf}`}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                    >
                                      {p.name}
                                    </a>
                                  </div>
                                </div>
                              );
                            })
                        ) : allowProductDL.value === 'never' ? (
                          <div class={style.msgEmailOrLogin}>
                            Please{' '}
                            <div
                              class={`${style.emailLink} ${style.inlineLinkDiv}`}
                              onClick={() =>
                                handleMustEmailLink(c.name_en + '')
                              }
                            >
                              email
                            </div>{' '}
                            to see this information. To register for an account,{' '}
                            <div
                              class={`${style.registerLink} ${style.inlineLinkDiv}`}
                              onClick={handleMustRegister}
                            >
                              click here
                            </div>
                          </div>
                        ) : userData?.authenticated ? (
                          c.products !== undefined &&
                          c.products
                            .filter((f) => {
                              return f.pdf !== null;
                            })
                            .map((p) => {
                              return (
                                <div class={style.prodDiv}>
                                  <div
                                    class={style.prodDivA}
                                    onClick={() => handleProdLiClick(p.id)}
                                  >
                                    <a
                                      href={`/files/${p?.pdf}`}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                    >
                                      {p.name}
                                    </a>
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          <div class={style.msgEmailOrLogin}>
                            <div
                              class={`${style.emailLink} ${style.inlineLinkDiv}`}
                              onClick={() =>
                                handleMustEmailLink(c.name_en + '')
                              }
                            >
                              Email
                            </div>
                            {' or '}
                            <div
                              class={`${style.loginLink} ${style.inlineLinkDiv}`}
                              onClick={handleMustLogin}
                            >
                              Login
                            </div>{' '}
                            to see this information. To register for an account,{' '}
                            <div
                              class={`${style.registerLink} ${style.inlineLinkDiv}`}
                              onClick={handleMustRegister}
                            >
                              click here
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {mustEmailLink !== undefined && (
        <div class={style.mustEmailDlg}>
          <div class={style.mustEmailInner}>
            <div
              class={style.meCloseX}
              onClick={() => setMustEmailLink(undefined)}
            >
              {' x '}
            </div>
            <div class={style.meBody}>
              {`${pleaseClickBlurb} ${mustEmailLink}`}
              <div class={style.meEmail}>
                <a
                  href={`mailto:${supportEmail}?subject=MSDS/SDS Request for datasheet ${mustEmailLink} [${lang}]&body=I am writing to request access to the ${
                    lang === 'en' ? '' : '[ Spanish ] '
                  }MSDS/SDS datasheet for [ ${mustEmailLink} ].%0D%0A%0D%0ATo verify my request, I can be contacted at:%0D%0A%0D%0A[ Telephone ]%0D%0A[ Email ]%0D%0A[ Twitter ]%0D%0A%0D%0AThank you,%0D%0A[ Full Name ]%0D%0A[ Job Title ]%0D%0A[ Company ]`}
                >
                  {sendEmailBlurb}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default MsdsSds;
