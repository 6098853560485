.wrapperDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-family: 'europa', 'Segoe UI light', 'Calibri', 'Helvetica', 'Arial',
    sans-serif !important;
  font-weight: 200;
  color: #ccc;
  overflow-x: hidden;
  background: #111;
  h1,
  h2,
  h3 {
    font-family: 'europa', 'Segoe UI light', 'Calibri', 'Helvetica', 'Arial',
      sans-serif !important;
    font-weight: 200;
  }
  h1 {
    color: #333;
    font-size: calc(8px + 1.4vw); //2rem;
  }
  h2 {
    color: #888;
    font-size: calc(8px + 1.35vw); //1.8rem;
  }
  h3 {
    color: #333;
    font-size: calc(8px + 1.3vw); //1.6rem;
  }
  p {
    font-size: calc(10px + 1vw);
    font-family: 'europa', 'Segoe UI light', 'Calibri', 'Helvetica', 'Arial',
      sans-serif !important;
    font-weight: 200;
  }
}
