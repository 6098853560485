.productsDiv {
  //NOT AT ALL THE SAME AS FLOTTEC - TOTALLY DIFFERENT *PAGE*
  font-family: 'europa', serif;
  font-size: 7rem;
  color: #333;
  .s1TopDiv {
    position: relative;
    width: 100%;
    height: 30vh;
    .s1TopBannerPhoto {
      width: 100%;
      height: 100%;
      background: url(../../../public/img/pallets_banner.jpg) no-repeat center
        center/cover scroll;
    }
    .s1TopOverlay {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
    .s1TopCqLogo {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 99vw;
      img {
        width: 400px;
      }
      margin-top: 2vh;
    }
  }
  .s2Overview {
    background: white;
    .s2OverviewInner {
      width: 68%;
      margin: 0 auto;
      h3 {
        margin: 0;
        padding: 6vh 0 2vh;
        color: #333;
        text-transform: uppercase;
        font-size: calc(8px + 1.5vw);
      }
      p {
        margin: 0;
        padding: 0;
        font-size: calc(10px + 1vw);
        font-weight: 400;
      }
      .s2List {
        max-width: calc(300px + 10vw);
        font-size: calc(10px + 1vw);
        ul {
          li {
            cursor: pointer;
            &:before {
              content: '\f0da';
              font-family: 'Font Awesome\ 5 Free';
              font-weight: 900;
              color: #222;
              position: absolute;
              left: -1em;
            }
            a {
              &:hover {
                color: #777;
              }
            }
          }
        }
      }
      .btnReqQuoteDiv {
        xxxmax-width: 40%;
        padding: 0 0 10vh;
        a {
          button {
            span {
              font-size: calc(12px + 1vw);
              line-height: calc(14px + 1vw);
            }
          }
        }
      }
    }
  }
  .s3Collectors {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: fit-content;
    color: #222;
    background: #eaeaea;
    display: flex;
    .s3SideLeft {
      flex: 1;
      flex-basis: 50%;
      height: 800px;
      background-image: url(../../../public/img/float_cell03.webp);
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: scroll;
    }
    .s3SideRight {
      flex: 1;
      flex-basis: 50%;
      padding: 2vh 7vw;
      h3 {
        margin: 0;
        padding: 6vh 0 2vh;
        color: #333;
        text-transform: uppercase;
        font-family: 'europa', serif;
        font-size: calc(8px + 1.5vw);

        line-height: 5rem;
        cursor: pointer;
      }
      p {
        margin: 0;
        padding: 0;
        font-size: calc(10px + 1vw);
        font-weight: 200;
      }
      & > p > div > p {
        margin: 2.5vh 0;
      }
      .s3List {
        font-size: calc(10px + 1vw);
        ul {
          li {
            cursor: pointer;
            &:before {
              content: '\f0da';
              font-family: 'Font Awesome\ 5 Free';
              font-weight: 900;
              color: #222;
              position: absolute;
              left: -1em;
            }
            a {
              &:hover {
                color: #777;
              }
            }
          }
        }
      }
    }
  }
  .s4Frothers {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: fit-content;
    color: #222;
    background: white;
    display: flex;
    .s4SideRight {
      flex: 1;
      flex-basis: 50%;
      height: 900px;
      background-image: url(../../../public/img/float_cell01_crop.webp);
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: scroll;
    }
    .s4SideLeft {
      flex: 1;
      flex-basis: 50%;
      padding: 2vh 7vw;
      h3 {
        margin: 0;
        padding: 6vh 0 2vh;
        color: #333;
        text-transform: uppercase;
        font-family: 'europa', serif;
        font-size: calc(8px + 1.5vw);

        line-height: 5rem;
        cursor: pointer;
      }
      p {
        margin: 0;
        padding: 0;
        font-size: calc(10px + 1vw);
        font-weight: 200;
      }
      & > p > div > p {
        margin: 2.5vh 0;
      }
      .s4List {
        font-size: calc(10px + 1vw);
        ul {
          li {
            &:before {
              content: '\f0da';
              font-family: 'Font Awesome\ 5 Free';
              font-weight: 900;
              color: #222;
              position: absolute;
              left: -1em;
            }
            a {
              &:hover {
                color: #777;
              }
            }
          }
        }
      }
    }
  }
  .s5ProcessingAids {
    padding: 10vh 15vw;
    background: #eaeaea;
    h3 {
      margin: 0;
      padding: 6vh 0 2vh;
      color: #333;
      text-transform: uppercase;
      font-family: 'europa', serif;
      font-size: calc(8px + 1.5vw);

      line-height: 5rem;
      cursor: pointer;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: calc(10px + 1vw);
      font-weight: 200;
    }
    & > p > div > p {
      margin: 2.5vh 0;
    }
    .s5List {
      font-size: calc(10px + 1vw);
      ul {
        li {
          &:before {
            content: '\f0da';
            font-family: 'Font Awesome\ 5 Free';
            font-weight: 900;
            color: #222;
            position: absolute;
            left: -1em;
          }
          a {
            &:hover {
              color: #777;
            }
          }
        }
      }
    }
  }
  .s6Surfactants {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: fit-content;
    color: #222;
    background: white;
    display: flex;
    .s6SideRight {
      flex: 1;
      flex-basis: 50%;
      height: 700px;
      background-image: url(../../../public/img/concentrator_crop.webp);
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: scroll;
    }
    .s6SideLeft {
      flex: 1;
      flex-basis: 50%;
      padding: 2vh 7vw;
      h3 {
        margin: 0;
        padding: 6vh 0 2vh;
        color: #333;
        text-transform: uppercase;
        font-family: 'europa', serif;
        font-size: calc(8px + 1.5vw);

        line-height: 2.5rem;
        cursor: pointer;
      }
      p {
        margin: 0;
        padding: 0;
        font-size: calc(10px + 1vw);
        font-weight: 200;
      }
      & > p > div > p {
        margin: 2.5vh 0;
      }
    }
  }
  .s7Depressants {
    padding: 10vh 15vw;
    background: #eaeaea;
    h3 {
      margin: 0;
      padding: 6vh 0 2vh;
      color: #333;
      text-transform: uppercase;
      font-family: 'europa', serif;
      font-size: calc(8px + 1.5vw);

      line-height: 5rem;
      cursor: pointer;
    }
    p {
      padding: 0;
      margin: 2.5vh 0;
      font-size: calc(10px + 1vw);
      font-weight: 200;
    }
  }
  .s8AdvancedProducts {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: fit-content;
    color: #222;
    background: white;
    display: flex;
    .s8SideLeft {
      flex: 1;
      flex-basis: 50%;
      min-height: 650px;
      background-image: url(../../../public/img/science_crop.webp);
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: scroll;
    }
    .s8SideRight {
      flex: 1;
      flex-basis: 50%;
      padding: 2vh 7vw;
      h3 {
        margin: 0;
        padding: 6vh 0 2vh;
        color: #333;
        text-transform: uppercase;
        font-family: 'europa', serif;
        font-size: calc(8px + 1.5vw);
      }
      p {
        padding: 0;
        margin: 2.5vh 0;
        font-size: calc(10px + 1vw);
        font-weight: 200;
      }
    }
  }
}
.aLink {
  text-decoration: none;
  color: green;
  button {
    span {
      font-size: calc(12px + 1vw);
      line-height: calc(14px + 1vw);
    }
  }
}

/* - - - - - - - - - - */

.btnChemiqa {
  display: flex;
  justify-content: center;
  background-color: #2fc4dc;
  color: #fff;
  border-radius: 0;
  padding: 0.75em 2.75em;
  font-size: 1.5rem;
  xxxfont-weight: 600;
  transition: all 0.3s;
  font-family: 'europa', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  &:hover {
    background-color: #2fc4dcbd;
  }
}
.ql-align-center {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5 {
  text-shadow: grey;
}
a {
  color: #333;
  text-decoration: none;
  &:visited {
    color: #333;
  }
}
.requestQuoteDiv {
  background: url(../../../public/img/request_quote.jpg) no-repeat center center
    fixed;
  background-size: cover;
}
.requestQuoteDiv .reqQuoteInner {
  text-align: center;
  padding: 15vh 0;
  text-shadow: 1px 1px 2px rgba(35, 35, 35, 1);
  background: rgba(0, 0, 0, 0.8);
}
.requestQuoteDiv .reqQuoteInner h5 {
  padding: 2vh 0 5vh;
  color: white;
}
.requestQuoteDiv .btnLetsTalkDiv {
  display: flex;
  justify-content: center;
}
