/**
 * Post function to POST JSON data to the server.
 * Data is stringified before being posted.
 * Returns promise with the returned JSON object
 * @export
 * @param {string} path
 * @param {any} data
 * @returns {Promise<any>}
 */
export async function post<Return = any>(
  path: string,
  data: any
): Promise<Return> {
  return fetch(path, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'same-origin',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
      dataType: 'json',
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

/**
 * Get function for server GET requests.
 * Returns promise with the returned JSON object
 * @export
 * @param {string} path
 * @returns {Promise<any>}
 */
export async function get<Return = any>(path: string): Promise<Return> {
  return fetch(path, {
    method: 'GET',
    mode: 'same-origin',
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
      dataType: 'json',
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
}

/**
 * Creates a get url with input params
 * @export
 * @param {string} url base path url (ex. /api/getData)
 * @param {{[key: string]: string | number | (string | number)[]}} params {y: 'data'}
 * @returns {string} /api/getData?y=data
 */
export function URLWithParams(
  url: string,
  params: { [key: string]: string | number | (string | number)[] | undefined }
): string {
  url += '?';
  const paramKeys: string[] = Object.keys(params);
  return paramKeys.reduce((acc, key, currIdx): string => {
    if (params[key] instanceof Array) {
      for (const value of params[key] as string[]) {
        acc += `&${key}=${encodeURIComponent(value as string)}`;
      }
    } else {
      if (currIdx !== 0) acc += '&';
      if (params[key] !== undefined) {
        acc += `${key}=${encodeURIComponent(params[key] as string)}`;
      }
    }
    return acc;
  }, url);
}
