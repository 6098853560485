.rdDiv {
  padding: 5vh 12vw;
  color: #444 !important;
  background: rgb(230, 230, 230);
  .pageContentFromDB {
    width: 100%;
    height: fit-content;
  }
  .rdPresentationsFromDB {
    width: 100%;
    height: fit-content;
    .singlePres {
      display: grid;
      grid-template-columns: 15% 85%;
      grid-template-areas: 'img data';
      border: 1px solid rgba(250, 250, 250, 0.5);
      padding: 1vh 2vw;
    }
    .presImg {
      grid-area: img;
      display: flex;
      justify-content: center;
    }
    .presData {
      grid-area: data;
      .presTitle {
        font-size: 1.4rem;
        font-weight: bold;
      }
      .presBlurb {
        font-size: 1.1rem;
        font-style: italic;
      }
    }
  }
}
.pdfAnchor {
  a {
    text-decoration: none;
    font-size: 1.2rem;
    color: #678cbf;
  }
  .pdfRequestOnly {
    font-size: 1.1em;
    a {
      font-size: 1.1em;
    }
  }
}
